
import { Options, Vue } from "vue-class-component";
import AccPageHeader from "../components/account-page-header-component.vue";
import { UserFilled } from "@element-plus/icons";
import AccPageRightNav from "../components/account-pages-right-nav.vue";

@Options({
  name: "AccountProfilePage",
  components: { AccPageHeader, UserFilled, AccPageRightNav },
})
export default class extends Vue {
  /**
   * So the user can be able to edit
   */
  isSelf = true;

  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }
  get loading() {
    return this.$store.state.account === undefined;
  }

  get isTalent() {
    return this.user?.type == "talent";
  }
}
