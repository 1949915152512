
import img from "@/assets/illustrations/talent/no-talents.svg";
import { ErrorUtil } from "@/shared";
import appBiddingProfileModal from "@/shared/components/business-talent/bidding-module/bidding-profile-modal.vue";
import { Bidding, Opportunity, User } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";

interface EquityResponse {
  status: "success";
  equity: number;
  cash: number;
}
@Options({
  data() {
    return {
      compensationPrediction: undefined,
    };
  },
  components: {
    "app-bidding-profile-modal": appBiddingProfileModal,
  },
})
export default class extends Vue {
  img = img;

  @Prop({ type: Object })
  opportunity!: Opportunity;

  @Prop({ type: Object })
  talent!: User;
  /**
   * Variables for the component
   */
  loading = true;
  loadingCompensation = true;

  isModalOpen = false;
  showClose = true;

  sentBidding = false;

  @Prop({ type: Boolean })
  hasAlreadyBidded!: boolean;

  equityDistribution = 50;

  marks = {
    0: {
      style: { color: "green" },
      label: "Equity",
    },
    50: "Evenly Distribute",
    100: {
      style: { color: "orange" },
      label: "Cash",
    },
  };

  /**
   * @call this to check if it should be open
   * because there are no opportunities
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
  }

  /**
   * @
   */
  fetchEquityPrediction() {
    this.$api.main
      .get<EquityResponse>(
        `/v1/opportunities/${this.opportunity._id}/equity/${this.talent._id}`
      )
      .then((resp) => {
        (this.$data as any).compensationPrediction = resp.data;
      })
      .finally(() => {
        this.loadingCompensation = false;
        this.loading = false;
      });
  }
  /**
   * @Watch for open trigger
   * if triggered the talent should be able to close
   */
  @Watch("$store.state.modals.isBiddingOpen")
  watchForOPen() {
    this.isModalOpen = this.$store.state.modals.isBiddingOpen;
    if (this.isModalOpen) {
      this.fetchEquityPrediction();
    }
  }

  /**
   *
   */
  @Watch("isModalOpen")
  watchForModalOpenChange() {
    console.log("Opened the modal");
  }

  form = {
    talent: undefined,
    opportunity: undefined,
    compensationTypes: [] as Array<"equity" | "cash">,
    equity: 0,
    cash: 0,
    currency: undefined,
    expireAt: undefined,
    description: undefined,
  };

  /**
   * @param score
   */
  submit() {
    this.loading = true;
    this.$api.main
      .post<Bidding>("/v1/biddings", {
        ...this.form,
        ...{
          talent: this.talent._id,
          opportunity: this.opportunity._id,
        },
      })
      .then((resp) => {
        this.finalizePosting(resp.data);
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  change() {
    this.form.equity = 0;
    this.form.cash = 0;
    // Id no cash chosen (Equity is 100%)
    if (!this.form.compensationTypes.includes("cash")) {
      this.equityDistribution = 100;
    }
    // If doesn't have equity (cash is 100, so equity is 0)
    if (!this.form.compensationTypes.includes("equity")) {
      this.equityDistribution = 0;
    }
  }

  /**
   * @triggers updates after creating a new Bidding profile
   */
  finalizePosting(opp: Bidding) {
    this.sentBidding = true;
    this.$message({ type: "success", message: "Successfully sent the offer." });
    setTimeout(() => {
      this.closeModal();
      this.$router.push(`/biddings`);
    }, 4000);
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    this.$store.commit("modals/bidding", false); // This is enough itself
    this.isModalOpen = false;
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
  }

  /**
   *
   */
  rules = {
    expireAt: [
      {
        type: "date",
        required: true,
        message: "End Date is required!",
        trigger: "blur",
      },
    ],
    compensationType: [
      {
        required: true,
        message: "This is required!",
        trigger: "blur",
      },
    ],
    cash: [
      {
        required: true,
        message: "This is required!",
        trigger: "blur",
      },
    ],
    description: [],
  };
  get disabledSave() {
    return false;
  }

  get hasAllTypes() {
    return this.form.compensationTypes.length == 2;
  }

  get equityPredicted() {
    return (
      (((this as any).compensationPrediction?.equity || 0) *
        this.equityDistribution) /
      100
    );
  }
  get cashPredicted() {
    return (
      (((this as any).compensationPrediction?.cash || 0) *
        (100 - this.equityDistribution)) /
      100
    );
  }
}
