
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

interface PasswordStrength {
  id: number;
  value: "Too weak" | "Weak" | "Medium" | "Strong";
  contains: ["lowercase", "uppercase", "symbol", "number"];
  length: number;
}
@Options({})
export default class extends Vue {
  @Prop()
  password!: string;

  result: PasswordStrength = {} as any;

  get isTooWeak() {
    return this.result.value === "Too weak";
  }

  get isWeak() {
    return this.result.value == "Weak";
  }

  get isMedium() {
    return this.result.value == "Medium";
  }

  get isStrong() {
    return this.result.value == "Strong";
  }

  @Watch("password")
  passwordChanged() {
    if (this.password.length > 0) {
      this.$api.main
        .post<PasswordStrength>("/v1/auth/password-check", {
          password: this.password,
        })
        .then((resp) => (this.result = resp.data));
    }
  }
}
