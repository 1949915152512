<template>
  <div>
    <app-user-section>
      <app-section-divider content="Change Password" />
      <!--  -->
      <div txt-center>
        <!--  -->
        <div txt-center>
          <el-image lazy :style="`width: 230px;`" :src="img"></el-image>
        </div>
        <!--  -->
        <p>
          Want to change your password?
          <br />
          Press the the button below, and we will <br />
          send the instructions in your email inbox
        </p>
        <el-button
          is-all-borderless
          plain
          type="primary"
          @click="sendResetIndstructions"
        >
          Send Reset Instructions
        </el-button>
      </div>
    </app-user-section>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import img from "@/assets/illustrations/forgot-password.svg";
import { ErrorUtil } from "@/shared/classes";

@Options({
  components: {},
  methods: {},
  data() {
    return {};
  },
})
export default class extends Vue {
  img = img;
  /**
   * @sends reset instructions
   */
  sendResetIndstructions() {
    this.$api.main
      .get("/v1/account/change-password")
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Sent password reset email.",
        });
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: "Failed to send password reset email.",
        });
      });
  }
}
</script>

<style lang="scss"></style>
