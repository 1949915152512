
import { Options, Vue } from "vue-class-component";
import StartSurvey from "@/assets/illustrations/survey/start-survey.svg";

@Options({
  name: "SurverResults",
  components: {},
  methods: {},
  data() {
    return {
      StartSurvey,
    };
  },
})
export default class extends Vue {
  get user() {
    return this.$store.state.account.user;
  }
}
