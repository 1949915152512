
import { ErrorUtil } from "@/shared/classes";
import { University } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import img from "@/assets/illustrations/user/education.svg";

@Options({
  name: "Add-Uni",
  emits: ["created"],
})
export default class extends Vue {
  img = img;
  isVisible = false;
  loading = false;

  form = {
    name: undefined as any,
    region: undefined as any,
    country: undefined as any,
  };

  /**
   * Add university
   *
   */

  addUni() {
    this.loading = true;
    this.$api.main
      .post<University>("/v1/universities", this.form)
      .then((resp) => {
        this.$emit("created", resp.data);
        this.isVisible = false;
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
