<template>
  <div :class="padded ? 'isPadded' : ''" class="App-Top-Nav-H">
    <div class="App-Top-Nav-Back"></div>
    <div class="App-Top-Nav">
      <!--  -->
      <!-- Left -->
      <div class="App-Top-Nav-Left" v-if="showLeft">
        <slot name="left" />
      </div>
      <!--  -->
      <!-- txt-center -->
      <div class="App-Top-Nav-Center" v-if="showMiddle">
        <slot name="center" />
      </div>
      <!--  -->
      <!-- Right -->
      <div class="App-Top-Nav-Right" v-if="showRight">
        <slot name="right" />
      </div>
      <!--  -->
    </div>
    <div class="App-Top-Nav-B">
      <slot name="bottom" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    padded: { type: Boolean, default: true },
    showLeft: { type: Boolean, default: true },
    showMiddle: { type: Boolean, default: true },
    showRight: { type: Boolean, default: true },
  },
  components: {},
})
export default class extends Vue {}
</script>

<style lang="scss">
$border-radius: map.get($--border-radius, "base");
$border-radius-s: map.get($--border-radius, "small");
$border-radius-t: $border-radius $border-radius $border-radius-s
  $border-radius-s !important;

//
// Small devices only
@media (max-width: 769px) {
  // Top Nav
  .App-Top-Nav-H {
    box-shadow: 0px -2px 9px 4px #00000042;
  }
}
//
.App-Top-Nav-H {
  background: white;
  border-radius: $border-radius-t;
  position: relative;
  //
  &.isPadded {
    margin-bottom: 8px;
  }
  // App-Top-Nav-Bottom
  .App-Top-Nav-B {
    > *[size-4] {
      > * {
        min-width: calc(100% / 4 - 46px);
      }
    }
    > *[size-2] {
      > * {
        min-width: calc(100% / 2 - 46px);
      }
    }
    > *[size-3] {
      > * {
        min-width: calc(100% / 3 - 46px);
      }
    }
    //
    > * {
      width: 100%;
      justify-content: space-between;
      > * {
        // flex-grow: 1;
        text-align: center;
        font-weight: 500;
        color: var(--el-text-color-primary);
        // font-size: map.get($--font-size, "medium");
      }
    }
    .el-menu-item i {
      color: var(--el-text-color-primary);
      margin-bottom: 6px;
      font-size: 20px;
      margin-right: 5px;
    }
  }
  // Back
  .App-Top-Nav-Back {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: $border-radius-t;
    background: var(--el-background-color-base);
    position: absolute;
    opacity: 0.5;
  }
  // Main Nav
  .App-Top-Nav {
    min-height: map.get($--input-height, "default") * 1.4;
    line-height: map.get($--input-height, "default") * 1.4;
    border-radius: $border-radius-t;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: math.div(map.get($--input-height, "default"), 8)
      math.div(map.get($--input-height, "default"), 4);
  }

  // Left side
  .App-Top-Nav-Left {
    width: 100px;
    // icon
    [class^="el-icon-"],
    [class*=" el-icon-"] {
      font-size: 20px;
      font-weight: bold;
      color: black;
    }
  }
  // txt-center side
  .App-Top-Nav-Center {
    //
    // Title
    [nav-title],
    [nav-title-med] {
      text-align: center;
      font-weight: bolder;
      display: flex;
      align-items: center;
    }
    [nav-title] {
      font-size: map.get($--font-size, "large") + 2px;
    }
    [nav-title-med] {
      font-size: map.get($--font-size, "medium");
    }
  }
  .App-Top-Nav-Right {
    width: 100px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
