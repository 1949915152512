
import { Options, Vue } from "vue-class-component";
import SurveyModal from "@/shared/components/survey-module/survey.modal.vue";
import CreateStartupProfileModal from "./views/businesses-page/components/create-business-modal.vue";
import CreateOpportunityModal from "./views/opportunities-page/components/opportunity-create/opportunity-create-component.vue";
import StartupNextModal from "./views/businesses-page/modals/business-next-modal.vue";
import StartupCofoundersModal from "./views/businesses-page/modals/business-cofounders-modal.vue";
import { WindowUtil } from "@/shared/utils";

@Options({
  name: "BusinessApp",
  title: "Equity4Talent Business",
  components: {
    SurveyModal,
    CreateStartupProfileModal,
    CreateOpportunityModal,
    StartupNextModal,
    StartupCofoundersModal,
  },
})
export default class extends Vue {
  /**
   * @business
   */
  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
  get fetchedBusinesses() {
    return this.$store.state.account.fetchedBusinesses;
  }
  /**
   * Modals check
   */
  openedEmailActivate = false;
  openedSurvey = false;
  openedStartup = false;
  openedStartupFuture = false;
  openedOnboardInfo = false;
  openedMainSkills = false;
  openedEducation = false;
  openedStandardTest = false;
  openedAvatar = false;
  openedStartupCofounders = false;
  openedOpportunity = false;

  /**
   *
   */
  checkedEmailActivate = false;
  checkedSurvey = false;
  checkedStartup = false;
  checkedStartupFuture = false;
  checkedOnboardInfo = false;
  checkedMainSkills = false;
  checkedEducation = false;
  checkedStandardTest = false;
  checkedAvatar = false;
  checkedStartupCofounders = false;
  checkedOpportunity = false;

  /**
   * The way filling in the forms follow each other
   * Indication of order
   */
  stages = [
    "EmailActivate",
    "Startup",
    "StartupFuture",
    "Survey",
    "OnboardInfo",
    "MainSkills",
    "Education",
    "StandardTest",
    "Avatar",
    "StartupCofounders",
    "Opportunity",
  ];

  /**
   * returns show component if
   * 1. All previous stages open must evaluate to false
   * 2. And one previous checked is true
   */
  vIf(stage) {
    const stageIndex = this.stages.findIndex((st) => st === stage);
    // console.log(`Found the index of stage ${stage} = ${stageIndex}`);
    const previousOpens = this.stages
      .slice(0, stageIndex)
      .map((mapStage) => `opened${mapStage}`);
    const previousCheck = `checked${this.stages[stageIndex - 1]}`;
    //
    console.log(`Previous Opens ${stage} ${previousOpens}`);
    console.log(`Previous Check for ${stage} ${this[previousCheck]}`);
    ///
    return (
      this[previousCheck] === true && // Previous check evaluates to true
      previousOpens.every((op) => this[op] === false) // All opens evaluate to false
    );
  }

  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }

  /**
   * @toogles values to change
   */
  toogleVariable(vr: string, value: boolean) {
    console.log("vr=", vr, " value=", value);
    this[vr] = value;
  }

  /**
   *
   */
  mounted() {
    WindowUtil.listenToChange(document, this.$store);
  }
}
