<template>
  <el-dropdown-menu>
    <!-- Business -->
    <router-link v-if="user.type === 'founder'" to="/account/startups">
      <el-dropdown-item icon="el-icon-office-building">
        <el-skeleton :loading="!business" animated>
          <template #template>
            <el-skeleton-item variant="h3" style="width: 140px" />
          </template>
          <template #default>
            {{ $filters.capitalize(business.name) }}
          </template>
        </el-skeleton>
      </el-dropdown-item>
    </router-link>
    <!-- Account -->
    <router-link to="/account/profile">
      <el-dropdown-item :divided="user.type === 'founder'" icon="el-icon-user">
        Account Profile
      </el-dropdown-item>
    </router-link>
    <!--  -->
    <router-link to="/account/settings">
      <el-dropdown-item icon="el-icon-setting">
        Account Settings
      </el-dropdown-item>
    </router-link>
    <!--  -->
    <div @click="logout">
      <el-dropdown-item divided icon="el-icon-right"> Logout </el-dropdown-item>
    </div>
  </el-dropdown-menu>
</template>

<script lang="ts">
import { AuthUtil } from "@/@auth/classes";
import { User } from "@/shared/interfaces";
import { ElLoading } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Options({})
export default class extends Vue {
  /**
   * Can still be gotten from the store.state.account.user
   */
  @Prop()
  user!: User;

  /**
   * @handles the logout process
   */
  logout() {
    console.log("Clicked the logout");
    const loading = ElLoading.service({ lock: true });
    AuthUtil.logout().finally(() => {
      setTimeout(() => {
        loading.close();
      }, 1000);
    });
  }
  /**
   * @business
   */
  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
}
</script>

<style lang="scss"></style>
