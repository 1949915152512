
import { Business } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import appStartupUser from "./startup-user-component.vue";

@Options({
  components: {
    appStartupUser,
  },
  methods: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  business!: Business;
}
