
import { Options, Vue } from "vue-class-component";
import { Checked, Star } from "@element-plus/icons";

import biddingCreateModal from "../biddings-page/components/bidding-create-modal.vue";
import { Bidding, ErrorUtil, User } from "@/shared";
import { ApiResourceResult } from "@/shared/vuex";

@Options({
  components: {
    Checked,
    Star,
    biddingCreateModal,
  },
  data() {
    return {
      talent: undefined,
    };
  },
})
export default class extends Vue {
  isSelf = false;
  loading = true;

  hasAlreadyBidded: boolean = "" as any;

  mounted() {
    this.userExists();
  }
  /**
   * checks if @current browsed user exists
   */
  async userExists() {
    if ((await this.getTalent()) === undefined) {
      this.$router.push(`/opportunities/${this.opportunityParam}`);
    } else {
      this.checkIfNotBiddedBefore();
    }
  }

  /**
   * @checks if this talents has not been bidded before
   * on this same opportunity
   */
  checkIfNotBiddedBefore() {
    this.loading = true;
    this.$api.main
      .get<ApiResourceResult<Bidding>>(
        `/v1/biddings?talent=${(this.$data as any).talent._id}&opportunity=${
          this.opportunity._id
        }`
      )
      .then((resp) => {
        this.hasAlreadyBidded =
          (resp.data.resources?.length || 0) > 0 ? true : false;
      })
      .finally(() => (this.loading = false));
  }

  /**
   * @returns talent id in the params
   */
  get talentParam() {
    return this.$route.params.talent;
  }
  /**
   * @returns opportunity id in the params
   */
  get opportunityParam() {
    return this.$route.params.opportunity;
  }
  /**
   * @try and get the talent
   */
  getTalent() {
    return this.$api.main
      .get<User>(`/v1/talents/${this.talentParam}/${this.opportunityParam}`)
      .then((resp) => {
        (this.$data as any).talent = resp.data;
        return resp.data;
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
        this.decideErrorRoute();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * @decide error routing
   */
  decideErrorRoute() {
    if (
      this.opportunityParam === "undefined" ||
      this.opportunityParam === undefined
    ) {
      this.$router.push(`/opportunities`);
    } else {
      this.$router.push(`/opportunities/${this.opportunityParam}`);
    }
  }

  /**
   * @current matched opportunity if any user
   */
  get opportunity() {
    return (this.$data as any).talent?.opportunity;
  }

  /**
   * @start bidding
   */
  startBidding() {
    this.$store.commit("modals/bidding", true);
  }
}
