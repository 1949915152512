<template>
  <div>
    <el-dialog
      custom-class="App-Create-Opportunity-Modal App-Unpadded"
      :model-value="isModalOpen"
      width="550px"
      @open="opened"
      @close="closeModal"
      :title="title"
      center
      :show-close="showClose"
      :close-on-click-modal="showClose"
      :close-on-press-escape="showClose"
      :append-to-body="true"
      :lock-scroll="true"
      :open-delay="200"
      :close-delay="200"
    >
      <!--  -->
      <el-scrollbar :always="true" max-height="500px">
        <div v-loading="loading">
          <div v-if="isShowingCongrats">
            <app-opportunity-congrats @submit="isShowingCongrats = false" />
          </div>
          <div v-if="!isShowingCongrats">
            <el-form
              v-if="skills !== undefined"
              @submit.prevent
              class="App-Styled-Form"
              :label-position="'top'"
              label-width="100px"
              :model="form"
              style="margin-top: 12px"
              :rules="rules"
            >
              <!--  -->
              <!-- Show business name -->
              <div v-if="business">
                <h3 txt-center f-w-700 m-tb-0>Creating for:</h3>
                <p txt-center m-t-0>
                  {{ business.name }}
                </p>
              </div>
              <!--  -->
              <!-- Skill -->
              <app-skill-input v-model="form.skill" />

              <!--  -->
              <!-- Number of talents -->
              <el-form-item prop="talents" label="Number of talents">
                <el-input-number
                  w-100
                  :controls="false"
                  placeholder="EX: 2"
                  v-model="form.talents"
                />
              </el-form-item>
              <!--  -->
              <!-- out come -->
              <el-form-item
                prop="outcome"
                label="What is the opportunity outcome?"
              >
                <el-input
                  placeholder="Build an MVP web app"
                  v-model="form.outcome"
                />
              </el-form-item>
              <!--  -->
              <el-divider />
              <!--  -->
              <el-space
                w-100
                style="justify-content: space-between"
                alignment="center"
                gutter="12"
                :wrap="false"
              >
                <el-form-item prop="startDate" label="Start Date">
                  <el-date-picker
                    format="DD/MM/YYYY"
                    placeholder="DD/MM/YYYY"
                    type="date"
                    v-model="form.startDate"
                  />
                </el-form-item>
                <el-form-item prop="endDate" label="End Date">
                  <el-date-picker
                    format="DD/MM/YYYY"
                    type="date"
                    placeholder="DD/MM/YYYY"
                    v-model="form.endDate"
                  />
                </el-form-item>
              </el-space>
              <!--  -->
              <!--  -->
              <el-divider />
              <!-- compensation  -->
              <el-form-item
                prop="compensation"
                label="How are you willing to compensate the talent?"
              >
                <el-select
                  multiple
                  v-model="form.compensation"
                  placeholder="Compensation Method"
                  w-100
                >
                  <el-option :value="'cash'" :label="'Cash'" />
                  <el-option :value="'equity'" :label="'Equity'" />
                </el-select>
              </el-form-item>
              <!-- Ways of working  -->
              <el-form-item prop="wow" label="Planned working strategy">
                <el-select
                  v-model="form.wow"
                  placeholder="Remote, Hybrid"
                  w-100
                >
                  <el-option
                    :key="`${vf}-wow`"
                    v-for="vf in wows"
                    :value="vf"
                    :label="$filters.capitalize(vf)"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- Location -->
              <transition name="el-zoom-in-top">
                <app-location-search
                  v-show="form.wow == 'physical' || form.wow == 'hybrid'"
                  label="Where is the opportunity based?"
                  :size="'default'"
                  v-model="form.location"
                />
              </transition>
              <!--  -->
              <!--  -->
              <el-divider />
              <!--  -->
              <div>
                <el-checkbox
                  @change="changeRegionFilter"
                  v-model="showRegionFilter"
                  size="large"
                  label="Add region filtering"
                >
                </el-checkbox>
                <div style="margin-bottom: 1.2rem; margin-top: -8px">
                  <small class="mute-secondary">
                    Filter the talents to match with based on either country or
                    region
                  </small>
                </div>
              </div>
              <div v-if="showRegionFilter && form.regionFilter != undefined">
                <el-form-item
                  prop="regionFilter"
                  label="Choose filtering category"
                >
                  <el-select
                    v-model="form.regionFilter.type"
                    placeholder="Region"
                    w-100
                    @change="changeRegionFilterType()"
                  >
                    <el-option
                      :key="`${vf}-regionfilter`"
                      v-for="vf in regionFilterTypes"
                      :value="vf"
                      :label="$filters.capitalize(vf)"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <!-- Country / region -->
                <el-form-item prop="regionFilterOption" label="Choose region">
                  <el-select
                    v-model="form.regionFilter.options"
                    placeholder="Choose"
                    multiple
                    filterable
                    w-100
                  >
                    <el-option
                      :key="`${vf}-regionfilter-op`"
                      v-for="vf in regionOptions"
                      :value="vf"
                      :label="$filters.capitalize(vf)"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <!--  -->
              <el-divider />
              <!--  -->
              <!-- Description -->
              <el-form-item prop="about" label="Opportunity Description">
                <el-input
                  rows="5"
                  type="textarea"
                  placeholder="Description"
                  v-model="form.description"
                />
              </el-form-item>
              <!--  -->
              <!--  -->
            </el-form>
          </div>
        </div>
      </el-scrollbar>
      <!--  -->
      <template #footer>
        <div v-if="!isShowingCongrats" txt-center>
          <el-button
            is-block
            icon="el-icon-check"
            @click="submit"
            size="large"
            type="primary"
            :disabled="disabledSave || loading"
          >
            Add Opportunity
          </el-button>
        </div>
      </template>
      <!--  -->
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { SkillFunction } from "@/@business/views/opportunities-page/components/skill-fetch.mixins";
import { ErrorUtil } from "@/shared";
import { Opportunity, regionFilterTypes, WOWs } from "@/shared/interfaces";
import { ApiResourceResult } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";
import countriesRegions from "./countries.json";
import appOpportunityCongrats from "./opportunity-create-congrats.vue";

@Options({
  components: {
    appOpportunityCongrats,
  },
})
export default class extends Vue {
  /**
   * Variables for the component
   */
  loading = false;
  isModalOpen = false;
  isShowingCongrats = false;
  showClose = true;
  /**
   * List of skills fetched from the DB and their salaries
   */
  skills: SkillFunction[] = [];
  wows = WOWs;

  prevOpportunities: ApiResourceResult<Opportunity> = {} as any;

  /**
   * @call this to check if it should be open
   * because there are no opportunities
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
    let hasNoOpportunities = await this.fetchOpportunities();
    this.isModalOpen = hasNoOpportunities;
    this.isShowingCongrats = hasNoOpportunities;
    if (this.isModalOpen) this.showClose = false;
  }
  /**
   * @Watch for open trigger
   * if triggered the user should be able to close
   */
  @Watch("$store.state.modals.isOpportunityOpen")
  watchForOpen() {
    console.log("Open the opportunity create");
    this.isModalOpen = this.$store.state.modals.isOpportunityOpen;
    if (this.isModalOpen) this.showClose = true;
  }

  @Watch("isModalOpen")
  watchForModalOpenChange() {
    console.log("Opened the modal");
  }

  /**
   * @business
   */
  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
  /**
   * @returns a title to use in the modal
   */
  get title() {
    if (this.isShowingCongrats) {
      return "";
    }
    return this.prevOpportunities === undefined
      ? ""
      : this.prevOpportunities.count || 0 > 0
      ? "Add Another Opportunity"
      : "Add Opportunity";
  }
  /**
   * @directly fetches the opportunities and checks if the modal should open directly
   */
  fetchOpportunities() {
    return this.$api.main
      .get<ApiResourceResult<Opportunity>>("/v1/opportunities?limit=1")
      .then((resp) => {
        this.prevOpportunities = resp.data;
        return resp.data.count === 0 ? true : false;
      });
  }

  emptyForm = {
    skill: undefined,
    outcome: undefined,
    talents: undefined,
    location: undefined,
    wow: undefined,
    teamSize: undefined,
    startDate: undefined,
    endDate: undefined,
    compensation: undefined,
    regionFilter: undefined,
    marketSize: undefined,
    description: undefined,
  };
  form = {} as any;
  currency = "£";

  /**
   * Region filtering
   */
  regionFilterTypes = regionFilterTypes.slice(0, 2);
  showRegionFilter = false;
  changeRegionFilter() {
    if (this.showRegionFilter) {
      this.form.regionFilter = {};
    } else {
      this.form.regionFilter = undefined;
    }
  }
  changeRegionFilterType() {
    this.form.regionFilter.options = undefined;
  }
  get regionOptions() {
    if (this.form.regionFilter.type == "region") {
      return Array.from(
        new Set(countriesRegions.map((c) => c.region)).values()
      );
    } else if (this.form.regionFilter.type == "country") {
      return countriesRegions.map((c) => c.country.toLowerCase());
    }
    return [];
  }
  /**
   * @param score
   */
  submit() {
    this.loading = true;
    this.$api.main
      .post<Opportunity>("/v1/opportunities", this.form)
      .then((resp) => {
        this.finalizePosting(resp.data);
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * @triggers updates after creating a new Opportunity profile
   */
  finalizePosting(opp: Opportunity) {
    this.closeModal();
    this.$store.dispatch("opportunities/fetch"); // Trigger to fetch opportunities
    this.$router.push(`/opportunities/${opp._id}`);
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.$store.commit("modals/opportunity", false); // This is enough itself
    this.isModalOpen = false;
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    this.form = { ...this.emptyForm };
  }

  /**
   *
   */
  rules = {
    skill: [{ required: true, message: "Skill is required!", trigger: "blur" }],
    talents: [
      {
        required: true,
        message: "Nbr of talents is required!",
        trigger: "blur",
      },
    ],
    outcome: [
      { required: true, message: "This is required!", trigger: "blur" },
    ],
    wow: [{ required: true, message: "This is required!", trigger: "blur" }],
    startDate: [
      {
        type: "date",
        required: true,
        message: "Start Date is required!",
        trigger: "blur",
      },
    ],
    endDate: [
      {
        type: "date",
        required: true,
        message: "End Date is required!",
        trigger: "blur",
      },
    ],
    compensation: [
      {
        required: true,
        message: "This is required!",
        trigger: "blur",
      },
    ],

    description: [
      {
        required: true,
        message: "This is required!",
        trigger: "blur",
      },
    ],
  };
  get disabledSave() {
    return false;
  }
}
</script>

<style lang="scss">
.App-Create-Opportunity-Modal {
  .App-Styled-Form {
    max-width: 450px;
    margin: auto;
  }
  .el-dialog__body {
    padding-top: 0 !important;
  }
}
</style>
