<template>
  <div>
    <el-dialog
      custom-class="App-User-Profile-Modal App-Unpadded"
      :model-value="isModalOpen"
      width="550px"
      :show-close="showClose"
      @open="opened"
      title="Bio Info"
      center
      :close-on-click-modal="showClose"
      :append-to-body="true"
      :lock-scroll="true"
      :open-delay="200"
      :close-delay="200"
      :before-close="closeModal"
    >
      <!--  -->
      <div v-loading="loading">
        <div>
          <div>
            <div txt-center>
              <el-image :style="`width: 150px;`" :src="img"></el-image>
              <p m-rl-auto style="max-width: 350px" txt-center>
                In accordance with our privacy policy, we only collect data for
                the express purpose of finding you matches, it will never be
                used for any other purpose.
              </p>
            </div>
          </div>
        </div>
        <el-form
          @submit.prevent
          class="App-Styled-Form"
          :label-position="'top'"
          label-width="100px"
          :model="form"
          style="margin-top: 12px"
        >
          <!-- Gender -->
          <el-form-item prop="gender" label="Gender">
            <el-select v-model="form.gender" placeholder="Gender" w-100>
              <el-option
                v-for="gender of genders"
                :key="`Gender - ${gender}`"
                :value="gender"
                :label="gender"
              />
            </el-select>
          </el-form-item>
          <!--  -->
          <!-- DOB -->
          <el-form-item prop="DOB" label="Date Of Birth">
            <el-date-picker
              type="date"
              format="DD/MM/YYYY"
              placeholder="DD/MM/YYYY"
              v-model="form.DOB"
            />
          </el-form-item>
          <!--  -->
          <el-divider />
          <!--  -->
          <div>
            <app-location-search
              label="Where are you based?"
              :size="'default'"
              v-model="form.location"
            />
          </div>
          <!--  -->
        </el-form>
      </div>
      <!--  -->
      <!--  -->
      <template #footer>
        <div txt-center>
          <el-button
            is-block
            icon="el-icon-check"
            @click="submit"
            size="large"
            type="primary"
            :disabled="disabledSave || loading"
          >
            Save Info
          </el-button>
        </div>
      </template>
      <!--  -->
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { ErrorUtil } from "@/shared/classes";
import { User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";
import img from "@/assets/illustrations/user/bio.svg";

@Options({})
export default class extends Vue {
  genders = ["Male", "Female", "Other", "Prefer not to say"];
  img = img;
  /**
   * Modal variables
   */
  loading = false;
  isModalOpen = false;
  showClose = false;

  /**
   * Form Data
   */
  form = {
    gender: undefined,
    DOB: undefined,
    location: undefined,
  };

  /**
   * @is the save button disabled
   */
  get disabledSave() {
    return (
      this.form.gender === undefined ||
      this.form.DOB === undefined ||
      this.form.location === undefined
    );
  }
  /**
   * @when the modal is checked
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      this.$store.state.account.user?.DOB === undefined ||
      this.$store.state.account.user?.gender === undefined ||
      this.$store.state.account.user?.location === undefined;
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<User>("/v1/account/profile", this.form)
      .then((resp) => {
        AccountUtil.fetchCurrent(this.$store).finally(
          () => (this.loading = false)
        );
        this.closeModal();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.$store.commit("modals/bioInfo", false);
    this.isModalOpen = false;
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to add education
   */
  @Watch("$store.state.modals.bioInfo")
  watchForOPen() {
    this.isModalOpen = this.$store.state.modals.bioInfo;
    if (this.isModalOpen) this.showClose = true;
  }
  /**
   * @update account after some changes
   */
  makeUpdatesAfterCreate() {
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
  }
}
</script>

<style lang="scss">
.App-User-Profile-Modal {
  .App-Styled-Form {
    max-width: 400px;
    margin: auto;
  }
}
</style>
