
import { Options, Vue } from "vue-class-component";
import OpportunitiesList from "./components/opportunities-list.component.vue";
import { Checked, Star } from "@element-plus/icons";
import { Watch } from "vue-property-decorator";
import { RouterUtilClass, VuexResourceFetchOptions } from "@/shared";

@Options({
  name: "AppOpportunitiesPage",
  components: { OpportunitiesList, Checked, Star },
})
export default class extends Vue {
  /**
   * @returns created opportunities
   */
  get opportunities() {
    return this.$store.state.opportunities.opportunities;
  }

  /**
   * Active index for the nav
   */
  get activeIndex() {
    return (
      this.$route.path +
      (this.$route.query.isArchived !== undefined
        ? `?isArchived=${this.$route.query.isArchived}`
        : "")
    );
  }
  /**
   * @returns the loading state of the created opportunities
   */
  get loading() {
    return this.$store.state.opportunities.loading;
  }

  mounted() {
    if (this.$route.query.isArchived === undefined) {
      this.$router.push("/opportunities?isArchived=false");
    }
    this.fetch();
  }

  fetch() {
    this.$store.dispatch("opportunities/fetch", {
      q: RouterUtilClass.getQuery(this.$route),
      clear: true,
    } as VuexResourceFetchOptions);
  }

  @Watch("$route.query.isArchived")
  routeChanged() {
    this.fetch();
  }
}
