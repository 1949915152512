<template>
  <div>
    <el-dialog
      custom-class="App-User-Profile-Modal"
      :model-value="isModalOpen"
      width="550px"
      :show-close="showClose"
      v-loading="loading"
      @open="opened"
      title="Startup Experience"
      center
      :close-on-click-modal="showClose"
      :append-to-body="true"
      :lock-scroll="true"
      :open-delay="200"
      :close-delay="200"
      :before-close="closeModal"
      :close-on-press-escape="false"
    >
      <!--  -->
      <div v-loading="loading">
        <div>
          <div txt-center>
            <el-image :style="`width: 150px;`" :src="img"></el-image>
            <p>
              Tell us about your experience <br />
              creating value for others.
            </p>
          </div>
        </div>
        <!--  -->
        <el-form
          @submit.prevent
          class="App-Styled-Form"
          :label-position="'top'"
          label-width="100px"
          :model="form"
          style="margin-top: 12px"
        >
          <!-- businessModel -->
          <el-form-item
            prop="businessModel"
            label="Which business models are you good at?"
          >
            <el-select
              v-model="form.startupExperience.models"
              placeholder="Business Model: ex: Edtech"
              w-100
              size="large"
              multiple
            >
              <el-option
                v-for="ind in businessModels"
                :value="ind"
                :label="ind"
                :key="`${ind}-ind`"
              >
                <el-space>
                  <i class="App-Loc-Icon el-icon-business" />
                  <span>{{ ind }}</span>
                </el-space>
              </el-option>
            </el-select>
          </el-form-item>
          <!-- stage -->
          <el-form-item
            prop="stage"
            label="Which startup stages are you good at?"
          >
            <el-select
              size="large"
              v-model="form.startupExperience.stages"
              placeholder="Startup Stage: ex: MVP"
              w-100
              multiple
            >
              <el-option
                v-for="ind in stages"
                :value="ind"
                :label="ind"
                :key="`${ind}-ind`"
              >
                <el-space>
                  <i class="App-Loc-Icon el-icon-business" />
                  <span>{{ ind }}</span>
                </el-space>
              </el-option>
            </el-select>
          </el-form-item>
          <!--  -->
        </el-form>
      </div>
      <!--  -->
      <!--  -->
      <template #footer>
        <div txt-center>
          <el-button
            is-block
            icon="el-icon-check"
            @click="submit"
            size="large"
            type="primary"
            :disabled="disabledSave || loading"
          >
            Save Experience
          </el-button>
        </div>
      </template>
      <!--  -->
    </el-dialog>
  </div>
</template>

<script lang="ts">
import img from "@/assets/illustrations/user/experience.svg";
import { ErrorUtil } from "@/shared/classes";
import { BusinessModels, BusinessStages, User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  img = img;
  /**
   * Modal variables
   */
  loading = false;
  isModalOpen = false;
  showClose = false;

  stages = BusinessStages;
  businessModels = BusinessModels;
  /**
   * Form Data
   */
  form = {
    startupExperience: {
      models: [],
      stages: [],
    },
  };

  /**
   * @is the save button disabled
   */
  get disabledSave() {
    return (
      this.form.startupExperience.models?.length === 0 ||
      this.form.startupExperience.stages?.length === 0
    );
  }

  /**
   * @when the modal is checked
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      this.$store.state.account.user?.startupExperience === undefined ||
      this.$store.state.account.user?.startupExperience?.models?.length === 0;
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<User>("/v1/account/profile", this.form)
      .then((resp) => {
        AccountUtil.fetchCurrent(this.$store).finally(
          () => (this.loading = false)
        );
        this.closeModal();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.$store.commit("modals/startupExperience", false); // This is enough itself
    this.isModalOpen = false;
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to add education
   */
  @Watch("$store.state.modals.startupExperience")
  watchForOPen() {
    this.isModalOpen = this.$store.state.modals.startupExperience;
    if (this.isModalOpen) this.showClose = true;
  }
  /**
   * @update account after some changes
   */
  makeUpdatesAfterCreate() {
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    this.prepareExistingData();
  }

  get user() {
    return this.$store.state.account.user;
  }
  /**
   * @on open add the existing data to the form
   */
  prepareExistingData() {
    this.form.startupExperience =
      this.user?.startupExperience ||
      ({
        models: [],
        stages: [],
      } as any);
  }
}
</script>

<style lang="scss">
.App-User-Profile-Modal {
  .App-Styled-Form {
    max-width: 400px;
    margin: auto;
  }
}
</style>
