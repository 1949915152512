
import img from "@/assets/illustrations/opportunity/no-opportunity.svg";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  img = img;

  @Prop({ type: Boolean })
  loading!: boolean;

  @Prop({ type: String, default: "No Opportunities Were Found" })
  content!: string;

  @Prop({ type: Object })
  resources!: any[];

  get isFounder() {
    return this.$store.state.account.user?.type === "founder";
  }

  @Emit("clickedEmpty")
  clickedEmpty() {
    return this;
  }
}
