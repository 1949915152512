
import { TermsLinksClass } from "@/shared/classes";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import jsCookie from "js-cookie";

@Options({})
export default class extends Vue {
  showOptions = false;
  checked = true;
  isOpen = false; //
  /**
   * The cookie notice cookie is stored globally
   */
  private cookieNoticeOps = {
    domain: process.env.VUE_APP_DOMAIN,
    expires: 3000000,
    path: "/",
  };
  private cookieName = "COOKIES";

  cookiesList = [
    {
      name: "Default E4T",
      description:
        "These are cookies set up by E4T for functionalities such as authentication and tracking whether you agree with our cookie policy or not.",
      value: true,
      canAlter: false,
    },
    {
      name: "Stripe",
      description:
        "We use Stripe for processing our payments, and we do consider their cookies as a necessity for our platform to function properly",
      value: true,
      canAlter: false,
    },
    {
      name: "Google Analytics",
      description:
        "Google Analytics cookies help us to understand your experience of the website and do not store any personal data. Visit our cookie policy for a full list of Google Analytics cookies used on this site.",
      value: true,
      canAlter: false,
    },
  ];
  /**
   * @unless we are on terms you have to check of has agreed
   */
  mounted() {
    this.checkIfAgreed();
  }

  @Watch("$route.path")
  onRouteChange() {
    this.checkIfAgreed();
  }

  /**
   * If we are on terms hide the modal
   * If we are not and the terms are not checked show the modal
   */
  checkIfAgreed() {
    if (this.$route.path.includes("/terms/")) this.isOpen = false;
    else if (!this.acceptance()?.includes("ACCEPTED")) {
      this.isOpen = true;
    }
  }
  /**
   * @returns if the user has agrred to terms and conditions
   */
  acceptance() {
    return jsCookie.get(this.cookieName);
  }
  /**
   * On agree
   * @stores the cookie
   * and hide the modal
   */
  acceptTerms() {
    jsCookie.set(this.cookieName, "ACCEPTED", this.cookieNoticeOps);
    this.isOpen = false;
  }

  /**
   * We close the window
   */
  rejectTerms() {
    window.open("", "_parent", "");
    window.close();
  }
  /**
   * Navigate to cookies policy
   */
  visitCookiesPolicy() {
    // If on about we use route
    if (`.${window.location.hash}` === process.env.VUE_APP_DOMAIN)
      this.$router.push("/terms/cookie-policy");
    // else we use window
    else TermsLinksClass.navigateOut("cookie-policy");
  }
}
