<template>
  <div class="App-Int">
    <!--  -->
    <!-- Top -->
    <div class="App-Int-Top">
      <el-space w-100 alignment="center" :size="12">
        <div>
          <app-over-avatars :size="50">
            <template #one
              ><el-avatar
                :src="interest.createdBy.avatar"
                icon="el-icon-user-solid"
                :size="45"
              ></el-avatar
            ></template>
            <template #two
              ><el-avatar
                :src="interest.business.avatar"
                icon="el-icon-user-solid"
                :size="45"
              ></el-avatar
            ></template>
          </app-over-avatars>
        </div>
        <el-space justify="space-between" w-100 :size="12">
          <div>
            <h3>{{ interest.business.name }}</h3>
            <span>
              {{ interest.business.industry }} industry
              {{ interest.business.stage }} stage
              <span style="margin-left: 10px" mute-secondary>
                {{ $moment(interest.createdAt).fromNow() }}
              </span>
            </span>
          </div>
        </el-space>
      </el-space>
    </div>
    <!--  -->
    <!-- Middle -->
    <div>
      <div>
        <h3>
          {{ interest.talents }} {{ interest.skill[1] }}
          {{ $filters.capitalize(decideTalentWording()) }} Needed to
          {{ interest.outcome }}
        </h3>
      </div>
      <div>
        <app-truncate
          :content="interest.description"
          :max="250"
          @viewMore="clickedMore"
          m-tb-0
        >
        </app-truncate>
      </div>
      <el-space :size="16" wrap>
        <el-tag tag-default is-all-borderless round>
          {{ $filters.capitalize(interest.compensation.join(", ")) }}
        </el-tag>
        <el-tag tag-default is-all-borderless round>{{
          $filters.capitalize(interest.wow)
        }}</el-tag>
        <span
          >From
          <span mute-secondary>
            {{ $moment(interest.startDate).format("D MMM yy") }}
          </span>
        </span>
        <span
          >To
          <span mute-secondary>
            {{ $moment(interest.endDate).format("D MMM yy") }}
          </span>
        </span>
        <!-- <span> {{ interest.talents }} {{decideTalentWording()}} needed</span> -->
      </el-space>
    </div>
    <!--  -->
    <!-- Bottom -->
    <el-divider />
    <el-row align="center" justify="space-between" w-100>
      <div>
        <el-link type="primary">Read More</el-link>
      </div>
      <el-button is-borderless icon="el-icon-share">Share</el-button>
    </el-row>
  </div>
</template>

<script lang="ts">
import { Interest } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  methods: {
    date(date: string) {
      return new Date(date).toLocaleDateString();
    },
  },

  filters: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  interest!: Interest;

  /**
   * @fired when the view more button is clicked
   */
  clickedMore() {
    this.$router.push(`/opportunities/${this.interest._id}`);
  }
}
</script>

<style lang="scss">
.App-Int {
  min-height: 200px;
  background: var(--el-color-white);
  border-radius: map.get($--border-radius, "base");
  padding: map.get($--button-padding-vertical, "medium")
    map.get($--button-padding-vertical, "large") * 2;
  // Top
  .App-Int-Top {
    h3,
    h4,
    h5 {
      font-weight: 600;
      margin: 0;
    }
  }
  /**
    // Text explaining
     */
  p {
    font-size: 14px;
    font-weight: 500;
  }
  h3,
  h4,
  h5 {
    font-weight: 700;
  }
}
</style>
