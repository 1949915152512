<template>
  <div>
    <el-dialog
      custom-class="App-User-Profile-Modal"
      :model-value="isModalOpen"
      width="550px"
      :show-close="showClose"
      @open="opened"
      title="Verify Email"
      center
      :close-on-click-modal="showClose"
      :append-to-body="true"
      :lock-scroll="true"
      :open-delay="200"
      :close-delay="200"
      :before-close="closeModal"
      :close-on-press-escape="false"
    >
      <!--  -->
      <!--  -->
      <div v-loading="loading">
        <div>
          <div>
            <div txt-center>
              <el-image :style="`width: 150px;`" :src="img"></el-image>
              <h2 m-tb-0 f-w-800 txt-center>
                Awesome! Now we just need you to confirm ownership of the
                email<br />
                to continue
              </h2>
              <p m-rl-auto style="max-width: 350px" txt-center>
                Visit your email inbox for <br />
                verification instructions
              </p>
              <el-button
                @click="resendVerification"
                type="primary"
                plain
                is-all-borderless
                icon="el-icon-refresh"
              >
                Resend Verification
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <!--  -->
    </el-dialog>
  </div>
</template>

<script lang="ts">
import img from "@/assets/illustrations/email-activation.svg";
import { ErrorUtil } from "@/shared/classes";
import { Options, Vue } from "vue-class-component";
import { Emit } from "vue-property-decorator";

@Options({
  emits: ["closed", "opened", "checked"],
})
export default class extends Vue {
  img = img;
  /**
   * Modal variables
   */
  loading = false;
  isModalOpen = false;
  showClose = false;

  /**
   * @when the modal is checked
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account.user?.verifiedEmail);
    this.isModalOpen = this.$store.state.account.user?.verifiedEmail !== true;
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    // this.$store.commit("modals/bioInfo", false);
    this.isModalOpen = false;
  }

  /**
   * @resend verification email
   */
  resendVerification() {
    this.loading = true;
    this.$api.main
      .get("/v1/auth/resend-email-verify")
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Have re-sent the activation email.",
        });
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err, {
            message: "Failed to resend the activation email.",
          }),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
  }
}
</script>

<style lang="scss">
.App-User-Profile-Modal {
  .App-Styled-Form {
    max-width: 400px;
    margin: auto;
  }
}
</style>
