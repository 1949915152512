
import img from "@/assets/illustrations/user/skills.svg";
import { ErrorUtil } from "@/shared/classes";
import { User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";
import { SkillsMixin } from "./mixins";

@Options({
  mixins: [SkillsMixin],
})
export default class extends Vue {
  img = img;
  /**
   * Modal variables
   */
  loading = false;
  isModalOpen = false;
  showClose = false;
  /**
   * Form Data
   */
  form = {
    areaOfExpertise: undefined as any,
    skills: [{ name: undefined, years: undefined }] as any[],
    otherSkills: [] as string[],
    interests: [] as string[],
  };

  /**
   * temp form
   */
  tempForm = {
    inputSkill: false,
    inputInterest: false,
    skill: "",
    interest: "",
  };

  /**
   * @add a main skill
   */
  addMainSkill() {
    this.form.skills.push({ name: undefined, years: undefined });
  }

  /**
   * @add a main skill
   */
  removeMainSkill() {
    if (this.form.skills.length > 1) this.form.skills.pop();
  }
  /**
   * @add a main skill
   */
  addOtherSkill() {
    this.form.otherSkills.push();
  }
  /**
   * @is the save button disabled
   */
  get disabledSave() {
    return (
      this.form.areaOfExpertise === undefined || this.form.skills?.length === 0
    );
  }

  /**
   * @when the modal is checked
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      this.$store.state.account.user?.areaOfExpertise === undefined ||
      this.$store.state.account.user?.skills === undefined ||
      this.$store.state.account.user?.skills?.length === 0;
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<User>("/v1/account/profile", this.form)
      .then((resp) => {
        return AccountUtil.fetchCurrent(this.$store);
      })
      .then((resp) => {
        this.closeModal();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close skills modal modal");
    this.$store.commit("modals/mainSkills", false); // This is enough itself
    this.isModalOpen = false;
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to add education
   */
  @Watch("$store.state.modals.mainSkills")
  watchForOPen() {
    this.isModalOpen = this.$store.state.modals.mainSkills;
    if (this.isModalOpen) this.showClose = true;
  }

  @Watch("isModalOpen")
  watchForModalOpenChange() {
    console.log("Opened the modal");
  }
  /**
   * @when called fetche the list of skills
   */

  /**
   * @update account after some changes
   */
  makeUpdatesAfterCreate() {
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    this.prepareExistingData();
  }

  get user() {
    return this.$store.state.account.user;
  }
  /**
   * @on open add the existing data to the form
   */
  prepareExistingData() {
    this.form.otherSkills = this.user?.otherSkills || [];
    this.form.interests = this.user?.interests || [];
    this.form.areaOfExpertise = this.user?.areaOfExpertise || [];
    this.form.skills =
      this.user?.skills?.map((r) => ({ name: r.name, years: r.years })) || [];
  }
}
