export class MapsUtils {

  /**
   * Returns key words in the geo results
   * @param res
   */
  static keywords(res: Record<string, any>) {
    return res.address_components.map(comp => comp.long_name).concat(res.types)
  }

  /**
   * returns country
   * @param res
   */
  static country(res: Record<string, any>) {
    return res.address_components.filter(comp => comp.types.includes('country'))[0]?.long_name
  }

  /**
   * returns city
   * @param res
   */
  static city(res: Record<string, any>) {
    return res.address_components.filter(comp => comp.types.includes('locality'))[0]?.long_name
  }

}
