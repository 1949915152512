
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * @improve to add icon
 */
@Options({})
export default class extends Vue {
  @Prop({ type: String })
  content!: string;
}
