
import { Business, Opportunity } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class extends Vue {
  @Prop({ default: {}, type: Object })
  opportunity!: Opportunity;

  @Prop({ default: {}, type: Object })
  business!: Business;

  get user() {
    return this.$store.state.account.user;
  }
  /**
   * @returns the user type viewing this opportunity
   */
  get viewedBy() {
    return this.$store.state.account.user?.type;
  }
}
