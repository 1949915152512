import { $api } from "@/shared";
import { User } from "@/shared/interfaces";
import { ApiResourceResult } from "@/shared/vuex";
import { StoreOptions } from "vuex";

export interface TalentsVuexState extends ApiResourceResult<User> {
  talents?: User[];
  loading: boolean;
}

export const TalentsVuex: StoreOptions<TalentsVuexState> = {
  ...{ namespaced: true },
  state: {
    talents: undefined,
    loading: false,
  },
  mutations: {
    /**
     * Assigns a talents as default
     * @param state
     * @param talents
     */
    talents(state, data: { data: User[]; clear: boolean }) {
      state.talents = data.data;
    },
  },
  actions: {
    /**
     * Fetches  talents
     * @param context
     */
    fetch(context) {
      context.state.loading = true;
      $api.main
        .get<User[]>("/v1/talents")
        .then((resp) => {
          context.state.talents = resp.data;
          context.state.count = resp.data.length
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
  },
  modules: {},
  //
  getters: {},
};
