<template>
  <el-space :size="14" fill w-100 class="App-Trans-List">
    <AppTransaction
      v-for="(trans, index) in transactions"
      :transaction="trans"
      :key="`trans-${trans._id}-${index}`"
    >
    </AppTransaction>
  </el-space>
</template>

<script lang="ts">
import { Transaction } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import AppTransaction from "./transaction-component.vue";

@Options({
  components: {
    AppTransaction,
  },
})
export default class extends Vue {
  @Prop({ type: Array })
  transactions!: Transaction[];
}
</script>

<style lang="scss">
.App-Trans-List {
}
</style>
