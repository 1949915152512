
import { Transaction } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import AppTransaction from "./transaction-component.vue";

@Options({
  components: {
    AppTransaction,
  },
})
export default class extends Vue {
  @Prop({ type: Array })
  transactions!: Transaction[];
}
