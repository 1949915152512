<template>
  <div class="App-Startup-Users">
    <el-space :size="-10">
      <div
        v-for="user of [business.founder]"
        :key="`user-${user._id}`"
      >
        <el-avatar :size="40" :src="user.avatar">
          <span v-blur="user">
            {{ $filters.initials(user) }}
          </span>
        </el-avatar>
      </div>
    </el-space>
  </div>
</template>

<script lang="ts">
import { Business } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  methods: {},

})
export default class extends Vue {
  @Prop({ type: Object })
  business!: Business;
}
</script>

<style lang="scss" scoped>
//
// ===========
// Actions
.App-Startup-Users {
  padding-top: 4px;
}
</style>
