
import { Opportunity, User } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  opportunity!: Opportunity;

  @Prop({ type: Object })
  talent!: User;
}
