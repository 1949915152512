
import { Options, Vue } from "vue-class-component";
import AccPageHeader from "../components/account-page-header-component.vue";
import AccPageRightNav from "../components/account-pages-right-nav.vue";
import { Check, Close } from "@element-plus/icons";
import { User } from "@element-plus/icons";
import { AccountUtil } from "@/shared/vuex";
import ChangePasswordComponent from "./components/account-password.vue";

@Options({
  components: {
    AccPageHeader,
    User,
    AccPageRightNav,
    Check,
    Close,
    ChangePasswordComponent,
  },
})
export default class extends Vue {
  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }

  loading = false;

  get availableForBids() {
    return this.user?.availableForBids ?? true;
  }

  /**
   * @param change the availability of the talent
   */
  changeAvailability(newVar: boolean) {
    this.loading = true;
    return this.$api.main
      .patch("/v1/account/profile", { availableForBids: newVar })
      .then((resp) => {
        return AccountUtil.fetchCurrent(this.$store);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
