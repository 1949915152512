
import { Options, Vue } from "vue-class-component";

@Options({})
export default class extends Vue {
  /**
   * Called to trigger opening of the opportunity create modal
   */
  createOpportunity(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.$store.commit("modals/opportunity", true);
  }
}
