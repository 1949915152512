<template>
  <el-space :size="8" fill w-100 class="App-Interests-List">
    <InterestComponent
      v-for="(opp, index) in interests"
      :Interest="opp"
      :key="`opp-${index}`"
    >
    </InterestComponent>
  </el-space>
</template>

<script lang="ts">
import { Interest } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import InterestComponent from "./interest.component.vue";

@Options({
  components: {
    InterestComponent,
  },
})
export default class extends Vue {
  @Prop({ type: Array })
  interests!: Interest[];
}
</script>

<style lang="scss">
.App-Interests-List {
}
</style>
