<template>
  <div class="App-Bidd-H">
    <!--  -->
    <div v-loading="loading" class="App-Bidd">
      <!--  -->
      <!-- Top Person -->
      <el-tooltip content="Visit the opportunity">
        <router-link :to="`/opportunities/${bidding.opportunity._id}`">
          <div class="App-Bidd-Tal">
            <div style="line-height: 18px" class="App-Bidd-T">
              <h4 m-tb-0 f-w-700 v-if="bidding.opportunity !== undefined">
                {{ bidding.opportunity.outcome }} Opportunity
              </h4>
              <div v-if="bidding.business">
                <span mute-primary style="padding-right: 8px">
                  {{ bidding.business.name }}
                </span>
                <span mute-secondary>
                  Sent {{ $moment(bidding.createdAt).fromNow() }}
                </span>
              </div>
            </div>
          </div>
        </router-link>
      </el-tooltip>
      <!--  -->
      <!--  -->
      <div class="App-Bid-Cont">
        <!--  -->
        <div v-if="user.type === 'founder'">
          <h4 m-tb-0 v-if="bidding.talent !== undefined">
            <span f-w-600 mute-secondary> Bidding for </span>
          </h4>
          <el-tooltip content="Visit talent's page">
            <router-link
              :to="`/talent/${bidding.talent._id}/${bidding.opportunity._id}`"
            >
              <h4 m-tb-0 f-w-600>
                <span
                  style="padding-right: 4px"
                  v-if="
                    bidding.talent.headline || bidding.talent.areaOfExpertise
                  "
                >
                  {{
                    $filters.capitalize(
                      bidding.talent.headline || bidding.talent.areaOfExpertise
                    )
                  }}(er)
                </span>
                <span>
                  <span
                    style="
                      display: inline-block;
                      height: 13px;
                      overflow: hidden;
                    "
                    v-blur="bidding.talent"
                  >
                    {{ $filters.capitalize(bidding.talent.firstName) }}
                    {{ $filters.capitalize(bidding.talent.lastName) }}
                  </span>
                </span>
              </h4></router-link
            >
          </el-tooltip>
          <el-divider style="margin: 12px 0" />
        </div>
        <!-- Middle Details -->
        <div>
          <app-truncate
            @viewMore="max = 20000"
            :max="max"
            :content="bidding.description"
          />
          <div>
            <el-space :size="12">
              <!--  -->
              <el-tag
                v-if="bidding.compensationTypes.includes('equity')"
                tag-default
                is-all-borderless
                round
              >
                <span> {{ $filters.number(bidding.equity) }}% Equity </span>
              </el-tag>
              <!--  -->
              <el-tag
                v-if="bidding.compensationTypes.includes('cash')"
                tag-default
                is-all-borderless
                round
              >
                <span>
                  {{ $filters.currency(bidding.currency) }}
                  {{ $filters.number(bidding.cash) }}
                </span>
              </el-tag>
              <!--  -->
              <span mute-secondary>
                Expiration {{ $moment(bidding.expireAt).fromNow() }}
              </span>
            </el-space>
          </div>
        </div>
        <!-- Bottom -->
        <div m-t-1>
          <el-row align="middle" justify="space-between" w-100>
            <!-- Left Size -->
            <div>
              <el-space :size="12">
                <span>
                  <el-tag
                    v-if="isExpired && bidding.status === 'ongoing'"
                    type="danger"
                    round
                    size="mini"
                    is-all-borderless
                    f-w-600
                  >
                    Expired
                  </el-tag>
                  <el-tag
                    v-else
                    :type="
                      bidding.status === 'ongoing'
                        ? 'warning'
                        : bidding.status === 'accepted'
                        ? 'success'
                        : 'danger'
                    "
                    round
                    size="mini"
                    is-all-borderless
                    f-w-600
                  >
                    {{ $filters.capitalize(bidding.status) }}
                  </el-tag>
                </span>
                <el-link v-if="false" size="mini" dark>Feedback</el-link>
              </el-space>
            </div>
            <!--  -->
            <!-- Right Side -->
            <div v-if="!isExpired">
              <!--  -->
              <span v-if="bidding.status === 'ongoing'">
                <el-button
                  v-if="user.type == 'talent'"
                  size="mini"
                  is-borderless
                  type="danger"
                  round
                  plain
                  icon="el-icon-close"
                  @click="rejectBid"
                >
                  Reject
                </el-button>
                <el-button
                  @click="acceptBid"
                  v-if="user.type == 'talent'"
                  size="mini"
                  is-borderless
                  type="success"
                  round
                  plain
                  icon="el-icon-check"
                >
                  Accept
                </el-button>
              </span>
              <!--  -->
              <el-button
                v-if="
                  user.type === 'founder' &&
                  bidding.talent.isPaid !== true &&
                  isAccepted
                "
                @click="openPayModal"
                :loading="loadingPay"
                is-borderless
                type="primary"
                round
                size="mini"
                icon="el-icon-cancel"
              >
                Pay
                <span v-if="talentPrice !== undefined">
                  {{ talentPrice.symbol }}
                  {{ $filters.number(talentPrice.amount) }}
                </span>
                <span> to reveal full details </span>
              </el-button>
              <!--  -->
            </div>
          </el-row>
        </div>
      </div>
      <!-- If User is Founder -->
      <div v-if="user.type === 'founder' && talentPrice !== undefined">
        <BidPayModal
          :talentPrice="talentPrice"
          @close="hidePayModal"
          :isOpen="payingModelOpen"
          :talent="bidding.talent"
          :opportunity="bidding.opportunity"
          :bidding="bidding"
        />
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script lang="ts">
import { Bidding } from "@/shared";
import { ErrorUtil } from "@/shared/classes";
import { Opportunity, User } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import BidPayModal from "./bidding-pay-modal.vue";

export interface PayForTalent {
  amount?: 1556;
  currency?: "gbp" | "usd";
  symbol?: "£" | "$";
  //
  amountBeforeTax?: number;
  tax?: number;
}
@Options({
  name: "BiddComponent",
  components: { BidPayModal },
})
export default class extends Vue {
  loading = false;

  @Prop({ type: Object })
  bidding!: Bidding;

  loadingPay = true;
  talentPrice: PayForTalent = {};

  payingModelOpen = false;

  max = 260;

  /**
   * @fired when the view more button is clicked
   */
  clickedMore() {
    this.$router.push(`/opportunities/${this.bidding._id}`);
  }

  /**
   * @on mount
   */
  mounted() {
    if (this.user?.type === "founder") this.predictPay();
  }

  get user() {
    return this.$store.state.account.user;
  }

  get isAccepted() {
    return this.bidding.status === "accepted";
  }
  /**
   * @helps predict how much to pay for a talent
   */
  predictPay() {
    this.loadingPay = true;
    this.$api.main
      .post<PayForTalent>("/v1/transactions/predict", {
        talent: (this.bidding.talent as User)._id,
        opportunity: (this.bidding.opportunity as Opportunity)._id,
      })
      .then((resp) => {
        this.talentPrice = resp.data;
      })
      .finally(() => {
        this.loadingPay = false;
      });
  }

  /**
   * @triggered when pay button is clicked
   */
  openPayModal() {
    this.payingModelOpen = true;
  }

  hidePayModal() {
    this.payingModelOpen = false;
  }

  /**
   * @returns whether the bidding is expired or not
   */
  get isExpired() {
    const expire = (this as any).$moment(this.bidding.expireAt);
    const now = (this as any).$moment(new Date());
    return expire.diff(now, "minutes") < 2;
  }

  /**
   * @
   */
  rejectBid() {
    this.loading = true;
    return this.$api.main
      .patch<Bidding>(`/v1/biddings/${this.bidding._id}/reject`)
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Rejected the bidding",
        });
        this.$router.push("/biddings?status=rejected");
      })
      .finally(() => (this.loading = false));
  }
  /**
   * @
   */
  acceptBid() {
    this.loading = true;
    return this.$api.main
      .patch<Bidding>(`/v1/biddings/${this.bidding._id}/accept`)
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Accepted the bidding",
        });
        this.$router.push("/biddings?status=accepted");
      })
      .finally(() => (this.loading = false));
  }
}
</script>

<style lang="scss">
.App-Bidd-Tal {
  background-color: rgba($color: $--background-color-base, $alpha: 0.4);
  padding: map.get($--button-padding-vertical, "small")
    map.get($--button-padding-vertical, "large") * 1.5;
}
.App-Bidd {
  min-height: 200px;
  background: var(--el-color-white);
  border-radius: map.get($--border-radius, "base");
  //
  .App-Bid-Cont {
    padding: map.get($--button-padding-vertical, "medium")
      map.get($--button-padding-vertical, "large") * 1.5;
    display: block;
  }
  // Top
  .App-Bidd-Top {
    h3,
    h4,
    h5 {
      font-weight: 600;
      margin: 0;
    }
  }
  /**
    // Text explaining
     */
  p {
    font-size: 14px;
    font-weight: 500;
  }
  h3,
  h4,
  h5 {
    font-weight: 700;
  }
}
</style>
