import { $api, VuexResourceFetchOptions } from "@/shared";
import { Opportunity } from "@/shared/interfaces";
import { ObjectUtil } from "@/shared/utils";
import { ApiResourceResult } from "@/shared/vuex";
import { StoreOptions } from "vuex";

export interface OpportunitiesVuexState extends ApiResourceResult<Opportunity> {
  opportunities?: Opportunity[];
  loading: boolean;
}

export const OpportunitiesVuex: StoreOptions<OpportunitiesVuexState> = {
  ...{ namespaced: true },
  state: {
    opportunities: undefined,
    loading: false,
  },
  mutations: {
    /**
     * Assigns a opportunities as default
     * @param state
     * @param data
     */
    opportunities(state, data: { data: Opportunity[]; clear: boolean }) {
      if (data.clear) {
        state.opportunities = data.data;
      } else {
        state.opportunities = state.opportunities
          ? state.opportunities.concat(data.data)
          : data.data;
      }
    },
  },
  actions: {
    /**
     * Fetches  opportunities
     * @param context
     */
    fetch(context, options?: VuexResourceFetchOptions) {
      context.state.loading = true;
      $api.main
        .get<ApiResourceResult<Opportunity>>(
          `/v1/opportunities?sort=-createdAt${options?.q}`
        )
        .then((resp) => {
          context.state.count = resp.data.count;
          context.state.next = resp.data.next;
          context.state.skipped = resp.data.skipped;
          context.state.opportunities = resp.data.resources;
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    /**
     * Indicates an opportunity that is being updated
     * @param context
     */
    opportunity(context, opportunity: Opportunity) {
      context.state.opportunities = context.state.opportunities?.map((opp) => {
        if (opp._id === opportunity._id) {
          opp = ObjectUtil.assign(opp, opportunity, ["business", "createdBy"]);
        }
        return opp;
      });
    },
  },
  modules: {},
  //
  getters: {},
};
