<template>
  <div>
    <div style="padding: 8px 24px; margin-bottom: 20px" txt-center>
      <div>
        <el-avatar v-if="talent" :size="100">
          <span v-blur="talent">
            {{ $filters.initials(talent) }}
          </span>
        </el-avatar>
      </div>
      <div txt-center>
        <!-- <span mute-secondary>For </span> -->
        <span dark f-w-600 v-blur="talent">
          {{ talent.firstName }} {{ talent.lastName }}
        </span>
      </div>
      <div v-if="opportunity" txt-center>
        <span mute-secondary>On </span>
        <span dark f-w-600>{{ opportunity.outcome }}</span>
      </div>
      <div v-if="opportunity" txt-center>
        <span>
          <el-space wrap :size="10">
            <span mute-secondary>Opportunity from </span>
            <span dark f-w-600>
              {{ $moment(opportunity.startDate).format("D MMM yy") }}
            </span>
            <span mute-secondary> to </span>
            <span dark f-w-600>
              {{ $moment(opportunity.endDate).format("D MMM yy") }}
            </span>
          </el-space>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Opportunity, User } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  opportunity!: Opportunity;

  @Prop({ type: Object })
  talent!: User;
}
</script>

<style lang="scss"></style>
