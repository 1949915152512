
import { ErrorUtil } from "@/shared/classes";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class extends Vue {
  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }
  /**
   * @copies the text
   */
  copyContent(txt: string) {
    console.log("Clicking copy");
    navigator.clipboard
      .writeText("txt")
      .then(() => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: "Copied the link.",
        });
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      });
  }
}
