import { Directive } from "@vue/runtime-core";

export const AppTruncateDirective: Directive = {
  /**
   *
   * @param el
   * @param binding
   * @param vnode
   */
  beforeMount(el, binding, vnode) {
    console;
  },
};
