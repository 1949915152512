
import { AuthUtil } from "@/@auth/classes";
import { User } from "@/shared/interfaces";
import { ElLoading } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Options({})
export default class extends Vue {
  /**
   * Can still be gotten from the store.state.account.user
   */
  @Prop()
  user!: User;

  /**
   * @handles the logout process
   */
  logout() {
    console.log("Clicked the logout");
    const loading = ElLoading.service({ lock: true });
    AuthUtil.logout().finally(() => {
      setTimeout(() => {
        loading.close();
      }, 1000);
    });
  }
  /**
   * @business
   */
  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
}
