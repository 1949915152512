<template>
  <div class="App-Startup-Comp">
    <!--  -->
    <!-- Startup Name Industry, Stage, Funding -->
    <div @click="clickedComponent" class="App-Startup-Comp-H">
      <el-avatar
        :src="business.avatar"
        icon="el-icon-office-building"
        :size="70"
      />
      <div>
        <h3 m-tb-0 f-w-700>{{ business.name }}</h3>

        <div>
          <h3
            style="display: inline-block; margin-right: 5px"
            mute-primary
            m-tb-0
            f-w-600
            v-if="business.industry"
          >
            {{ $filters.capitalize(business.industry) }} Industry,
          </h3>
          <h4
            mute-secondary
            style="display: inline-block; margin-right: 5px"
            m-tb-0
            f-w-600
            v-if="business.stage"
          >
            {{ $filters.capitalize(business.stage) }} Stage
          </h4>
          <h4
            mute-secondary
            style="display: inline-block"
            m-tb-0
            f-w-600
            v-if="business.businessModel"
          >
            {{ $filters.capitalize(business.businessModel) }}
          </h4>
        </div>
        <div mute-secondary>
          <div v-if="business.location">
            {{ business.location.description }}
          </div>
          <div v-else>No Location Provided</div>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- Startup People -->
    <!-- <app-section-divider content="People">
      <el-icon><User f-w-700 primary /></el-icon>
    </app-section-divider> -->
    <!--  -->
    <!-- <div class="App-Startup-People">
      <el-space fill w-100 direction="vertical">
        <div> -->
    <div>
      <el-divider style="margin: 10px 0 4px 0px"></el-divider>
      <!-- <StartupUsersComponent :business="business" /> -->

      <el-row class="row-bg" justify="end">
        <el-col :span="6">
          <div txt-right>
            <span>
              <el-tag v-if="isDefault" size="mini" round> Current </el-tag>
            </span>
            <el-button
              size="mini"
              @click="setDefault"
              type="primary"
              round
              v-if="!isDefault"
            >
              Set Default
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- </div>
      </el-space>
    </div> -->
    <!--  -->
  </div>
</template>

<script lang="ts">
import { Business } from "@/shared";
import appSectionDivider from "@/shared/components/app-section-divider.vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { User } from "@element-plus/icons";
import StartupUsersComponent from "./startup-users-component.vue";
import { FounderAccountUtil } from "@/@business/store";

@Options({
  components: { appSectionDivider, User, StartupUsersComponent },
})
export default class extends Vue {
  @Prop({ type: Object })
  business!: Business;

  /**
   * @when clicked we go to the new
   */
  clickedComponent() {
    this.$router.push(`/startups/${this.business._id}`);
  }

  /**
   * Is this business founder's default?
   */
  get isDefault() {
    return (
      this.$store.state.account.user?.defaultBusiness === this.business._id
    );
  }
  /**
   * Sets this startup as default
   */
  setDefault() {
    this.$api.main
      .post(`/v1/auth/default-business/${this.business._id}`)
      .then((resp) => {
        FounderAccountUtil.renewKey(this.$store);
      });
  }
}
</script>

<style lang="scss">
.App-Startup-Comp {
  background: white;
  border-radius: 6px;
  min-height: 100px;
  padding: map.get($--button-padding-vertical, "medium")
    map.get($--button-padding-vertical, "large") * 2
    map.get($--button-padding-vertical, "mini")
    map.get($--button-padding-vertical, "large") * 2;
  transition: 300ms ease;

  &:hover {
    background-color: lighten($color: $--background-color-base, $amount: 10%);
  }
  // Header
  //
  .App-Startup-Comp-H {
    cursor: pointer;
    display: flex;
    align-items: center;
    //
    .el-avatar {
      margin-right: 10px;
    }
    div {
      margin: -2px 0;
    }
  }
}
</style>
