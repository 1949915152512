export * from "./utils";
import {
  AccountVuex,
  AccountVuexState,
} from "@/shared/vuex/store/account.vuex";
import { createStore } from "vuex";
import { createLogger } from "vuex";
import { BusinessBiddingsVuex, BusinessBiddingsVuexState } from "./biddings.vuex";
import { ModalsVuex, ModalsVuexState } from "./modals.vuex";
import {
  OpportunitiesVuex,
  OpportunitiesVuexState,
} from "./opportunities.vuex";
import { TalentVuex, TalentVuexState } from "./talent.vuex";
import { TalentsVuex, TalentsVuexState } from "./talents.vuex";
import { TransactionsVuex, TransactionsVuexState } from "./transactions.vuex";

export interface BusinessPlatformState {
  account: AccountVuexState;
  opportunities: OpportunitiesVuexState;
  talents: TalentsVuexState;
  talent: TalentVuexState;
  modals: ModalsVuexState;
  biddings: BusinessBiddingsVuexState;
  transactions: TransactionsVuexState;
}
/**
 *
 */
export const BusinessStore = createStore<BusinessPlatformState>({
  plugins: [createLogger({})],
  modules: {
    account: AccountVuex as any,
    opportunities: OpportunitiesVuex as any,
    talents: TalentsVuex as any,
    talent: TalentVuex as any,
    modals: ModalsVuex as any,
    biddings: BusinessBiddingsVuex as any,
    transactions: TransactionsVuex as any
  },
});

export default BusinessStore;
