
import img from "@/assets/illustrations/user/experience.svg";
import { User } from "@/shared";
import { MomentUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class extends Vue {
  img = img;

  @Prop({ type: Object })
  user!: User;

  @Prop({ type: Boolean })
  isSelf!: boolean;
  /**
   * @clicked add
   */
  addExperience() {
    this.$store.commit("modals/addExperience", true);
  }

  get experience() {
    return [...(this.user.experience || [])]?.sort((a, b) => {
      return new Date(b.from).getTime() - new Date(a.from).getTime();
    });
  }

  dateDiff = MomentUtil.dateDifference;
}
