
import { Interest } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import InterestComponent from "./interest.component.vue";

@Options({
  components: {
    InterestComponent,
  },
})
export default class extends Vue {
  @Prop({ type: Array })
  interests!: Interest[];
}
