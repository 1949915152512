import { AuthUtil } from "@/@auth/classes";
import { FounderAccountUtil } from "@/@business/store";
import { $api, User } from "@/shared";
import { AxiosError } from "axios";
import { Store } from "vuex";

export class AccountUtil {
  /**
   * @fetches and returns current user account
   * @param store
   */
  static fetchCurrent(store: Store<any>) {
    return $api.main
      .get<User>("/v1/account")
      .then((resp) => {
        return this.handleResponse(store, resp.data);
      })
      .then((user) => {
        store.commit("account/user", user);
        return user;
      })
      .catch((err) => {
        this.handleError(err);
        throw err;
      })
      .finally(() => {
        if (store) store.state.account.loading = false;
      });
  }

  /**
   *
   * @param store
   * @param user
   * @returns
   */
  private static handleResponse(store: Store<any>, user: User) {
    /**
     * If Founder there are some stuff to handle
     */
    if (user.type === "founder") {
      return FounderAccountUtil.finalizeFounderFetch(store, user)
        .then(() => {
          return user;
        })
        .catch(() => {
          return user;
        });
    } else return user;
  }

  /**
   * @param err
   * Handles account fetch error
   * Logs out when necessary
   */
  static handleError(err: AxiosError) {
    console.log("AccountUtil Err", err.response);
    console.log("AccountUtil Err", err);
    if (
      err.response?.data?.error === "Forbidden" ||
      err.response?.data?.statusCode === 403
    ) {
      AuthUtil.logout();
    }
  }

  // static isAuthError() {}
}
