import { Business, User } from ".";
import { QuestionCategory } from "./user";

export type SurveyScore = Record<QuestionCategory, number>;

export interface Question {
  _id: string;
  question: string;
  category?: QuestionCategory;
  responses: QuestionResponse[];
}
export interface QuestionResponse {
  _id: string;
  response: string;
  weight: number;
}

/**
 * Survey submit
 */
export interface SingleSurveyResponseDto {
  question: string;
  response: string; // The user submits _id of response in array
}

export interface SubmitSurveyDto {
  score: SurveyScore;
}

/**
 *
 * Invitatioon data
 */

export type InvitationStatus = "pending" | "accepted" | "denied";
export const InvitationStatuses = ["pending", "accepted", "denied"];

export interface Invitation {
  email: string;
  expireAt: Date;
  invitedBy: string | User;
  status: InvitationStatus;
  business?: string | Business;
  key: string;
  type: "talent" | "co-founder";
}

export const glossary: {
  dimension: QuestionCategory;
  heading: string;
  description: string;
}[] = [
  {
    dimension: "thinking",
    heading: "Contrarian thinking",
    description:
      "Contrarian thinking refers to an individual’s tendency to have thoughts and ideas that are out of the ordinary. This trait is useful in entrepreneurship because contrarian thinkers are more likely to generate ideas that are novel. On the flipside, ideas of contrarian thinkers are more difficult to communicate leading often to other people finding it difficult to understand them.",
  },
  {
    dimension: "perseverance",
    heading: "Perseverance",
    description:
      "Perseverance refers to an individual’s determination in completing any task that they take on. Entrepreneurship is a constant stream of difficult problems, so having the determination to overcome each of those problems will be helpful. At the same time, however, entrepreneurship requires experimentation and pivoting and perseverance may create rigidity in this process.",
  },
  {
    dimension: "confidence",
    heading: "Confidence",
    description:
      "Confidence refers to an individual’s belief that they can be successful. Given that entrepreneurs operate under extreme uncertainty, confidence is often an important driver of action. However, extreme confidence may also create blndspots, diminishing one’s ability to adapt based on feedback.",
  },
  {
    dimension: "decision",
    heading: "Decision-making under uncertainty",
    description:
      "Decision-making under uncertainty measures an individual’s experimentation mindset. An individual with an experimentation mindset considers decisions, directions and ideas as iterative processes. This is usually a helpful trait in the early stages of a startup where flexibility and quick adaptability are important. Meanwhile, excessive experimentation may hamper with a startup’s ability to create routines and processes that allow the startup to generate value to customers on a consistent basis.",
  },
  {
    dimension: "motivation",
    heading: "Motivation",
    description:
      "Motivation measures an individual’s drive to succeed. A highly motivated individual will tend to have more energy and work at a faster pace. The faster a startup builds the product, gets to market and generates revenue, the faster it will succeed. At the same time, faster pace makes an individual and a startup more susceptible to mistakes as well as exhaustion.",
  },
  {
    dimension: "planning",
    heading: "Planning",
    description:
      "Planning refers to an individual’s ability to create roadmaps and identify bumps in the road in advance. This allows the individual to assess progress and avoid unnecessary detours. However, plans that are too specific may slow down progress and adaptability.",
  },
  {
    dimension: "vision",
    heading: "Vision",
    description:
      "Vision refers to an individual’s preference to think in abstraction. Vision allows a startup to create meaning beyond its economic value and this can be a powerful force. In the meantime, too much focus on the vision may often blur the startup’s main requirement for survival, that of generating a profit. ",
  },
  {
    dimension: "leadership",
    heading: "Leadership",
    description:
      "Leadership in the startup context refers to an individual’s ability to inspire early employees and other stakeholders. This involves creating strong, warm ties, effectively guiding others in extreme uncertainty and untapping their potential. Startup leadership is very different from leadership in a larger organization, so as the startup grows the requirements change considerably.",
  },
];
