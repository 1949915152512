<template>
  <div>
    <el-dialog
      custom-class="App-Create-Business-Modal App-Unpadded"
      :model-value="isModalOpen"
      width="500px"
      :show-close="showClose"
      :close-on-click-modal="showClose"
      :close-on-press-escape="showClose"
      @open="opened"
      title="Co-founder Profile"
      center
      :append-to-body="true"
      :lock-scroll="true"
      :open-delay="200"
      :before-close="closeModal"
      :close-delay="200"
    >
      <!--  -->
      <el-scrollbar :always="true" v-loading="loading" max-height="70vh">
        <div>
          <el-form
            @submit.prevent
            class="App-Styled-Form"
            :label-position="'top'"
            label-width="100px"
            :model="form"
            style="margin-top: 12px"
          >
            <!--  -->
            <!-- co-founder names -->
            <el-form-item prop="names" label="Full Names">
              <el-input placeholder="Names" v-model="form.names" />
            </el-form-item>
            <!--  -->
            <!-- Skills -->
            <div>
              <el-divider />
              <h4 txt-center mt-2>Skills</h4>
              <el-row
                :gutter="16"
                align="middle"
                justify="center"
                v-for="(skill, index) of form.skills"
                :key="`skill-input-${index}${skill.name}`"
              >
                <el-col :span="index == 0 ? 18 : 16">
                  <app-skill-input w-100 v-model="form.skills[index].name" />
                </el-col>
                <el-col :span="6">
                  <el-tooltip content="Years of experience">
                    <el-form-item prop="experience" label="Years">
                      <el-input-number
                        w-100
                        :min="0"
                        :max="100"
                        :controls="false"
                        placeholder="EX: 5"
                        v-model="form.skills[index].years"
                      />
                    </el-form-item>
                  </el-tooltip>
                </el-col>
                <el-col txt-right v-if="index !== 0" :span="2">
                  <el-button
                    style="margin-bottom: 18px"
                    v-if="index > 0"
                    @click="removeSkill(index)"
                    icon="el-icon-delete"
                    circle
                    size="mini"
                  />
                </el-col>
              </el-row>
              <div txt-center>
                <el-button
                  class="button-new-tag"
                  size="small"
                  @click="addSkill"
                  round
                  icon="el-icon-plus"
                >
                  Add Skill
                </el-button>
              </div>
            </div>
            <!--  -->
            <!-- Education -->
            <el-divider />
            <div>
              <div>
                <h4 txt-center mt-2>Education History</h4>
                <el-space fill direction="vertical">
                  <div
                    v-for="(edu, index) of form.education"
                    :key="`edu-input-${index}`"
                  >
                    <app-education-input
                      :index="index"
                      v-model="form.education[index]"
                      @remove="removeEducation(index)"
                    />
                  </div>
                </el-space>
                <div txt-center>
                  <el-button
                    class="button-new-tag"
                    size="small"
                    @click="addEducation"
                    round
                    icon="el-icon-plus"
                  >
                    Add Education
                  </el-button>
                </div>
              </div>
            </div>
            <!--  -->
            <!--  -->
          </el-form>
        </div>
      </el-scrollbar>
      <!--  -->
      <template #footer>
        <div txt-center>
          <el-button
            is-block
            icon="el-icon-check"
            @click="saveData"
            size="large"
            type="primary"
            :disabled="disableSave || loading"
          >
            Save
          </el-button>
        </div>
      </template>
      <!--  -->
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { FounderAccountUtil } from "@/@business/store";
import { ErrorUtil } from "@/shared";
import { Business, BusinessCoFounder, User } from "@/shared/interfaces";
import { CircleCheckFilled, WarningFilled } from "@element-plus/icons";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";

@Options({
  emits: ["opened", "closed"],
  components: {
    WarningFilled,
    CircleCheckFilled,
  },
})
export default class extends Vue {
  showClose = false;

  @Prop({ type: Boolean })
  isModalOpen!: boolean;

  @Prop({ type: Object })
  business!: Business;

  @Prop({ type: Object })
  coFounder?: BusinessCoFounder;

  @Prop({ type: Number })
  coFounderIndex!: number;

  loading = false;

  form = {
    names: undefined,
    skills: [] as any,
    education: [] as any,
  };

  mounted() {
    this.assignData();
  }

  get disableSave() {
    return this.form.names === undefined || this.form.skills.length == 0;
  }

  /**
   * @assigns data to the form when mounted
   */
  assignData() {
    this.form.names = this.coFounder?.names as any;
    this.form.skills = this.coFounder?.skills || ([] as any);
    this.form.education =
      this.coFounder?.education?.map((ed) => {
        ed.organisation =
          typeof ed.organisation == "string"
            ? ed.organisation
            : ed.organisation._id;
        return ed;
      }) || ([] as any);
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal(): void {
    console;
  }

  @Emit("opened")
  opened() {
    console;
  }

  saveData() {
    this.loading = true;
    let business: Business = { ...this.business };
    business.coFounders[this.coFounderIndex] = this.form as any;
    /**
     * Remove unwanted fields
     */
    business.coFounders = business.coFounders.map((co) => {
      co.education = co.education?.map((ed) => {
        (ed as any)._id = undefined as any;
        (ed as any).id = undefined as any;
        return ed;
      });
      co.skills = co.skills?.map((ski) => {
        (ski as any)._id = undefined as any;
        (ski as any).id = undefined as any;
        return ski;
      });
      (co as any)._id = undefined as any;
      (co as any).id = undefined as any;
      return co;
    });
    /**
     * Saved results
     */
    this.$api.main
      .patch<Business>(`/v1/businesses/${this.business._id}`, {
        coFounders: business.coFounders,
      })
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Saved co-founder's info!",
        });
        return this.finalizeUpdates(resp.data);
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  finalizeUpdates(business: Business) {
    this.loading = true;
    this.closeModal();
    FounderAccountUtil.finalizeFounderFetch(
      this.$store,
      this.$store.state.account.user as User
    );
  }
  /**
   * Skills deal with
   */
  addSkill() {
    this.form.skills.push({} as any);
  }
  removeSkill(index) {
    if (this.form.skills.length > 0) this.form.skills.splice(index, 1);
  }
  /**
   * Education deal with
   */
  addEducation() {
    this.form.education.push({} as any);
  }
  removeEducation(index) {
    this.form.education.splice(index, 1);
  }
}
</script>

<style lang="scss">
.App-Create-Business-Modal {
}
</style>
