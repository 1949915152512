<template>
  <div>
    <!--  -->
    <!--  -->
    <!-- <div class="App-Small-Device hidden-lg-and-up">
      <div>
        <div txt-center>
          <app-logo :size="60"></app-logo>
        </div>
        <h1 primary txt-center>Oops!</h1>
        <p txt-center>
          E4T is not mobile responsive for now, but our team is busy working on
          it.
          <br />
          <br />
          <b> Try using a larger device. </b>
        </p>
      </div>
    </div> -->
    <!--  -->
    <!--  -->
    <div class="App-Main-Layout-Bt">
      <el-row justify="center" :gutter="10" type="flex" align="center">
        <!--  -->
        <!--  -->
        <!--  -->
        <el-col
          class="hidden-md-and-down"
          :xs="24"
          :sm="20"
          :md="20"
          :lg="6"
          :xl="6"
        >
          <el-affix style="min-width: 300px;" target="body" position="top" :offset="4">
            <slot name="left" />
          </el-affix>
        </el-col>
        <!--  -->
        <!--  -->
        <!-- The middle part that gets seen when on small devices -->
        <el-col :xs="24" :sm="20" :md="20" :lg="11" :xl="11">
          <el-affix style="min-width: 300px;" target="body" position="top" :offset="0">
            <div class="App-Main-Layout-Bt-M">
              <slot name="top" />
            </div>
          </el-affix>
          <div class="App-Main-Layout-Bt-M App-Main-Layout-Bt-Conts">
            <slot name="middle" />
          </div>
          <!--  -->
          <div
            class="
              hidden-lg-and-up
              App-Main-Layout-Bt-M App-Main-Layout-Bottom-Nav
            "
          >
            <slot name="bottom-nav" />
          </div>
          <!--  -->
        </el-col>
        <!--  -->
        <!--  -->
        <!--  -->
        <el-col
          class="hidden-md-and-down"
          :xs="24"
          :sm="20"
          :md="20"
          :lg="7"
          :xl="7"
        >
          <el-affix style="min-width: 300px;" target="body" position="top" :offset="4">
            <slot name="right" />
          </el-affix>
        </el-col>
        <!--  -->
        <!--  -->
        <!--  -->
      </el-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  name: "App-Mainlayout",
  components: {},
})
export default class extends Vue {}
</script>

<style lang="scss">
//
// Responsivelly maximum width
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .App-Main-Layout-Bt {
    padding: 4px 0px 0px 0px;
    max-width: 990px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .App-Main-Layout-Bt {
    max-width: 1200px;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .App-Main-Layout-Bt {
    max-width: 1400px;
  }
}
//
// Small devices only
@media (max-width: 769px) {
  // Contents
  .App-Main-Layout-Bt-Conts {
    padding: 8px 8px 100px 8px;
  }
}
//
.App-Main-Layout-Bt {
  margin: auto;

  > * {
    width: 100%; // To avoid the scroll thing
  }
  //
  // Middle part
  .App-Main-Layout-Bt-M {
    max-width: 650px;
    margin: auto;
  }
}
//
.App-Small-Device {
  background: white;
  height: 100vh;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  > div {
    max-width: 400px;
    margin: auto;
  }
  h1 {
    font-size: 2.5rem;
  }
  p {
    font-size: 130%;
  }
}
//
// Bottom Nav styling
.App-Bottom-Nav {
  min-height: 60px;
  width: 100%;
  background: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 200;
  box-shadow: 0px 2px 9px 4px #00000042;

  .el-menu--horizontal {
    display: flex;
    justify-content: space-between;
  }
  .el-menu-item {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    i {
      margin: 3px 0;
      margin-top: 8px;
      font-weight: bold;
      margin-right: 0;
      width: auto;
    }
    span {
      font-size: 80%;
      line-height: 16px;
    }
  }
}
</style>
