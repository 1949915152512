import { AuthUtil } from "@/@auth/classes";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { User, UserType } from "..";

const appsBases = {
  admin: process.env.VUE_APP_BASE_URL_ADMIN,
  talent: process.env.VUE_APP_BASE_URL_TALENT,
  business: process.env.VUE_APP_BASE_URL_BUSINESS,
};
/**
 *
 * @param to
 * @param from
 * @param next
 */
export const InNavigateRespectively = (
  user: User,
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const currentAppUrl = document.location.hostname.split(".")[0] as any;
  console.log("Going to ", currentAppUrl);
  // if trying to access business
  if (user.type === "founder" && currentAppUrl.search("business") !== -1) {
    next();
    return;
  }
  // if trying to access talent
  else if (user.type === "talent" && currentAppUrl.search("talent") !== -1) {
    next();
    return;
  }
  // if trying to access admin
  else if (user.type === "admin" && currentAppUrl.search("admin") !== -1) {
    next();
    return;
  }
  /**
   * If here redirect a user to where he/she belongs responsively
   */
  AuthUtil.redirectUser(user);
};
