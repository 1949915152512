export class ObjectUtil {
  /**
   *
   * @param obj
   * @param fields
   */
  static pick(obj: Record<string, any>, fields: string[]) {
    const newObjc: Record<string, any> = {};
    fields.forEach((field) => {
      newObjc[field] = obj[field];
    });
    return newObjc;
  }

  /**
   * Picks fields from the newObj and makes changes in the old obj
   * @param obj
   * @param newObj
   */
  static assign<T>(obj: T, newObj: Record<string, any>, ignore: string[] = []) {
    Object.keys(newObj).forEach((key) => {
      if (!ignore.includes(key)) obj[key] = newObj[key];
    });
    return obj;
  }

  /**
   * Performs a deep merge of objects and returns new object. Does not modify
   * objects (immutable) and merges arrays via concatenation.
   *
   * @param {...object} objects - Objects to merge
   * @returns {object} New object with merged key/values
   *
   * https://stackoverflow.com/a/48218209
   */
  static mergeDeep(obj: Record<string, any>) {
    return JSON.parse(JSON.stringify(obj));
  }
}
