export const OpportunityMixin = {
    data() {
      return {
        isEditing: false,
      };
    },
    methods: {
      /**
       * @update
       */
      updateOpportunity() {
        (this as any).$data.isEditing = true;
      },
      stopUpdateOpportunity() {
        (this as any).$data.isEditing = false;
      },
      /**
       * @
       */
      shareTwitter() {
        console;
      },
    },
  };
  