export class TermsLinksClass {
  /**
   * Navigates outside
   * @param link
   */
  static navigateOut(link: string) {
    window.open(`${process.env.VUE_APP_BASE_URL_ABOUT}/terms/${link}`);
  }
}

export type TermLink =
  | "terms-of-service"
  | "terms-of-use"
  | "privacy-policy"
  | "cookie-policy"
  | "glossary";
