<template>
  <div>
    <app-cookie-notice />
    <!--  -->
    <div>
      <router-view />
    </div>
    <!--  -->
    <div v-if="user && user.type === 'founder'">
      <!--  -->
      <app-user-email-activate
        @checked="toogleVariable('checkedEmailActivate', true)"
        @opened="toogleVariable('openedEmailActivate', true)"
        @closed="toogleVariable('openedEmailActivate', false)"
      />
      <!--  -->
      <!-- Create Startup Modal -->
      <CreateStartupProfileModal
        v-if="fetchedBusinesses && vIf('Startup')"
        @checked="toogleVariable('checkedStartup', true)"
        @opened="toogleVariable('openedStartup', true)"
        @closed="toogleVariable('openedStartup', false)"
      />
      <!-- Provide future stage, and future skills  -->
      <StartupNextModal
        :business="business"
        v-if="business && vIf('StartupFuture')"
        @checked="toogleVariable('checkedStartupFuture', true)"
        @opened="toogleVariable('openedStartupFuture', true)"
        @closed="toogleVariable('openedStartupFuture', false)"
      />
      <!--  -->
      <!-- Entrepreneurial Standing -->
      <SurveyModal
        v-if="vIf('Survey')"
        @checked="toogleVariable('checkedSurvey', true)"
        @opened="toogleVariable('openedSurvey', true)"
        @closed="toogleVariable('openedSurvey', false)"
        :user="user"
      />
      <!--  -->
      <!-- Info Filling About -->
      <app-user-onboard-info
        v-if="vIf('OnboardInfo')"
        @checked="toogleVariable('checkedOnboardInfo', true)"
        @opened="toogleVariable('openedOnboardInfo', true)"
        @closed="toogleVariable('openedOnboardInfo', false)"
      />
      <!--  -->
      <!-- Main Skills -->
      <app-user-main-skills-modal
        v-if="vIf('MainSkills')"
        @checked="toogleVariable('checkedMainSkills', true)"
        @opened="toogleVariable('openedMainSkills', true)"
        @closed="toogleVariable('openedMainSkills', false)"
      />
      <!--  -->
      <!--  -->
      <app-user-add-edu-modal
        v-if="vIf('Education')"
        @checked="toogleVariable('checkedEducation', true)"
        @opened="toogleVariable('openedEducation', true)"
        @closed="toogleVariable('openedEducation', false)"
      />
      <!--  -->
      <app-user-standardtests-modal
        v-if="vIf('StandardTest')"
        @checked="toogleVariable('checkedStandardTest', true)"
        @opened="toogleVariable('openedStandardTest', true)"
        @closed="toogleVariable('openedStandardTest', false)"
      />
      <!--  -->
      <!-- Profile Picture -->
      <app-user-avatar-modal
        v-if="vIf('Avatar')"
        @checked="toogleVariable('checkedAvatar', true)"
        @opened="toogleVariable('openedAvatar', true)"
        @closed="toogleVariable('openedAvatar', false)"
      />
      <!--  -->
      <!-- Startup Cofounders Info too -->
      <StartupCofoundersModal
        :business="business"
        v-if="vIf('StartupCofounders')"
        @checked="toogleVariable('checkedStartupCofounders', true)"
        @opened="toogleVariable('openedStartupCofounders', true)"
        @closed="toogleVariable('openedStartupCofounders', false)"
      />
      <!--  -->
      <!-- Create Opportunity Modal -->
      <CreateOpportunityModal
        v-if="vIf('Opportunity')"
        @checked="toogleVariable('checkedOpportunity', true)"
        @opened="toogleVariable('openedOpportunity', true)"
        @closed="toogleVariable('openedOpportunity', false)"
      />
      <!--  -->
      <!--  -->
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import SurveyModal from "@/shared/components/survey-module/survey.modal.vue";
import CreateStartupProfileModal from "./views/businesses-page/components/create-business-modal.vue";
import CreateOpportunityModal from "./views/opportunities-page/components/opportunity-create/opportunity-create-component.vue";
import StartupNextModal from "./views/businesses-page/modals/business-next-modal.vue";
import StartupCofoundersModal from "./views/businesses-page/modals/business-cofounders-modal.vue";
import { WindowUtil } from "@/shared/utils";

@Options({
  name: "BusinessApp",
  title: "Equity4Talent Business",
  components: {
    SurveyModal,
    CreateStartupProfileModal,
    CreateOpportunityModal,
    StartupNextModal,
    StartupCofoundersModal,
  },
})
export default class extends Vue {
  /**
   * @business
   */
  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
  get fetchedBusinesses() {
    return this.$store.state.account.fetchedBusinesses;
  }
  /**
   * Modals check
   */
  openedEmailActivate = false;
  openedSurvey = false;
  openedStartup = false;
  openedStartupFuture = false;
  openedOnboardInfo = false;
  openedMainSkills = false;
  openedEducation = false;
  openedStandardTest = false;
  openedAvatar = false;
  openedStartupCofounders = false;
  openedOpportunity = false;

  /**
   *
   */
  checkedEmailActivate = false;
  checkedSurvey = false;
  checkedStartup = false;
  checkedStartupFuture = false;
  checkedOnboardInfo = false;
  checkedMainSkills = false;
  checkedEducation = false;
  checkedStandardTest = false;
  checkedAvatar = false;
  checkedStartupCofounders = false;
  checkedOpportunity = false;

  /**
   * The way filling in the forms follow each other
   * Indication of order
   */
  stages = [
    "EmailActivate",
    "Startup",
    "StartupFuture",
    "Survey",
    "OnboardInfo",
    "MainSkills",
    "Education",
    "StandardTest",
    "Avatar",
    "StartupCofounders",
    "Opportunity",
  ];

  /**
   * returns show component if
   * 1. All previous stages open must evaluate to false
   * 2. And one previous checked is true
   */
  vIf(stage) {
    const stageIndex = this.stages.findIndex((st) => st === stage);
    // console.log(`Found the index of stage ${stage} = ${stageIndex}`);
    const previousOpens = this.stages
      .slice(0, stageIndex)
      .map((mapStage) => `opened${mapStage}`);
    const previousCheck = `checked${this.stages[stageIndex - 1]}`;
    //
    console.log(`Previous Opens ${stage} ${previousOpens}`);
    console.log(`Previous Check for ${stage} ${this[previousCheck]}`);
    ///
    return (
      this[previousCheck] === true && // Previous check evaluates to true
      previousOpens.every((op) => this[op] === false) // All opens evaluate to false
    );
  }

  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }

  /**
   * @toogles values to change
   */
  toogleVariable(vr: string, value: boolean) {
    console.log("vr=", vr, " value=", value);
    this[vr] = value;
  }

  /**
   *
   */
  mounted() {
    WindowUtil.listenToChange(document, this.$store);
  }
}
</script>

<style lang="scss">
@import "./styles.scss";
</style>
