
import { Interest } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  methods: {
    date(date: string) {
      return new Date(date).toLocaleDateString();
    },
  },

  filters: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  interest!: Interest;

  /**
   * @fired when the view more button is clicked
   */
  clickedMore() {
    this.$router.push(`/opportunities/${this.interest._id}`);
  }
}
