<template>
  <div class="App-User-Top">
    <div class="App-User-Top-Prof">
      <!--  -->
      <div v-loading="loadingPic" class="App-User-Top-D">
        <div txt-center>
          <!--  -->
          <!--  -->
          <el-dropdown
            @command="handleCommand"
            as-pointer
            v-if="isSelf"
            trigger="click"
          >
            <el-avatar as-pointer :size="180" :src="user.avatar">
              <span v-blur="user">
                {{ $filters.initials(user) }}
              </span>
            </el-avatar>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="upload" icon="el-icon-delete">
                  Upload
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="user.avatar !== undefined"
                  command="delete"
                  icon="el-icon-upload"
                >
                  Delete
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <!--  -->
          <el-avatar v-else :size="180" :src="user.avatar">
            <span v-blur="user">
              {{ $filters.initials(user) }}
            </span>
          </el-avatar>
          <!--  -->
          <!--  -->
        </div>
        <div>
          <h2 m-tb-0 v-blur="user">{{ user.firstName }} {{ user.lastName }}</h2>
          <div>{{ user.headline || user.areaOfExpertise }}</div>
          <div mute-secondary v-if="user.location">
            {{ user.location.description }}
          </div>
          <div>
            <el-link
              v-if="user.email"
              type="primary"
              :href="`mailto:${user.email}`"
              size="mini"
            >
              {{ user.email }}
            </el-link>
          </div>
        </div>
        <div>
          <p v-blur="user" v-if="user.about" m-tb-1>
            {{ user.about }}
          </p>
        </div>
        <!--  -->
        <!-- Ent Standing -->
        <div m-t-1 style="border-radius: 6px; background: #eff6f4">
          <!-- If founder looking at talent -->
          <div v-if="account.type === 'founder' && user.type === 'talent'">
            <div v-if="business">
              <app-ent-standing
                :labelSelf="`${$filters.capitalize(business.name)}`"
                :labelOther="`${$filters.capitalize(user.firstName)}(Talent)`"
                :standingSelf="business.standing"
                :standingOther="user.standing"
                size="medium"
              />
            </div>
          </div>
          <!-- If talent -->
          <div v-if="account.type === 'talent'">
            <app-ent-standing
              :labelSelf="`${$filters.capitalize(user.firstName)}(You)`"
              :standingSelf="user.standing"
              size="medium"
            />
          </div>
        </div>
        <!--  -->
        <!-- Matched Opoprtunity -->
        <!-- When Opportunity Is Provided We also include it -->
        <div m-t-3 v-if="opportunity && business">
          <app-section-divider content="Matched Opportunity">
            <i class="el-icon-s-opportunity"></i>
          </app-section-divider>
          <div>
            <h4 f-w-600 m-tb-0>{{ opportunity.outcome }}</h4>
            <div>
              <span mute-secondary v-if="opportunity.business">
                {{ business.name }}
              </span>
            </div>
          </div>
        </div>
        <!--  -->
        <!--  -->
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Opportunity, User } from "@/shared";
import { ErrorUtil } from "@/shared/classes";
import appEntStanding from "@/shared/components/survey-module//ent-standing/ent-standing.component.vue";
import { AccountUtil } from "@/shared/vuex";
import { DeleteFilled, UploadFilled } from "@element-plus/icons";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {
    appEntStanding,
    DeleteFilled,
    UploadFilled,
  },
})
export default class extends Vue {
  @Prop({ type: Object })
  user!: User;

  get account(): User {
    return this.$store.state.account.user as User;
  }

  @Prop({ type: Object })
  opportunity!: Opportunity;

  @Prop({ type: Boolean })
  isSelf!: boolean;

  loadingPic = false;
  /**
   * @for founders who are looking at a talent
   * They need to see which business the oppotunity is for
   */
  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }

  /**
   * @param command
   */
  handleCommand(command: "upload" | "delete") {
    if (command === "upload") this.uploadPic();
    else this.deletePic();
  }
  /**
   * @param
   */
  deletePic() {
    this.loadingPic = true;
    this.$api.main
      .delete("/v1/account/avatars")
      .then(() => {
        AccountUtil.fetchCurrent(this.$store);
      })
      .catch((err) => {
   this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });      })
      .finally(() => {
        this.loadingPic = false;
      });
  }

  /**
   * @param upload trigger
   */
  uploadPic() {
    this.$store.commit("modals/avatar", true);
  }
}
</script>

<style lang="scss">
.App-User-Top {
  // Top
  // Details
  .App-User-Top-D {
    margin-top: -105px;
    .el-avatar {
      background: $--color-dark-blue;
      font-size: 40px;
      font-weight: bold;
    }
  }
}
[App-User-Profile-Top] {
  background-color: $--background-base-light;
  height: 105px;
}
</style>
