
import { Opportunity } from "@/shared";
import { Checked, InfoFilled } from "@element-plus/icons";
import { Options, Vue } from "vue-class-component";

import OpportunityUsersList from "../talents-page/matched-talents-page/components/opportunity-talents-list.vue";
import OpportunityPageHeader from "./opportunity-page-header.vue";
import OpportunityPageDetails from "./opportunity-page-details.vue";
import {
  UserFilled,
  Opportunity as OpportunityBulb,
} from "@element-plus/icons";
import { OpportunityMixin } from "../opportunities-page/components/opportunity-mixin";
import OpportunityUpdateModal from "../opportunities-page/components/update-opportunity-modal.vue";
import { Watch } from "vue-property-decorator";
import appStartupOpp from "./opportunity-startup-details.vue";

@Options({
  mixins: [OpportunityMixin],
  components: {
    OpportunityUsersList,
    OpportunityPageHeader,
    OpportunityPageDetails,
    Checked,
    InfoFilled,
    UserFilled,
    OpportunityUpdateModal,
    appStartupOpp,
    OpportunityBulb,
  },
  data() {
    return {
      opportunity: undefined,
    };
  },
})
export default class extends Vue {
  loading = true;

  get isSelf() {
    return this.user?.type === "founder";
  }

  get user() {
    return this.$store.state.account.user;
  }

  mounted() {
    this.opportunityFetch();
  }

  @Watch("$route.params.id")
  whenRouteChanges() {
    this.opportunityFetch();
  }

  /**
   * @param command
   */
  handleCommand(command: "edit" | "archive") {
    if (command === "edit") (this as any).updateOpportunity();
    else this.archiveOpportunity();
  }

  archiveOpportunity() {
    console;
  }
  /**
   * checks if @current browsed user exists
   */
  opportunityFetch() {
    this.loading = true;
    this.$api.main
      .get<Opportunity>(`/v1/opportunities/${this.$route.params.id}`)
      .then((resp) => {
        (this.$data as any).opportunity = resp.data;
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 300);
      });
  }

  /**
   * @if talent we don't fetch the opportunity
   * We retrieve it from the $store.state
   */
  getIfTalent() {
    console;
  }
  /**
   * @business
   */
  get business() {
    return this.user?.type === "founder"
      ? this.businessState
      : (this.$data as any).opportunity.business;
  }

  get businessState() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
}
