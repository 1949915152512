
import { Options, Vue } from "vue-class-component";
import { UserFilled } from "@element-plus/icons";

@Options({ name: "AccountPagesRightNav", components: { UserFilled } })
export default class extends Vue {
  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }
}
