<template>
  <span>
    <el-button
      @click="isVisible = true"
      icon="el-icon-plus"
      circle
      size="mini"
    />
    <el-dialog
      v-model="isVisible"
      title="Add a University"
      center
      :append-to-body="true"
      width="450px"
    >
      <div txt-center>
        <el-image :style="`width: 80px;`" :src="img"></el-image>
        <p>
          Add the univerty name <br />
          if you can't find it on our list.
        </p>
      </div>
      <el-form
        @submit.prevent
        class="App-Styled-Form"
        :label-position="'top'"
        label-width="100px"
        :model="form"
        style="margin-top: 12px"
      >
        <!-- uni Name -->
        <el-form-item prop="name" label="University's name">
          <el-input
            placeholder="African Leadership University"
            v-model="form.name"
          />
        </el-form-item>
      </el-form>
      <!--  -->
      <template #footer>
        <span class="dialog-footer">
          <el-row>
            <el-col :span="12">
              <el-button @click="isVisible = false">Cancel</el-button>
            </el-col>
            <el-col :span="12">
              <el-button type="primary" @click="addUni"> Save </el-button>
            </el-col>
          </el-row>
        </span>
      </template>
    </el-dialog>
  </span>
</template>
<script lang="ts">
import { ErrorUtil } from "@/shared/classes";
import { University } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import img from "@/assets/illustrations/user/education.svg";

@Options({
  name: "Add-Uni",
  emits: ["created"],
})
export default class extends Vue {
  img = img;
  isVisible = false;
  loading = false;

  form = {
    name: undefined as any,
    region: undefined as any,
    country: undefined as any,
  };

  /**
   * Add university
   *
   */

  addUni() {
    this.loading = true;
    this.$api.main
      .post<University>("/v1/universities", this.form)
      .then((resp) => {
        this.$emit("created", resp.data);
        this.isVisible = false;
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>

<style lang="scss">
</style>
