
import img from "@/assets/illustrations/undraw_winners_congrats.svg";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  emits: ["submit"],
})
export default class extends Vue {
  img = img;
  submit() {
    this.$emit("submit");
  }
}
