
import { FounderAccountUtil } from "@/@business/store";
import { ErrorUtil } from "@/shared";
import { Business, BusinessCoFounder, User } from "@/shared/interfaces";
import { CircleCheckFilled, WarningFilled } from "@element-plus/icons";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";

@Options({
  emits: ["opened", "closed"],
  components: {
    WarningFilled,
    CircleCheckFilled,
  },
})
export default class extends Vue {
  showClose = false;

  @Prop({ type: Boolean })
  isModalOpen!: boolean;

  @Prop({ type: Object })
  business!: Business;

  @Prop({ type: Object })
  coFounder?: BusinessCoFounder;

  @Prop({ type: Number })
  coFounderIndex!: number;

  loading = false;

  form = {
    names: undefined,
    skills: [] as any,
    education: [] as any,
  };

  mounted() {
    this.assignData();
  }

  get disableSave() {
    return this.form.names === undefined || this.form.skills.length == 0;
  }

  /**
   * @assigns data to the form when mounted
   */
  assignData() {
    this.form.names = this.coFounder?.names as any;
    this.form.skills = this.coFounder?.skills || ([] as any);
    this.form.education =
      this.coFounder?.education?.map((ed) => {
        ed.organisation =
          typeof ed.organisation == "string"
            ? ed.organisation
            : ed.organisation._id;
        return ed;
      }) || ([] as any);
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal(): void {
    console;
  }

  @Emit("opened")
  opened() {
    console;
  }

  saveData() {
    this.loading = true;
    let business: Business = { ...this.business };
    business.coFounders[this.coFounderIndex] = this.form as any;
    /**
     * Remove unwanted fields
     */
    business.coFounders = business.coFounders.map((co) => {
      co.education = co.education?.map((ed) => {
        (ed as any)._id = undefined as any;
        (ed as any).id = undefined as any;
        return ed;
      });
      co.skills = co.skills?.map((ski) => {
        (ski as any)._id = undefined as any;
        (ski as any).id = undefined as any;
        return ski;
      });
      (co as any)._id = undefined as any;
      (co as any).id = undefined as any;
      return co;
    });
    /**
     * Saved results
     */
    this.$api.main
      .patch<Business>(`/v1/businesses/${this.business._id}`, {
        coFounders: business.coFounders,
      })
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Saved co-founder's info!",
        });
        return this.finalizeUpdates(resp.data);
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  finalizeUpdates(business: Business) {
    this.loading = true;
    this.closeModal();
    FounderAccountUtil.finalizeFounderFetch(
      this.$store,
      this.$store.state.account.user as User
    );
  }
  /**
   * Skills deal with
   */
  addSkill() {
    this.form.skills.push({} as any);
  }
  removeSkill(index) {
    if (this.form.skills.length > 0) this.form.skills.splice(index, 1);
  }
  /**
   * Education deal with
   */
  addEducation() {
    this.form.education.push({} as any);
  }
  removeEducation(index) {
    this.form.education.splice(index, 1);
  }
}
