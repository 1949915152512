<template>
  <div class="App-Opp">
    <!--  -->
    <!-- Top -->
    <OpportunityHeader :opportunity="opportunity"></OpportunityHeader>
    <!--  -->
    <!-- Middle -->
    <div>
      <div>
        <h3 as-pointer @click="goToOpportunity">
          {{ opportunity.talents }} {{ opportunity.skill[1] }}
          {{ $filters.capitalize(decideTalentWording()) }} Needed to
          {{ opportunity.outcome }}
        </h3>
      </div>
      <div>
        <app-truncate
          :content="opportunity.description"
          :max="max"
          @viewMore="clickedMore"
          m-tb-0
        >
        </app-truncate>
      </div>
      <el-space :size="10" wrap>
        <el-tooltip
          v-if="opportunity.compensation"
          content="Way of compensation"
        >
          <el-tag tag-default is-all-borderless round>
            {{ $filters.capitalize(opportunity.compensation.join(", ")) }}
          </el-tag>
        </el-tooltip>
        <!--  -->
        <el-tooltip content="Way of working, can be Remote, Physical, or Both.">
          <el-tag tag-default is-all-borderless round>
            {{ $filters.capitalize(opportunity.wow) }}
          </el-tag>
        </el-tooltip>
        <!--  -->
        <span
          v-if="
            (opportunity.wow == 'hybrid' || opportunity.wow == 'physical') &&
            opportunity.location !== undefined
          "
        >
          <i primary class="el-icon-location" style="margin-right: 2px" />
          {{ opportunity.location.description }}
        </span>
        <!--  -->
        <span>
          From
          <span mute-secondary>
            {{ $moment(opportunity.startDate).format("D MMM yy") }}
          </span>
        </span>
        <span>
          To
          <span mute-secondary>
            {{ $moment(opportunity.endDate).format("D MMM yy") }}
          </span>
        </span>
        <!-- <span> {{ opportunity.talents }} {{decideTalentWording()}} needed</span> -->
      </el-space>
    </div>
    <!--  -->
    <!-- Bottom -->
    <el-divider style="margin-bottom: 8px" />
    <el-row align="center" justify="space-between" w-100>
      <div>
        <el-link v-show="!isAtMax" @click="goToOpportunity" type="primary"
          >Read More
        </el-link>
      </div>
      <div>
        <el-button
          v-if="isOwner"
          @click="updateOpportunity"
          is-all-borderless
          icon="el-icon-edit"
          size="small"
          round
          >Edit</el-button
        >
        <!-- <el-dropdown>
          <el-button
            is-all-borderless
            icon="el-icon-position"
            size="small"
            round
            >Share</el-button
          >
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="upload" icon="el-icon-twitter">
                Twitter
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->
      </div>
    </el-row>
    <OpportunityUpdateModal
      v-if="isOwner"
      @closed="stopUpdateOpportunity"
      :opportunity="opportunity"
      :isModalOpen="isEditing"
    />
  </div>
</template>

<script lang="ts">
import { Opportunity } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import OpportunityHeader from "./opportunity-header.vue";
import { OpportunityMixin } from "./opportunity-mixin";

import OpportunityUpdateModal from "./update-opportunity-modal.vue";
@Options({
  mixins: [OpportunityMixin],
  components: { OpportunityHeader, OpportunityUpdateModal },
  methods: {
    date(date: string) {
      return new Date(date).toLocaleDateString();
    },
  },

  filters: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  opportunity!: Opportunity;

  max = 250;

  get user() {
    return this.$store.state.account.user;
  }

  get isOwner() {
    return this.user?.type === "founder";
  }

  /**
   * @fired when the view more button is clicked
   */
  clickedMore() {
    this.max = 200000;
  }

  /**
   * @click go to the details page
   */
  goToOpportunity() {
    this.$router.push(`/opportunities/${this.opportunity._id}`);
  }

  get isAtMax() {
    return this.max === 200000;
  }

  decideTalentWording() {
    return this.opportunity.talents > 1 ? "talents" : "talent";
  }
}
</script>

<style lang="scss">
.App-Opp {
  min-height: 200px;
  background: var(--el-color-white);
  border-radius: map.get($--border-radius, "base");
  padding: map.get($--button-padding-vertical, "medium")
    map.get($--button-padding-vertical, "large") * 2
    map.get($--button-padding-vertical, "small")
    map.get($--button-padding-vertical, "large") * 2;
  // Top
  .App-Opp-Top {
    h3,
    h4,
    h5 {
      font-weight: 600;
      margin: 0;
    }
  }
  /**
    // Text explaining
     */
  p {
    font-size: 14px;
    font-weight: 500;
  }
  h3,
  h4,
  h5 {
    font-weight: 700;
  }
}
</style>
