
import { Options, Vue } from "vue-class-component";
import { Checked, Star } from "@element-plus/icons";
import { Watch } from "vue-property-decorator";
import { BiddingsVuexFetchOptions } from "@/@business/store/biddings.vuex";
import { RouterUtilClass } from "@/shared";
@Options({
  components: { Checked, Star },
})
export default class extends Vue {
  /**
   * @returns created biddings
   */
  get biddings() {
    return this.$store.state.biddings.biddings;
  }

  /**
   * Active index for the nav
   */
  get activeIndex() {
    return (
      this.$route.path +
      (this.$route.query.status !== undefined
        ? `?status=${this.$route.query.status}`
        : "")
    );
  }
  /**
   * @returns the loading state of the created biddings
   */
  get loading() {
    return this.$store.state.biddings.loading;
  }

  @Watch("$route.query.status")
  routeChanged() {
    this.fetch();
  }

  fetch() {
    this.$store.dispatch("biddings/fetch", {
      q: RouterUtilClass.getQuery(this.$route),
      clear: true,
    } as BiddingsVuexFetchOptions);
  }

  mounted() {
    this.fetch();
  }
}
