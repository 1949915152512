
import img from "@/assets/illustrations/email-activation.svg";
import { ErrorUtil } from "@/shared/classes";
import { Options, Vue } from "vue-class-component";
import { Emit } from "vue-property-decorator";

@Options({
  emits: ["closed", "opened", "checked"],
})
export default class extends Vue {
  img = img;
  /**
   * Modal variables
   */
  loading = false;
  isModalOpen = false;
  showClose = false;

  /**
   * @when the modal is checked
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account.user?.verifiedEmail);
    this.isModalOpen = this.$store.state.account.user?.verifiedEmail !== true;
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    // this.$store.commit("modals/bioInfo", false);
    this.isModalOpen = false;
  }

  /**
   * @resend verification email
   */
  resendVerification() {
    this.loading = true;
    this.$api.main
      .get("/v1/auth/resend-email-verify")
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Have re-sent the activation email.",
        });
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err, {
            message: "Failed to resend the activation email.",
          }),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
  }
}
