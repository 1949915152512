
import { BusinessStages } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "BusinessStageInput",
  emits: ["update:modelValue"],
})
export default class extends Vue {
  stages = BusinessStages;

  @Prop()
  modelValue!: string;

  @Prop({ type: String, default: "Startup Stage" })
  label!: string;

  setValue(value: string) {
    this.$emit("update:modelValue", value);
  }
}
