
import { OpportunityMixin } from "@/@business/views/opportunities-page/components/opportunity-mixin";
import { Opportunity, User } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import talentsList from "./talents-list.vue";

/**
 * @this component fetches the current talents
 * loops through them
 * and fetches matched talent for each opportunity
 */
@Options({
  mixins: [OpportunityMixin],
  components: { talentsList },
})
export default class extends Vue {
  @Prop({ type: Object })
  opportunity!: Opportunity;

  @Prop({ default: true, type: Boolean })
  showHeader!: boolean;

  @Prop({ default: true, type: Boolean })
  showLink!: boolean;

  ignoreRegionFilter = false;
  /**
   * @how many talents were retrieved
   */
  count() {
    return this.talents.length;
  }

  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
  talents: any[] = [];
  otherTalents: any[] = [];

  loading = false;
  /**
   *
   */
  mounted() {
    this.fetchTalents();
  }

  @Watch("opportunity")
  opportunityChanged() {
    this.fetchTalents();
  }
  /**
   * @fetches talents for a specific opportunity in a view more way
   */
  fetchTalents() {
    this.loading = true;
    this.$api.main
      .get<User[]>(`/v1/talents?opportunity=${this.opportunity._id}`)
      .then(async (resp) => {
        this.talents = resp.data;
        if (this.talents.length == 0) {
          await this.fetchTalentsWithoutRegion();
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  fetchTalentsWithoutRegion() {
    this.$api.main
      .get<User[]>(
        `/v1/talents?opportunity=${this.opportunity._id}&ignoreRegion=true`
      )
      .then((resp) => {
        this.otherTalents = resp.data;
      });
  }
}
