import { InGuardGenerate } from "@/shared/guards";
import AccountAccountPage from "@/shared/views/account-pages/account-page/account-page.vue";
import AccountProfilePage from "@/shared/views/account-pages/account-profile-page/account-profile-page.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import BusinessStore from "../store";
import AccountStartupsPage from "../views/account-pages/account-startups-page/account-startups-page.vue";
import BiddingsPage from "../views/biddings-page/biddings-page.vue";
import StartupPage from "../views/businesses-page/businesses-page.vue";
import OpportunitiesPage from "../views/opportunities-page/opportunities-page.vue";
import OpportunityPage from "../views/opportunity-page/opportunity-page.vue";
import BusinessRouterApp from "../views/Router.App.vue";
/**
 * Single talent
 */
import TalentPage from "../views/talent-page/talent-page.vue";
import InterestedTalentsPage from "../views/talents-page/interests-page/interests-page.vue";
import MatchedTalentsPage from "../views/talents-page/matched-talents-page/matched-talents-page.vue";
/**
 * Talents & Passions pages
 */
import TalentsPage from "../views/talents-page/talents-page.vue";
import TransactionsPage from "../views/transactions-page/transactions-page.vue";


/**
 *
 */
const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    beforeEnter: InGuardGenerate(BusinessStore),
    component: BusinessRouterApp,
    children: [
      {
        path: "",
        redirect: "talents",
      },
      {
        path: "opportunities",
        component: OpportunitiesPage,
      },
      {
        path: "opportunities/:id",
        component: OpportunityPage,
      },
      {
        path: "startups/:id",
        component: StartupPage,
      },
      {
        path: "talents",
        component: TalentsPage,
        children: [
          {
            path: "",
            redirect: "/talents/matched",
          },
          {
            path: "matched",
            component: MatchedTalentsPage,
          },
          {
            path: "interested",
            component: InterestedTalentsPage,
          },
          {
            path: ":pathMatch(.*)*",
            redirect: "/talents/matched",
          },
        ],
      },
      {
        path: "talent/:talent/:opportunity",
        component: TalentPage,
      },
      {
        path: "biddings",
        component: BiddingsPage,
      },
      /**
       *
       */
      {
        path: "transactions",
        component: TransactionsPage,
      },
      /**
       * Account related
       */
      {
        path: "account",
        redirect: "/account/account",
      },
      {
        path: "account/account",
        component: AccountAccountPage,
      },
      {
        path: "account/settings",
        redirect: "/account/account",
        // component: AccountSettingsPage,
      },
      {
        path: "account/profile",
        component: AccountProfilePage,
      },
      {
        path: "account/startups",
        component: AccountStartupsPage,
      },
      {
        path: "account/:pathMatch(.*)*",
        redirect: "/account/account",
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
