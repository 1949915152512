
import { Opportunity, User } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import appTalent from "./talent.vue";

@Options({
  components: { appTalent },
})
export default class extends Vue {
  @Prop({ type: Object })
  talents!: User[];

  @Prop({ type: Object })
  opportunity!: Opportunity;
}
