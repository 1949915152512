<template>
  <app-main-layout class="App-Opportunity-Page">
    <!--  -->
    <!--  -->
    <template v-slot:left>
      <app-left-nav />
    </template>
    <!--  -->
    <template v-slot:bottom-nav>
      <app-bottom-nav />
    </template>
    <!--  -->
    <!--  -->
    <template v-slot:top>
      <app-top-nav :padded="false">
        <template v-slot:left>
          <el-button
            v-if="false"
            type="text"
            plain
            circle
            is-all-borderless
            icon="el-icon-back"
          />
        </template>
        <template v-slot:center>
          <span style="line-height: 20px !important" nav-title>
            <h4
              style="padding-right: 6px"
              m-tb-0
              v-if="opportunity !== undefined"
            >
              {{ opportunity.outcome }}
            </h4>
            <div>
              <span mute-secondary style="font-weight: 500; font-size: 14px">
                Opportunity
              </span>
            </div>
          </span>
        </template>
        <template v-if="opportunity !== undefined && user" v-slot:right>
          <el-dropdown v-if="user.type === 'founder'" @command="handleCommand">
            <el-button type="primary" icon="el-icon-more" round></el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="edit" icon="el-icon-edit">
                  Edit
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </app-top-nav>
    </template>
    <!--  -->
    <template v-slot:middle>
      <!--  -->
      <div v-loading="loading" style="min-height: 60vh">
        <div v-if="opportunity !== undefined">
          <!--  -->
          <div>
            <OpportunityPageHeader
              v-if="business"
              :business="business"
              :opportunity="opportunity"
            />
            <!--  -->
            <div class="App-Opportunity-D">
              <!--  -->
              <!-- Top -->
              <div class="App-Opportunity-D-T" m-t-1 m-b-2>
                <p txt-center m-tb-0>
                  Looking For
                  <span dark f-w-700>{{ opportunity.talents }} Talent(s)</span>
                </p>
                <h3
                  txt-center
                  f-w-600
                  m-tb-0
                  v-if="Array.isArray(opportunity.skill)"
                >
                  <span mute-secondary>In </span>
                  {{ $filters.capitalize(opportunity.skill[0]) }} /
                  {{ $filters.capitalize(opportunity.skill[1]) }}
                </h3>
                <h2 m-tb-10px txt-center primary>
                  {{ opportunity.outcome }}
                </h2>
              </div>
              <!--  -->
              <!-- Startup profile -->
              <app-startup-opp :business="business"></app-startup-opp>
              <!--  -->
              <!-- Region Filtering -->
              <div v-if="opportunity.regionFilter">
                <div>
                  <el-divider />
                  <el-row>
                    <el-col :span="24">
                      <div class="App-Opp-Star-C">
                        <div class="App-Opp-Star-C-S" mute-secondary>
                          Looking for talents from
                        </div>
                        <div
                          v-if="opportunity.regionFilter.options"
                          class="App-Opp-Star-C-B"
                        >
                          <!-- {{ opportunity.regionFilter.type }} -->
                          {{
                            opportunity.regionFilter.options
                              .map((c) => $filters.capitalize(c))
                              .join(", ")
                          }}
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <!--  -->
              <!-- Other Opp detaisl -->
              <app-section-divider>
                <!-- <el-icon>
                  <OpportunityBulb />
                </el-icon> -->
              </app-section-divider>
              <el-space direction="vertical" alignment="flex-start" :size="16">
                <OpportunityPageDetails
                  :opportunity="opportunity"
                  :business="business"
                />
                <app-truncate
                  style="font-size: 14px"
                  :content="opportunity.description"
                  :max="4000"
                  m-tb-0
                >
                </app-truncate>
              </el-space>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div v-if="opportunity !== undefined && user.type === 'founder'">
            <!--  -->
            <!--  -->
            <OpportunityUsersList
              :showHeader="false"
              :showLink="false"
              :opportunity="opportunity"
            />
            <!--  -->
            <OpportunityUpdateModal
              @closed="stopUpdateOpportunity"
              :opportunity="opportunity"
              :isModalOpen="isEditing"
              @update="opportunityFetch"
            />
            <!--  -->
          </div>
        </div>
      </div>
    </template>
    <!--  -->
    <template v-slot:right>
      <app-right-nav>
        <!--  -->
        <div v-if="user.type === 'founder'">
          <app-business-right-nav />
        </div>
        <div v-else>
          <app-user-right-intro />
        </div>
        <app-opportunities-list-nav />
      </app-right-nav>
    </template>
    <!--  -->
    <!--  -->
  </app-main-layout>
</template>

<script lang="ts">
import { Opportunity } from "@/shared";
import { Checked, InfoFilled } from "@element-plus/icons";
import { Options, Vue } from "vue-class-component";

import OpportunityUsersList from "../talents-page/matched-talents-page/components/opportunity-talents-list.vue";
import OpportunityPageHeader from "./opportunity-page-header.vue";
import OpportunityPageDetails from "./opportunity-page-details.vue";
import {
  UserFilled,
  Opportunity as OpportunityBulb,
} from "@element-plus/icons";
import { OpportunityMixin } from "../opportunities-page/components/opportunity-mixin";
import OpportunityUpdateModal from "../opportunities-page/components/update-opportunity-modal.vue";
import { Watch } from "vue-property-decorator";
import appStartupOpp from "./opportunity-startup-details.vue";

@Options({
  mixins: [OpportunityMixin],
  components: {
    OpportunityUsersList,
    OpportunityPageHeader,
    OpportunityPageDetails,
    Checked,
    InfoFilled,
    UserFilled,
    OpportunityUpdateModal,
    appStartupOpp,
    OpportunityBulb,
  },
  data() {
    return {
      opportunity: undefined,
    };
  },
})
export default class extends Vue {
  loading = true;

  get isSelf() {
    return this.user?.type === "founder";
  }

  get user() {
    return this.$store.state.account.user;
  }

  mounted() {
    this.opportunityFetch();
  }

  @Watch("$route.params.id")
  whenRouteChanges() {
    this.opportunityFetch();
  }

  /**
   * @param command
   */
  handleCommand(command: "edit" | "archive") {
    if (command === "edit") (this as any).updateOpportunity();
    else this.archiveOpportunity();
  }

  archiveOpportunity() {
    console;
  }
  /**
   * checks if @current browsed user exists
   */
  opportunityFetch() {
    this.loading = true;
    this.$api.main
      .get<Opportunity>(`/v1/opportunities/${this.$route.params.id}`)
      .then((resp) => {
        (this.$data as any).opportunity = resp.data;
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 300);
      });
  }

  /**
   * @if talent we don't fetch the opportunity
   * We retrieve it from the $store.state
   */
  getIfTalent() {
    console;
  }
  /**
   * @business
   */
  get business() {
    return this.user?.type === "founder"
      ? this.businessState
      : (this.$data as any).opportunity.business;
  }

  get businessState() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
}
</script>

<style lang="scss">
.App-Opportunity-Page {
  .App-Opportunity-D {
    background: white;
    min-height: 400px;
    padding: 10px 24px;
  }
  .App-Top-Nav-H {
    padding: 16px 0;
  }
  .App-Opportunity-T {
    background-color: #fdfefe;
    min-height: 60px;
    padding: 10px 20px 20px 20px;
  }
  [nav-title],
  [nav-title-med] {
    display: block !important;
  }
}
</style>
