
import { AuthUtil } from "@/@auth/classes";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class extends Vue {
  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }

  /**
   * @handles the logout process
   */
  logout() {
    AuthUtil.logout();
  }
}
