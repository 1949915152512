<template>
  <div>
    <el-dialog
      custom-class="App-Create-Business-Modal App-Unpadded"
      :model-value="isModalOpen"
      width="550px"
      :show-close="showClose"
      :close-on-click-modal="showClose"
      :close-on-press-escape="showClose"
      @open="opened"
      title="Co-founder Profiles"
      center
      :append-to-body="true"
      :lock-scroll="true"
      :open-delay="200"
      :before-close="closeModal"
      :close-delay="200"
    >
      <!--  -->
      <el-scrollbar :always="true" v-loading="loading" max-height="70vh">
        <div>
          <!--  -->
          <div>
            <div>
              <div txt-center>
                <el-image :style="`width: 250px;`" :src="img"></el-image>
                <p
                  v-if="business.coFoundersSize > 0"
                  m-rl-auto
                  style="max-width: 350px"
                  txt-center
                >
                  Provide your co-founders <br />
                  education and skills information
                </p>
                <p v-else m-rl-auto style="max-width: 350px" txt-center>
                  You don't need to fill this.
                  <br />
                  You have indicated that you don't have co-founders.
                </p>
              </div>
            </div>
          </div>
          <!--  -->
          <div txt-center>
            <el-space fill w-100 alignment="center" direction="vertical">
              <div
                v-for="(coFounder, index) of coFounders"
                :key="`cofounder-${index}`"
              >
                <BusinessCofounderModal
                  v-if="modals[index] === true"
                  :isModalOpen="modals[index] === true"
                  @closed="modals[index] = false"
                  :business="business"
                  :coFounder="coFounders[index]"
                  :coFounderIndex="index"
                />
                <el-space :wrap="false" direction="horizontal">
                  <el-tag
                    :style="{
                      minWidth:
                        index != 0 && index != coFounders.length - 1
                          ? '250px'
                          : '100px',
                    }"
                    type="info"
                    round
                  >
                    <span style="padding-right: 6px">
                      <el-icon v-if="isCoFounderFilled(index)">
                        <CircleCheckFilled success />
                      </el-icon>
                      <el-icon v-else>
                        <WarningFilled danger />
                      </el-icon>
                    </span>

                    <span v-if="coFounder">
                      {{
                        $filters.capitalize(
                          coFounder.names ||
                            `Provide Names (Co-founder ${index + 1})`
                        )
                      }}
                    </span>
                    <span v-else>
                      Provide Names (Co-founder {{ index + 1 }})</span
                    >
                  </el-tag>
                  <el-button
                    @click="editCofounder(index)"
                    icon="el-icon-edit"
                    circle
                    size="mini"
                  />
                </el-space>
              </div>
            </el-space>
          </div>
          <!--  -->
        </div>
      </el-scrollbar>
      <!--  -->
      <template #footer>
        <div txt-center>
          <el-button
            is-block
            icon="el-icon-check"
            @click="exit"
            size="large"
            type="primary"
            :disabled="someInfoIsMissing() || loading"
          >
            Continue
          </el-button>
        </div>
      </template>
      <!--  -->
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { Business } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import img from "@/assets/illustrations/startup/team.svg";
import { WarningFilled, CircleCheckFilled } from "@element-plus/icons";
import BusinessCofounderModal from "./business-cofounder-modal.vue";

@Options({
  emits: ["checked", "opened", "closed"],
  components: {
    WarningFilled,
    CircleCheckFilled,
    BusinessCofounderModal,
  },
})
export default class extends Vue {
  img = img;
  isModalOpen = false;
  showClose = false;

  /** Indicates the modals opening */
  modals: Array<number> = [];

  @Prop({ type: Object })
  business!: Business;

  loading = false;

  @Emit("checked")
  mounted() {
    this.shouldOpen();
  }

  @Watch("business")
  listenToChanges() {
    this.shouldOpen();
  }

  exit() {
    this.closeModal();
  }

  someInfoIsMissing() {
    console.log("Check if some info is missing ", this.business.coFounders);
    console.log("Check if some info is missing ", this.business.coFoundersSize);
    if (
      this.business.coFounders === undefined ||
      this.business.coFoundersSize !== this.business.coFounders?.length
    ) {
      console.log("coFounder ", true)
      return true;
    }
    this.business.coFounders?.forEach((coFounder) => {
      console.log("coFounder in coFounder", coFounder)
      // If any of the cofounders doesn't have the skills set
      // Education is not mandatory
      if (
        coFounder === undefined ||
        coFounder.names === undefined ||
        coFounder.skills === undefined ||
        coFounder.skills.length === 0
      ) {
        console.log("coFounder 2", true)
        return true;
      }
    });
  }
  /**
   * @loops within the co-founders and checks if their information
   * Was filled in
   */
  shouldOpen(): boolean {
    // Size is different?
    console.log("In business cofounders");
    if (this.someInfoIsMissing()) {
      console.log("Some info is missing");
      this.$store.commit("modals/coFounders", true);
      this.isModalOpen = true;
    }
    return this.isModalOpen;
  }

  @Watch("$store.state.modals.coFounders")
  watchForOpen(): void {
    this.isModalOpen = this.$store.state.modals.coFounders;
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal(): void {
    this.$store.commit("modals/coFounders", false); // This is enough itself
    this.isModalOpen = false;
  }

  @Emit("opened")
  opened() {
    console;
  }

  get coFounders(): Array<any> {
    if (this.business.coFounders.length === this.business.coFoundersSize) {
      return this.business.coFounders;
    } else {
      return this.business.coFounders.concat(
        new Array(
          this.business.coFoundersSize - this.business.coFounders.length
        )
      );
    }
  }

  isCoFounderFilled(index): boolean {
    if (
      this.coFounders[index]?.names === undefined ||
      this.coFounders[index]?.names === "" ||
      this.coFounders[index]?.skills === undefined ||
      this.coFounders[index]?.skills.length === 0
    ) {
      return false;
    }
    return true;
  }
  /**
   * Trigger editing the cofounder info
   * @param userIndex
   */
  editCofounder(userIndex: number): void {
    this.modals[userIndex] = true as any;
  }
}
</script>

<style lang="scss">
.App-Create-Business-Modal {
}
</style>
