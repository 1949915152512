<template>
  <div class="App-User-Section">
      <slot />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},

})
export default class extends Vue {}
</script>

<style lang="scss">
.App-User-Section {
  background: white;
  border-radius: map.get($--border-radius, "base");
  padding: 16px 28px;
  min-height: 150px;
  margin-bottom: 8px;
}
</style>
