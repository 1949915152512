<template>
  <div class="App-Password-Strength-H">
    <div class="App-Password-Strength" :class="result.value">
      <div>
        <div
          :class="(isTooWeak || isWeak || isMedium || isStrong).toString()"
        ></div>
      </div>
      <div>
        <div :class="(isWeak || isMedium || isStrong).toString()"></div>
      </div>
      <div>
        <div :class="(isMedium || isStrong).toString()"></div>
      </div>
      <div>
        <div :class="isStrong.toString()"></div>
      </div>
    </div>
    <div class="App-Password-Feed">
      <small mute-primary>
        {{ result.value }}
      </small>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

interface PasswordStrength {
  id: number;
  value: "Too weak" | "Weak" | "Medium" | "Strong";
  contains: ["lowercase", "uppercase", "symbol", "number"];
  length: number;
}
@Options({})
export default class extends Vue {
  @Prop()
  password!: string;

  result: PasswordStrength = {} as any;

  get isTooWeak() {
    return this.result.value === "Too weak";
  }

  get isWeak() {
    return this.result.value == "Weak";
  }

  get isMedium() {
    return this.result.value == "Medium";
  }

  get isStrong() {
    return this.result.value == "Strong";
  }

  @Watch("password")
  passwordChanged() {
    if (this.password.length > 0) {
      this.$api.main
        .post<PasswordStrength>("/v1/auth/password-check", {
          password: this.password,
        })
        .then((resp) => (this.result = resp.data));
    }
  }
}
</script>

<style lang="scss">
.App-Password-Strength-H {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  .App-Password-Feed {
    width: 100px;
    text-align: right;
    margin-bottom: 2;
  }
}
.App-Password-Strength {
  display: flex;
  flex-grow: 1;
  width: 100%;
  > * > * {
    transition: 300ms ease;
  }
  .false {
    background-color: grey;
    opacity: 0.2;
  }
  //   justify-content: space-between;
  &.Strong {
    > * > .true {
      background-color: green;
    }
  }
  &.Medium {
    > * > .true {
      background-color: orange;
    }
  }
  &.Weak,
  &.Too.weak {
    > * > .true {
      background-color: red;
    }
  }
  //
  > * {
    padding: 3px 2px 3px 2px;
    width: math.div(100%, 4);
    > * {
      height: 4px;
      border-radius: 4px;
    }
  }
}
</style>
