import { $api } from "@/shared";
import { User, Business } from "@/shared/interfaces";
import { createStore, Module, StoreOptions } from "vuex";

export interface AccountVuexState {
  user?: User;
  business?: string; // Default business being addressed
  businesses?: Business[];
  loading: boolean;
  fetchedBusinesses: boolean;
}

export const AccountVuex: StoreOptions<AccountVuexState> = {
  ...{ namespaced: true },
  state: {
    user: undefined,
    loading: false,
    fetchedBusinesses: false,
  },
  mutations: {
    /**
     * Assigns a business as default
     * @param state
     * @param business
     */
    business(state, business: string) {
      state.business = business;
    },
    /**
     * Assigns businesses as default
     * @param state
     * @param businesses
     */
    businesses(state, businesses: Business[]) {
      state.businesses = businesses;
    },
    fetchedBusinesses(state, fetchedBusinesses: boolean) {
      state.fetchedBusinesses = fetchedBusinesses;
    },
    /**
     * Assigns a business as default
     * @param state
     * @param user
     */
    user(state, user: User) {
      state.user = user;
    },
  },
  actions: {
    /**
     * Fetches this user and populates the user part
     * @param context
     */
    fetch(context) {
      context.state.loading = true;
      $api.main
        .get<User>("/v1/account")
        .then((resp) => {
          context.commit("user", resp.data);
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
  },
  modules: {},
  //
  getters: {},
};

export const AccountBusinessExtendedVuex = {
  state: {},
  actions: {},
};
