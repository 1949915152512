
import { Business } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { OfficeBuilding } from "@element-plus/icons";
@Options({
  components: { OfficeBuilding },
  methods: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  business!: Business;

  @Prop({ type: Boolean, default: true })
  showHeader!: boolean;
}
