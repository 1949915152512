<template>
  <div>
    <el-dialog
      custom-class="App-User-Profile-Modal App-Unpadded"
      :model-value="isModalOpen"
      width="550px"
      :show-close="showClose"
      :close-on-click-modal="showClose"
      @open="opened"
      title="Work Experience"
      center
      :append-to-body="true"
      :lock-scroll="true"
      :open-delay="200"
      :close-delay="200"
      :before-close="closeModal"
      :close-on-press-escape="false"
    >
      <!--  -->
      <el-scrollbar :always="true" max-height="600px">
        <div v-loading="loading">
          <div>
            <div txt-center>
              <el-image :style="`width: 150px;`" :src="img"></el-image>
              <p>Tell us about your professional experience</p>
            </div>
          </div>
          <el-form
            @submit.prevent
            class="App-Styled-Form"
            :label-position="'top'"
            label-width="100px"
            :model="form"
            style="margin-top: 12px"
          >
            <!--  -->
            <div
              v-for="(exp, index) of form.experience"
              :key="Math.random() + index"
            >
              <app-section-divider>
                <i class="el-icon-document"></i>
                <span style="padding: 0 10px">
                  {{
                    $filters.capitalize(exp.organisation || "Work Experience")
                  }}
                </span>
                <el-button
                  v-if="index > 0"
                  @click="removeExperience(index)"
                  icon="el-icon-delete"
                  circle
                  size="mini"
                />
              </app-section-divider>
              <el-form-item prop="organisation" label="Organisation">
                <el-input
                  placeholder="EX: E4T"
                  v-model="form.experience[index].organisation"
                />
              </el-form-item>
              <el-form-item prop="role" label="Role">
                <el-input
                  placeholder="EX: UI/UX Designer"
                  v-model="form.experience[index].role"
                />
              </el-form-item>
              <el-row :gutter="12">
                <el-col :span="12">
                  <el-form-item prop="From" label="Start Date">
                    <el-date-picker
                      type="month"
                      format="MM/YYYY"
                      placeholder="MM/YYYY"
                      v-model="form.experience[index].from"
                    />
                  </el-form-item>
                </el-col>
                <el-col v-if="stillThere[index] !== true" :span="12">
                  <el-form-item prop="To" label="End Date">
                    <el-date-picker
                      type="month"
                      format="MM/YYYY"
                      placeholder="MM/YYYY"
                      v-model="form.experience[index].to"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <div style="margin-bottom: 36px; margin-top: -10px">
                    <el-checkbox
                      @change="
                        if (stillThere[index])
                          form.experience[index].to = undefined;
                      "
                      v-model="stillThere[index]"
                      label="Currently working at the organization."
                      size="small"
                    />
                  </div>
                </el-col>
              </el-row>
            </div>
            <!--  -->
            <div txt-center>
              <el-button
                type="info"
                is-borderless
                round
                @click="addExperience"
                size="small"
                icon="el-icon-plus"
                >Add Experience</el-button
              >
            </div>
            <!--  -->
          </el-form>
        </div>
      </el-scrollbar>
      <!--  -->
      <template #footer>
        <div txt-center>
          <el-button
            is-block
            icon="el-icon-check"
            @click="submit"
            size="large"
            type="primary"
            :disabled="loading || disabled"
          >
            Save Experience
          </el-button>
        </div>
      </template>
      <!--  -->
    </el-dialog>
  </div>
</template>

<script lang="ts">
import img from "@/assets/illustrations/user/experience.svg";
import { ErrorUtil } from "@/shared/classes";
import { User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";

@Options({
  name: "Add-Experience",
  emits: ["checked", "opened", "closed"],
})
export default class extends Vue {
  img = img;
  /**
   * Modal variables
   */
  loading = false;
  isModalOpen = false;
  showClose = true;
  /**
   * experience dull
   */
  nullExperience = {
    organisation: undefined as unknown as string,
    from: undefined as unknown as string,
    to: undefined as unknown as string,
    role: undefined as unknown as string,
    description: undefined as unknown as string,
  };
  /**
   * Form Data
   */
  stillThere = [];
  form = {
    experience: [Object.assign({}, this.nullExperience)],
  };

  get disabled() {
    return this.form.experience.length == 0;
  }

  addExperience() {
    this.form.experience.push(Object.assign({}, this.nullExperience));
  }
  removeExperience(index: number) {
    this.form.experience.splice(index, 1);
  }
  /**
   * @when the modal is checked
   */
  @Emit("checked")
  mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      this.$store.state.account.user?.experience === undefined ||
      this.$store.state.account.user?.experience?.length === 0;
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<User>("/v1/account/profile", this.form)
      .then((resp) => {
        AccountUtil.fetchCurrent(this.$store).finally(
          () => (this.loading = false)
        );
        this.closeModal();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.$store.commit("modals/addExperience", false); // This is enough itself
    this.isModalOpen = false;
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to add education
   */
  get modalStoreStatus() {
    return this.$store.state.modals.addExperience;
  }
  @Watch("modalStoreStatus")
  watchForOPen() {
    console.log("Clicked the add experience");
    this.isModalOpen = this.modalStoreStatus;
    if (this.isModalOpen) this.showClose = true;
  }
  /**
   * @update account after some changes
   */
  makeUpdatesAfterCreate() {
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    this.prepareExistingData();
  }

  get user() {
    return this.$store.state.account.user;
  }
  /**
   * @on open add the existing data to the form
   */
  prepareExistingData() {
    this.form.experience =
      this.user?.experience?.map((r) => ({
        organisation: r.organisation,
        from: r.from,
        to: r.to,
        role: r.role,
        description: r.description,
      })) || [];
  }
}
</script>

<style lang="scss">
.App-User-Profile-Modal {
  .App-Styled-Form {
    max-width: 400px;
    margin: auto;
  }
}
</style>
