<template>
  <div class="App-User-Right">
    <!--  -->
    <div>
      <h3 f-w-700 txt-center m-tb-0>
        {{ $filters.capitalize(user.firstName) }}
        {{ $filters.capitalize(user.lastName) }}
      </h3>
    </div>
    <!--  -->
    <div>
      <h4 f-w-600 txt-center m-tb-0 v-if="user.headine || user.areaOfExpertise">
        {{ $filters.capitalize(user.headine || user.areaOfExpertise) }}
      </h4>
    </div>
    <!--  -->
    <div txt-center mute-secondary v-if="user.location">
      {{ user.location.description }}
    </div>
    <!--  -->
    <div m-tb-1 txt-center>
      <el-space :size="10">
        <el-tag
          v-if="user.linkedin"
          style="color: rgb(10, 102, 194); background: rgba(10, 102, 194, 0.1)"
          as-pointer
          @click="copyContent(user.linkedIn)"
          is-all-borderless
          round
        >
          <i class="el-icon-link" />
          LinkedIn
        </el-tag>
        <el-tag
          v-if="user.portfolio"
          as-pointer
          @click="copyContent(user.portfolio)"
          type="primary"
          is-all-borderless
          round
        >
          <i class="el-icon-link" />
          Portfolio
        </el-tag>
      </el-space>
    </div>
    <!--  -->
    <div>
      <app-ent-standing
        :labelSelf="`${$filters.capitalize(user.firstName)} (You)`"
        :standingSelf="user.standing"
        size="mini"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { ErrorUtil } from "@/shared/classes";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class extends Vue {
  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }
  /**
   * @copies the text
   */
  copyContent(txt: string) {
    console.log("Clicking copy");
    navigator.clipboard
      .writeText("txt")
      .then(() => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: "Copied the link.",
        });
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      });
  }
}
</script>

<style lang="scss">
.App-User-Right {
  padding: 10px 20px;
}
</style>
