import { SurveyScore } from "./survey";

/**
 * User
 */
export type UserType = "founder" | "talent" | "admin";
export type QuestionCategory =
  | "thinking"
  | "perseverance"
  | "confidence"
  | "decision"
  | "motivation"
  | "planning"
  | "vision"
  | "leadership";
export const QuestionCategories = [
  "thinking",
  "perseverance",
  "confidence",
  "decision",
  "motivation",
  "planning",
  "vision",
  "leadership",
];
export const QuestionCategoryFull = {
  thinking: "Contrarian thinking",
  perseverance: "Perseverance",
  confidence: "Confidence",
  decision: "Decision-making under uncertainty",
  motivation: "Motivation",
  planning: "Planning",
  vision: "Vision",
  leadership: "Leadership",
};
export interface User {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  type: UserType;

  // if founder
  defaultBusiness?: string;

  canRetakeSurvey: boolean;

  standing: SurveyScore;
  survey: string;
  avatar: string;
  cover: string;
  keywords: string[];
  verifiedEmail: boolean;

  onboarding: {
    toldToFillProfile: boolean;
    skippedEducation: boolean;
    skippedStandardTests: boolean;
    skippedProfilePicture: boolean;
    skippedInvitingTalents: boolean;
  };

  availableForBids: boolean;

  standardTests: UserStandardTest[];

  experience?: UserExperience[];
  education?: UserEducation[];
  skills?: UserSkill[];

  startupExperience?: UserStartupExperience;

  areaOfExpertise?: string;

  otherSkills?: string[];
  interests?: string[];

  DOB: string;
  gender: string;
  location: Record<string, string>;
}

/**
 * User interfaces
 */
export interface UserExperience {
  organisation: string;
  from: string;
  to: string;
  role: string;
  description: string;
}

export interface UserEducation {
  organisation: string | { name: string; _id: string };
  from: Date;
  to: Date;
  degree?: string;
  program: string;
  description: string;
}

/**
 *
 */
export const StandarTests = [
  { name: "GMAT", minScore: 0, maxScore: 10000 },
  // { name: "GRE (verbal + math)", minScore: 260, maxScore: 340 },
  { name: "LSAT", minScore: 120, maxScore: 180 },
  { name: "SAT", minScore: 400, maxScore: 1600 },
  { name: "ACT", minScore: 1, maxScore: 36 },
  { name: "Standard IQ Test", minScore: 0, maxScore: 10000 },
];
interface UserStandardTest {
  name: string;
  score: number;
}

export interface UserSkill {
  name: string;
  years: Date;
}
interface UserStartupExperience {
  models: string[];
  stages: string[];
}
