<template>
  <div class="Acc-Page-Right-Nav">
    <!--  -->
    <div v-if="user.type === 'talent'">
      <app-user-right-intro />
    </div>
    <div v-else>
      <app-business-right-nav />
    </div>
    <!--  -->
    <el-menu router class="Acc-Page-Right-Menu">
      <el-menu-item index="/account/account">
        <i class="el-icon-lock"></i>
        <span>Account</span>
      </el-menu-item>
      <!--  -->
      <el-menu-item index="/account/profile">
        <i class="el-icon-lock"></i>
        <span>Profile</span>
      </el-menu-item>
      <!--  -->
      <el-menu-item v-if="false" index="/account/settings">
        <i class="el-icon-setting"></i>
        <span>Settings</span>
      </el-menu-item>
      <!--  -->
      <el-menu-item v-if="user.type === 'founder'" index="/account/startups">
        <i class="el-icon-office-building"></i>
        <span>Startups</span>
      </el-menu-item>
      <!--  -->
    </el-menu>
    <!--  -->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { UserFilled } from "@element-plus/icons";

@Options({ name: "AccountPagesRightNav", components: { UserFilled } })
export default class extends Vue {
  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }
}
</script>

<style lang="scss">
.Acc-Page-Right-Nav {
  // Menu
  .Acc-Page-Right-Menu {
    margin-top: 16px;
    .el-menu-item {
      height: 54px;
      line-height: 54px;
      color: $--color-dark-blue;
      font-weight: 600;
      border-radius: 6px;
      // max-width: 200px;
      margin: 3px 0;

      // Icon
      [class^="el-icon-"] {
        background: rgba($color: $--color-primary, $alpha: 0.1);
        line-height: 38px;
        width: 38px;
        border-radius: 40px;
        color: black;
        font-weight: bolder;
        margin-right: 10px;
      }
    }
  }
}
</style>
