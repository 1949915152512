
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TermsLinksClass } from "@/shared/classes";

@Options({
  components: {},
})
export default class extends Vue {
  @Prop({ default: true })
  openExternal!: boolean;

  @Prop({ default: "default" })
  size!: "large" | "default";

  get currentYear() {
    return new Date().getFullYear();
  }

  /**
   * @param link
   */
  openLink(link: string) {
    if (this.openExternal) {
      TermsLinksClass.navigateOut(link);
    } else {
      this.$router.push(`/terms/${link}`);
    }
  }
}
