<template>
  <div class="App-Bottom-Nav">
    <el-menu mode="horizontal">
      <!--  -->
      <!--  -->
      <!--  -->
      <router-link to="/talents">
        <el-menu-item>
          <i class="el-icon-user"></i>
          <span>Talents</span>
        </el-menu-item>
      </router-link>
      <!--  -->
      <!--  -->
      <router-link to="/opportunities?isArchived=false">
        <el-menu-item>
          <i class="el-icon-s-opportunity"></i>
          <span>Opportunities</span>
        </el-menu-item>
      </router-link>
      <!--  -->
      <router-link to="/biddings">
        <el-menu-item index="/biddings">
          <i class="el-icon-document"></i>
          <span>Bids</span>
        </el-menu-item>
      </router-link>
      <!--  -->
      <router-link to="/transactions">
        <el-menu-item index="/transactions">
          <i class="el-icon-money"></i>
          <span>Transactions</span>
        </el-menu-item>
      </router-link>
      <!--  -->
      <span>
        <el-dropdown>
          <el-menu-item>
            <i class="el-icon-user"></i>
            <span>Account</span>
          </el-menu-item>
          <!--  -->
          <template #dropdown>
            <el-dropdown-menu>
              <router-link to="/account/profile">
                <el-dropdown-item
                  :divided="user.type === 'founder'"
                  icon="el-icon-user"
                >
                  Account Profile
                </el-dropdown-item>
              </router-link>
              <!--  -->
              <router-link to="/account/settings">
                <el-dropdown-item icon="el-icon-setting">
                  Account Settings
                </el-dropdown-item>
              </router-link>
              <!--  -->
              <div @click="logout">
                <el-dropdown-item divided icon="el-icon-right">
                  Logout
                </el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </template>
          <!--  -->
        </el-dropdown>
      </span>
      <!--  -->
      <!--  -->
    </el-menu>
  </div>
</template>

<script lang="ts">
import { AuthUtil } from "@/@auth/classes";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class extends Vue {
  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }

  /**
   * @handles the logout process
   */
  logout() {
    AuthUtil.logout();
  }
}
</script>

<style lang="scss"></style>
