<template>
  <app-main-layout>
    <!--  -->
    <!--  -->
    <template v-slot:left>
      <app-left-nav />
    </template>
    <!--  -->
    <template v-slot:bottom-nav>
      <app-bottom-nav />
    </template>
    <!--  -->
    <!--  -->
    <template v-slot:top>
      <app-top-nav :padded="false">
        <template v-slot:left>
          <el-button
            v-if="false"
            type="text"
            plain
            circle
            is-all-borderless
            icon="el-icon-back"
          />
        </template>
        <template v-slot:center>
          <span nav-title>Account Profile</span>
        </template>
        <template v-slot:right> </template>
        <template v-slot:bottom> </template>
      </app-top-nav>
    </template>
    <!--  -->
    <template v-slot:middle>
      <!--  -->
      <div v-loading="loading" v-if="user">
        <!--  -->
        <!--  -->
        <AccPageHeader>
          <template #icon>
            <el-icon><UserFilled primary /></el-icon>
          </template>
          <template #content>
            <span>
              Customize how your profile looks like for other E4T users
            </span>
          </template>
        </AccPageHeader>
        <!--  -->
        <!-- Top profile and skills -->
        <div App-User-Profile-Top></div>
        <app-user-section
          style="
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
          "
        >
          <app-user-top-profile :isSelf="isSelf" :user="user" />
        </app-user-section>
        <!--  -->
        <!--  -->
        <app-user-skills :isSelf="isSelf" :user="user" />
        <!--  -->
        <!-- Education & Experience -->
        <app-user-section>
          <app-user-education :isSelf="isSelf" :user="user" />
          <app-user-experience v-if="isTalent" :isSelf="isSelf" :user="user" />
        </app-user-section>
        <!--  -->
        <!-- standard tests -->
        <app-user-section>
          <app-user-standardtests :isSelf="isSelf" :user="user" />
        </app-user-section>
        <!--  -->
        <!--  -->
      </div>
    </template>
    <!--  -->
    <template v-slot:right>
      <app-right-nav>
        <AccPageRightNav />
      </app-right-nav>
    </template>
    <!--  -->
    <!--  -->
  </app-main-layout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AccPageHeader from "../components/account-page-header-component.vue";
import { UserFilled } from "@element-plus/icons";
import AccPageRightNav from "../components/account-pages-right-nav.vue";

@Options({
  name: "AccountProfilePage",
  components: { AccPageHeader, UserFilled, AccPageRightNav },
})
export default class extends Vue {
  /**
   * So the user can be able to edit
   */
  isSelf = true;

  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }
  get loading() {
    return this.$store.state.account === undefined;
  }

  get isTalent() {
    return this.user?.type == "talent";
  }
}
</script>

<style lang="scss"></style>
