<template>
  <div class="App-Right-Nav">
    <app-account-top :user="user" />
    <!--  -->
    <div class="App-Right-Nav-Cont">
      <slot> </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class extends Vue {
  /**
   * @returns current signed in user
   */
  get user() {
    return this.$store.state.account.user;
  }
}
</script>

<style lang="scss">
.App-Right-Nav {
  min-height: 300px;
  background: white;
  border-radius: var(--el-border-radius-base) !important;

  //
  //
  .App-Right-Nav-Cont {
    padding: map.get($--button-padding-vertical, "large")
      map.get($--button-padding-vertical, "large");
  }
}
</style>
