<template>
  <div class="App-Opp-List-Nav">
    <!--  -->
    <el-skeleton :loading="loading" :rows="5" animated>
      <!--  -->
      <template #template>
        <el-space fill w-100 :size="12">
          <el-skeleton-item
            v-for="opp of [1, 2, 3, 4]"
            :key="`skele${opp}`"
            variant="p"
            style="width: 50%"
          />
        </el-space>
      </template>
      <!--  -->
      <!--  -->
      <template #default>
        <div v-if="opportunities.length > 0">
          <app-section-divider content="Opportunities">
            <el-icon><Bulb /> </el-icon>
          </app-section-divider>
          <el-space fill w-100 :size="12">
            <router-link
              v-for="opp of opportunities"
              :key="`opp-nav-${opp}`"
              :to="`/opportunities/${opp._id}`"
            >
              <div class="App-Opp-List-C">
                <el-icon class="App-Icon" style="font-size: 30px"
                  ><CircleCheck />
                </el-icon>
                <div>
                  <h4 m-tb-0 f-w-700>{{ opp.outcome }}</h4>
                  <div  style="margin-top: -2px;">
                    <span mute-primary>
                      {{ $filters.capitalize(opp.skill[0]) }} /
                      {{ $filters.capitalize(opp.skill[1]) }}
                    </span>
                  </div>
                  <div style="margin-top: -3px;">
                    <el-space wrap :size="10">
                      <small mute-secondary>
                        {{ $moment(opp.startDate).format("D MMM yy") }}
                      </small>
                      <span>-</span>
                      <small mute-secondary>
                        {{ $moment(opp.endDate).format("D MMM yy") }}
                      </small>
                    </el-space>
                  </div>
                </div>
              </div>
            </router-link>
          </el-space>
        </div>
      </template>
      <!--  -->
      <!--  -->
    </el-skeleton>
  </div>
</template>

<script lang="ts">
import { Opportunity } from "@/shared";
import { ApiResourceResult } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { CircleCheck, Opportunity as Bulb } from "@element-plus/icons";

@Options({
  components: { CircleCheck, Bulb },
  methods: {},
})
export default class extends Vue {
  /**
   * @variables indicating the opportunies fetch
   */
  opportunities: Opportunity[] = [];
  loading = true;
  count = undefined as any;

  get user() {
    return this.$store.state.account.user;
  }

  mounted() {
    this.fetchOpportunities();
  }

  /**
   * @fetches the opportunies availble from this business profile
   */
  fetchOpportunities() {
    return this.$api.main
      .get("/v1/opportunities?limit=5&sort=-createdAt")
      .then((resp) => {
        this.opportunities =
          this.user?.type === "founder" ? resp.data.resources : resp.data || [];
        this.count =
          this.user?.type === "founder" ? resp.data.count : resp.data?.length;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>

<style lang="scss">
.App-Opp-List-Nav {
  padding-top: 10px;
  padding: 4px 2px;
  .App-Opp-List-C {
    display: flex;
    align-items: flex-start;
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
    padding: 4px 6px;
    transition: 300ms ease;

    &:hover {
      background-color: var(--el-background-color-base);
    }
    .App-Icon {
      font-weight: bold;
      font-weight: bolder;
      margin-right: 10px;
      color: var(--el-text-color-primary);
      margin-top: 4px;
    }
  }
}
</style>
