import { apiPlugin, ApiPluginOptions, TitleMixin } from "@/shared";
import { RegisterGlobalComponents } from "@/shared/components";
import { RegisterTalentBusinessComponents } from "@/shared/components/business-talent";

import { RegisterGlobalFilters } from "@/shared/filters";
import { RegisterGlobalUtils } from "@/shared/utils";
import ElementPlus, { ElCollapseTransition } from "element-plus";
import { createApp } from "vue";
import App from "./App.vue";
/**
 * Layout and global components
 */
import {
  BusinessRightNavComponent,
  LeftNavComponent,
  BottomNavComponent,
} from "./components";

import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import VueGtag from "vue-gtag";

/**
 * Create the main app and attach the needed plugins and modules
 */
const app = createApp(App);
/**
 * Register global components
 */

app
  .use(store)
  .use(router)
  .use(apiPlugin, {
    apis: {
      main: process.env.VUE_APP_API_MAIN,
      admin: process.env.VUE_APP_API_ADMIN,
    },
  } as ApiPluginOptions)
  .use(RegisterGlobalFilters)
  .use(RegisterGlobalUtils)
  .mixin(TitleMixin)
  .use(ElementPlus)
  // Enable google analytics
  .use(VueGtag, {
    appName: "Business",
    config: { id: "G-EHPYD80XK1" },
  });
/**
 * Components
 */
RegisterGlobalComponents(app);
RegisterTalentBusinessComponents(app);

app
  .component("el-collapse-transition", ElCollapseTransition)
  /**
   * Register shared components
   */
  .component("app-left-nav", LeftNavComponent)
  .component("app-business-right-nav", BusinessRightNavComponent)
  .component("app-bottom-nav", BottomNavComponent)
  /**
   * Mount
   */
  .mount("#app");
