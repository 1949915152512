
import { Opportunity, User } from "@/shared";
import { ErrorUtil } from "@/shared/classes";
import appEntStanding from "@/shared/components/survey-module//ent-standing/ent-standing.component.vue";
import { AccountUtil } from "@/shared/vuex";
import { DeleteFilled, UploadFilled } from "@element-plus/icons";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {
    appEntStanding,
    DeleteFilled,
    UploadFilled,
  },
})
export default class extends Vue {
  @Prop({ type: Object })
  user!: User;

  get account(): User {
    return this.$store.state.account.user as User;
  }

  @Prop({ type: Object })
  opportunity!: Opportunity;

  @Prop({ type: Boolean })
  isSelf!: boolean;

  loadingPic = false;
  /**
   * @for founders who are looking at a talent
   * They need to see which business the oppotunity is for
   */
  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }

  /**
   * @param command
   */
  handleCommand(command: "upload" | "delete") {
    if (command === "upload") this.uploadPic();
    else this.deletePic();
  }
  /**
   * @param
   */
  deletePic() {
    this.loadingPic = true;
    this.$api.main
      .delete("/v1/account/avatars")
      .then(() => {
        AccountUtil.fetchCurrent(this.$store);
      })
      .catch((err) => {
   this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });      })
      .finally(() => {
        this.loadingPic = false;
      });
  }

  /**
   * @param upload trigger
   */
  uploadPic() {
    this.$store.commit("modals/avatar", true);
  }
}
