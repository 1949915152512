import { $api } from "@/shared";
import { Bidding } from "@/shared/interfaces";
import { ApiResourceResult } from "@/shared/vuex";
import { StoreOptions } from "vuex";

export interface TransactionsVuexState extends ApiResourceResult<Bidding> {
  transactions?: Bidding[];
  loading: boolean;
}

export const TransactionsVuex: StoreOptions<TransactionsVuexState> = {
  ...{ namespaced: true },
  state: {
    transactions: undefined,
    loading: false,
  },
  mutations: {
    /**
     * Assigns a transactions as default
     * @param state
     * @param data
     */
    transactions(state, data: { data: Bidding[]; clear: boolean }) {
      if (data.clear) {
        state.transactions = data.data;
      } else {
        state.transactions = state.transactions
          ? state.transactions.concat(data.data)
          : data.data;
      }
    },
  },
  actions: {
    /**
     * Fetches transactions
     * @param context
     */
    fetch(context) {
      context.state.loading = true;
      $api.main
        .get<ApiResourceResult<Bidding>>("/v1/transactions?sort=-createdAt")
        .then((resp) => {
          context.state.count = resp.data.count;
          context.state.next = resp.data.next;
          context.state.skipped = resp.data.skipped;
          context.state.transactions = resp.data.resources;
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
  },
  modules: {},
  //
  getters: {},
};
