
import { Options, Vue } from "vue-class-component";
import OpportunityTalentsList from "./components/opportunity-talents-list.vue";

/**
 * @this component fetches the current opportunities
 * loops through them
 * and fetches matched talent for each opportunity
 */
@Options({
  components: { OpportunityTalentsList },
})
export default class extends Vue {
  /**
   * @opportunities
   */
  get opportunities() {
    return this.$store.state.opportunities.opportunities;
  }
  /**
   * @loading
   */
  get loading() {
    return this.$store.state.opportunities.loading;
  }
  /**
   * @count
   */
  get count() {
    return this.$store.state.opportunities.count;
  }
  /**
   *
   */
  mounted() {
    this.$store.dispatch("opportunities/fetch");
  }
}
