<template>
  <app-main-layout>
    <!--  -->
    <!--  -->
    <template v-slot:left>
      <app-left-nav />
    </template>
    <!--  -->
    <template v-slot:bottom-nav>
      <app-bottom-nav />
    </template>
    <!--  -->
    <!--  -->
    <template v-slot:top>
      <app-top-nav padded>
        <template v-slot:left>
          <el-button
            v-if="false"
            type="text"
            plain
            circle
            is-all-borderless
            icon="el-icon-back"
          />
        </template>
        <!--  -->
        <template v-slot:center>
          <span nav-title>Bidding</span>
        </template>
        <!--  -->
        <template v-slot:right>
          <el-button
            v-if="false"
            type="text"
            plain
            circle
            is-all-borderless
            icon="el-icon-search"
          />
        </template>
        <!--  -->
        <template v-slot:bottom>
          <el-menu :default-active="activeIndex" router mode="horizontal">
            <el-menu-item index="/biddings">
              <el-icon>
                <checked />
              </el-icon>
              <span>All</span>
            </el-menu-item>
            <el-menu-item index="/biddings?status=ongoing">
              <el-icon>
                <star />
              </el-icon>
              <span>Awaiting</span>
            </el-menu-item>
            <el-menu-item index="/biddings?status=accepted">
              <el-icon>
                <star />
              </el-icon>
              <span>Accepted</span>
            </el-menu-item>
            <el-menu-item index="/biddings?status=rejected">
              <el-icon>
                <star />
              </el-icon>
              <span>Denied</span>
            </el-menu-item>
          </el-menu>
        </template>
      </app-top-nav>
    </template>
    <!--  -->
    <template v-slot:middle>
      <!--  -->
      <!-- Loaded biddings -->
      <div>
        <app-biddings-list :biddings="biddings"></app-biddings-list>
        <!--  -->
        <app-loader-biddings :loading="loading" :resources="biddings" />
        <!--  -->
      </div>
    </template>
    <!--  -->
    <template v-slot:right>
      <app-right-nav>
        <app-business-right-nav />
      </app-right-nav>
    </template>
    <!--  -->
    <!--  -->
  </app-main-layout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Checked, Star } from "@element-plus/icons";
import { Watch } from "vue-property-decorator";
import { BiddingsVuexFetchOptions } from "@/@business/store/biddings.vuex";
import { RouterUtilClass } from "@/shared";
@Options({
  components: { Checked, Star },
})
export default class extends Vue {
  /**
   * @returns created biddings
   */
  get biddings() {
    return this.$store.state.biddings.biddings;
  }

  /**
   * Active index for the nav
   */
  get activeIndex() {
    return (
      this.$route.path +
      (this.$route.query.status !== undefined
        ? `?status=${this.$route.query.status}`
        : "")
    );
  }
  /**
   * @returns the loading state of the created biddings
   */
  get loading() {
    return this.$store.state.biddings.loading;
  }

  @Watch("$route.query.status")
  routeChanged() {
    this.fetch();
  }

  fetch() {
    this.$store.dispatch("biddings/fetch", {
      q: RouterUtilClass.getQuery(this.$route),
      clear: true,
    } as BiddingsVuexFetchOptions);
  }

  mounted() {
    this.fetch();
  }
}
</script>

<style lang="scss"></style>
