export const BusinessFormRules = {
    name: [
      { required: true, message: "Startup name is required!", trigger: "blur" },
    ],
    industry: [
      {
        required: true,
        message: "Startup industry is required!",
        trigger: "blur",
      },
    ],
    location: [
      {
        required: true,
        message: "Location is required!",
        trigger: "blur",
      },
    ],
    stage: [
      {
        required: true,
        message: "Startup stage is required!",
        trigger: "blur",
      },
    ],
    teamSize: [
      {
        required: true,
        message: "Team size is required!",
        trigger: "blur",
      },
    ],
    coFoundersSize: [
      {
        required: true,
        message: "This is required!",
        trigger: "blur",
      },
    ],
    foundingYear: [
      {
        required: true,
        message: "This is required!",
        trigger: "blur",
      },
    ],
    funding: [
      {
        required: true,
        message: "This is required!",
        trigger: "blur",
      },
    ],
  };