<template>
  <div>
    <!--  -->
    <!-- Loading -->
    <transition name="el-fade-in-linear">
      <div v-show="loading" class="App-Loader-Loading">
        <slot name="loading"> </slot>
      </div>
      <!--  -->
    </transition>
    <!-- Empty -->
    <transition name="el-fade-in-linear">
      <div
        v-show="!loading && (!resources || resources.length === 0)"
        class="App-Loader-Empty"
      >
        <slot name="empty"> </slot>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * This component has two slots
 * For handling when data is empty
 * And when data is loading
 */
@Options({})
export default class extends Vue {
  @Prop({ type: Boolean })
  loading!: boolean;

  @Prop({ type: Object })
  resources!: any[];
}
</script>

<style lang="scss">
.App-Loader-Empty,
.App-Loader-Loading {
  padding: 20px 40px;
  background: white;
  min-height: 200px;
  border-radius: map.get($--border-radius, "base");
}
</style>
