
import { ErrorUtil } from "@/shared/classes";
import { User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";
import img from "@/assets/illustrations/user/bio.svg";

@Options({})
export default class extends Vue {
  genders = ["Male", "Female", "Other", "Prefer not to say"];
  img = img;
  /**
   * Modal variables
   */
  loading = false;
  isModalOpen = false;
  showClose = false;

  /**
   * Form Data
   */
  form = {
    gender: undefined,
    DOB: undefined,
    location: undefined,
  };

  /**
   * @is the save button disabled
   */
  get disabledSave() {
    return (
      this.form.gender === undefined ||
      this.form.DOB === undefined ||
      this.form.location === undefined
    );
  }
  /**
   * @when the modal is checked
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      this.$store.state.account.user?.DOB === undefined ||
      this.$store.state.account.user?.gender === undefined ||
      this.$store.state.account.user?.location === undefined;
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<User>("/v1/account/profile", this.form)
      .then((resp) => {
        AccountUtil.fetchCurrent(this.$store).finally(
          () => (this.loading = false)
        );
        this.closeModal();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.$store.commit("modals/bioInfo", false);
    this.isModalOpen = false;
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to add education
   */
  @Watch("$store.state.modals.bioInfo")
  watchForOPen() {
    this.isModalOpen = this.$store.state.modals.bioInfo;
    if (this.isModalOpen) this.showClose = true;
  }
  /**
   * @update account after some changes
   */
  makeUpdatesAfterCreate() {
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
  }
}
