<template>
  <div class="App-Opp-Top">
    <el-space w-100 alignment="stretch" :size="12">
      <div>
        <app-over-avatars :size="50">
          <template v-if="opportunity.createdBy" #one>
            <el-avatar
              lazy
              :src="opportunity.createdBy.avatar"
              icon="el-icon-user-solid"
              :size="45"
            />
          </template>
          <template v-if="opportunity.business" #two>
            <el-avatar
              :src="opportunity.business.avatar"
              icon="el-icon-office-building"
              :size="45"
            />
          </template>
        </app-over-avatars>
      </div>
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        "
      >
        <div v-if="opportunity.business">
          <h3>{{ opportunity.business.name }}</h3>
          <span>
            {{ opportunity.business.industry }} Industry,
            {{ opportunity.business.stage }} Stage
            <span style="margin-left: 10px" mute-secondary>
              {{ $moment(opportunity.createdAt).fromNow() }}
            </span>
          </span>
        </div>
        <!-- If talent then show boost -->
        <div success v-if="user.type == 'talent'">
          <i class="el-icon-top" success />
          <span success f-w-600>
            {{ $filters.boost(opportunity.boost) }}
          </span>
        </div>
        <!--  -->
      </div>
    </el-space>
  </div>
</template>

<script lang="ts">
import { Opportunity } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  opportunity!: Opportunity;

  get user() {
    return this.$store.state.account.user;
  }
}
</script>

<style lang="scss">
.App-Opp-Top {
  .el-space {
    .el-space__item {
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
}
</style>
