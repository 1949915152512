<template>
  <div class="App-Trans-H">
    <!--  -->
    <el-tooltip content="Visit the opportunity">
      <router-link :to="`/opportunities/${transaction.opportunity._id}`">
        <div class="App-Trans-Tal">
          <div style="line-height: 18px" class="App-Trans-T">
            <h4 m-tb-0 f-w-700 v-if="transaction.opportunity !== undefined">
              {{ transaction.opportunity.outcome }} Opportunity
            </h4>
            <div v-if="business">
              <span mute-primary style="padding-right: 8px">
                {{ business.name }}
              </span>
              <span mute-secondary>
                Sent {{ $moment(transaction.createdAt).fromNow() }}
              </span>
            </div>
          </div>
          <div></div>
        </div>
      </router-link>
    </el-tooltip>
    <!--  -->
    <!--  -->
    <div class="App-Trans">
      <!--  -->
      <div></div>
      <!--  -->
      <div>
        <h4 m-tb-0 f-w-600 v-if="transaction.talent !== undefined">
          <span mute-secondary> Paying for </span>
        </h4>
        <el-tooltip content="See talent's page">
          <router-link
            :to="`/talent/${transaction.talent._id}/${transaction.opportunity._id}`"
          >
            <h4 m-tb-0 f-w-600>
              <span
                style="padding-right: 4px"
                v-if="
                  transaction.talent.headline ||
                  transaction.talent.areaOfExpertise
                "
              >
                {{
                  $filters.capitalize(
                    transaction.talent.headline ||
                      transaction.talent.areaOfExpertise
                  )
                }}
              </span>
              <span v-blur="transaction.talent">
                {{ $filters.capitalize(transaction.talent.firstName) }}
                {{ $filters.capitalize(transaction.talent.lastName) }}
              </span>
            </h4>
          </router-link>
        </el-tooltip>
        <el-divider style="margin: 12px 0" />

        <app-truncate
          v-if="transaction.bidding"
          @viewMore="max = 20000"
          :max="max"
          :content="transaction.bidding.description"
        />
      </div>
      <!--  -->
      <!-- Footer -->
      <div class="App-Trans-Fo">
        <el-row :gutter="12" align="middle">
          <el-col :span="12">
            <el-tag
              is-borderless
              :type="
                transaction.status === 'canceled'
                  ? 'danger'
                  : transaction.status === 'succeeded'
                  ? 'success'
                  : 'warning'
              "
              is-rounded
              size="mini"
            >
              {{ $filters.capitalize(transaction.status) }}
            </el-tag>
          </el-col>
          <el-col :span="11" txt-right>
            <h3 m-tb-0 f-w-700 primary>
              {{ talentPrice.symbol }}{{ transaction.amount }}
            </h3>
            <!--  -->
            <span v-if="transaction.status !== 'succeeded'">
              <el-button
                v-if="false"
                @click="payingModelOpen = true"
                :loading="loadingPay"
                is-borderless
                type="primary"
                round
                size="mini"
                icon="el-icon-cancel"
                >Pay
                <span v-if="talentPrice !== undefined">
                  {{ talentPrice.symbol }} {{ talentPrice.amount }}
                </span>
              </el-button>
              <BidPayModal
                v-if="talentPrice !== undefined && transaction.bidding"
                :talentPrice="talentPrice"
                @close="payingModelOpen = false"
                :isOpen="payingModelOpen"
                :talent="transaction.bidding.talent"
                :opportunity="transaction.opportunity"
                :bidding="transaction.bidding"
              />
            </span>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PayForTalent } from "@/shared/components/business-talent/bidding-module/bidding-component.vue";
import { Transaction } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import BidPayModal from "@/shared/components/business-talent/bidding-module/bidding-pay-modal.vue";

@Options({
  BidPayModal,
})
export default class extends Vue {
  max = 250;

  @Prop({ type: Object })
  transaction!: Transaction;

  talentPrice: PayForTalent = {} as any;

  /**
   * Payment Modal Variables
   */
  loadingPay = false;
  payingModelOpen = false;

  mounted() {
    this.talentPrice = {
      amount: this.transaction.amount,
      currency: this.transaction.currency,
      symbol: this.transaction.currency === "gbp" ? "£" : "$",
    };
  }
  /**
   * @business
   */
  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
}
</script>

<style lang="scss">
.App-Trans-H {
  background-color: rgba($color: white, $alpha: 1);
  border-radius: map.get($--border-radius, "base");
  //
  // Footer
  .App-Trans-Fo {
    padding: 4px 0 0 0;
  }

  // Header
  .App-Trans-Tal {
    background-color: rgba($color: $--background-color-base, $alpha: 0.5);
    padding: map.get($--button-padding-vertical, "small")
      map.get($--button-padding-vertical, "large") * 1.5;
  }
}
.App-Trans {
  background: white;
  border-radius: map.get($--border-radius, "base");
  padding: map.get($--button-padding-vertical, "small")
    map.get($--button-padding-vertical, "large") * 1.5;
}
</style>
