<template>
  <div>
    <el-space alignment="flex-start" direction="vertical" :size="8">
      <div>
        <el-space :size="10" wrap>
          <el-tag tag-default is-all-borderless round>
            {{ $filters.capitalize(opportunity.compensation.join(", ")) }}
          </el-tag>
          <!--  -->
          <el-tag tag-default is-all-borderless round>
            {{ $filters.capitalize(opportunity.wow) }}
          </el-tag>
          <!--  -->
        </el-space>
      </div>
      <div>
        <el-space :size="8" wrap>
          <span
            v-if="
              (opportunity.wow == 'hybrid' || opportunity.wow == 'physical') &&
              opportunity.location !== undefined
            "
          >
            <i primary class="el-icon-location" style="margin-right: 2px" />
            {{ opportunity.location.description }}
          </span>
        </el-space>
      </div>
      <div>
        <el-space :size="8">
          <el-icon primary style="margin-right: 2px">
            <calendar />
          </el-icon>
          <span>
            From
            <span mute-secondary>
              {{ $moment(opportunity.startDate).format("D MMM yy") }}
            </span>
          </span>
          <span>
            To
            <span mute-secondary>
              {{ $moment(opportunity.endDate).format("D MMM yy") }}
            </span>
          </span>
        </el-space>
      </div>
    </el-space>
  </div>
</template>

<script lang="ts">
import { Business, Opportunity } from "@/shared";
import { Calendar } from "@element-plus/icons";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: { Calendar },

})
export default class extends Vue {
  @Prop({ default: {}, type: Object })
  opportunity!: Opportunity;

  @Prop({ default: {}, type: Object })
  business!: Business;
}
</script>

<style lang="scss"></style>
