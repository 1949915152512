<template>
  <div v-loading="loading" class="App-Survey-Rate">
    <div>
      <!--  -->
      <h1 m-tb-1 txt-center>Entrepreneurial Assessment</h1>
      <!--  -->
      <p f-w-600 txt-center dark txt-120>
        Use the slider to distribute entrepreneurial points to the following
        entrepreneurial dimensions based on how your founding team currently
        stands.
      </p>
      <el-divider> </el-divider>
      <p txt-center>
        You have 32 points to distribute to the entrepreneurial dimensions below
        where each dimension can get points between 0 and 8.
      </p>
      <h1 txt-center>{{ remaining }}</h1>
      <!--  -->
    </div>
    <!--  -->
    <div class="App-Survey-Rate-H">
      <el-space direction="vertical" w-100 fill>
        <div v-for="item of glossary" :key="`CatL${item.dimension}`">
          <div class="App-Survey-Rate-Item">
            <span>
              <el-space :size="10">
                <h4 f-w-600 h-tb-0>
                  {{ $filters.capitalize(dimensionFull(item.dimension)) }}
                </h4>
                <span>
                  <el-popover
                    placement="top-start"
                    :title="$filters.capitalize(dimensionFull(item.dimension))"
                    :width="200"
                    trigger="hover"
                    :content="item.description"
                  >
                    <template #reference>
                      <el-icon style="display: flex" :size="16">
                        <QuestionFilled
                      /></el-icon>
                    </template>
                  </el-popover>
                </span>
              </el-space>
            </span>
            <div>
              <div class="App-Survey-Rate-In">
                <el-slider
                  w-100
                  :label="dimensionFull(item.dimension)"
                  show-stops
                  :step="1"
                  :max="8"
                  :min="0"
                  input-size="large"
                  v-model="score[item.dimension]"
                  show-tooltip
                  @input="inputDim(item.dimension, $event)"
                />
                <span class="App-Survey-Rate-In-M">
                  {{ score[item.dimension] }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </el-space>
    </div>
    <!--  -->
    <div txt-center m-t-2>
      <el-button
        icon="el-icon-check"
        @click="submitSurvey"
        size="large"
        type="primary"
        is-block
        :disabled="!canSubmit"
      >
        Save
      </el-button>
    </div>
    <!--  -->
  </div>
</template>

<script lang="ts">
import { ErrorUtil } from "@/shared/classes";
import {
  glossary,
  QuestionCategories,
  QuestionCategory,
  QuestionCategoryFull,
  SubmitSurveyDto,
  SurveyScore,
} from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Emit } from "vue-property-decorator";
import { QuestionFilled } from "@element-plus/icons";
@Options({ components: { QuestionFilled } })
export default class extends Vue {
  loading = false;
  score: SurveyScore = {} as any;
  remaining = 32;
  glossary = glossary;

  get canSubmit() {
    return this.remaining === 0;
  }

  get max() {
    return this.remaining < 8 ? this.remaining : 8;
  }

  /**
   * @param col
   */
  dimensionFull(col: string) {
    return QuestionCategoryFull[col] || col;
  }

  /**
   * Everytime a dimension is changed
   * we change the remaining points
   */
  inputDim(dimension: QuestionCategory, value: number) {
    this.calculateRemaining();
    if (this.remaining < 0) {
      this.$message.closeAll();
      this.$message({
        type: "error",
        message: "Insufficent remaining points for distribution.",
        duration: 6000,
      });
    }
  }

  calculateRemaining() {
    let sum = 0;
    Object.values(this.score).forEach((score) => {
      if (score !== undefined) sum = sum + score;
    });
    this.remaining = 32 - sum;
  }

  /**
   * @on mount fetch questions and start answering
   */
  mounted() {
    QuestionCategories.forEach((cat) => {
      this.score[cat] = 0;
    });
  }

  /**
   * Submits the survey response
   */
  @Emit("submitted")
  submitSurvey() {
    this.loading = true;
    return this.$api.main
      .post("/v1/surveys", { score: this.score } as SubmitSurveyDto)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
        throw err;
      })
      .finally(() => (this.loading = false));
  }
}
</script>

<style lang="scss">
//
.App-Survey-Rate {
  .App-Survey-Rate-H {
    max-width: 90%;
    margin: auto;
  }
  .App-Survey-Rate-Item {
    > span {
      display: block;
      margin-bottom: -12px;
    }
    h4 {
      margin: 0;
    }
  }

  .el-slider__button-wrapper {
    &:not(.dragging) {
      .el-slider__button {
        border-color: $--color-black;
      }
    }
  }

  .el-slider__runway:not(:hover) .el-slider__bar {
    background-color: $--color-black !important;
  }

  //
  .App-Survey-Rate-In {
    display: flex;
    align-items: center;
    .App-Survey-Rate-In-M {
      padding-left: 14px;
      display: inline-block;
    }
  }
}
//
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fade-enter {
  transition-delay: 300ms;
}
.el-popover.el-popper {
  text-align: left !important;
}
</style>
