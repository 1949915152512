import { User } from "..";

export class UserUtils {
  static isAdmin(user: User) {
    return user.type === "admin";
  }
  static isFounder(user: User) {
    return user.type === "founder";
  }
  static isTalent(user: User) {
    return user.type === "talent";
  }
}
