<template>
  <div>
    <el-dialog
      custom-class="App-User-Profile-Modal App-Unpadded"
      :model-value="isModalOpen"
      width="550px"
      :show-close="showClose"
      @open="opened"
      title="Skills"
      center
      :close-on-click-modal="showClose"
      :append-to-body="true"
      :lock-scroll="true"
      :open-delay="200"
      :close-delay="200"
      :before-close="closeModal"
      :close-on-press-escape="false"
    >
      <!--  -->
      <el-scrollbar :always="true" max-height="600px">
        <div v-loading="loading">
          <!--  -->
          <div>
            <div txt-center>
              <el-image :style="`width: 150px;`" :src="img"></el-image>
              <p>
               Tell us about your core skills
              </p>
            </div>
          </div>
          <!--  -->
          <el-form
            @submit.prevent
            class="App-Styled-Form"
            :label-position="'top'"
            label-width="100px"
            :model="form"
            style="margin-top: 12px"
          >
            <!--  -->
            <el-form-item
              prop="areaOfExpertise"
              label="What are you exceptionally good at? (Superpower)"
            >
              <el-input
                placeholder="Marketing Expert"
                v-model="form.areaOfExpertise"
              />
            </el-form-item>
            <!-- Main Skills -->
            <div>
              <app-section-divider content="Main skills">
                <i class="el-icon-wok" />
              </app-section-divider>
              <div m-t-2>
                <el-space :size="12" fill direction="vertical">
                  <el-row
                    v-for="(skill, index) of form.skills"
                    :key="Math.random() + index"
                    :gutter="12"
                  >
                    <el-col :span="14">
                      <app-skill-input v-model="form.skills[index].name">
                      </app-skill-input>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item
                        prop="experience"
                        label="Years of experience"
                      >
                        <el-input-number
                          w-100
                          :min="0"
                          :max="100"
                          :controls="false"
                          placeholder="EX: 5"
                          v-model="form.skills[index].years"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-space>
              </div>
              <div>
                <el-space wrap :size="16">
                  <el-button
                    class="button-new-tag"
                    size="small"
                    @click="addMainSkill"
                    round
                    icon="el-icon-plus"
                  >
                    Add Main Skill
                  </el-button>
                  <el-button
                    class="button-new-tag"
                    size="small"
                    @click="removeMainSkill"
                    round
                    icon="el-icon-remove"
                  >
                    Remove Last Skill
                  </el-button>
                </el-space>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div v-if="false" m-t-4>
              <app-section-divider content="Other Skills">
                <i class="el-icon-wok" />
              </app-section-divider>
              <div m-tb-2></div>
              <div>
                <el-space wrap m-b-1 :size="12">
                  <el-tag
                    v-for="tag in form.otherSkills"
                    :key="`skill-${tag}`"
                    tag-default
                    is-all-borderless
                    closable
                    :disable-transitions="false"
                    @close="handleCloseSkill(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                </el-space>
                <div>
                  <el-form-item
                    v-if="tempForm.inputSkill"
                    style="display: inline-block"
                    prop="description"
                  >
                    <el-input
                      ref="saveTagInputSkill"
                      v-model="tempForm.skill"
                      class="input-new-tag"
                      placeholder="Enter Skill Name"
                      @keyup.enter="handleInputConfirmSkill"
                      @blur="handleInputConfirmSkill"
                    >
                    </el-input>
                  </el-form-item>
                  <el-button
                    m-tb-1
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="showInputSkill"
                    round
                    icon="el-icon-plus"
                  >
                    Add Other Skill
                  </el-button>
                </div>
              </div>
            </div>
            <!--  -->
            <div v-if="false" m-t-4>
              <app-section-divider content="Passions">
                <i class="el-icon-wok" />
              </app-section-divider>
              <div m-tb-2></div>
              <div>
                <div>
                  <el-space wrap m-b-1 :size="12">
                    <el-tag
                      v-for="tag in form.interests"
                      :key="`interest-${tag}`"
                      tag-default
                      is-all-borderless
                      closable
                      :disable-transitions="false"
                      @close="handleCloseInterest(tag)"
                    >
                      {{ tag }}
                    </el-tag>
                  </el-space>
                  <div>
                    <el-form-item
                      v-if="tempForm.inputInterest"
                      style="display: inline-block"
                      prop="description"
                    >
                      <el-input
                        ref="saveTagInputInterest"
                        v-model="tempForm.interest"
                        class="input-new-tag"
                        placeholder="Passion"
                        @keyup.enter="handleInputConfirmInterest"
                        @blur="handleInputConfirmInterest"
                      >
                      </el-input>
                    </el-form-item>
                    <el-button
                      m-tb-1
                      v-else
                      class="button-new-tag"
                      size="small"
                      @click="showInputInterest"
                      round
                      icon="el-icon-plus"
                    >
                      Add Passion
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
          </el-form>
        </div>
      </el-scrollbar>
      <!--  -->
      <template #footer>
        <div txt-center>
          <el-button
            is-block
            icon="el-icon-check"
            @click="submit"
            size="large"
            type="primary"
            :disabled="disabledSave || loading"
          >
            Save Skills
          </el-button>
        </div>
      </template>
      <!--  -->
    </el-dialog>
  </div>
</template>

<script lang="ts">
import img from "@/assets/illustrations/user/skills.svg";
import { ErrorUtil } from "@/shared/classes";
import { User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";
import { SkillsMixin } from "./mixins";

@Options({
  mixins: [SkillsMixin],
})
export default class extends Vue {
  img = img;
  /**
   * Modal variables
   */
  loading = false;
  isModalOpen = false;
  showClose = false;
  /**
   * Form Data
   */
  form = {
    areaOfExpertise: undefined as any,
    skills: [{ name: undefined, years: undefined }] as any[],
    otherSkills: [] as string[],
    interests: [] as string[],
  };

  /**
   * temp form
   */
  tempForm = {
    inputSkill: false,
    inputInterest: false,
    skill: "",
    interest: "",
  };

  /**
   * @add a main skill
   */
  addMainSkill() {
    this.form.skills.push({ name: undefined, years: undefined });
  }

  /**
   * @add a main skill
   */
  removeMainSkill() {
    if (this.form.skills.length > 1) this.form.skills.pop();
  }
  /**
   * @add a main skill
   */
  addOtherSkill() {
    this.form.otherSkills.push();
  }
  /**
   * @is the save button disabled
   */
  get disabledSave() {
    return (
      this.form.areaOfExpertise === undefined || this.form.skills?.length === 0
    );
  }

  /**
   * @when the modal is checked
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      this.$store.state.account.user?.areaOfExpertise === undefined ||
      this.$store.state.account.user?.skills === undefined ||
      this.$store.state.account.user?.skills?.length === 0;
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<User>("/v1/account/profile", this.form)
      .then((resp) => {
        return AccountUtil.fetchCurrent(this.$store);
      })
      .then((resp) => {
        this.closeModal();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close skills modal modal");
    this.$store.commit("modals/mainSkills", false); // This is enough itself
    this.isModalOpen = false;
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to add education
   */
  @Watch("$store.state.modals.mainSkills")
  watchForOPen() {
    this.isModalOpen = this.$store.state.modals.mainSkills;
    if (this.isModalOpen) this.showClose = true;
  }

  @Watch("isModalOpen")
  watchForModalOpenChange() {
    console.log("Opened the modal");
  }
  /**
   * @when called fetche the list of skills
   */

  /**
   * @update account after some changes
   */
  makeUpdatesAfterCreate() {
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    this.prepareExistingData();
  }

  get user() {
    return this.$store.state.account.user;
  }
  /**
   * @on open add the existing data to the form
   */
  prepareExistingData() {
    this.form.otherSkills = this.user?.otherSkills || [];
    this.form.interests = this.user?.interests || [];
    this.form.areaOfExpertise = this.user?.areaOfExpertise || [];
    this.form.skills =
      this.user?.skills?.map((r) => ({ name: r.name, years: r.years })) || [];
  }
}
</script>

<style lang="scss">
.App-User-Profile-Modal {
  .App-Styled-Form {
    max-width: 400px;
    margin: auto;
  }
}
</style>
