export const IndustriesList = [
  "Data Management & Analytics",
  "Engineering, Product, and Design",
  "Finance and Accounting",
  "Human Resources",
  "Infrastructure",
  "Legal",
  "Marketing",
  "Office Management",
  "Operations",
  "Productivity",
  "Recruiting and Talent",
  "Retail",
  "Sales",
  "Security",
  "Supply Chain, Logistics and Delivery",
  "EdTech",
  "Consumer Electronics",
  "Content",
  "Media & Gaming",
  "Job and Career Services",
  "Social",
  "Transportation Services",
  "Travel, Leisure and Tourism",
  "Virtual and Augmented Reality",
  "Asset Management",
  "Banking and Exchange",
  "Consumer Finance 67",
  "Credit and Lending 58",
  "Insurance",
  "Payments",
  "Crypto/Blockchain",
  "Housing and Real Estate",
  "Government",
  "Health Tech",
  "E-commerce",
  "HardTech",
  "Cybersecurity",
  "Marketplace",
  "AdTech",
  "BioTech",
  "Aerospace",
  "Other",
];
