<template>
  <el-dialog
    custom-class="App-User-Profile-Modal App-Unpadded"
    :model-value="isModalOpen"
    width="550px"
    :show-close="true"
    @open="opened"
    title="Title"
    txt-center
    :close-on-click-modal="false"
    :append-to-body="true"
    :lock-scroll="true"
    :open-delay="200"
    :close-delay="200"
  >
    <!--  -->
    <div v-loading="loading">
      <el-form
        @submit.prevent
        class="App-Styled-Form"
        :label-position="'top'"
        label-width="100px"
        :model="form"
        style="margin-top: 12px"
      >
      </el-form>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { ErrorUtil } from "@/shared/classes";
import { User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  /**
   * Modal variables
   */
  loading = true;
  isModalOpen = false;
  showClose = true;
  /**
   * Form Data
   */
  form = {};

  /**
   * @when the modal is checked
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<User>("/v1/account", this.form)
      .then(() => {
        console;
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.isModalOpen = false;
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to add education
   */
  @Watch("$store.state.modals.addEducation")
  watchForOPen() {
    this.isModalOpen = this.$store.state.modals.addEducation;
    if (this.isModalOpen) this.showClose = true;
  }
  /**
   * @update account after some changes
   */
  makeUpdatesAfterCreate() {
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
  }
}
</script>

<style lang="scss">
.App-User-Profile-Modal {
  .App-Styled-Form {
    max-width: 400px;
    margin: auto;
  }
}
</style>
