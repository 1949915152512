
import { User } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import img from "@/assets/illustrations/user/education.svg";
import { MomentUtil } from "@/shared/vuex";

@Options({
  emits: ["create"],
})
export default class extends Vue {
  img = img;

  @Prop({ type: Object })
  user!: User;

  @Prop({ type: Boolean })
  isSelf!: boolean;
  /**
   * @clicked add
   */
  editStandardTests() {
    this.$store.commit("modals/standardTests", true);
  }

  dateDiff = MomentUtil.dateDifference;
}
