<template>
  <el-form-item v-loading="loadingSkills" prop="description" :label="label">
    <el-cascader :model-value="modelValue" @change="setValue" :options="skills">
      <template #default="{ node, data }">
        <span>{{ $filters.capitalize(data.value) }}</span>
        <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
      </template>
    </el-cascader>
  </el-form-item>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "SkillInput",
  emits: ["update:modelValue"],
  mixins: [],
})
export default class extends Vue {
  @Prop()
  modelValue!: Array<any>;

  @Prop({ type: String, default: "Skill Name" })
  label!: string;

  mounted() {
    console.log("Mounted the skill input element");
    this.fetchSkills();
  }

  setValue(value: Array<any>) {
    this.$emit("update:modelValue", value);
  }
  fetchSkills() {
    this.$store.dispatch("modals/fetchSkills");
  }
  get loadingSkills() {
    return this.$store.state.modals.fetchingSkills;
  }
  get skills() {
    return this.$store.state.modals.skillsList;
  }
}
</script>

<style lang="scss"></style>
