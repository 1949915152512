<template>
  <el-form-item prop="stage" :label="label">
    <el-select
      :model-value="modelValue"
      @change="setValue"
      placeholder="Startup Stage: ex: MVP"
      w-100
    >
      <el-option
        v-for="ind in stages"
        :value="ind"
        :label="ind"
        :key="`${ind}-ind`"
      >
        <el-space>
          <i class="App-Loc-Icon el-icon-business" />
          <span>{{ ind }}</span>
        </el-space>
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script lang="ts">
import { BusinessStages } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "BusinessStageInput",
  emits: ["update:modelValue"],
})
export default class extends Vue {
  stages = BusinessStages;

  @Prop()
  modelValue!: string;

  @Prop({ type: String, default: "Startup Stage" })
  label!: string;

  setValue(value: string) {
    this.$emit("update:modelValue", value);
  }
}
</script>

<style lang="scss"></style>
