
import { FounderAccountUtil } from "@/@business/store";
import { ErrorUtil } from "@/shared";
import {
  Business,
  BusinessModels,
  BusinessStages,
  IndustriesList,
  User
} from "@/shared/interfaces";
import { ObjectUtil } from "@/shared/utils";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { BusinessFormRules } from "./business-form";

@Options({
  emits: ["checked", "opened", "closed"],
  components: {},
})
export default class extends Vue {
  /**
   *
   */
  @Prop({ type: Boolean })
  isModalOpen!: boolean;

  showClose = true;
  //
  industries = IndustriesList;
  //
  stages = BusinessStages;
  businessModels = BusinessModels;

  @Prop({ type: Object })
  business!: Business;

  /**
   *
   */
  rules = BusinessFormRules;
  mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.rules.coFoundersSize = undefined as any;
    this.assignForm();
  }

  form = {
    name: undefined,
    industry: undefined,
    businessModel: undefined,
    location: undefined,
    stage: undefined,
    teamSize: undefined,
    website: undefined,
    foundingYear: undefined,
    marketSize: undefined,
    funding: {} as any,
    about: undefined,
  };

  /**
   *
   */
  loading = false;
  /**
   * @param score
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<Business>(`/v1/businesses/${this.business._id}`, this.form)
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Updated business profile successfully!",
        });
        this.makeUpdatesAfterCreate(resp.data);
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * @triggers updates after creating a new business profile
   */
  @Emit("update")
  makeUpdatesAfterCreate(business: Business) {
    this.loading = true;
    this.closeModal();
    FounderAccountUtil.finalizeFounderFetch(
      this.$store,
      this.$store.state.account.user as User
    );
    return business;
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
  }

  editFields = [
    "name",
    "industry",
    "businessModel",
    "location",
    "stage",
    "teamSize",
    "website",
    "foundingYear",
    "marketSize",
    "funding",
    "about",
  ];

  /**
   *
   */
  @Emit("opened")
  opened() {
    this.assignForm();
  }

  assignForm() {
    this.form = Object.assign(
      {},
      ObjectUtil.pick(this.business, this.editFields) as any
    );
    this.form.funding =
      typeof this.business.funding === "object" ? this.business.funding : {};
  }

  get disabledSave() {
    return false;
  }
}
