import { App } from "vue";

import AppLeftNav from "./navigation/left-nav-component.vue";
import AppMainLayout from "./layout/main-layout.vue";
import RightNavComponent from "./navigation/right-nav-component.vue";
import TopNavComponent from "./navigation/top-nav-holder-component.vue";
import AppOpportunitiesListNav from "./navigation/opportunities-list-nav-component.vue";

import { AppBidding, AppBiddingsList } from "./bidding-module";
import {
  appUserSkills,
  appUserSection,
  appUserEducation,
  appUserExperience,
  appUserTopProfile,
  appUserRightIntro,
  appUserStandardTests,
} from "./user-profile-module";
import {
  addEducationModal,
  addExperienceModal,
  addStandardTestModal,
  bioInfoModal,
  editEducationModal,
  editExperienceModal,
  inviteTalentsModal,
  mainSkillsModal,
  profilePictureModal,
  startupSkillsModal,
  UserEmailActivate,
  userInfoOnboard,
} from "./user-profile-modals";
import AppPasswordStrength from "./other/password-strength.vue";

import AppBusinessStageInput from "./inputs/business-stage-input.vue";
import AppEducationInput from "./inputs/education-input.vue";
import AppSkillInput from "./inputs/skill-input.vue";

export const RegisterTalentBusinessComponents = (app: App): void => {
  app
    .component("app-main-layout", AppMainLayout)
    .component("app-left-nav-h", AppLeftNav)
    .component("app-right-nav", RightNavComponent)
    .component("app-top-nav", TopNavComponent)
    .component("app-opportunities-list-nav", AppOpportunitiesListNav)
    // Biddings
    .component("app-bidding", AppBidding)
    .component("app-biddings-list", AppBiddingsList)
    // user profile components
    .component("app-user-skills", appUserSkills)
    .component("app-user-section", appUserSection)
    .component("app-user-education", appUserEducation)
    .component("app-user-standardtests", appUserStandardTests)
    .component("app-user-experience", appUserExperience)
    .component("app-user-top-profile", appUserTopProfile)
    .component("app-user-right-intro", appUserRightIntro)
    // modals
    .component("app-user-add-edu-modal", addEducationModal)
    .component("app-user-add-exp-modal", addExperienceModal)
    .component("app-user-edit-edu-modal", editEducationModal)
    .component("app-user-edit-exp-modal", editExperienceModal)
    .component("app-user-bio-modal", bioInfoModal)
    .component("app-user-invite-talent-modal", inviteTalentsModal)
    .component("app-user-main-skills-modal", mainSkillsModal)
    .component("app-user-avatar-modal", profilePictureModal)
    .component("app-user-startup-skills-modal", startupSkillsModal)
    .component("app-user-standardtests-modal", addStandardTestModal)
    .component("app-user-onboard-info", userInfoOnboard)
    .component("app-user-email-activate", UserEmailActivate)
    // Password
    .component("app-password-strength", AppPasswordStrength)
    // Inputs
    .component("app-business-stage-input", AppBusinessStageInput)
    .component("app-education-input", AppEducationInput)
    .component("app-skill-input", AppSkillInput);
  //
  // Other Loaders
};
