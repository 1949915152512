<template>
  <div class="App-User-Skills">
    <!--  -->
    <!--  -->
    <!--  -->
    <app-user-section v-if="isTalent">
      <div v-if="isSelf || user.areaOfExpertise">
        <app-section-divider content="Expertise">
          <i class="el-icon-s-opportunity"></i>
          <template v-if="isSelf" #action>
            <el-button
              @click="editAreaOfExpertise()"
              class="el-icon-edit"
              circle
              size="mini"
            />
          </template>
        </app-section-divider>
        <div>
          <StartupExperience :user="user" />
        </div>
      </div>
    </app-user-section>
    <!--  -->
    <!--  -->
    <!--  -->
    <app-user-section>
      <div v-if="isSelf || showSkillsSection">
        <app-section-divider content="Main Skills">
          <i class="el-icon-user"></i>
          <template v-if="isSelf" #action>
            <el-button
              @click="editSkills()"
              class="el-icon-edit"
              circle
              size="mini"
            />
          </template>
        </app-section-divider>
        <div>
          <el-space alignment="flex-start" direction="vertical" :size="12">
            <div
              :key="`${index}-${skill._id}`"
              v-for="(skill, index) of user.skills"
            >
              <h3 m-tb-0 round f-w-700>
                {{ $filters.capitalize(skill.name.join(", ")) }}
              </h3>
              <span mute-secondary>
                ({{ skill.years }} years of experience)
              </span>
            </div>
          </el-space>
        </div>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <div v-if="false">
        <div
          m-t-3
          v-if="
            isTalent &&
            (isSelf || showInterestsSection) &&
            user.otherSkills !== undefined &&
            user.otherSkills.length > 0
          "
        >
          <app-section-divider content="Other Skills & Passions">
            <i class="el-icon-user"></i>
            <template v-if="isSelf" #action>
              <el-button
                @click="editAreaOfExpertise()"
                class="el-icon-edit"
                circle
                size="mini"
              />
            </template>
          </app-section-divider>
          <div>
            <el-space wrap :size="12">
              <el-tag
                v-for="(otherSkill, index) of user.otherSkills"
                :key="`${index}-${otherSkill}`"
                round
                is-borderless
              >
                {{ otherSkill }}
              </el-tag>
              <el-tag
                v-for="(interest, index) of user.interests"
                :key="`${index}-${interest}`"
                tag-default
                round
                is-borderless
              >
                {{ interest }}
              </el-tag>
            </el-space>
          </div>
        </div>
      </div>
    </app-user-section>
    <!--  -->
  </div>
</template>

<script lang="ts">
import { User } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import StartupExperience from "./subcomponents/startup-experience.vue";

@Options({
  components: {
    StartupExperience,
  },
})
export default class extends Vue {
  @Prop({ type: Object })
  user!: User;

  get isTalent() {
    return this.user.type == "talent";
  }

  @Prop({ type: Boolean })
  isSelf!: boolean;

  /**
   * @when clicked opens the skills modal
   */
  editSkills() {
    this.$store.commit("modals/mainSkills", true);
  }

  /**
   * @edit
   */
  editAreaOfExpertise() {
    this.$store.commit("modals/startupExperience", true);
  }

  get showSkillsSection() {
    return (this.user.skills?.length ?? 0) > 0;
  }
  get showInterestsSection() {
    return (
      (this.user.otherSkills?.length ?? 0) > 0 ||
      (this.user.interests?.length ?? 0) > 0
    );
  }
}
</script>

<style lang="scss">
.App-User-Skills {
}
</style>
