<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  title: "Equity4Talent Business",
  components: {},

})
export default class extends Vue {}
</script>

<style lang="scss"></style>
