<template>
  <div class="App-Section-Divider">
    <el-divider content-position="left">
      <span class="App-Div-Icon">
        <slot> </slot>
      </span>
      <span>
        {{ content }}
      </span>
      <!--  -->
      <div class="App-Div-Action">
        <slot name="action"> </slot>
      </div>
      <!--  -->
    </el-divider>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * @improve to add icon
 */
@Options({})
export default class extends Vue {
  @Prop({ type: String })
  content!: string;
}
</script>

<style lang="scss">
.App-Section-Divider {
  .el-divider {
    background-color: var(--el-border-color-extra-light);
    height: 2px;
    margin-bottom: 28px;
  }
  .el-divider--horizontal {
    // max-width: 300px;
    .el-divider__text {
      font-weight: bold;
    }
  }
  .App-Div-Icon {
    margin-right: 4px;
    margin-top: 3px;
    
    [class^="el-icon-"],
    [class*=" el-icon-"] {
      // color: $--color-primary;
      font-weight: bolder;
      font-size: 16px;
    }
  }
  .App-Div-Action {
    display: inline-block;
    > * {
      margin-left: 40px;
    }
  }
}
</style>
