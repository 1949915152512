
import { ErrorUtil } from "@/shared/classes";
import { User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";
//
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { Plus } from "@element-plus/icons";

@Options({
  components: {
    Cropper,
    Plus,
  },
})
export default class extends Vue {
  /**
   * Modal variables
   */
  loading = false;
  isModalOpen = false; // false
  showClose = false;
  /**
   * Crop data
   */
  avatar = "";

  /**
   * @when the modal is checked
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen = this.$store.state.account.user?.avatar === undefined;
  }

  /**
   * @when the cropper is ready
   */
  finishedLoadingCropper() {
    this.loading = false;
  }

  /**
   * @param file
   */
  handleAdd(file) {
    console.log("File Added ", file);
    this.avatar = file.url;
  }

  /**
   * @param file
   */
  handleRemove() {
    (this.$refs as any).upload.clearFiles();
    this.avatar = "";
  }

  /**
   * @submits image only
   */
  submitImage() {
    const { canvas } = (this.$refs as any).cropper.getResult();
    return new Promise((resolve, reject) => {
      if (canvas) {
        const form = new FormData();
        return canvas.toBlob((blob) => {
          form.append("file", blob);
          // You can use axios, superagent and other libraries instead here
          resolve(this.$api.main.put<User>("/v1/account/avatars", form));
          // Perhaps you should add the setting appropriate file format here
        }, "image/jpeg");
      } else reject(new Error("Something went wrong."));
    });
  }
  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
    this.submitImage()
      .then((resp) => {
        AccountUtil.fetchCurrent(this.$store).finally(
          () => (this.loading = false)
        );
        this.closeModal();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.$store.commit("modals/avatar", false); // This is enough itself
    this.isModalOpen = false;
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to add education
   */
  @Watch("$store.state.modals.avatar")
  watchForOpen() {
    this.isModalOpen = this.$store.state.modals.avatar;
    console.log("Should open the upload");
    if (this.isModalOpen) this.showClose = true;
  }
  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
  }
}
