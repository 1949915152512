
import { Bidding } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  @Prop({ type: Array })
  biddings!: Bidding[];
}
