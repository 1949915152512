<template>
  <div>
    <el-dialog
      custom-class="App-Survey-Modal"
      :model-value="isSurveyModalOpen"
      width="550px"
      :show-close="false"
      @open="opened"
      center
      :close-on-click-modal="false"
      :append-to-body="true"
      :lock-scroll="true"
      :open-delay="200"
      :close-delay="200"
    >
      <!--  -->
      <div v-loading="loading">
        <!--  -->
        <!--  -->
        <div v-if="isStart">
          <app-survey-start />
        </div>
        <!--  -->
        <!-- ARating -->
        <div v-if="isRating">
          <app-survey-rating @submitted="showSurveyResults" />
        </div>
        <!--  -->
        <!-- if results -->
        <div v-if="isResults">
          <app-survey-results />
        </div>
      </div>

      <!--  -->
      <div>
        <div v-if="isStart" txt-center class="dialog-footer">
          <el-button @click="startSurveyRating" size="large" type="primary">
            Start Evaluation
          </el-button>
        </div>
        <div v-if="isResults" txt-center class="dialog-footer">
          <el-button
            icon="el-icon-check"
            @click="closeModal"
            size="large"
            type="primary"
          >
            Continue
          </el-button>
        </div>
      </div>
      <!--  -->
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { SurveyScore, User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import AppSurveyRating from "./survey-rating.component.vue";
import AppSurveyResults from "./survey-results.component.vue";
import AppSurveyStart from "./survey-start.component.vue";
@Options({
  name: "SurveyModal",
  emits: ["checked", "opened", "closed"],
  components: {
    AppSurveyStart,
    AppSurveyRating,
    AppSurveyResults,
  },
})
export default class extends Vue {
  isSurveyModalOpen = false;

  /**
   *
   */
  @Emit("checked")
  mounted() {
    let user: User = this.$store.state.account.user!;
    if (user.type === "founder")
      this.isSurveyModalOpen =
        this.business?.standing === undefined ||
        this.business?.canRetakeSurvey === true ||
        this.$store.state.modals.isSurveyOpen;
    else if (user.type === "talent") {
      this.isSurveyModalOpen =
        user.standing === undefined ||
        user.canRetakeSurvey === true ||
        this.$store.state.modals.isSurveyOpen;
    }
  }

  @Prop({ type: Boolean }) isOpen!: boolean;

  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
  /**
   * Determines the step of the ent survey
   */
  step: "start" | "rating" | "results" = "start"; //"start";
  loading = false;
  /**
   * Stores results
   */
  score!: SurveyScore;
  /**
   *
   */
  get isStart() {
    return this.step === "start";
  }
  get isRating() {
    return this.step === "rating";
  }
  get isResults() {
    return this.step === "results";
  }

  /**
   * When clicked now brings the rating
   * Part step by step
   * @starts the survey rating
   */
  startSurveyRating() {
    this.step = "rating";
  }
  /**
   * Receives the survey score results for the just submited survey
   * @param score
   */
  showSurveyResults(score: SurveyScore) {
    this.score = score;
    this.step = "results";
    this.loading = true;
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.$store.commit("modals/survey", false);
    this.isSurveyModalOpen = false;
  }

  @Emit("opened")
  opened() {
    console;
  }
}
</script>

<style lang="scss">
.App-Survey-Modal {
  .el-dialog__body {
    padding-top: 8px !important;
  }
  .el-dialog__header {
    padding: 0;
    padding-bottom: 0;
  }
}
</style>
