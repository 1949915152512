<template>
  <div>
    <div v-if="business" class="App-Bus-Right-Nav">
      <!--  -->
      <div>
        <h3 f-w-700 txt-center m-tb-0>
          {{ $filters.capitalize(business.name) }}
        </h3>
      </div>
      <!--  -->
      <div>
        <h4 f-w-600 txt-center m-tb-0 v-if="business.industry">
          {{ $filters.capitalize(business.industry) }} Industry
        </h4>
      </div>
      <!--  -->
      <div txt-center mute-secondary v-if="business.location">
        {{ business.location.description }}
      </div>
      <!--  -->
      <div m-tb-1 txt-center>
        <el-space :size="10">
          <a v-if="business.website" :href="business.website" target="__blank">
            <el-tag
              as-pointer
              @click="copyContent(business.website)"
              type="primary"
              is-all-borderless
              round
            >
              <i class="el-icon-link" />
              Website
            </el-tag>
          </a>
        </el-space>
      </div>
      <!--  -->
      <div>
        <app-ent-standing
          :labelSelf="`${$filters.capitalize(business.name)} (You)`"
          :standingSelf="business.standing"
          size="mini"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class extends Vue {
  /**
   * @business
   */
  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
}
</script>

<style lang="scss">
.App-Bus-Right-Nav {
  padding: 2px 20px 10px 20px;
}
</style>
