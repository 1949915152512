<template>
  <el-dialog
    custom-class="App-Create-Bidding-Modal App-Unpadded"
    :model-value="isModalOpen"
    width="550px"
    :show-close="showClose"
    @open="opened"
    @close="closeModal"
    :title="!sentBidding ? 'Send Bidding' : ''"
    center
    :close-on-click-modal="false"
    :append-to-body="true"
    :lock-scroll="true"
    :open-delay="200"
    :close-delay="200"
  >
    <!--  -->
    <div v-loading="loading">
      <!--  -->
      <div txt-center v-if="sentBidding">
        <div txt-center style="min-height: 60px; padding: 10px 20px">
          <el-image :style="`width: 160px;`" :src="img"></el-image>
        </div>
        <h2 txt-center>Sent the offer!</h2>
        <p>
          Look out in your inbox for any response <br />
          from the talent.
        </p>
      </div>
      <!--  -->
      <div v-else>
        <div v-if="hasAlreadyBidded === false">
          <el-form
            @submit.prevent
            class="App-Styled-Form"
            :label-position="'top'"
            label-width="100px"
            :model="form"
            style="margin-top: 12px"
            :rules="rules"
          >
            <!--  -->
            <!--  -->
            <app-bidding-profile-modal
              :opportunity="opportunity"
              :talent="talent"
            />
            <!--  -->
            <!-- compensation  -->
            <el-form-item
              prop="compensationTypes"
              label="How are you willing to compensate the talent?"
            >
              <el-select
                multiple
                v-model="form.compensationTypes"
                placeholder="Compensation Type"
                @change="change"
                w-100
              >
                <el-option :value="'cash'" :label="'Cash'" />
                <el-option :value="'equity'" :label="'Equity'" />
              </el-select>
            </el-form-item>
            <!--  -->
            <div
              v-if="hasAllTypes"
              m-b-2
              style="
                max-width: 400px;
                margin-left: auto !important;
                margin-right: auto !important;
              "
            >
              <el-slider
                v-model="equityDistribution"
                :min="0"
                :max="100"
                :marks="marks"
              />
            </div>
            <!--  -->
            <div>
              <div
                v-if="form.compensationTypes.length > 0"
                v-loading="loadingCompensation"
                txt-center
              >
                <div m-b-1 v-if="compensationPrediction">
                  <h4 m-tb-0>Proposed Compensation</h4>
                  <el-tooltip
                    :content="`For ${$filters.boost(
                      compensationPrediction.boost
                    )}`"
                  >
                    <div>
                      <!--  -->
                      <span v-if="form.compensationTypes.includes('equity')">
                        {{ equityPredicted.toFixed(3) }}%
                        <span> Equity</span>
                      </span>
                      <div v-if="hasAllTypes">or</div>
                      <span v-if="form.compensationTypes.includes('cash')">
                        <span>
                          $
                          {{ $filters.number(cashPredicted) }}
                        </span>
                        <span> Cash</span>
                      </span>
                      <!--  -->
                    </div>
                  </el-tooltip>
                  <el-divider />
                </div>
              </div>
            </div>
            <!-- El -->
            <!--  -->
            <!--  -->
            <!-- Equity -->
            <el-form-item
              v-if="form.compensationTypes.includes('equity')"
              prop="equity"
              label="How much equity? In % (Percentages)."
            >
              <el-input-number
                w-100
                :min="0"
                :max="100"
                :controls="false"
                placeholder="EX: 5"
                v-model="form.equity"
              />
            </el-form-item>
            <!--  -->
            <!-- Currency and amount -->
            <div v-if="form.compensationTypes.includes('cash')">
              <el-space :size="16" w-100>
                <el-form-item prop="currency" label="Currency">
                  <el-select
                    prop="currency"
                    v-model="form.currency"
                    placeholder="Currency"
                    style="width: 100%"
                  >
                    <el-option label="£ (Pound)" value="gbp"></el-option>
                    <el-option label="$ (US Dollars)" value="usd"></el-option>
                    <el-option label="€ (Euros)" value="euro"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="cash" label="Compensation Cash?">
                  <el-input-number
                    style="width: 100%"
                    :controls="false"
                    placeholder="200,000"
                    v-model="form.cash"
                  >
                  </el-input-number>
                </el-form-item>
              </el-space>
            </div>
            <!--  -->
            <!--  -->
            <!-- v-if="false" -->

            <!--  -->
            <el-divider />
            <!--  -->
            <el-form-item prop="expireAt" label="Expiration Date">
              <el-date-picker
                format="DD/MM/YYYY"
                type="date"
                placeholder="DD/MM/YYYY"
                v-model="form.expireAt"
              />
            </el-form-item>
            <!-- Description -->
            <el-form-item prop="description" label="Add your offer">
              <el-input
                rows="5"
                type="textarea"
                placeholder="Description"
                v-model="form.description"
              />
            </el-form-item>
            <!--  -->
            <!--  -->
          </el-form>
        </div>
        <div v-if="hasAlreadyBidded === true">
          <h2 txt-center>Oops!</h2>
          <p txt-center m-tb-0>
            You have already placed a bid for <br />
            this talent on this same opportunity.
            <br />
            Check your email inbox for any response from the talent if any.
          </p>
        </div>
      </div>
    </div>
    <template #footer>
      <div txt-center>
        <el-button
          v-if="hasAlreadyBidded === false && !sentBidding"
          is-block
          icon="el-icon-position"
          @click="submit"
          size="large"
          type="primary"
          :disabled="disabledSave || loading"
        >
          Send Bidding
        </el-button>
      </div>
    </template>
    <!--  -->
  </el-dialog>
</template>

<script lang="ts">
import img from "@/assets/illustrations/talent/no-talents.svg";
import { ErrorUtil } from "@/shared";
import appBiddingProfileModal from "@/shared/components/business-talent/bidding-module/bidding-profile-modal.vue";
import { Bidding, Opportunity, User } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";

interface EquityResponse {
  status: "success";
  equity: number;
  cash: number;
}
@Options({
  data() {
    return {
      compensationPrediction: undefined,
    };
  },
  components: {
    "app-bidding-profile-modal": appBiddingProfileModal,
  },
})
export default class extends Vue {
  img = img;

  @Prop({ type: Object })
  opportunity!: Opportunity;

  @Prop({ type: Object })
  talent!: User;
  /**
   * Variables for the component
   */
  loading = true;
  loadingCompensation = true;

  isModalOpen = false;
  showClose = true;

  sentBidding = false;

  @Prop({ type: Boolean })
  hasAlreadyBidded!: boolean;

  equityDistribution = 50;

  marks = {
    0: {
      style: { color: "green" },
      label: "Equity",
    },
    50: "Evenly Distribute",
    100: {
      style: { color: "orange" },
      label: "Cash",
    },
  };

  /**
   * @call this to check if it should be open
   * because there are no opportunities
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
  }

  /**
   * @
   */
  fetchEquityPrediction() {
    this.$api.main
      .get<EquityResponse>(
        `/v1/opportunities/${this.opportunity._id}/equity/${this.talent._id}`
      )
      .then((resp) => {
        (this.$data as any).compensationPrediction = resp.data;
      })
      .finally(() => {
        this.loadingCompensation = false;
        this.loading = false;
      });
  }
  /**
   * @Watch for open trigger
   * if triggered the talent should be able to close
   */
  @Watch("$store.state.modals.isBiddingOpen")
  watchForOPen() {
    this.isModalOpen = this.$store.state.modals.isBiddingOpen;
    if (this.isModalOpen) {
      this.fetchEquityPrediction();
    }
  }

  /**
   *
   */
  @Watch("isModalOpen")
  watchForModalOpenChange() {
    console.log("Opened the modal");
  }

  form = {
    talent: undefined,
    opportunity: undefined,
    compensationTypes: [] as Array<"equity" | "cash">,
    equity: 0,
    cash: 0,
    currency: undefined,
    expireAt: undefined,
    description: undefined,
  };

  /**
   * @param score
   */
  submit() {
    this.loading = true;
    this.$api.main
      .post<Bidding>("/v1/biddings", {
        ...this.form,
        ...{
          talent: this.talent._id,
          opportunity: this.opportunity._id,
        },
      })
      .then((resp) => {
        this.finalizePosting(resp.data);
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  change() {
    this.form.equity = 0;
    this.form.cash = 0;
    // Id no cash chosen (Equity is 100%)
    if (!this.form.compensationTypes.includes("cash")) {
      this.equityDistribution = 100;
    }
    // If doesn't have equity (cash is 100, so equity is 0)
    if (!this.form.compensationTypes.includes("equity")) {
      this.equityDistribution = 0;
    }
  }

  /**
   * @triggers updates after creating a new Bidding profile
   */
  finalizePosting(opp: Bidding) {
    this.sentBidding = true;
    this.$message({ type: "success", message: "Successfully sent the offer." });
    setTimeout(() => {
      this.closeModal();
      this.$router.push(`/biddings`);
    }, 4000);
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    this.$store.commit("modals/bidding", false); // This is enough itself
    this.isModalOpen = false;
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
  }

  /**
   *
   */
  rules = {
    expireAt: [
      {
        type: "date",
        required: true,
        message: "End Date is required!",
        trigger: "blur",
      },
    ],
    compensationType: [
      {
        required: true,
        message: "This is required!",
        trigger: "blur",
      },
    ],
    cash: [
      {
        required: true,
        message: "This is required!",
        trigger: "blur",
      },
    ],
    description: [],
  };
  get disabledSave() {
    return false;
  }

  get hasAllTypes() {
    return this.form.compensationTypes.length == 2;
  }

  get equityPredicted() {
    return (
      (((this as any).compensationPrediction?.equity || 0) *
        this.equityDistribution) /
      100
    );
  }
  get cashPredicted() {
    return (
      (((this as any).compensationPrediction?.cash || 0) *
        (100 - this.equityDistribution)) /
      100
    );
  }
}
</script>

<style lang="scss">
.App-Create-Bidding-Modal {
  .App-Styled-Form {
    max-width: 450px;
    margin: auto;
  }
  .el-slider__marks-text {
    word-break: keep-all !important;
    font-size: 11px !important;
  }
}
</style>
