<template>
  <div>
    <el-dialog
      custom-class="App-User-Profile-Modal"
      :model-value="isModalOpen"
      width="550px"
      :show-close="false"
      :close-on-click-modal="false"
      @open="opened"
      title="Create Profile"
      center
      :append-to-body="true"
      :lock-scroll="true"
      :open-delay="200"
      :close-delay="200"
      :close-on-press-escape="false"
    >
      <!--  -->
      <div v-loading="loading">
        <p txt-center style="max-width: 350px" m-t-0 m-rl-auto>
          <span v-if="user.type === 'talent'">
            Next up, we will require you to fill-in the following details, so we
            can match you with opportunities that leverage your skillsets.
          </span>
          <span v-else>
            Fill-in the following details to create a profile account for you
            and your co-founders.
          </span>
        </p>
        <div class="App-U-Info-Modal-Secs">
          <el-space alignment="left" :size="10" direction="vertical">
            <div
              v-for="section of sections"
              :key="section.title"
              class="App-U-Info-Modal-Sec"
            >
              <i primary f-w-700 class="el-icon-circle-check"></i>
              <span>{{ section.title }}</span>
              <!-- <small
                style="margin-left: 10px"
                v-if="section.canFillLater"
                mute-secondary
              >
                (Can fill it later)
              </small> -->
            </div>
          </el-space>
        </div>
        <!--  -->
        <div txt-center>
          <el-space direction="vertical" :size="12" w-100 fill>
            <el-button
              v-if="false"
              is-all-borderless
              plain
              class="Btn-Linkedin"
              is-block
              size="large"
            >
              Import From LinkedIn
            </el-button>
            <el-button
              @click="submit"
              is-borderless
              plain
              type="primary"
              is-block
              size="large"
            >
              Continue
            </el-button>
          </el-space>
        </div>
        <!--  -->
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { ErrorUtil } from "@/shared/classes";
import { User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit } from "vue-property-decorator";

@Options({
  emits: ["checked", "opened", "closed"],
})
export default class extends Vue {
  /**
   * Modal variables
   */
  loading = true;
  isModalOpen = false;
  showClose = true;
  /**
   * Form Data
   */
  form = { toldToFillProfile: true };

  talentSections = [
    { title: "Work Experience", canFillLater: false },
    { title: "Startup Experience", canFillLater: false },
    { title: "Skills", canFillLater: false },
    { title: "Education History", canFillLater: true },
    { title: "Standardized Tests", canFillLater: false },
    { title: "Bio Information", canFillLater: false },
    { title: "Profile Picture", canFillLater: true },
  ];

  founderSections = [
    { title: "Founder Skills", canFillLater: false },
    { title: "Founder Education", canFillLater: true },
    { title: "Standardized Tests", canFillLater: false },
    { title: "Profile Picture", canFillLater: true },
    { title: "Co-founders' Profiles", canFillLater: true },
  ];

  get sections() {
    if (this.user?.type === "founder") {
      return this.founderSections;
    } else {
      return this.talentSections;
    }
  }
  /**
   * @when the modal is checked
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      this.$store.state.account.user?.onboarding?.toldToFillProfile !== true;
    this.loading = false;
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<User>("/v1/account/onboard", this.form)
      .then((resp) => {
        AccountUtil.fetchCurrent(this.$store).finally(
          () => (this.loading = false)
        );
        this.closeModal();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.isModalOpen = false;
  }

  // /**
  //  * @Watch for open trigger
  //  * if triggered the user should be able to add education
  //  */
  // @Watch("$store.state.modals")
  // watchForOPen() {
  //   this.isModalOpen = this.$store.state.modals;
  //   if (this.isModalOpen) this.showClose = true;
  // }
  /**
   * @update account after some changes
   */
  makeUpdatesAfterCreate() {
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }
  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
  }
}
</script>

<style lang="scss">
.App-User-Profile-Modal {
  .App-Styled-Form {
    max-width: 400px;
    margin: auto;
  }
}
.App-U-Info-Modal-Secs {
  max-width: 400px;
  margin: auto;
  padding: 10px 10px 20px 10px;
}
.App-U-Info-Modal-Sec {
  font-weight: 600;
  text-align: left;
  color: black;
  display: flex;
  align-items: center;
  padding: 4px;
  i {
    margin-right: 10px;
    font-size: 30px;
    line-height: 30px;
  }
}

.Btn-Linkedin {
  background: #0d4392 !important;
  color: white !important;
}
</style>
