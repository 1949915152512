import moment from "moment";

export * from "./store";
export * from "./utils";
export * from "./interface";

export class MomentUtil {
  /**
   *
   * @improve this, it has a bug
   * @param date1
   * @param date2
   * @returns
   */
  static dateDifference(date1: string, date2: string) {
    const mom1 = moment(new Date(date1));
    const mom2 = moment(new Date(date2));
    const years = Math.abs(mom1.diff(mom2, "years"));
    const months = Math.abs(mom1.diff(mom2, "months"));
    if (years > 0) {
      if (months > 0) {
        return `${years} Years, ${months} Months`;
      }
      return `${years} Years`;
    }
    return `${months} Months`;
  }
}
