
import { PayForTalent } from "@/shared/components/business-talent/bidding-module/bidding-component.vue";
import { Transaction } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import BidPayModal from "@/shared/components/business-talent/bidding-module/bidding-pay-modal.vue";

@Options({
  BidPayModal,
})
export default class extends Vue {
  max = 250;

  @Prop({ type: Object })
  transaction!: Transaction;

  talentPrice: PayForTalent = {} as any;

  /**
   * Payment Modal Variables
   */
  loadingPay = false;
  payingModelOpen = false;

  mounted() {
    this.talentPrice = {
      amount: this.transaction.amount,
      currency: this.transaction.currency,
      symbol: this.transaction.currency === "gbp" ? "£" : "$",
    };
  }
  /**
   * @business
   */
  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
}
