import { App } from "@vue/runtime-core";
import moment from "moment";
import { ApiPluginOptions } from "..";
import {
  fullNames,
  initials,
  capitalize,
  slice,
  numberFormatter,
  currencyFormatter,
  boostFormatter,
} from "./filters";

export const Filters = {
  // Attach global filterd
  fullNames: fullNames,
  initials: initials,
  capitalize: capitalize,
  slice: slice,
  number: numberFormatter,
  currency: currencyFormatter,
  boost: boostFormatter,
};

export const RegisterGlobalFilters = {
  /**
   *
   * @param app
   * @param options
   */
  install: (app: App, options: ApiPluginOptions): void => {
    /**
     * Custom created filters
     */
    app.config.globalProperties.$filters = Filters;
    /**
     * Install moment
     */
    app.config.globalProperties.$moment = moment;
  },
};
