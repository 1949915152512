<template>
  <div class="App-Talent-List">
    <el-row :gutter="12">
      <el-col v-for="t of talents" :key="`tal-${t._id}`" :span="24" :md="12">
        <appTalent :otherTalents="talents" :opportunity="opportunity" :user="t" />
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { Opportunity, User } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import appTalent from "./talent.vue";

@Options({
  components: { appTalent },
})
export default class extends Vue {
  @Prop({ type: Object })
  talents!: User[];

  @Prop({ type: Object })
  opportunity!: Opportunity;
}
</script>

<style lang="scss">
.App-Talent-List {
  padding: 4px 0;
}
</style>
