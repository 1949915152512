<template>
  <div class="App-Footer" :class="`App-Footer-${size}`">
    <el-space direction="vertical" w-100 :fill="true">
      <div class="App-Footer-Links">
        <el-space
          alignment="center"
          style="justify-content: center"
          wrap
          :size="10"
          txt-center
        >
          <el-link @click="openLink('terms-of-service')" txt-center>
            Terms of Service
          </el-link>
          <el-link @click="openLink('terms-of-use')" txt-center>
            Terms of Use
          </el-link>
          <el-link @click="openLink('privacy-policy')" txt-center>
            Privacy Policy
          </el-link>
          <el-link @click="openLink('cookie-policy')" txt-center>
            Cookie Policy
          </el-link>
          <el-link @click="openLink('glossary')" txt-center>Glossary</el-link>
        </el-space>
      </div>
      <div class="App-Footer-Info">
        <div txt-center>
          Copyright © 2021 - {{ currentYear }} Equity4Talent Limited. All rights
          reserved.
        </div>
        <div txt-center>VAT Reg Number 397 0593 54</div>
        <div txt-center>
          Level 39, One Canada Square, Canary Wharf, London E14 5AB, UK
        </div>
      </div>
    </el-space>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TermsLinksClass } from "@/shared/classes";

@Options({
  components: {},
})
export default class extends Vue {
  @Prop({ default: true })
  openExternal!: boolean;

  @Prop({ default: "default" })
  size!: "large" | "default";

  get currentYear() {
    return new Date().getFullYear();
  }

  /**
   * @param link
   */
  openLink(link: string) {
    if (this.openExternal) {
      TermsLinksClass.navigateOut(link);
    } else {
      this.$router.push(`/terms/${link}`);
    }
  }
}
</script>

<style lang="scss">
.App-Footer {
  // Default
  &.App-Footer-default {
    .App-Footer-Links {
      margin-bottom: 20px;
      [txt-center] {
        font-weight: 500;
      }
      text-align: center;
    }
    .App-Footer-Info {
      font-size: map.get($--font-size, "extra-small");
      color: var(--el-text-color-regular);
    }
    .el-space__item {
      padding-bottom: 1px !important;
    }
  }
  //
  &.App-Footer-large {
    .App-Footer-Links {
      margin-bottom: 10px;
      [txt-center] {
        font-weight: 600;
      }
      text-align: center;
    }
    .App-Footer-Info {
      font-size: map.get($--font-size, "small");
      color: var(--el-text-color-regular);
    }
    .el-link {
      padding: 4px 10px;
      min-width: 90px;
      text-align: center;
    }
  }
}
</style>
