<template>
  <div class="App-User-Standardized Tests">
    <app-section-divider content="Standardized Tests">
      <i class="el-icon-postcard"></i>
      <template v-if="isSelf" #action>
        <el-button
          @click="editStandardTests()"
          class="el-icon-edit"
          circle
          size="mini"
        />
      </template>
    </app-section-divider>
    <!--  -->
    <!--  -->
    <el-empty
      v-if="user.standardTests === undefined || user.standardTests.length === 0"
      :image="img"
    >
      <template #description>
        <h2 m-tb-0>Oops!</h2>
        <p>
          No Standardized Tests information <br />
          were provided
        </p>
      </template>
      <el-button
        v-if="isSelf"
        @click="editStandardTests"
        is-borderless
        plain
        type="primary"
        icon="el-icon-plus"
        size="large"
      >
        Add Standardized Tests
      </el-button>
    </el-empty>
    <!--  -->
    <div>
      <div>
        <el-timeline m-t-3>
          <el-timeline-item
            v-for="(test, index) of user.standardTests"
            :key="`test-${index}`"
            size="large"
            :center="true"
            placement="bottom"
          >
            <div>
              <h3 m-tb-0>
                <span v-if="test.name">{{ test.name }}</span>
              </h3>
              <p m-tb-0 v-if="test.score">
                Score: {{ test.score }}
              </p>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
    <!--  -->
    <!--  -->
  </div>
</template>

<script lang="ts">
import { User } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import img from "@/assets/illustrations/user/education.svg";
import { MomentUtil } from "@/shared/vuex";

@Options({
  emits: ["create"],
})
export default class extends Vue {
  img = img;

  @Prop({ type: Object })
  user!: User;

  @Prop({ type: Boolean })
  isSelf!: boolean;
  /**
   * @clicked add
   */
  editStandardTests() {
    this.$store.commit("modals/standardTests", true);
  }

  dateDiff = MomentUtil.dateDifference;
}
</script>

<style lang="scss"></style>
