
import img from "@/assets/illustrations/user/experience.svg";
import { ErrorUtil } from "@/shared/classes";
import { User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";

@Options({
  name: "Add-Experience",
  emits: ["checked", "opened", "closed"],
})
export default class extends Vue {
  img = img;
  /**
   * Modal variables
   */
  loading = false;
  isModalOpen = false;
  showClose = true;
  /**
   * experience dull
   */
  nullExperience = {
    organisation: undefined as unknown as string,
    from: undefined as unknown as string,
    to: undefined as unknown as string,
    role: undefined as unknown as string,
    description: undefined as unknown as string,
  };
  /**
   * Form Data
   */
  stillThere = [];
  form = {
    experience: [Object.assign({}, this.nullExperience)],
  };

  get disabled() {
    return this.form.experience.length == 0;
  }

  addExperience() {
    this.form.experience.push(Object.assign({}, this.nullExperience));
  }
  removeExperience(index: number) {
    this.form.experience.splice(index, 1);
  }
  /**
   * @when the modal is checked
   */
  @Emit("checked")
  mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      this.$store.state.account.user?.experience === undefined ||
      this.$store.state.account.user?.experience?.length === 0;
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<User>("/v1/account/profile", this.form)
      .then((resp) => {
        AccountUtil.fetchCurrent(this.$store).finally(
          () => (this.loading = false)
        );
        this.closeModal();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.$store.commit("modals/addExperience", false); // This is enough itself
    this.isModalOpen = false;
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to add education
   */
  get modalStoreStatus() {
    return this.$store.state.modals.addExperience;
  }
  @Watch("modalStoreStatus")
  watchForOPen() {
    console.log("Clicked the add experience");
    this.isModalOpen = this.modalStoreStatus;
    if (this.isModalOpen) this.showClose = true;
  }
  /**
   * @update account after some changes
   */
  makeUpdatesAfterCreate() {
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    this.prepareExistingData();
  }

  get user() {
    return this.$store.state.account.user;
  }
  /**
   * @on open add the existing data to the form
   */
  prepareExistingData() {
    this.form.experience =
      this.user?.experience?.map((r) => ({
        organisation: r.organisation,
        from: r.from,
        to: r.to,
        role: r.role,
        description: r.description,
      })) || [];
  }
}
