export const SkillsMixin = {
  methods: {
    /**
     *
     * @param tag
     */
    handleCloseSkill(tag) {
      (this as any).form.otherSkills.splice(
        (this as any).form.otherSkills.indexOf(tag),
        1
      );
    },
    /**
     * @param
     */
    showInputSkill() {
      (this as any).tempForm.inputSkill = true;
      (this as any).$nextTick((_) => {
        (this as any).$refs.saveTagInputSkill.$refs.input.focus();
      });
    },
    /**
     *
     */
    handleInputConfirmSkill() {
      const inputValue = (this as any).tempForm.skill;
      if (inputValue) {
        (this as any).form.otherSkills.push(inputValue);
      }
      (this as any).tempForm.inputSkill = false;
      (this as any).tempForm.skill = "";
    },

    /**
     *
     * @param tag
     */
    handleCloseInterest(tag) {
      (this as any).form.interests.splice(
        (this as any).form.interests.indexOf(tag),
        1
      );
    },
    /**
     * @param
     */
    showInputInterest() {
      (this as any).tempForm.inputInterest = true;
      (this as any).$nextTick((_) => {
        (this as any).$refs.saveTagInputInterest.$refs.input.focus();
      });
    },
    /**
     *
     */
    handleInputConfirmInterest() {
      const inputValue = (this as any).tempForm.interest;
      if (inputValue) {
        (this as any).form.interests.push(inputValue);
      }
      (this as any).tempForm.inputInterest = false;
      (this as any).tempForm.interest = "";
    },
  },
};
