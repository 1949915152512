<template>
  <div class="App-User-Education">
    <app-section-divider content="Education">
      <i class="el-icon-postcard"></i>
      <template v-if="isSelf" #action>
        <el-button
          @click="addEducation()"
          class="el-icon-edit"
          circle
          size="mini"
        />
      </template>
    </app-section-divider>
    <!--  -->
    <!--  -->
    <el-empty
      v-if="user.education === undefined || user.education.length === 0"
      :image="img"
    >
      <template #description>
        <h2 m-tb-0>Oops!</h2>
        <p>
          No Education information <br />
          for this user was found
        </p>
      </template>
      <el-button
        v-if="isSelf"
        @click="addEducation"
        is-borderless
        plain
        type="primary"
        icon="el-icon-plus"
        size="large"
      >
        Add Education Information
      </el-button>
    </el-empty>
    <!--  -->
    <div>
      <div>
        <el-timeline m-t-3>
          <el-timeline-item
            v-for="(edu, index) of education"
            :key="`education-${index}`"
            size="large"
            :center="true"
            placement="bottom"
          >
            <div>
              <h3 m-tb-0>
                <span v-if="edu.degree">{{ edu.degree }}, </span
                >{{ edu.program }}
              </h3>
              <p m-tb-0 v-if="edu.organisation && edu.organisation.name">{{ edu.organisation.name }}</p>
              <div>
                <span>
                  <span v-if="edu.from">
                    {{ $moment(edu.from).format("MMM yy") }}
                  </span>
                  <span> - </span>
                  <span v-if="edu.to">
                    {{ $moment(edu.to).format("MMM yy") }}
                  </span>
                  <span v-else>Present</span>
                </span>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
    <!--  -->
    <!--  -->
  </div>
</template>

<script lang="ts">
import { User } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import img from "@/assets/illustrations/user/education.svg";
import { MomentUtil } from "@/shared/vuex";

@Options({
  emits: ["create"],
})
export default class extends Vue {
  img = img;

  @Prop({ type: Object })
  user!: User;

  @Prop({ type: Boolean })
  isSelf!: boolean;
  /**
   * @clicked add
   */
  addEducation() {
    this.$store.commit("modals/addEducation", true);
  }

  get education() {
    return [...(this.user.education || [])]?.sort((a, b) => {
      return  new Date(b.from).getTime() - new Date(a.from).getTime();
    });
  }

  dateDiff = MomentUtil.dateDifference;
}
</script>

<style lang="scss"></style>
