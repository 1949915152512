import { User } from "..";
import { Boost } from "../interfaces";

/**
 * Returns a user's names combined
 * @param user
 */
export const fullNames = (user?: User) => {
  return `${user?.firstName} ${user?.lastName}`.trim();
};

export const slice = (txt: string, length: number) => {
  return `${txt?.slice(0, length)}${txt.length > length ? "..." : ""}`;
};

/**
 * Returns a user's initials
 * @param user
 */
export const initials = (user?: User | string) => {
  const name =
    typeof user == "string" ? user : `${user?.firstName} ${user?.lastName}`;
  return name
    .trim()
    .split(" ")
    .map((name) => name.charAt(0).toUpperCase())
    .join(".");
};

/**
 *
 * @param txt
 * @returns
 */
export const capitalize = (txt: string) => {
  if (typeof txt !== "string") return "";
  console.log("Received for capitalization", txt);
  return `${txt?.charAt(0)?.toUpperCase()}${txt?.slice(1)}`;
};

/**
 *
 * @param num
 * @returns
 */
export const numberFormatter = (num?: number | string) => {
  if (num === undefined || num === null) return num;
  if (typeof num === "string") {
    num = parseInt(num);
  }
  return num?.toLocaleString();
};

/**
 *
 * @param num
 * @returns
 */
export const boostFormatter = (boost: Boost) => {
  return `${boost?.percentage_change?.toFixed(2) || "?"}% Boost`;
};

/**
 *
 * @param c
 */
export const currencyFormatter = (c: string) => {
  const clist = {
    gbp: "£",
    usd: "$",
    euro: "€",
  };
  if (clist[c] !== undefined) return clist[c];
  else return c;
};
