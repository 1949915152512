
import { User } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import StartupExperience from "./subcomponents/startup-experience.vue";

@Options({
  components: {
    StartupExperience,
  },
})
export default class extends Vue {
  @Prop({ type: Object })
  user!: User;

  get isTalent() {
    return this.user.type == "talent";
  }

  @Prop({ type: Boolean })
  isSelf!: boolean;

  /**
   * @when clicked opens the skills modal
   */
  editSkills() {
    this.$store.commit("modals/mainSkills", true);
  }

  /**
   * @edit
   */
  editAreaOfExpertise() {
    this.$store.commit("modals/startupExperience", true);
  }

  get showSkillsSection() {
    return (this.user.skills?.length ?? 0) > 0;
  }
  get showInterestsSection() {
    return (
      (this.user.otherSkills?.length ?? 0) > 0 ||
      (this.user.interests?.length ?? 0) > 0
    );
  }
}
