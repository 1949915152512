<template>
  <el-dropdown style="width: 100%" trigger="click">
    <div @click="clickedAccount" class="App-Acc-Top">
      <el-skeleton style="width: 300px" :loading="!loadedUser" animated>
        <!--  -->
        <template #template>
          <div
            style="
              display: flex;
              align-items: center;
              justify-items: space-between;
            "
          >
            <el-skeleton-item
              variant="circle"
              style="width: 50px; height: 50px; margin-right: 10px"
            />
            <el-skeleton-item
              variant="text"
              style="width: calc(100% - 100px); height: 20px"
            />
          </div>
        </template>
        <!--  -->
        <!--  -->
        <template #default>
          <div v-if="user" w-100 class="App-Acc-Top-C">
            <!--  -->
            <el-avatar :src="user.avatar" class="App-Acc-Top-Av" :size="40">
              {{ $filters.initials(user) }}
            </el-avatar>
            <!--  -->
            <div class="App-Acc-Top-D">
              <h4>{{ $filters.fullNames(user) }}</h4>
              <!--  -->
              <span class="App-Acc-Top-DS" v-if="$utils.user.isTalent(user)">
                <small mute-secondary>
                  {{ $filters.capitalize(user.headline || user.type) }}
                </small>
              </span>
              <!--  -->
              <span
                v-else-if="$utils.user.isFounder(user)"
                class="App-Acc-Top-DS"
              >
                <AccountTopBusiness :user="user" />
              </span>
              <!--  -->
              <span v-else-if="$utils.user.isAdmin(user)"> Admin </span>
              <!--  -->
            </div>
            <!--  -->
            <el-button
              class="App-Acc-Top-Btn"
              icon="el-icon-setting"
              plain
              circle
              is-borderless
            >
            </el-button>
          </div>
        </template>
      </el-skeleton>
    </div>
    <!--  -->
    <template #dropdown>
      <account-top-dropdown :user="user" />
    </template>
    <!--  -->
  </el-dropdown>
</template>

<script lang="ts">
import { User } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import AccountTopBusiness from "./account-top-business.component.vue";
import AccountTopDropdown from "./account-top-dropdown.component.vue";

@Options({
  components: {
    AccountTopBusiness,
    AccountTopDropdown,
  },

  computed: {
    /**
     * @returns whether the user is loaded or not
     */
    loadedUser() {
      return this.$props.user !== undefined;
    },
  },
})
export default class extends Vue {
  // user received
  @Prop({ type: Object })
  user!: User;

  clickedAccount() {
    console.log("Clicked here");
  }
}
</script>

<style lang="scss">
.App-Acc-Top {
  background: white;
  min-height: map.get($--input-height, "large");
  padding: map.get($--button-padding-vertical, "small");
  border-radius: var(--el-border-radius-base) !important;
  cursor: pointer;
  transition: 300ms ease-in-out;
  &:hover {
    background-color: rgba($--background-color-base, 0.3);
  }

  //
  .App-Acc-Top-C {
    display: flex;
    align-items: center;
    width: 100%;
    // Details
    .App-Acc-Top-D {
      margin: 0 8px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 1px;
      flex-grow: 1;
    }
    // Avatar Part
    // Names & Business Part
    h4 {
      margin: 0;
      font-weight: bold;
      line-height: 16px;
    }
    // Button Part
    .App-Acc-Top-DS {
      // line-height: 22px;
    }
  }
  .App-Acc-Top-Btn {
    &:not(:hover) {
      background-color: rgba($--background-color-base, 0.3);
    }
  }
  //
  .App-Acc-Top-Av {
    background-color: $--color-dark-blue;
  }
}
</style>
