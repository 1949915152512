<template>
  <app-main-layout>
    <!--  -->
    <!--  -->
    <template v-slot:left>
      <app-left-nav />
    </template>
    <!--  -->
    <template v-slot:bottom-nav>
      <app-bottom-nav />
    </template>
    <!--  -->
    <!--  -->
    <template v-slot:top>
      <app-top-nav :padded="false">
        <template v-slot:left>
          <el-button
            v-if="false"
            type="text"
            plain
            circle
            is-all-borderless
            icon="el-icon-back"
          />
        </template>
        <template v-slot:center>
          <span nav-title>Startups</span>
        </template>
        <template v-slot:right> </template>
        <template v-slot:bottom> </template>
      </app-top-nav>
    </template>
    <!--  -->
    <template v-slot:middle>
      <!--  -->
      <div v-loading="loading" v-if="user">
        <!--  -->
        <!--  -->
        <AccPageHeader :padded="true">
          <template #icon>
            <el-icon><OfficeBuilding primary /></el-icon>
          </template>
          <template #content>
            <span>
              Startups/ Businesses that you are a founder/co-founder of!
            </span>
          </template>
        </AccPageHeader>
        <!--  -->
        <!--  -->
        <div>
          <el-space :size="14" fill w-100>
            <div
              v-for="business of businessesData.resources"
              :key="`busess-${business._id}`"
            >
              <AppStartupComponent :business="business" />
            </div>
          </el-space>
        </div>
        <!--  -->
        <!--  -->
        <div txt-center>
          <el-tooltip
            content="Click to create another startup with you as a founder"
          >
            <el-button
              m-tb-3
              @click="createStartup"
              type="primary"
              is-block
              plain
              size="large"
              is-borderless
              icon="el-icon-plus"
            >
              Create Another Startup Profile
            </el-button>
          </el-tooltip>
        </div>
        <!--  -->
        <!--  -->
        <!--  -->
      </div>
    </template>
    <!--  -->
    <template v-slot:right>
      <app-right-nav>
        <AccPageRightNav />
      </app-right-nav>
    </template>
    <!--  -->
    <!--  -->
  </app-main-layout>
</template>

<script lang="ts">
import { Business, ErrorUtil } from "@/shared";
import AccPageHeader from "@/shared/views/account-pages/components/account-page-header-component.vue";
import AccPageRightNav from "@/shared/views/account-pages/components/account-pages-right-nav.vue";
import { ApiResourceResult } from "@/shared/vuex";
import { OfficeBuilding } from "@element-plus/icons";
import { Options, Vue } from "vue-class-component";
import AppStartupComponent from "./startup-component.vue";

@Options({
  components: {
    AccPageHeader,
    OfficeBuilding,
    AccPageRightNav,
    AppStartupComponent,
  },
})
export default class extends Vue {
  /**
   * So the user can be able to edit
   */
  isSelf = true;
  loading = false;
  businessesData: ApiResourceResult<Business> = {
    count: undefined,
    skipped: undefined,
    next: undefined,
    resources: [],
  };
  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }

  /**
   * @commits to open the business modal to open
   */
  createStartup() {
    this.$store.commit("modals/business", true);
  }
  /**
   * @
   */
  mounted() {
    this.fetchBusinesses();
  }
  /**
   * @returns businesses
   */
  fetchBusinesses() {
    this.loading = true;
    return this.$api.main
      .get<ApiResourceResult<Business>>("/v1/businesses")
      .then((resp) => {
        this.businessesData = resp.data;
      })
      .catch((err) => {
   this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });      })
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>

<style lang="scss"></style>
