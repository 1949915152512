
import { Bidding } from "@/shared";
import { ErrorUtil } from "@/shared/classes";
import { Opportunity, User } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import BidPayModal from "./bidding-pay-modal.vue";

export interface PayForTalent {
  amount?: 1556;
  currency?: "gbp" | "usd";
  symbol?: "£" | "$";
  //
  amountBeforeTax?: number;
  tax?: number;
}
@Options({
  name: "BiddComponent",
  components: { BidPayModal },
})
export default class extends Vue {
  loading = false;

  @Prop({ type: Object })
  bidding!: Bidding;

  loadingPay = true;
  talentPrice: PayForTalent = {};

  payingModelOpen = false;

  max = 260;

  /**
   * @fired when the view more button is clicked
   */
  clickedMore() {
    this.$router.push(`/opportunities/${this.bidding._id}`);
  }

  /**
   * @on mount
   */
  mounted() {
    if (this.user?.type === "founder") this.predictPay();
  }

  get user() {
    return this.$store.state.account.user;
  }

  get isAccepted() {
    return this.bidding.status === "accepted";
  }
  /**
   * @helps predict how much to pay for a talent
   */
  predictPay() {
    this.loadingPay = true;
    this.$api.main
      .post<PayForTalent>("/v1/transactions/predict", {
        talent: (this.bidding.talent as User)._id,
        opportunity: (this.bidding.opportunity as Opportunity)._id,
      })
      .then((resp) => {
        this.talentPrice = resp.data;
      })
      .finally(() => {
        this.loadingPay = false;
      });
  }

  /**
   * @triggered when pay button is clicked
   */
  openPayModal() {
    this.payingModelOpen = true;
  }

  hidePayModal() {
    this.payingModelOpen = false;
  }

  /**
   * @returns whether the bidding is expired or not
   */
  get isExpired() {
    const expire = (this as any).$moment(this.bidding.expireAt);
    const now = (this as any).$moment(new Date());
    return expire.diff(now, "minutes") < 2;
  }

  /**
   * @
   */
  rejectBid() {
    this.loading = true;
    return this.$api.main
      .patch<Bidding>(`/v1/biddings/${this.bidding._id}/reject`)
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Rejected the bidding",
        });
        this.$router.push("/biddings?status=rejected");
      })
      .finally(() => (this.loading = false));
  }
  /**
   * @
   */
  acceptBid() {
    this.loading = true;
    return this.$api.main
      .patch<Bidding>(`/v1/biddings/${this.bidding._id}/accept`)
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Accepted the bidding",
        });
        this.$router.push("/biddings?status=accepted");
      })
      .finally(() => (this.loading = false));
  }
}
