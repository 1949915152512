
import { Options, Vue } from "vue-class-component";
import { Checked, Star } from "@element-plus/icons";
@Options({
  components: { Checked, Star },
})
export default class extends Vue {
  /**
   * @returns created talents
   */
  get talents() {
    return this.$store.state.talents.talents;
  }

  /**
   * @returns the loading state of the created talents
   */
  get loading() {
    return this.$store.state.talents.loading;
  }

  mounted() {
    this.$store.dispatch("talents/fetch");
  }
}
