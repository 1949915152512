<template>
  <div class="App-Cookie-Notice-Modal-Parent">
    <el-dialog
      custom-class="App-Cookie-Notice-Modal"
      v-model="isOpen"
      title="We use cookies!"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      :lock-scroll="false"
      :modal="true"
    >
      <div>
        <p class="App-Cookie-Notice-P">
          We use cookies for the purposes of enabling site functionality and to
          monitor traffic to our website. By clicking <b>“Accept”</b> you
          consent to our use of cookies, as further described in our
          <el-button type="text" @click="visitCookiesPolicy()">
            Cookies Policy
          </el-button>
        </p>
        <!--  -->
        <div v-show="showOptions">
          <el-divider></el-divider>
          <el-table :data="cookiesList" style="width: 100%">
            <el-table-column prop="name" label="Cookie Name">
              <template #default="scope">
                <h3 m-tb-0>{{ scope.row.name }}</h3>
                <p
                  style="font-size: 90%; line-height: 0.9rem"
                  mute-secondary
                  m-tb-0
                >
                  <span>{{ scope.row.description }}</span>
                </p>
              </template>
            </el-table-column>
            <el-table-column
              align="right"
              #default="scope"
              prop="name"
              label="Status"
              width="100"
            >
              <el-switch
                :disabled="!scope.row.canAlter"
                v-model="scope.row.value"
              ></el-switch>
            </el-table-column>
          </el-table>
        </div>
        <!--  -->
      </div>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button icon="el-icon-close" @click="rejectTerms()">
            Withdraw
          </el-button> -->
          <el-button
            v-if="!showOptions"
            icon="el-icon-eye"
            @click="showOptions = true"
          >
            Show Options
          </el-button>
          <el-button icon="el-icon-check" @click="acceptTerms()" type="primary">
            Accept
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { TermsLinksClass } from "@/shared/classes";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import jsCookie from "js-cookie";

@Options({})
export default class extends Vue {
  showOptions = false;
  checked = true;
  isOpen = false; //
  /**
   * The cookie notice cookie is stored globally
   */
  private cookieNoticeOps = {
    domain: process.env.VUE_APP_DOMAIN,
    expires: 3000000,
    path: "/",
  };
  private cookieName = "COOKIES";

  cookiesList = [
    {
      name: "Default E4T",
      description:
        "These are cookies set up by E4T for functionalities such as authentication and tracking whether you agree with our cookie policy or not.",
      value: true,
      canAlter: false,
    },
    {
      name: "Stripe",
      description:
        "We use Stripe for processing our payments, and we do consider their cookies as a necessity for our platform to function properly",
      value: true,
      canAlter: false,
    },
    {
      name: "Google Analytics",
      description:
        "Google Analytics cookies help us to understand your experience of the website and do not store any personal data. Visit our cookie policy for a full list of Google Analytics cookies used on this site.",
      value: true,
      canAlter: false,
    },
  ];
  /**
   * @unless we are on terms you have to check of has agreed
   */
  mounted() {
    this.checkIfAgreed();
  }

  @Watch("$route.path")
  onRouteChange() {
    this.checkIfAgreed();
  }

  /**
   * If we are on terms hide the modal
   * If we are not and the terms are not checked show the modal
   */
  checkIfAgreed() {
    if (this.$route.path.includes("/terms/")) this.isOpen = false;
    else if (!this.acceptance()?.includes("ACCEPTED")) {
      this.isOpen = true;
    }
  }
  /**
   * @returns if the user has agrred to terms and conditions
   */
  acceptance() {
    return jsCookie.get(this.cookieName);
  }
  /**
   * On agree
   * @stores the cookie
   * and hide the modal
   */
  acceptTerms() {
    jsCookie.set(this.cookieName, "ACCEPTED", this.cookieNoticeOps);
    this.isOpen = false;
  }

  /**
   * We close the window
   */
  rejectTerms() {
    window.open("", "_parent", "");
    window.close();
  }
  /**
   * Navigate to cookies policy
   */
  visitCookiesPolicy() {
    // If on about we use route
    if (`.${window.location.hash}` === process.env.VUE_APP_DOMAIN)
      this.$router.push("/terms/cookie-policy");
    // else we use window
    else TermsLinksClass.navigateOut("cookie-policy");
  }
}
</script>

<style lang="scss">
.App-Cookie-Notice-Modal-Parent {
  .el-overlay {
    background: transparent;
  }
}
.App-Cookie-Notice-Modal.el-dialog {
  width: 98% !important;
  max-width: 600px;
  margin-bottom: 0px !important;
  background: whitesmoke;

  .el-dialog__body {
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: unset;
  }
  .App-Cookie-Notice-P {
    font-size: 100%;
    margin: 0;
    * {
      font-size: inherit;
    }
  }
}
</style>
