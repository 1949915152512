<template>
  <div>
    <el-form-item :label="label">
      <el-select
        :size="size"
        style="width: 100%"
        filterable
        remote
        :default-active-first-option="false"
        :show-arrow="false"
        :not-found-content="null"
        :remote-method="handleSearch"
        @change="handleChange"
        :loading="loading"
        v-model="modelValue.description"
        :placeholder="placeholder"
      >
        <!--  -->
        <template #empty>
          <i class="Could not find a matching location."></i>
        </template>
        <!--  -->
        <el-option
          v-for="d in searchResults"
          :value="d.description"
          :label="d.description"
          :key="`${d.description}`"
        >
          <el-space>
            <i class="App-Loc-Icon el-icon-location" />
            <span>{{ d.description }}</span>
          </el-space>
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script lang="ts">
import { ref } from "@vue/reactivity";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { MapsUtils } from "./MapsUtils";
/// <reference types="google.maps" />

/**
 *
 *
 * Maps loading Observable
 */
const mapsLoadingMonitor = ref({
  loading: false,
});
/**
 *
 */
Object.defineProperty(window, "google", {
  get() {
    return mapsLoadingMonitor.value.loading;
  },
  set(loading) {
    mapsLoadingMonitor.value.loading = loading;
  },
});

/**
 * Main component script
 */
@Options({
  emits: ["update:modelValue", "mapIsLoaded"],
})
export default class extends Vue {
  @Prop({ type: Object, default: () => ({} as Record<string, any>) })
  modelValue!: Record<string, any>;

  @Prop({ default: "default", type: String })
  size!: string;

  @Prop({ default: "Search for location", type: String })
  placeholder!: string;

  @Prop({ default: "Location", type: String })
  label!: string;

  searchResults = [] as Array<any>;
  service!: Record<string, any>;
  locateService!: Record<string, any>;
  loading = false;
  geoLocation: LocationGive | undefined | void = undefined;

  mounted() {
    if (!this.mapIsLoaded) {
      let mapScript: HTMLScriptElement = document.createElement("script");
      mapScript.setAttribute(
        "src",
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyD6MWl-k5IvePe5YCevVcOLjmVrn0zltVA&libraries=places`
      );
      document.head.appendChild(mapScript);
      console.log(mapScript);
    } else {
      this.initMaps();
      this.selectedLocationWasThere();
    }
  }

  @Watch("mapIsLoaded")
  onMapIsLoaded() {
    this.initMaps();
  }

  get mapIsLoaded() {
    return (window as any).google?.maps !== undefined;
  }

  /**
   * check if selected location was there
   */
  selectedLocationWasThere() {
    if (this.modelValue?.description !== undefined) {
      this.handleChange(this.modelValue.description);
    }
  }
  /**
   *
   */
  @Emit("mapIsLoaded")
  initMaps() {
    this.service = new (window as any).google.maps.places.AutocompleteService();
    this.locateService = new (window as any).google.maps.Geocoder();
    this.selectedLocationWasThere();
  }
  /**
   * fetch places
   */
  fetch(searchTxt: string) {
    this.loading = true;
    this.searchResults = [];
    this.service.getPlacePredictions(
      {
        input: searchTxt,
        // types: ["(address)", "(establishment)"],
        types: ["(regions)"],
      },
      this.assignResults
    );
  }

  /**
   *
   */
  assignResults(predictions: Array<Record<string, any>> | null, status: any) {
    console.log("Predictions ", predictions);
    // if not okay
    if (status !== (window as any).google.maps.places.PlacesServiceStatus.OK) {
      this.searchResults = [];
      return;
    }
    this.searchResults =
      predictions?.map((prediction: Record<string, any>) => prediction) || [];
    // Sort based on if a location is "administrative_area_level_1" or "administrative_area_level_2"
    this.searchResults = this.searchResults.sort((loc1, loc2) => {
      let loc1status =
        (loc1.types as Array<string>).includes("administrative_area_level_1") ||
        (loc1.types as Array<string>).includes("administrative_area_level_2") ||
        (loc1.types as Array<string>).includes("country")
          ? 1
          : 0;
      let loc2status =
        (loc2.types as Array<string>).includes("administrative_area_level_1") ||
        (loc2.types as Array<string>).includes("administrative_area_level_2") ||
        (loc2.types as Array<string>).includes("country")
          ? 1
          : 0;
      return loc1status - loc2status;
    });
    //
    this.loading = false;
  }
  /**
   * @param searchTxt
   */
  handleSearch(searchTxt: string) {
    if (this.mapIsLoaded) {
      console.log(`Should be searching ${searchTxt}.`);
      this.fetch(searchTxt);
    }
  }
  /**
   * Called when new search is selected
   * @param selectedValue
   */
  handleChange(suggesstionTxt: string) {
    if (this.mapIsLoaded) {
      this.loading = true;
      console.log("Changed location ", suggesstionTxt);
      this.locateService.geocode(
        { address: suggesstionTxt },
        this.changeLocation
      );
    }
  }
  /**
   * Called to finalise reformulate the location with geometry info
   * @param
   */
  @Emit("update:modelValue")
  changeLocation(res: Array<Record<string, any>> | null) {
    this.geoLocation = this.formulateLocation(res);
    this.loading = false;
    console.log("Giving this location ", this.geoLocation);
    return this.geoLocation;
  }
  /**
   * @formulate location
   */
  formulateLocation(
    res: Array<Record<string, any>> | null
  ): LocationGive | void {
    if (res == null) return;
    console.log("Should show ", res[0].geometry.bounds?.toJSON());
    return {
      country: MapsUtils.country(res[0]),
      city: MapsUtils.city(res[0]),
      description: this.modelValue.description,
      coordinates: [
        res[0].geometry.location.lng(),
        res[0].geometry.location.lat(),
      ],
    };
  }
}

interface LocationGive {
  /**
   * Long, lat
   */
  coordinates: [number, number];
  country: string;
  city?: string;
  description?: string;
}
</script>

<style lang="scss">
.App-Loc-Icon {
  opacity: 0.6;
  color: rgba($--color-primary, 0.9);
}
</style>
