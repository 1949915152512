
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Opportunity from "./opportunity.component.vue";

@Options({
  components: {
    Opportunity,
  },
})
export default class extends Vue {
  @Prop({ type: Array })
  opportunities!: Opportunity[];
}
