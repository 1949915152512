<template>
  <div>
    <div class="App-Lef-Nav">
      <div class="App-Lef-Nav-W">
        <el-space direction="vertical" alignment="center" w-100 fill>
          <!-- Top Logo Section -->
          <app-logo :size="30"></app-logo>
          <!-- Main Navigation -->
          <div>
            <!-- Top Main -->
            <div class="App-Lef-Nav-W-M">
              <slot name="top" />
            </div>
            <!--  -->
            <el-divider />
            <!-- Bottom -->
            <div class="App-Lef-Nav-W-S">
              <div>
                <slot name="bottom" />
              </div>
            </div>
          </div>
          <!--  -->
          <div></div>
        </el-space>
      </div>
      <!--  -->
      <app-footer txt-center></app-footer>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},

})
export default class extends Vue {}
</script>

<style lang="scss">
.App-Lef-Nav {
  min-height: 500px;
  width: 100%;
  max-width: 400px;

  // white section
  .App-Lef-Nav-W {
    border-radius: var(--el-border-radius-base) !important;
    background: white;
    padding: map.get($--button-padding-vertical, "large") * 1.5
      map.get($--button-padding-vertical, "large") * 1.5
      map.get($--button-padding-vertical, "large") * 1.5
      map.get($--button-padding-vertical, "large") * 1.5;
    min-height: 500px;
    margin-bottom: map.get($--button-padding-vertical, "large");
    display: block;

    .el-menu-item {
      min-width: 200px;
      display: inline-block;
      border-radius: 30px;
      [class^="el-icon-"] {
        color: $--color-primary;
        font-weight: bold;
      }
    }
    .el-space {
      min-height: 500px;
    }
    .App-Logo {
      margin-left: 22px;
    }
  }
  //
  .el-divider--horizontal {
    margin: 18px 20px;
    opacity: 0.4;
    max-width: 100px;
  }
  //
  //
  .App-Btn-Create {
    margin-left: 8px;
    [class^="el-icon-"] {
      margin-right: unset;
      width: unset;
      text-align: unset;
      font-size: unset;
      vertical-align: unset;
      color: white !important;
    }
  }

  //
  // Active menu
  .router-link-exact-active {
    .el-menu-item {
      color: var(--el-color-primary) !important;
      background: var(--el-menu-item-hover-fill);
    }
  }
}
</style>
