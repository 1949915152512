import { App } from "@vue/runtime-core";
import { ApiPluginOptions } from "..";
import { UserUtils } from "./user";

export const RegisterGlobalUtils = {
  install: (app: App, options: ApiPluginOptions): void => {
    app.config.globalProperties.$utils = {
      // Attach global filterd
      user: UserUtils,
    };
  },
};



export * from './object-utils.class'
export * from './window-util'