export class StringUtil {
  /**
   * Shortens a certain string by length
   * @param text
   * @param length
   */
  static shorten(text: string, length = 60) {
    if (text?.length < length) return text;
    return `${text?.slice(0, length)}...`;
  }
}
