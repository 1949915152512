
import { Options, Vue } from "vue-class-component";
import img from "@/assets/illustrations/forgot-password.svg";
import { ErrorUtil } from "@/shared/classes";

@Options({
  components: {},
  methods: {},
  data() {
    return {};
  },
})
export default class extends Vue {
  img = img;
  /**
   * @sends reset instructions
   */
  sendResetIndstructions() {
    this.$api.main
      .get("/v1/account/change-password")
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Sent password reset email.",
        });
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: "Failed to send password reset email.",
        });
      });
  }
}
