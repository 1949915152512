<template>
  <div class="App-Startup-Users-List">
    <el-space fill w-100 direction="vertical">
      <appStartupUser :isCofounder="false" :user="business.founder" />
      <!--  -->
      <!--  -->
      <div v-if="business.coFoundersSize > 0">
        <el-divider />
        <!--  -->
        <div>
          <el-space fill>
            <appStartupUser
              v-for="(cofo, index) of business.coFounders"
              :key="`${index}-user`"
              :coFounder="cofo"
              :isCofounder="true"
            />
          </el-space>
        </div>
      </div>
    </el-space>
  </div>
</template>

<script lang="ts">
import { Business } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import appStartupUser from "./startup-user-component.vue";

@Options({
  components: {
    appStartupUser,
  },
  methods: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  business!: Business;
}
</script>

<style lang="scss">
//
// ===========
// Actions
.App-Startup-Users-List {
  padding-top: 4px;
}
</style>
