<template>
  <app-main-layout>
    <!--  -->
    <!--  -->
    <template v-slot:left>
      <app-left-nav />
    </template>
    <!--  -->
    <template v-slot:bottom-nav>
      <app-bottom-nav />
    </template>
    <!--  -->
    <!--  -->
    <template v-slot:top>
      <app-top-nav padded>
        <template v-slot:left>
          <el-button
            v-if="false"
            type="text"
            plain
            circle
            is-all-borderless
            icon="el-icon-back"
          />
        </template>
        <template v-slot:center>
          <span nav-title>Opportunities</span>
        </template>
        <template v-slot:right> </template>
        <template v-slot:bottom>
          <el-menu
            size-2
            :default-active="activeIndex"
            router
            mode="horizontal"
          >
            <el-menu-item index="/opportunities?isArchived=false">
              <el-icon>
                <checked />
              </el-icon>
              <span>Active</span>
            </el-menu-item>
            <el-menu-item index="/opportunities?isArchived=true">
              <el-icon>
                <star />
              </el-icon>
              <span>Archived</span>
            </el-menu-item>
          </el-menu>
        </template>
      </app-top-nav>
    </template>
    <!--  -->
    <template v-slot:middle>
      <!--  -->
      <!-- Loaded Opportunities -->
      <div>
        <OpportunitiesList :opportunities="opportunities"></OpportunitiesList>
        <app-loader-opportunities
          style="margin-top: 8px"
          :loading="loading"
          :resources="opportunities"
          content="Can't find any opportunities."
        />
      </div>
    </template>
    <!--  -->
    <template v-slot:right>
      <app-right-nav>
        <app-business-right-nav />
        <app-opportunities-list-nav />
      </app-right-nav>
    </template>
    <!--  -->
    <!--  -->
  </app-main-layout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import OpportunitiesList from "./components/opportunities-list.component.vue";
import { Checked, Star } from "@element-plus/icons";
import { Watch } from "vue-property-decorator";
import { RouterUtilClass, VuexResourceFetchOptions } from "@/shared";

@Options({
  name: "AppOpportunitiesPage",
  components: { OpportunitiesList, Checked, Star },
})
export default class extends Vue {
  /**
   * @returns created opportunities
   */
  get opportunities() {
    return this.$store.state.opportunities.opportunities;
  }

  /**
   * Active index for the nav
   */
  get activeIndex() {
    return (
      this.$route.path +
      (this.$route.query.isArchived !== undefined
        ? `?isArchived=${this.$route.query.isArchived}`
        : "")
    );
  }
  /**
   * @returns the loading state of the created opportunities
   */
  get loading() {
    return this.$store.state.opportunities.loading;
  }

  mounted() {
    if (this.$route.query.isArchived === undefined) {
      this.$router.push("/opportunities?isArchived=false");
    }
    this.fetch();
  }

  fetch() {
    this.$store.dispatch("opportunities/fetch", {
      q: RouterUtilClass.getQuery(this.$route),
      clear: true,
    } as VuexResourceFetchOptions);
  }

  @Watch("$route.query.isArchived")
  routeChanged() {
    this.fetch();
  }
}
</script>

<style lang="scss"></style>
