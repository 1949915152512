<template>
  <app-main-layout>
    <!--  -->
    <!--  -->
    <template v-slot:left>
      <app-left-nav />
    </template>
    <!--  -->
    <template v-slot:bottom-nav>
      <app-bottom-nav />
    </template>
    <!--  -->
    <!--  -->
    <template v-slot:top>
      <app-top-nav :padded="false">
        <template v-slot:left>
          <el-button
            v-if="false"
            type="text"
            plain
            circle
            is-all-borderless
            icon="el-icon-back"
          />
        </template>
        <template v-slot:center>
          <span nav-title>Account</span>
        </template>
        <template v-slot:right> </template>
        <template v-slot:bottom> </template>
      </app-top-nav>
    </template>
    <!--  -->
    <template v-slot:middle>
      <!--  -->
      <AccPageHeader :padded="true">
        <template #icon>
          <el-icon><User primary /></el-icon>
        </template>
        <template #content>
          <span>Control how you experience the E4T platform</span>
        </template>
      </AccPageHeader>
      <!--  -->
      <!--  -->
      <app-user-section>
        <div>
          <app-section-divider content="Email" />
          <p>
            <el-tag is-all-borderless type="info">
              {{ user.email }}
            </el-tag>
          </p>
        </div>
        <!--  -->
        <div v-if="user.type === 'talent'">
          <app-section-divider content="Available For Bids?" />
          <p>
            <el-switch
              @change="changeAvailability"
              :model-value="availableForBids"
              style="margin-left: 24px"
              :active-icon="Check"
              :inactive-icon="Close"
              active-text="Available"
              inactive-text="Unavailable"
            >
            </el-switch>
          </p>
        </div>
      </app-user-section>
      <!--  -->
      <!--  -->
      <ChangePasswordComponent />
      <!--  -->
      <!--  -->
    </template>
    <!--  -->
    <template v-slot:right>
      <app-right-nav>
        <AccPageRightNav />
      </app-right-nav>
    </template>
    <!--  -->
    <!--  -->
  </app-main-layout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AccPageHeader from "../components/account-page-header-component.vue";
import AccPageRightNav from "../components/account-pages-right-nav.vue";
import { Check, Close } from "@element-plus/icons";
import { User } from "@element-plus/icons";
import { AccountUtil } from "@/shared/vuex";
import ChangePasswordComponent from "./components/account-password.vue";

@Options({
  components: {
    AccPageHeader,
    User,
    AccPageRightNav,
    Check,
    Close,
    ChangePasswordComponent,
  },
})
export default class extends Vue {
  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }

  loading = false;

  get availableForBids() {
    return this.user?.availableForBids ?? true;
  }

  /**
   * @param change the availability of the talent
   */
  changeAvailability(newVar: boolean) {
    this.loading = true;
    return this.$api.main
      .patch("/v1/account/profile", { availableForBids: newVar })
      .then((resp) => {
        return AccountUtil.fetchCurrent(this.$store);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>

<style lang="scss"></style>
