import { RouteLocationNormalizedLoaded, Router } from "vue-router";

export interface VuexResourceFetchOptions {
  clear: boolean;
  router?: Router;
  q?: string;
}

export class RouterUtilClass {
  /**
   *
   * @param router
   */
  static extractQuery(router: Router) {
    return router.currentRoute.value.fullPath.split("?")[1] || "";
  }

  /**
   *
   * @param router
   */
  static getQuery(route: RouteLocationNormalizedLoaded) {
    const queryPart = route.fullPath.split("?")[1];
    console.log("$Route ", queryPart);
    //
    if (queryPart !== undefined && queryPart.length > 0) return `&${queryPart}`;
    else return "";
  }
}
