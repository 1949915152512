<template>
  <div class="App-Opp-Create-Before">
    <div>
      <div txt-center>
        <el-image :style="`height: 250px;`" :src="img"></el-image>
        <h2 f-w-700>Congratulations!</h2>
        <p m-t-0>
          Now that you have filled in your startup, entrepreneurial and personal
          information, it is time to shine and share opportunities with our
          entrepreneurial talent.
        </p>
      </div>
      <div>
        <div txt-center>
          <el-button
            is-block
            icon="el-icon-check"
            @click="submit"
            type="primary"
            size="large"
          >
            Continue
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import img from "@/assets/illustrations/undraw_winners_congrats.svg";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  emits: ["submit"],
})
export default class extends Vue {
  img = img;
  submit() {
    this.$emit("submit");
  }
}
</script>

<style lang="scss">
.App-Opp-Create-Before {
  > div {
    max-width: 400px;
    margin: auto;
  }
}
</style>
