
import { User } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import AccountTopBusiness from "./account-top-business.component.vue";
import AccountTopDropdown from "./account-top-dropdown.component.vue";

@Options({
  components: {
    AccountTopBusiness,
    AccountTopDropdown,
  },

  computed: {
    /**
     * @returns whether the user is loaded or not
     */
    loadedUser() {
      return this.$props.user !== undefined;
    },
  },
})
export default class extends Vue {
  // user received
  @Prop({ type: Object })
  user!: User;

  clickedAccount() {
    console.log("Clicked here");
  }
}
