
import { SkillFunction } from "@/@business/views/opportunities-page/components/skill-fetch.mixins";
import { ErrorUtil } from "@/shared";
import { Opportunity, regionFilterTypes, WOWs } from "@/shared/interfaces";
import { ApiResourceResult } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";
import countriesRegions from "./countries.json";
import appOpportunityCongrats from "./opportunity-create-congrats.vue";

@Options({
  components: {
    appOpportunityCongrats,
  },
})
export default class extends Vue {
  /**
   * Variables for the component
   */
  loading = false;
  isModalOpen = false;
  isShowingCongrats = false;
  showClose = true;
  /**
   * List of skills fetched from the DB and their salaries
   */
  skills: SkillFunction[] = [];
  wows = WOWs;

  prevOpportunities: ApiResourceResult<Opportunity> = {} as any;

  /**
   * @call this to check if it should be open
   * because there are no opportunities
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
    let hasNoOpportunities = await this.fetchOpportunities();
    this.isModalOpen = hasNoOpportunities;
    this.isShowingCongrats = hasNoOpportunities;
    if (this.isModalOpen) this.showClose = false;
  }
  /**
   * @Watch for open trigger
   * if triggered the user should be able to close
   */
  @Watch("$store.state.modals.isOpportunityOpen")
  watchForOpen() {
    console.log("Open the opportunity create");
    this.isModalOpen = this.$store.state.modals.isOpportunityOpen;
    if (this.isModalOpen) this.showClose = true;
  }

  @Watch("isModalOpen")
  watchForModalOpenChange() {
    console.log("Opened the modal");
  }

  /**
   * @business
   */
  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
  /**
   * @returns a title to use in the modal
   */
  get title() {
    if (this.isShowingCongrats) {
      return "";
    }
    return this.prevOpportunities === undefined
      ? ""
      : this.prevOpportunities.count || 0 > 0
      ? "Add Another Opportunity"
      : "Add Opportunity";
  }
  /**
   * @directly fetches the opportunities and checks if the modal should open directly
   */
  fetchOpportunities() {
    return this.$api.main
      .get<ApiResourceResult<Opportunity>>("/v1/opportunities?limit=1")
      .then((resp) => {
        this.prevOpportunities = resp.data;
        return resp.data.count === 0 ? true : false;
      });
  }

  emptyForm = {
    skill: undefined,
    outcome: undefined,
    talents: undefined,
    location: undefined,
    wow: undefined,
    teamSize: undefined,
    startDate: undefined,
    endDate: undefined,
    compensation: undefined,
    regionFilter: undefined,
    marketSize: undefined,
    description: undefined,
  };
  form = {} as any;
  currency = "£";

  /**
   * Region filtering
   */
  regionFilterTypes = regionFilterTypes.slice(0, 2);
  showRegionFilter = false;
  changeRegionFilter() {
    if (this.showRegionFilter) {
      this.form.regionFilter = {};
    } else {
      this.form.regionFilter = undefined;
    }
  }
  changeRegionFilterType() {
    this.form.regionFilter.options = undefined;
  }
  get regionOptions() {
    if (this.form.regionFilter.type == "region") {
      return Array.from(
        new Set(countriesRegions.map((c) => c.region)).values()
      );
    } else if (this.form.regionFilter.type == "country") {
      return countriesRegions.map((c) => c.country.toLowerCase());
    }
    return [];
  }
  /**
   * @param score
   */
  submit() {
    this.loading = true;
    this.$api.main
      .post<Opportunity>("/v1/opportunities", this.form)
      .then((resp) => {
        this.finalizePosting(resp.data);
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * @triggers updates after creating a new Opportunity profile
   */
  finalizePosting(opp: Opportunity) {
    this.closeModal();
    this.$store.dispatch("opportunities/fetch"); // Trigger to fetch opportunities
    this.$router.push(`/opportunities/${opp._id}`);
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.$store.commit("modals/opportunity", false); // This is enough itself
    this.isModalOpen = false;
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    this.form = { ...this.emptyForm };
  }

  /**
   *
   */
  rules = {
    skill: [{ required: true, message: "Skill is required!", trigger: "blur" }],
    talents: [
      {
        required: true,
        message: "Nbr of talents is required!",
        trigger: "blur",
      },
    ],
    outcome: [
      { required: true, message: "This is required!", trigger: "blur" },
    ],
    wow: [{ required: true, message: "This is required!", trigger: "blur" }],
    startDate: [
      {
        type: "date",
        required: true,
        message: "Start Date is required!",
        trigger: "blur",
      },
    ],
    endDate: [
      {
        type: "date",
        required: true,
        message: "End Date is required!",
        trigger: "blur",
      },
    ],
    compensation: [
      {
        required: true,
        message: "This is required!",
        trigger: "blur",
      },
    ],

    description: [
      {
        required: true,
        message: "This is required!",
        trigger: "blur",
      },
    ],
  };
  get disabledSave() {
    return false;
  }
}
