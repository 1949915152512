
import { Options, Vue } from "vue-class-component";
import StartSurvey from "@/assets/illustrations/survey/start-survey.svg";
import entStanding from "./ent-standing/ent-standing.component.vue";
import { glossary, SurveyScore } from "@/shared/interfaces";

@Options({
  components: { entStanding },
  methods: {},
  data() {
    return {
      StartSurvey,
    };
  },
})
export default class extends Vue {
  glossary = glossary;

  /**
   * @returns User account type
   */
  userType() {
    return (
      this.$store.state.account.user?.type ||
      this.$store.state.account.user?.firstName
    );
  }

  /**
   * @returns user's ent standing
   */
  get standing(): SurveyScore | undefined {
    if (this.$store.state.account.user?.type == "talent")
      return this.$store.state.account.user?.standing;
    else return this.business?.standing;
  }

  /**
   * @business
   */
  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
}
