<template>
  <div>
    <el-dialog
      custom-class="App-User-Profile-Modal App-Unpadded"
      :model-value="isModalOpen"
      width="550px"
      :show-close="showClose"
      :close-on-click-modal="showClose"
      @open="opened"
      title="Education"
      center
      :append-to-body="false"
      :lock-scroll="true"
      :open-delay="200"
      :close-delay="200"
      :before-close="closeModal"
      :close-on-press-escape="false"
    >
      <!--  -->
      <el-scrollbar :always="true" max-height="600px">
        <div v-loading="loading">
          <div>
            <div txt-center>
              <el-image :style="`width: 150px;`" :src="img"></el-image>
              <p>
                Please tell us about <br />
                your education history
              </p>
            </div>
          </div>
          <el-form
            @submit.prevent
            class="App-Styled-Form"
            :label-position="'top'"
            label-width="100px"
            :model="form"
            style="margin-top: 12px"
          >
            <!--  -->
            <div
              v-for="(edu, index) of form.education"
              :key="Math.random() + index"
            >
              <app-education-input
                :index="index"
                v-model="form.education[index]"
                @remove="removeEducation(index)"
              />
            </div>
            <!--  -->
            <div txt-center>
              <el-button
                type="info"
                is-borderless
                round
                @click="addEducation"
                size="small"
                icon="el-icon-plus"
                >Add Education</el-button
              >
            </div>
            <!--  -->
          </el-form>
        </div>
      </el-scrollbar>
      <!--  -->
      <template #footer>
        <div txt-center>
          <el-button
            is-block
            icon="el-icon-check"
            @click="submit"
            size="large"
            type="primary"
            :disabled="loading"
          >
            Save Education Info
          </el-button>
        </div>
      </template>
      <!--  -->
    </el-dialog>
  </div>
</template>

<script lang="ts">
import img from "@/assets/illustrations/user/education.svg";
import { ErrorUtil } from "@/shared/classes";
import { relevantPrograms, User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";

@Options({
  name: "Add-Education",
  emits: ["checked", "opened", "closed"],
  components: {},
})
export default class extends Vue {
  img = img;

  relevantPrograms = relevantPrograms;
  /**
   * Modal variables
   */
  loading = false;
  isModalOpen = false;
  showClose = true;
  /**
   * education dull
   */
  nullEducation = {
    organisation: undefined,
    from: undefined,
    to: undefined,
    degree: undefined,
    program: undefined,
    description: undefined,
  };

  form = {
    education: [Object.assign({}, this.nullEducation)],
  };

  addEducation() {
    this.form.education.push(Object.assign({}, this.nullEducation));
  }
  removeEducation(index: number) {
    this.form.education.splice(index, 1);
  }
  get user() {
    return this.$store.state.account.user;
  }
  /**
   * @when the modal is checked
   */
  @Emit("checked")
  mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      (this.user?.education === undefined ||
        this.user?.education?.length === 0) &&
      this.user?.onboarding.skippedEducation !== true;
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<User>("/v1/account/profile", this.form)
      .then((resp) => {
        AccountUtil.fetchCurrent(this.$store).finally(
          () => (this.loading = false)
        );
        this.closeModal();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.$store.commit("modals/addEducation", false); // This is enough itself
    this.isModalOpen = false;
    this.$api.main.patch<User>("/v1/account/onboard", {
      skippedEducation: true,
    });
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to add education
   */
  @Watch("$store.state.modals.addEducation")
  watchForOPen() {
    this.isModalOpen = this.$store.state.modals.addEducation;
    if (this.isModalOpen) {
      this.showClose = true;
    }
    this.prepareExistingData();
  }
  /**
   * @update account after some changes
   */
  makeUpdatesAfterCreate() {
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
    this.prepareExistingData();
  }

  /**
   * @on open add the existing data to the form
   */
  prepareExistingData() {
    this.form.education =
      this.user?.education?.map((r) => ({
        organisation:
          typeof r.organisation === "string"
            ? r.organisation
            : r.organisation._id,
        from: r.from,
        to: r.to,
        degree: r.degree,
        program: r.program,
        description: r.description,
      })) || ([] as any);
  }
}
</script>

<style lang="scss">
.App-User-Profile-Modal {
  .App-Styled-Form {
    max-width: 400px;
    margin: auto;
  }
}
.el-select-v2__wrapper {
  background-color: transparent !important;
  border: none !important;
}
</style>
