
import { ErrorUtil } from "@/shared/classes";
import {
  glossary,
  QuestionCategories,
  QuestionCategory,
  QuestionCategoryFull,
  SubmitSurveyDto,
  SurveyScore,
} from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Emit } from "vue-property-decorator";
import { QuestionFilled } from "@element-plus/icons";
@Options({ components: { QuestionFilled } })
export default class extends Vue {
  loading = false;
  score: SurveyScore = {} as any;
  remaining = 32;
  glossary = glossary;

  get canSubmit() {
    return this.remaining === 0;
  }

  get max() {
    return this.remaining < 8 ? this.remaining : 8;
  }

  /**
   * @param col
   */
  dimensionFull(col: string) {
    return QuestionCategoryFull[col] || col;
  }

  /**
   * Everytime a dimension is changed
   * we change the remaining points
   */
  inputDim(dimension: QuestionCategory, value: number) {
    this.calculateRemaining();
    if (this.remaining < 0) {
      this.$message.closeAll();
      this.$message({
        type: "error",
        message: "Insufficent remaining points for distribution.",
        duration: 6000,
      });
    }
  }

  calculateRemaining() {
    let sum = 0;
    Object.values(this.score).forEach((score) => {
      if (score !== undefined) sum = sum + score;
    });
    this.remaining = 32 - sum;
  }

  /**
   * @on mount fetch questions and start answering
   */
  mounted() {
    QuestionCategories.forEach((cat) => {
      this.score[cat] = 0;
    });
  }

  /**
   * Submits the survey response
   */
  @Emit("submitted")
  submitSurvey() {
    this.loading = true;
    return this.$api.main
      .post("/v1/surveys", { score: this.score } as SubmitSurveyDto)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
        throw err;
      })
      .finally(() => (this.loading = false));
  }
}
