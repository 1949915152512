
import { Opportunity } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import OpportunityHeader from "./opportunity-header.vue";
import { OpportunityMixin } from "./opportunity-mixin";

import OpportunityUpdateModal from "./update-opportunity-modal.vue";
@Options({
  mixins: [OpportunityMixin],
  components: { OpportunityHeader, OpportunityUpdateModal },
  methods: {
    date(date: string) {
      return new Date(date).toLocaleDateString();
    },
  },

  filters: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  opportunity!: Opportunity;

  max = 250;

  get user() {
    return this.$store.state.account.user;
  }

  get isOwner() {
    return this.user?.type === "founder";
  }

  /**
   * @fired when the view more button is clicked
   */
  clickedMore() {
    this.max = 200000;
  }

  /**
   * @click go to the details page
   */
  goToOpportunity() {
    this.$router.push(`/opportunities/${this.opportunity._id}`);
  }

  get isAtMax() {
    return this.max === 200000;
  }

  decideTalentWording() {
    return this.opportunity.talents > 1 ? "talents" : "talent";
  }
}
