<template>
  <router-link to="/">
    <div class="App-Logo">
      <el-image :style="{ height: `${size}px` }" fit="contain" :src="LogoImg" />
    </div>
  </router-link>
</template>

<script>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LogoImg from "@/assets/logo.svg";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    size: { default: 20, type: Number },
  },
  components: {},
  methods: {},
  data() {
    return {
      LogoImg,
    };
  },
})
export default class extends Vue {
  clicked() {
    this.$router.push("/");
  }
}
</script>

<style lang="scss">
.App-Logo {
  padding: 4px;
  cursor: pointer;
}
</style>
