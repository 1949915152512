import { AuthToken } from "@/@auth/classes";
import { LoginResponse } from "@/@auth/interfaces";
import { $api, Business, User } from "@/shared";
import { AccountUtil, ApiResourceResult } from "@/shared/vuex";
import { Store } from "vuex";

export class FounderAccountUtil {
  /**
   * @fetches and returns current user account
   * @param store
   * @param user
   */
  static async finalizeFounderFetch(store: Store<any>, user: User) {
    const businesses = await this.fetchBusinesses(store);
    const business = AuthToken.decodeToken()?.business;
    if (business) {
      store.commit("account/business", business);
    } else {
      store.commit(
        "account/business",
        businesses.data.resources ? businesses.data.resources[0]._id : undefined
      );
    }
  }

  /**
   * Fetches the startups associated to this user
   * if startups are there but not in the token we renew they to have a default startup
   * @param store
   * @returns
   */
  private static fetchBusinesses(store: Store<any>) {
    return $api.main
      .get<ApiResourceResult<Business>>("/v1/businesses?sort=-createdAt")
      .then((resp) => {
        console.log("AuthToken.decodeToken ", AuthToken.decodeToken());
        this.renewKeyIfNoBusinessInTheKey(resp.data, store);
        store.commit("account/businesses", resp.data.resources);
        store.commit("account/fetchedBusinesses", true);
        return resp;
      })
      .catch((err) => {
        console.log("Facing an error ", err);
        throw err;
      });
  }

  /**
   * iF no business in key but the business exist
   * @param ApiResourceResult
   * @param
   * @param Business
   */
  private static renewKeyIfNoBusinessInTheKey(
    resp: ApiResourceResult<Business>,
    store: Store<any>
  ) {
    console.log("Trying to renew the key");
    if (
      AuthToken.decodeToken().business === undefined &&
      (resp.resources ?? [])?.length > 0
    ) {
      this.renewKey(store);
    }
  }

  /**
   *
   * @param store
   * @returns
   */
  static renewKey(store: Store<any>) {
    return $api.main
      .get<LoginResponse>("/v1/auth/renew")
      .then((resp) => {
        AuthToken.setToken(resp.data.token);
        location.reload();
        return;
      })
      .then((resp) => {
        return AccountUtil.fetchCurrent(store);
      });
  }
}
