<template>
  <app-main-layout>
    <!--  -->
    <!--  -->
    <template v-slot:left>
      <app-left-nav />
    </template>
    <!--  -->
    <template v-slot:bottom-nav>
      <app-bottom-nav />
    </template>
    <!--  -->
    <!--  -->
    <template v-slot:top>
      <app-top-nav padded>
        <template v-slot:left>
          <el-button
            v-if="false"
            type="text"
            plain
            circle
            is-all-borderless
            icon="el-icon-back"
          />
        </template>
        <template v-slot:center>
          <span nav-title>Talents</span>
        </template>
        <!--  -->
        <template v-slot:right> </template>
        <!--  -->
        <template v-slot:bottom>
          <el-menu size-2 mode="horizontal">
            <!--  -->
            <router-link to="/talents/matched">
              <el-menu-item index="/talents/matched">
                <el-icon>
                  <checked />
                </el-icon>
                <span>Matched</span>
              </el-menu-item>
            </router-link>
            <!--  -->
            <!-- <router-link to="/talents/interested">
              <el-menu-item>
                <el-icon>
                  <star />
                </el-icon>
                <span>Interested</span>
              </el-menu-item>
            </router-link> -->
          </el-menu>
        </template>
      </app-top-nav>
    </template>
    <!--  -->
    <template v-slot:middle>
      <!--  -->
      <!-- Loaded talents -->
      <div>
        <router-view></router-view>
      </div>
    </template>
    <!--  -->
    <template v-slot:right>
      <app-right-nav>
        <app-business-right-nav />
        <app-opportunities-list-nav />
      </app-right-nav>
    </template>
    <!--  -->
    <!--  -->
  </app-main-layout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Checked, Star } from "@element-plus/icons";
@Options({
  components: { Checked, Star },
})
export default class extends Vue {
  /**
   * @returns created talents
   */
  get talents() {
    return this.$store.state.talents.talents;
  }

  /**
   * @returns the loading state of the created talents
   */
  get loading() {
    return this.$store.state.talents.loading;
  }

  mounted() {
    this.$store.dispatch("talents/fetch");
  }
}
</script>

<style lang="scss"></style>
