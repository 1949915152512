<template>
  <app-main-layout class="App-Startup-Page">
    <!--  -->
    <!--  -->
    <template v-slot:left>
      <app-left-nav />
    </template>
    <!--  -->
    <template v-slot:bottom-nav>
      <app-bottom-nav />
    </template>
    <!--  -->
    <!--  -->
    <template v-slot:top>
      <app-top-nav :padded="false">
        <template v-slot:left>
          <el-button
            v-if="false"
            type="text"
            plain
            circle
            is-all-borderless
            icon="el-icon-back"
          />
        </template>
        <template v-slot:center>
          <span style="line-height: 20px !important" nav-title>
            <h4 style="padding-right: 6px" m-tb-0 v-if="business !== undefined">
              {{ business.name }}'s
            </h4>
            <div>
              <span mute-secondary style="font-weight: 500; font-size: 14px">
                Profile
              </span>
            </div>
          </span>
        </template>
        <template v-if="business !== undefined && user" v-slot:right>
          <el-dropdown @command="handleCommand">
            <el-button type="primary" icon="el-icon-more" round></el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="edit" icon="el-icon-edit">
                  Edit Startup Profile
                </el-dropdown-item>
                <el-dropdown-item command="editFuture" icon="el-icon-edit">
                  Startup Future
                </el-dropdown-item>
                <el-dropdown-item command="cofounders" icon="el-icon-edit">
                  Co-founders Profiles
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </app-top-nav>
    </template>
    <!--  -->
    <template v-slot:middle>
      <!--  -->
      <div v-loading="loading" class="App-Startup-D-H">
        <!--  -->
        <startupPageHeader :business="business" :loading="loading" />
        <!--  -->
        <div v-if="business !== undefined">
          <!--  -->
          <div>
            <app-user-section>
              <div>
                <appStartupOpp :showHeader="false" :business="business" />
              </div>
              <!--  -->
              <div v-if="business.about">
                <div m-t-3></div>
                <app-section-divider content="Description">
                  <el-icon>
                    <OpportunityBulb />
                  </el-icon>
                </app-section-divider>
                <app-truncate
                  v-if="business.about"
                  style="font-size: 14px"
                  :content="business.about"
                  :max="4000"
                  m-tb-0
                >
                </app-truncate>
              </div>
            </app-user-section>
          </div>
          <!--  -->
          <!--  -->
          <!-- Co-Founders -->
          <app-user-section style="margin-top: 16px">
            <div>
              <app-section-divider :content="`Founders'profiles`">
                <i class="el-icon-user"></i>
              </app-section-divider>
              <div>
                <appStartupUsersList :business="business" />
              </div>
            </div>
          </app-user-section>
          <!--  -->
          <!--  -->
          <div v-if="business !== undefined && user.type === 'founder'">
            <BusinessUpdateModal
              @closed="isEditing = false"
              :business="business"
              :isModalOpen="isEditing"
              @update="updatedBusiness"
            />
          </div>
          <!--  -->
          <!--  -->
        </div>
      </div>
    </template>
    <!--  -->
    <template v-slot:right>
      <app-right-nav>
        <app-business-right-nav />
        <app-opportunities-list-nav />
      </app-right-nav>
    </template>
    <!--  -->
    <!--  -->
  </app-main-layout>
</template>

<script lang="ts">
import { Business } from "@/shared";
import {
  Checked,
  InfoFilled,
  Opportunity as OpportunityBulb,
  UserFilled,
} from "@element-plus/icons";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import appStartupUsersList from "../account-pages/account-startups-page/startup-users-list.vue";
import appStartupOpp from "../opportunity-page/opportunity-startup-details.vue";
import startupPageHeader from "./business-page-header.vue";
import BusinessUpdateModal from "./components/edit-business-modal.vue";

@Options({
  name: "AppBusinessPage",
  components: {
    startupPageHeader,
    appStartupUsersList,
    Checked,
    InfoFilled,
    UserFilled,
    appStartupOpp,
    OpportunityBulb,
    BusinessUpdateModal,
  },
  data() {
    return {
      business: undefined,
    };
  },
})
export default class extends Vue {
  loading = true;
  isEditing = false;
  isEditingFuture = false;

  get isSelf() {
    return this.user?.type === "founder";
  }

  get user() {
    return this.$store.state.account.user;
  }

  mounted() {
    this.businessFetch();
  }

  @Watch("$route.params.id")
  whenRouteChanges() {
    this.businessFetch();
  }

  /**
   * @param command
   */
  handleCommand(command: "edit" | "editFuture" | "cofounders" | "archive") {
    if (command === "edit") this.isEditing = true;
    if (command === "editFuture") {
      this.$store.commit("modals/future", true);
    }
    if (command === "cofounders") {
      this.$store.commit("modals/coFounders", true);
    } else this.archiveBusiness();
  }

  archiveBusiness() {
    console;
  }

  /**
   * @update business data
   */
  @Watch("$store.state.account.businesses")
  updatedBusiness() {
    this.businessFetch();
  }
  /**
   * checks if @current browsed user exists
   */
  businessFetch() {
    this.loading = true;
    this.$api.main
      .get<Business>(`/v1/businesses/${this.$route.params.id}`)
      .then((resp) => {
        (this.$data as any).business = resp.data;
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 300);
      });
  }
}
</script>

<style lang="scss">
.App-Startup-Page {
  //
  .App-Startup-D-H {
    min-height: 60vh;
    // background: white;
  }
  //
  .App-Startup-D {
    background: white;
    min-height: 500px;
    padding: 10px 34px;
    padding-bottom: 3rem;
  }
  .App-Top-Nav-H {
    padding: 16px 0;
  }
  .App-Startup-T {
    background-color: #fdfefe;
    min-height: 60px;
    padding: 10px 20px 20px 20px;
  }
  [nav-title],
  [nav-title-med] {
    display: block !important;
  }
}
</style>
