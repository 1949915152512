<template>
  <div>
    <div class="App-Startup-Head">
      <div v-if="business !== undefined">
        <div txt-center>
          <el-avatar
            :src="business.avatar"
            icon="el-icon-office-building"
            m-lr-auto
            :size="150"
          />
        </div>
        <div txt-center>
          <h2 m-tb-0 f-w-700>{{ $filters.capitalize(business.name) }}</h2>
          <!--  -->
          <h3 m-tb-0 f-w-600 mute-primary>
            <span> {{ business.stage }} Stage </span>
            <span
              mute-secondary
              style="padding-left: 10px; padding-right: 10px"
            >
              |
            </span>
            <span> {{ business.industry }} Industry </span>
            <br />
            <span> {{ business.businessModel }} Business Model </span>
          </h3>
          <!--  -->
          <div mute-secondary>{{ business.coFoundersSize + 1 }} Founders</div>
          <div mute-secondary>
            <span style="padding-left: 12px" v-if="business.location">
              {{ business.location.description }}
            </span>
          </div>
        </div>
        <!--  -->
        <!--  -->
        <app-ent-standing
          class="App-Ent-Standing"
          :labelSelf="`${$filters.capitalize(
            $filters.slice(business.name, 30)
          )}`"
          :standingSelf="business.standing"
          size="medium"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Business } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  methods: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  business!: Business;

  @Prop({ type: Boolean })
  loading!: boolean;
}
</script>

<style lang="scss">
.App-Startup-Head {
  min-height: 500px;
  background-color: #ebf4f2;
  margin-bottom: 10px;
  padding: 5px 20px 20px 20px;
  .App-Ent-Standing {
    margin-top: 14px;
    margin-bottom: 10px;
    background-color: white;
  }
  .el-avatar {
    i {
      font-size: 50px;
      font-weight: bold;
      vertical-align: middle;
    }
  }
}
</style>
