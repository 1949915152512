<template>
  <div class="App-Over-Avatars">
    <!-- Image one -->
    <span class="App-Over-Avatar-One"><slot name="one" /> </span>
    <!-- Image two -->
    <span :style="{ marginLeft: `${-size / 4}px` }" class="App-Over-Avatar-Two">
      <slot name="two" />
    </span>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * This component when given two pictures
 * It displays one over the top of the other
 */
@Options({
  components: {},

})
export default class extends Vue {
  @Prop()
  size = 50;
}
</script>

<style lang="scss">
.App-Over-Avatars {
  display: flex;
  align-items: center;
  .App-Over-Avatar-One {
    > * {
      background-color: $--color-dark-blue;
    }
  }

  .App-Over-Avatar-Two {
    > * {
      background-color: lighten($color: $--color-dark-blue, $amount: 30%);
    }
  }
}
</style>
