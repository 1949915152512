<template>
  <app-main-layout class="User-Page">
    <!--  -->
    <!--  -->
    <template v-slot:left>
      <app-left-nav />
    </template>
    <!--  -->
    <template v-slot:bottom-nav>
      <app-bottom-nav />
    </template>
    <!--  -->
    <template v-slot:top>
      <app-top-nav :padded="false">
        <template v-slot:left>
          <el-button
            v-if="false"
            type="text"
            plain
            circle
            is-all-borderless
            icon="el-icon-back"
          />
        </template>
        <template v-slot:center>
          <span nav-title v-loading="loading">
            <div style="display: inline-block" v-blur="talent" v-if="talent">
              {{ talent.firstName }}'s
            </div>
            <span style="padding-left: 6px"> Profile</span>
          </span>
        </template>
        <template
          v-if="talent"
          v-loading="loading || hasAlreadyBidded"
          v-slot:right
        >
          <!-- Todo: Done -->
          <div v-if="hasAlreadyBidded === true" style="margin-right: 10px">
            <el-tag type="warning" is-rounded> Sent Bid </el-tag>
          </div>
          <div
            v-else-if="
              hasAlreadyBidded === false && talent.availableForBids !== false
            "
          >
            <el-button
              @click="startBidding"
              type="primary"
              icon="el-icon-position"
              round
            >
              Get this talent
            </el-button>
            <biddingCreateModal
              :hasAlreadyBidded="hasAlreadyBidded"
              v-if="opportunity"
              :talent="talent"
              :opportunity="opportunity"
            />
          </div>
        </template>
      </app-top-nav>
    </template>
    <!--  -->
    <template v-slot:middle>
      <!--  -->
      <div v-loading="loading">
        <div v-if="talent">
          <div App-User-Profile-Top>
            <div
              class="App-Talent-T-B"
              v-if="talent.boost !== undefined"
              success
            >
              <i class="el-icon-top" success />
              <span success f-w-600>
                {{ $filters.boost(talent.boost) }}</span
              >
            </div>
          </div>
          <app-user-section
            style="
              border-top-left-radius: 0px !important;
              border-top-right-radius: 0px !important;
            "
          >
            <app-user-top-profile :opportunity="opportunity" :user="talent" />
          </app-user-section>
          <!--  -->
          <!--  -->
          <app-user-skills :user="talent" />
          <!--  -->
          <!--  -->
          <app-user-section>
            <app-user-education :isSelf="isSelf" :user="talent" />
            <app-user-experience :isSelf="isSelf" :user="talent" />
          </app-user-section>
          <!--  -->
          <!--  -->
          <!--  -->
        </div>
      </div>
    </template>
    <!--  -->
    <template v-slot:right>
      <app-right-nav>
        <app-business-right-nav />
        <app-opportunities-list-nav />
      </app-right-nav>
    </template>
    <!--  -->
    <!--  -->
  </app-main-layout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Checked, Star } from "@element-plus/icons";

import biddingCreateModal from "../biddings-page/components/bidding-create-modal.vue";
import { Bidding, ErrorUtil, User } from "@/shared";
import { ApiResourceResult } from "@/shared/vuex";

@Options({
  components: {
    Checked,
    Star,
    biddingCreateModal,
  },
  data() {
    return {
      talent: undefined,
    };
  },
})
export default class extends Vue {
  isSelf = false;
  loading = true;

  hasAlreadyBidded: boolean = "" as any;

  mounted() {
    this.userExists();
  }
  /**
   * checks if @current browsed user exists
   */
  async userExists() {
    if ((await this.getTalent()) === undefined) {
      this.$router.push(`/opportunities/${this.opportunityParam}`);
    } else {
      this.checkIfNotBiddedBefore();
    }
  }

  /**
   * @checks if this talents has not been bidded before
   * on this same opportunity
   */
  checkIfNotBiddedBefore() {
    this.loading = true;
    this.$api.main
      .get<ApiResourceResult<Bidding>>(
        `/v1/biddings?talent=${(this.$data as any).talent._id}&opportunity=${
          this.opportunity._id
        }`
      )
      .then((resp) => {
        this.hasAlreadyBidded =
          (resp.data.resources?.length || 0) > 0 ? true : false;
      })
      .finally(() => (this.loading = false));
  }

  /**
   * @returns talent id in the params
   */
  get talentParam() {
    return this.$route.params.talent;
  }
  /**
   * @returns opportunity id in the params
   */
  get opportunityParam() {
    return this.$route.params.opportunity;
  }
  /**
   * @try and get the talent
   */
  getTalent() {
    return this.$api.main
      .get<User>(`/v1/talents/${this.talentParam}/${this.opportunityParam}`)
      .then((resp) => {
        (this.$data as any).talent = resp.data;
        return resp.data;
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
        this.decideErrorRoute();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * @decide error routing
   */
  decideErrorRoute() {
    if (
      this.opportunityParam === "undefined" ||
      this.opportunityParam === undefined
    ) {
      this.$router.push(`/opportunities`);
    } else {
      this.$router.push(`/opportunities/${this.opportunityParam}`);
    }
  }

  /**
   * @current matched opportunity if any user
   */
  get opportunity() {
    return (this.$data as any).talent?.opportunity;
  }

  /**
   * @start bidding
   */
  startBidding() {
    this.$store.commit("modals/bidding", true);
  }
}
</script>

<style lang="scss">
[App-User-Profile-Top] {
  display: flex;
  align-items: flex-end;
  text-align: right;
  justify-content: flex-end;
  .App-Talent-T-B {
    padding: 6px 14px;
    font-size: 130%;
  }
}
</style>
