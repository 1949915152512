import { SurveyScore } from ".";
import { UserEducation, UserSkill } from "./user";

interface SkillNeeded {
  name: [];
}
export interface BusinessCoFounder {
  names?: string;
  skills?: UserSkill[];
  education?: UserEducation[];
}
export interface Business {
  _id: string;
  name: string;
  industry: string;
  businessModel: string;
  location: string;
  stage: string;
  nextStage: string;
  skillsNeeded?: SkillNeeded[];
  coFounders: BusinessCoFounder[];
  teamSize: string;
  website: string;
  foundingYear: number;
  marketSize: string;
  funding: string;
  about: string;
  canRetakeSurvey: boolean;
  standing: SurveyScore;
  coFoundersSize: number;
}
/**
 * @Improve to fetch these from the API
 */
export const BusinessStages = ["Idea", "MVP", "PMF", "Traction", "Growth"];

export const BusinessModels = [
  "Enterprise",
  "SaaS",
  "Usage-Based",
  "Subscription",
  "Transactional",
  "Marketplace",
  "E-commerce",
  "Advertising",
  "Hardware",
];

export type WOW = "remote" | "hybrid" | "physical";
export const WOWs = ["remote", "physical", "hybrid"];
