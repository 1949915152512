
import { Options, Vue } from "vue-class-component";
import transasctionsList from "./components/transactions-list-component.vue";
import { Checked, Star, Refresh } from "@element-plus/icons";
@Options({
  components: { transasctionsList, Checked, Star, Refresh },
})
export default class extends Vue {
  /**
   * @returns created transactions
   */
  get transactions() {
    return this.$store.state.transactions.transactions;
  }

  /**
   * @returns the loading state of the created transactions
   */
  get loading() {
    return this.$store.state.transactions.loading;
  }

  mounted() {
    this.$store.dispatch("transactions/fetch");
  }
}
