
import { QuestionCategoryFull, SurveyScore } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "AppEntStanding",
  components: {},
})
export default class extends Vue {
  @Prop({ type: String, default: "medium" })
  size!: "mini" | "small" | "medium";

  heights = {
    mini: 100,
    small: 180,
    medium: 210,
  };
  get height() {
    return `${this.heights[this.size]}px`;
  }

  get colHeight() {
    return this.heights[this.size];
  }

  widths = {
    mini: 220,
    small: 320,
    medium: 340,
  };
  get width() {
    return `${this.widths[this.size]}px`;
  }

  spaces = {
    mini: 9,
    small: 10,
    medium: 12,
  };
  get space() {
    return this.spaces[this.size];
  }
  /**
   * Labels
   */
  @Prop({ type: String, require: true })
  labelSelf!: string;

  @Prop({ type: String })
  labelOther!: string;
  /**
   * Default variables
   */
  max = 8;
  get loadingStanding() {
    return {
      thinking: 3,
      perseverance: 5,
      confidence: 4,
      decision: 6,
      motivation: 4,
      planning: 8,
      vision: 3,
      leadership: 6,
    };
  }
  /**
   * @param score
   * Out of a hundred calculates the height of the column element
   */
  calculateHeight(score: number, height = 100) {
    return (score / this.max) * height; // In terms of 100% by default
  }
  /**
   * Standing
   */
  @Prop({ type: Object, require: true })
  standingSelf!: SurveyScore;

  @Prop({ type: Object })
  standingOther!: SurveyScore;
  /**
   * glossary link
   */
  get glossary(): string {
    return `${process.env.VUE_APP_BASE_URL_ABOUT}/glossary`;
  }

  /**
   * Now the height is nolonger dynamic
   * @improve the code to reflect it
   */
  get maxCol(): number {
    return 8;
  }

  /**
   * @returns Columns Names
   */
  get columns() {
    return Object.keys(this.loadingStanding);
  }

  /**
   * @param col
   */
  fullColName(col: string) {
    return QuestionCategoryFull[col] || col;
  }
}
