<template>
  <div>
    <!--  -->
    <div v-if="false" m-b-10px>
      <el-space :size="10" wrap>
        <span mute-primary>Domain of Expertise</span>
        <span>:</span>
        <h3 m-tb-0 style="font-weight: 600">
          {{ user.areaOfExpertise }}
        </h3>
      </el-space>
    </div>
    <!--  -->
    <div v-if="user.startupExperience && isTalent">
      <div m-b-10px v-if="user.startupExperience.stages">
        <el-space :size="10" wrap>
          <span mute-primary>Startup Stages</span>
          <span>:</span>
          <span>
            <h3 m-tb-0 style="font-weight: 600">
              {{ user.startupExperience.stages.join(", ") }}
            </h3>
          </span>
        </el-space>
      </div>
      <!--  -->
      <div m-b-10px v-if="user.startupExperience.models">
        <el-space :size="10" wrap>
          <span mute-primary>Business Models</span>
          <span>:</span>
          <span>
            <h3 m-tb-0 style="font-weight: 600">
              {{ user.startupExperience.models.join(", ") }}
            </h3>
          </span>
        </el-space>
      </div>
    </div>
    <!--  -->
    <!--  -->
  </div>
</template>

<script lang="ts">
import { User } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  methods: {},
  data() {
    return {};
  },
})
export default class extends Vue {
  @Prop()
  user!: User;

  get isTalent() {
    return this.user.type == "talent";
  }
}
</script>

<style lang="scss"></style>
