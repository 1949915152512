import { App } from "vue";
/**
 * Global components
 */
import AppLogo from "./logo.vue";
import AppFooter from "./footer.component.vue";
import AppAccountTop from "./account-top/account-top.component.vue";
import AppTruncate from "./truncate.vue";
import AppLocationSearch from "./location/location-search.vue";

import AppOverAvatars from "./over-avatars.vue";
import AppLoaderEmpty from "./loaders/loader-empty.vue";
import { RegisterGlobalDirectices } from "../directives";
import AppSectionDivider from "./app-section-divider.vue";
/**
 *
 */
import AppLoaderOpportunities from "./loaders/loader-opportunities.vue";
import AppLoaderBiddings from "./loaders/loader-biddings.vue";
import AppLoaderTalents from "./loaders/loader-talents.vue";
import AppLoaderTransactions from "./loaders/loader-transactions.vue";

import "element-plus/theme-chalk/display.css";

import appEntStanding from "@/shared/components/survey-module//ent-standing/ent-standing.component.vue";

export * from "./business-talent";

import CookieNotice from "./cookie-notice/cookie-notice-component.vue";

export const RegisterGlobalComponents = (app: App): void => {
  app
    .component("app-logo", AppLogo)
    .component("app-footer", AppFooter)
    .component("app-account-top", AppAccountTop)
    .component("app-location-search", AppLocationSearch)
    .component("app-truncate", AppTruncate)
    .component("app-over-avatars", AppOverAvatars)
    .component("app-section-divider", AppSectionDivider)
    // survey things
    .component("app-ent-standing", appEntStanding)
    //
    // Loaders
    .component("app-loader-empty", AppLoaderEmpty)
    .component("app-loader-opportunities", AppLoaderOpportunities)
    .component("app-loader-biddings", AppLoaderBiddings)
    .component("app-loader-transactions", AppLoaderTransactions)
    .component("app-loader-talents", AppLoaderTalents)

    //
    .component("app-cookie-notice", CookieNotice);

  /**
   * Register directives too
   */
  RegisterGlobalDirectices(app);
};
