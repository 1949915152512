
import { Opportunity, User } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Options({
  components: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  user!: User;

  @Prop({ type: Object })
  otherTalents!: User[];

  @Prop({ type: Object })
  opportunity!: Opportunity;

  /**
   * @when a talent card is clicked it redirects to the talent's page
   */
  clickedTalent() {
    this.$store.commit("talent/talent", this.user);
    this.$store.commit("talent/otherTalents", this.otherTalents);
    this.$store.commit("talent/opportunity", this.opportunity);
    this.$router.push(`/talent/${this.user._id}/${this.opportunity._id}`);
  }
}
