<template>
  <div txt-center>
    <el-image :src="StartSurvey" />
  </div>
  <div txt-center>
    <h2 m-tb-1 txt-center>Entrepreneurial Assessment</h2>
    <p mtb-0 txt-center>
      <span v-if="user.type === 'founder'">
        Next up, we will require you to rate your founding team from an
        entrepreneurial context. Doing so will enable us to match you with
        talent that not only compliment your technical skills but also elevate
        the entrepreneurial abilities of your team.
      </span>
      <span v-else>
        You're only a few steps away from being matched to high-value
        opportunities throughout our global network. Tell us a little more about
        your entrepreneurial experience. Choose the first response, or closest
        fit that comes to mind.
      </span>
    </p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import StartSurvey from "@/assets/illustrations/survey/start-survey.svg";

@Options({
  name: "SurverResults",
  components: {},
  methods: {},
  data() {
    return {
      StartSurvey,
    };
  },
})
export default class extends Vue {
  get user() {
    return this.$store.state.account.user;
  }
}
</script>

<style lang="scss"></style>
