import { $api } from "@/shared";
import { User, Opportunity } from "@/shared/interfaces";
import { ApiResourceResult } from "@/shared/vuex";
import { StoreOptions } from "vuex";

export interface TalentVuexState {
  talent?: User;
  otherTalents?: User[];
  opportunity?: Opportunity;
  loading: boolean;
}

export const TalentVuex: StoreOptions<TalentVuexState> = {
  ...{ namespaced: true },
  state: {
    talent: undefined,
    otherTalents: undefined,
    loading: false,
    opportunity: undefined
  },
  mutations: {
    /**
     * Assigns a otherTalents as default
     * @param state
     * @param talents
     */
    talent(state, talent: User) {
      state.talent = talent;
    },
    /**
     * Assigns a otherTalents as default
     * @param state
     * @param talents
     */
    talents(state, talents: User[]) {
      state.otherTalents = talents;
    },
    /**
     * Assigns a opportunity as default
     * @param state
     * @param talents
     */
    opportunity(state, opportunity: Opportunity) {
      state.opportunity = opportunity;
    },
  },
  actions: {},
  modules: {},
  //
  getters: {},
};
