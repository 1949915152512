<template>
  <p>
    <span v-html="trimmedContent"> </span>
    <el-link @click="viewMore" type="primary" v-if="showMore">
      {{ "... view more" }}
    </el-link>
  </p>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

@Options({
  emits: ["viewMore"],
})
export default class extends Vue {
  /**
   * Receives text
   */
  @Prop({ type: String }) content!: string;
  @Prop({ type: Number, default: 300 }) max!: number;
  /**
   * @param txt
   */
  private richTxt(str: string) {
    str = (str || "").replace(/(?:\r\n|\r|\n)/g, "<br>");
    return str;
  }

  get trimmedContent() {
    if (this.content?.length > this.max) {
      return this.richTxt(this.content?.substring(0, this.max));
    } else {
      return this.richTxt(this.content);
    }
  }

  /**
   * Show more?
   */
  get showMore() {
    return this.content?.length > this.max;
  }

  @Emit("viewMore")
  viewMore() {
    return this;
  }
}
</script>

<style lang="scss"></style>
