<template>
  <app-left-nav-h>
    <template #top>
      <el-menu active-text-color="#F15B22">
        <!--  -->
        <router-link to="/talents">
          <el-menu-item index="/talents">
            <i class="el-icon-user"></i>
            <span>Talents</span>
          </el-menu-item>
        </router-link>
        <!--  -->
        <router-link to="/opportunities?isArchived=false">
          <el-menu-item index="/opportunities?isArchived=false">
            <i class="el-icon-s-opportunity"></i>
            <span>Opportunities</span>
            <!--  -->
            <span>
              <el-tooltip content="Add Opportunity">
                <el-button
                  class="App-Btn-Create"
                  circle
                  type="primary"
                  icon="el-icon-plus"
                  @click="createOpportunity"
                >
                </el-button>
              </el-tooltip>
            </span>
            <!--  -->
          </el-menu-item>
        </router-link>
        <!--  -->
        <router-link to="/biddings">
          <el-menu-item index="/biddings">
            <i class="el-icon-document"></i>
            <span>Bids</span>
          </el-menu-item>
        </router-link>
        <!--  -->
        <router-link to="/transactions">
          <el-menu-item index="/transactions">
            <i class="el-icon-money"></i>
            <span>Transactions</span>
          </el-menu-item>
        </router-link>
        <!--  -->
      </el-menu>
    </template>
    <!--  -->
    <!-- Bottom Main -->
    <template #bottom>
      <el-menu>
        <router-link to="/account/startups">
          <el-menu-item index="/account/startups">
            <span>Startups</span>
          </el-menu-item>
        </router-link>
        <!--  -->
        <router-link to="/account/account">
          <el-menu-item index="/account/account">
            <span>Account</span>
          </el-menu-item>
        </router-link>
        <!--  -->
        <router-link to="/account/profile">
          <el-menu-item index="/account/profile">
            <span>Profile</span>
          </el-menu-item>
        </router-link>
      </el-menu>
    </template>
  </app-left-nav-h>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class extends Vue {
  /**
   * Called to trigger opening of the opportunity create modal
   */
  createOpportunity(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.$store.commit("modals/opportunity", true);
  }
}
</script>

<style lang="scss"></style>
