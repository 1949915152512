import { Directive } from "@vue/runtime-core";

export const AppBlurContent: Directive = {
  /**
   *
   * @param el
   * @param binding
   * @param vnode
   */
  mounted(el: HTMLElement, binding, vnode) {
    console.log("Blur value , ", binding.value.isPaid === false);
    if (binding.value.type === "talent" && binding.value.isPaid === false) {
      console.log(el, " el");
      el.style.height = `${el.clientHeight}px`;
      //
      const clonEl = document.createElement("div");
      clonEl.style.filter = "blur(7px )";
      clonEl.innerHTML = el.innerHTML;
      clonEl.style.display = "inline-block";
      clonEl.style.backgroundColor = "#8080804a";
      //
      el.innerHTML = "";
      el.append(clonEl);
      el.style.overflow = "hidden";
    }
  },
};
