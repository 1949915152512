<template>
  <div>
    <el-dialog
      custom-class="App-Create-Business-Modal App-Unpadded"
      :model-value="isModalOpen"
      width="550px"
      :show-close="showClose"
      :close-on-click-modal="showClose"
      :close-on-press-escape="showClose"
      @open="opened"
      title="Startup Next Stage"
      center
      :append-to-body="true"
      :lock-scroll="true"
      :open-delay="200"
      :before-close="closeModal"
      :close-delay="200"
    >
      <!--  -->
      <div>
        <el-scrollbar
          ref="scrollbarRef"
          :always="true"
          v-loading="loading"
          max-height="70vh"
        >
          <div ref="innerRef">
            <!--  -->
            <div>
              <div>
                <div txt-center>
                  <el-image :style="`width: 250px;`" :src="img"></el-image>
                  <p m-rl-auto style="max-width: 350px" txt-center>
                    What is your next startup stage? <br />
                    What skills do you think you need to get there?
                  </p>
                </div>
              </div>
            </div>
            <!--  -->
            <el-form
              @submit.prevent
              class="App-Styled-Form"
              :label-position="'top'"
              label-width="100px"
              :model="form"
              style="margin-top: 12px"
            >
              <!--  -->
              <!-- stage -->
              <app-business-stage-input
                label="Your startup's next stage"
                v-model="form.nextStage"
              />
              <!--  -->
              <el-divider />
              <!-- Skill input -->
              <h4 txt-center mt-2>
                Select the skillsets needed to transition your startup
                {{ startupName }} to the next stage, indicated above.
              </h4>
              <el-row
                align="middle"
                justify="center"
                v-for="(skill, index) of form.skillsNeeded"
                :key="`skill-input-${index}${skill.name}`"
              >
                <el-col :span="index == 0 ? 24 : 22">
                  <app-skill-input
                    w-100
                    v-model="form.skillsNeeded[index].name"
                  />
                </el-col>
                <el-col txt-right v-if="index !== 0" :span="2">
                  <el-button
                    style="margin-bottom: 18px"
                    v-if="index > 0"
                    @click="removeSkill(index)"
                    icon="el-icon-delete"
                    circle
                    size="mini"
                  />
                </el-col>
              </el-row>
              <!--  -->
              <div m-b-3 txt-center>
                <el-button
                  class="button-new-tag"
                  size="small"
                  @click="addSkill"
                  round
                  icon="el-icon-plus"
                >
                  Add Skill
                </el-button>
              </div>
              <!--  -->
            </el-form>
          </div>
        </el-scrollbar>
      </div>
      <!--  -->
      <template #footer>
        <div txt-center>
          <el-button
            is-block
            icon="el-icon-check"
            @click="submit"
            size="large"
            type="primary"
            :disabled="disabledSave || loading"
          >
            Save
          </el-button>
        </div>
      </template>
      <!--  -->
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { FounderAccountUtil } from "@/@business/store";
import img from "@/assets/illustrations/startup/future.svg";
import { ErrorUtil } from "@/shared";
import { Business, User } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { ElScrollbar } from "element-plus";
import { Filters } from "@/shared/filters";

@Options({
  emits: ["checked", "opened", "closed"],
  components: {},
})
export default class extends Vue {
  img = img;
  isModalOpen = false;
  showClose = false;
  loading = false;

  get scrollbarRef(): typeof ElScrollbar {
    return this.$refs.scrollbarRef as any;
  }
  get innerRef(): HTMLDivElement {
    return this.$refs.innerRef as any;
  }

  @Prop({ type: Object })
  business!: Business;

  get startupName() {
    return Filters.capitalize(this.business.name);
  }

  @Emit("checked")
  mounted() {
    this.isModalOpen = this.shouldOpenModal;
    console.log(`Business Next Modal ${this.isModalOpen}`);
  }

  /**
   * Determines if this modal shoould open
   */
  get shouldOpenModal() {
    return (
      this.business.nextStage === undefined ||
      this.business.nextStage.length == 0 ||
      this.business.skillsNeeded?.length === 0
    );
  }
  /**
   * Form to later on submit
   */
  form = {
    nextStage: undefined,
    skillsNeeded: [{}],
  };

  /**
   * @param score
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<Business>(`/v1/businesses/${this.business._id}`, this.form)
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Saved the future!",
        });
        return this.finalizeUpdates(resp.data);
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * @triggers updates after creating a new business profile
   */
  finalizeUpdates(business: Business) {
    this.loading = true;
    this.closeModal();
    FounderAccountUtil.finalizeFounderFetch(
      this.$store,
      this.$store.state.account.user as User
    );
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    this.$store.commit("modals/future", false); // This is enough itself
    this.isModalOpen = false;
  }

  @Watch("$store.state.modals.isFutureOpen")
  watchForOpen() {
    this.isModalOpen = this.$store.state.modals.isFutureOpen;
  }

  @Emit("opened")
  opened() {
    this.assignForm();
  }

  assignForm() {
    this.form.nextStage = this.business.nextStage as any;
    this.form.skillsNeeded = this.business.skillsNeeded as any;
    if ((this.form.skillsNeeded?.length ?? 0) === 0) {
      this.form.skillsNeeded = [{ name: [] }] as any;
    }
  }

  addSkill() {
    this.form.skillsNeeded.push({ name: [] });
    setTimeout(() => {
      this.scrollbarRef?.setScrollTop(this.innerRef?.clientHeight + 100);
    }, 600);
  }
  removeSkill(index) {
    if (this.form.skillsNeeded.length > 0)
      this.form.skillsNeeded.splice(index, 1);
  }

  get disabledSave() {
    return this.form.nextStage == undefined;
  }
}
</script>

<style lang="scss">
.App-Create-Business-Modal {
  .App-Styled-Form {
    max-width: 400px;
    margin: auto;
  }
}
</style>
