<template>
  <div class="App-Startup-Acc">
    <!--  -->
    <el-avatar :size="40" :src="avatar">
      <span v-blur="names">
        {{ $filters.initials(names) }}
      </span>
    </el-avatar>
    <!--  -->
    <div>
      <h4 m-tb-0 f-w-700>
        <span>
          {{ names }}
        </span>
      </h4>
      <!--  -->
      <div>
        <span style="padding-right: 4px" mute-primary> {{ headline }}</span>
      </div>
      <div>
        <span style="padding-right: 4px" mute-primary>
          <span>{{ education.length }} education record(s), </span>
          <span>{{ skills.length }} skills.</span>
        </span>
      </div>
    </div>
    <!-- Actions -->
    <div class="App-Startup-Acc-Act"></div>
  </div>
</template>

<script lang="ts">
import { BusinessCoFounder, User } from "@/shared";
import { Filters } from "@/shared/filters";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  methods: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  user!: User;

  @Prop({ type: Object })
  coFounder!: BusinessCoFounder;

  @Prop({ type: Boolean })
  isCofounder!: boolean;

  get avatar(): string {
    return !this.isCofounder ? this.user.avatar : "";
  }

  get names(): string {
    if (this.isCofounder === false)
      return `${Filters.capitalize(this.user.firstName)} ${Filters.capitalize(
        this.user.lastName
      )}`;
    else return Filters.capitalize(this.coFounder.names || "Co-founder");
  }
  get headline(): string {
    return this.isCofounder ? "Co-founder" : "Founder";
  }

  get skills() {
    return (this.isCofounder ? this.coFounder.skills : this.user.skills) || [];
  }

  get education() {
    return (
      (this.isCofounder ? this.coFounder.education : this.user.education) || []
    );
  }
}
</script>

<style lang="scss">
//
// ===========
// Actions
.App-Startup-Acc {
  display: flex;
  align-items: center;
  //
  .el-avatar {
    margin-right: 10px;
  }
  //
  h4 {
    margin-bottom: -4px;
  }
  //
  // Actions
  .App-Startup-Acc-Act {
  }
}
</style>
