
import { BusinessCoFounder, User } from "@/shared";
import { Filters } from "@/shared/filters";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  methods: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  user!: User;

  @Prop({ type: Object })
  coFounder!: BusinessCoFounder;

  @Prop({ type: Boolean })
  isCofounder!: boolean;

  get avatar(): string {
    return !this.isCofounder ? this.user.avatar : "";
  }

  get names(): string {
    if (this.isCofounder === false)
      return `${Filters.capitalize(this.user.firstName)} ${Filters.capitalize(
        this.user.lastName
      )}`;
    else return Filters.capitalize(this.coFounder.names || "Co-founder");
  }
  get headline(): string {
    return this.isCofounder ? "Co-founder" : "Founder";
  }

  get skills() {
    return (this.isCofounder ? this.coFounder.skills : this.user.skills) || [];
  }

  get education() {
    return (
      (this.isCofounder ? this.coFounder.education : this.user.education) || []
    );
  }
}
