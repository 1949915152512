
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * This component has two slots
 * For handling when data is empty
 * And when data is loading
 */
@Options({})
export default class extends Vue {
  @Prop({ type: Boolean })
  loading!: boolean;

  @Prop({ type: Object })
  resources!: any[];
}
