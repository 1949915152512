import { $api } from "@/shared";
import { Bidding } from "@/shared/interfaces";
import { ApiResourceResult } from "@/shared/vuex";
import { Router } from "vue-router";
import { StoreOptions } from "vuex";

export interface BusinessBiddingsVuexState extends ApiResourceResult<Bidding> {
  biddings?: Bidding[];
  loading: boolean;
}
export interface BiddingsVuexFetchOptions {
  clear: boolean;
  router?: Router;
  q?: string;
}
export const BusinessBiddingsVuex: StoreOptions<BusinessBiddingsVuexState> = {
  ...{ namespaced: true },
  state: {
    biddings: undefined,
    loading: false,
  },
  mutations: {
    /**
     * Assigns a Biddings as default
     * @param state
     * @param data
     */
    biddings(state, data: { data: Bidding[]; clear: boolean }) {
      if (data.clear) {
        state.biddings = data.data;
      } else {
        state.biddings = state.biddings
          ? state.biddings.concat(data.data)
          : data.data;
      }
    },
  },
  actions: {
    /**
     * Fetches biddings
     * @param context
     */
    fetch(context, options?: BiddingsVuexFetchOptions) {
      context.state.loading = true;
      $api.main
        .get<ApiResourceResult<Bidding>>(
          `/v1/biddings?sort=-createdAt${options?.q}`
        )
        .then((resp) => {
          context.state.count = resp.data.count;
          context.state.next = resp.data.next;
          context.state.skipped = resp.data.skipped;
          context.state.biddings = resp.data.resources;
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
  },
  modules: {},
  //
  getters: {},
};
