<template>
  <span>
    <el-dialog
      custom-class="App-Update-Opportunity-Modal App-Unpadded"
      :model-value="isModalOpen"
      width="550px"
      @open="opened"
      @close="closeModal"
      title="Update Opportunity"
      center
      :show-close="showClose"
      :close-on-click-modal="showClose"
      :close-on-press-escape="showClose"
      :append-to-body="true"
      :lock-scroll="true"
      :open-delay="200"
      :close-delay="200"
    >
      <!--  -->
      <el-scrollbar :always="true" max-height="500px">
        <div v-loading="loading">
          <el-form
            v-if="skills !== undefined"
            @submit.prevent
            class="App-Styled-Form"
            :label-position="'top'"
            label-width="100px"
            :model="form"
            style="margin-top: 12px"
          >
            <!--  -->
            <!--  -->
            <!-- Number of talents -->
            <el-form-item prop="talents" label="Number of talents">
              <el-input-number
                w-100
                :controls="false"
                placeholder="EX: 2"
                v-model="form.talents"
              />
            </el-form-item>
            <!--  -->
            <!-- out come -->
            <el-form-item
              prop="outcome"
              label="What is the opportunity outcome?"
            >
              <el-input
                placeholder="Build an MVP web app"
                v-model="form.outcome"
              />
            </el-form-item>
            <!--  -->
            <el-divider />
            <!--  -->
            <el-space
              w-100
              style="justify-content: space-between"
              alignment="center"
              gutter="12"
              :wrap="false"
            >
              <el-form-item prop="startDate" label="Start Date">
                <el-date-picker
                  type="date"
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  v-model="form.startDate"
                />
              </el-form-item>
              <el-form-item prop="endDate" label="End Date">
                <el-date-picker
                  type="date"
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  v-model="form.endDate"
                />
              </el-form-item>
            </el-space>
            <!--  -->
            <!--  -->
            <el-divider />
            <!-- compensation  -->
            <el-form-item
              prop="compensation"
              label="How are you willing to compensate the talent?"
            >
              <el-select
                multiple
                v-model="form.compensation"
                placeholder="Compensation Method"
                w-100
              >
                <el-option :value="'cash'" :label="'Cash'" />
                <el-option :value="'equity'" :label="'Equity'" />
              </el-select>
            </el-form-item>
            <!-- Ways of working  -->
            <el-form-item prop="wow" label="Planned working strategy">
              <el-select v-model="form.wow" placeholder="Remote, Hybrid" w-100>
                <el-option
                  :key="`${vf}-wow`"
                  v-for="vf in wows"
                  :value="vf"
                  :label="$filters.capitalize(vf)"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- Location -->
            <transition name="el-zoom-in-top">
              <app-location-search
                v-show="form.wow == 'physical' || form.wow == 'hybrid'"
                label="Where is the opportunity based?"
                :size="'default'"
                v-model="form.location"
              />
            </transition>
            <!--  -->
            <el-divider />
            <!--  -->
            <div>
              <el-checkbox
                @change="changeRegionFilter"
                v-model="showRegionFilter"
                size="large"
                label="Add region filtering"
              >
              </el-checkbox>
              <div style="margin-bottom: 1.2rem; margin-top: -8px">
                <small class="mute-secondary">
                  Filter the talents to match with based on either country or
                  region
                </small>
              </div>
            </div>
            <div v-if="showRegionFilter && form.regionFilter != undefined">
              <el-form-item
                prop="regionFilter"
                label="Choose filtering category"
              >
                <el-select
                  v-model="form.regionFilter.type"
                  placeholder="Region"
                  w-100
                  @change="changeRegionFilterType()"
                >
                  <el-option
                    :key="`${vf}-regionfilter`"
                    v-for="vf in regionFilterTypes"
                    :value="vf"
                    :label="$filters.capitalize(vf)"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- Country / region -->
              <el-form-item prop="regionFilterOption" label="Choose region">
                <el-select
                  v-model="form.regionFilter.options"
                  placeholder="Choose"
                  multiple
                  filterable
                  w-100
                >
                  <el-option
                    :key="`${vf}-regionfilter-op`"
                    v-for="vf in regionOptions"
                    :value="vf"
                    :label="$filters.capitalize(vf)"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!--  -->
            <el-divider />
            <!--  -->
            <!-- Description -->
            <el-form-item prop="about" label="Opportunity Description">
              <el-input
                rows="5"
                type="textarea"
                placeholder="Description"
                v-model="form.description"
              />
            </el-form-item>
            <!--  -->
            <!--  -->
          </el-form>
        </div>
      </el-scrollbar>
      <!--  -->
      <template #footer>
        <div txt-center>
          <el-button
            is-block
            icon="el-icon-check"
            @click="submit"
            size="large"
            type="primary"
            :disabled="disabledSave || loading"
          >
            Save Changes
          </el-button>
        </div>
      </template>
      <!--  -->
    </el-dialog>
  </span>
</template>

<script lang="ts">
import { SkillFunction } from "@/@business/views/opportunities-page/components/skill-fetch.mixins";
import { ErrorUtil } from "@/shared";
import { Opportunity, regionFilterTypes, WOWs } from "@/shared/interfaces";
import { ObjectUtil } from "@/shared/utils";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import countriesRegions from "./opportunity-create/countries.json";

@Options({
  emits: ["opened", "closed"],
  components: {},
})
export default class extends Vue {
  /**
   * Variables for the component
   */
  loading = false;

  @Prop({ type: Boolean })
  isModalOpen!: boolean;

  @Prop({ type: Object })
  opportunity!: Opportunity;

  showClose = true;
  /**
   * List of skills fetched from the DB and their salaries
   */
  skills: SkillFunction[] = [];
  wows = WOWs;

  /**
   * @call this to check if it should be open
   * because there are no opportunities
   */
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.pickFields();
  }

  editFields = [
    "talents",
    "startDate",
    "endDate",
    "outcome",
    "description",
    "wow",
    "isPublished",
    "compensation",
    "location",
    "regionFilter",
  ];

  form = {} as any;

  /**
   * Region filtering
   */
  regionFilterTypes = regionFilterTypes.slice(0, 2);
  showRegionFilter = false;
  changeRegionFilter() {
    if (this.showRegionFilter) {
      this.form.regionFilter = {};
    } else {
      this.form.regionFilter = undefined;
    }
  }
  changeRegionFilterType() {
    this.form.regionFilter.options = undefined;
  }
  get regionOptions() {
    if (this.form.regionFilter.type == "region") {
      return Array.from(
        new Set(countriesRegions.map((c) => c.region)).values()
      );
    } else if (this.form.regionFilter.type == "country") {
      return countriesRegions.map((c) => c.country.toLowerCase());
    }
    return [];
  }
  /**
   * @param score
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<Opportunity>(
        `/v1/opportunities/${this.opportunity._id}`,
        this.form
      )
      .then((resp) => {
        this.finalizePosting(resp.data);
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * @triggers updates after creating a new Opportunity profile
   */
  @Emit("update")
  finalizePosting(opp: Opportunity) {
    this.$store.dispatch("opportunities/opportunity", opp);
    this.closeModal();
  }

  pickFields() {
    this.form = ObjectUtil.mergeDeep(
      ObjectUtil.pick(this.opportunity, this.editFields)
    );
    if (this.form.regionFilter != undefined) {
      this.showRegionFilter = true;
    }
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console;
  }

  @Emit("opened")
  opened() {
    this.pickFields();
  }

  /**
   * @improve this so it can check whether the form is empty or not
   */
  get disabledSave() {
    return false;
  }
}
</script>

<style lang="scss">
.App-Update-Opportunity-Modal {
  .App-Styled-Form {
    max-width: 450px;
    margin: auto;
  }
}
</style>
