<template>
  <div @click="clickedTalent" class="App-Talent">
    <div class="App-Talent-T">
      <div>
        <div class="App-Talent-T-B" v-if="user.boost !== undefined" success>
          <i class="el-icon-top" success />
          <span success f-w-600> {{ $filters.boost(user.boost) }}</span>
        </div>
      </div>
    </div>
    <div class="App-Talent-D">
      <div txt-center>
        <el-avatar :size="70" :src="user.avatar">
          <span v-blur="user">
            {{ $filters.initials(user) }}
          </span>
        </el-avatar>
      </div>
      <div txt-center>
        <h3 v-blur="user">{{ user.firstName }} {{ user.lastName }}</h3>
      </div>
      <div txt-center>
        <div>
          <h4>{{ user.areaOfExpertise }}</h4>
        </div>
        <div v-if="user.startupExperience">
          <h4 v-if="user.startupExperience.stages">
            {{ user.startupExperience.stages.join(", ") }}
          </h4>
          <h4 v-if="user.startupExperience.models">
            {{ user.startupExperience.models.join(", ") }}
          </h4>
        </div>
      </div>
      <el-divider />
      <div txt-center mute-secondary v-if="user.location">
        {{ user.location.description }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Opportunity, User } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Options({
  components: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  user!: User;

  @Prop({ type: Object })
  otherTalents!: User[];

  @Prop({ type: Object })
  opportunity!: Opportunity;

  /**
   * @when a talent card is clicked it redirects to the talent's page
   */
  clickedTalent() {
    this.$store.commit("talent/talent", this.user);
    this.$store.commit("talent/otherTalents", this.otherTalents);
    this.$store.commit("talent/opportunity", this.opportunity);
    this.$router.push(`/talent/${this.user._id}/${this.opportunity._id}`);
  }
}
</script>

<style lang="scss">
.App-Talent {
  min-height: 180px;
  background: white;
  border-radius: map.get($--border-radius, "base");
  margin-bottom: 6px;
  cursor: pointer;
  transition: 300ms ease-in;
  &:hover {
    box-shadow: map.get($--box-shadow, "light");
  }
  // Top
  .App-Talent-T {
    background-color: rgba($--background-color-base, 0.5);
    height: 60px;
  }
  // Details
  .App-Talent-D {
    margin-top: -55px;
    padding: map.get($--button-padding-vertical, "large")
      map.get($--button-padding-vertical, "large")
      map.get($--button-padding-vertical, "large")
      map.get($--button-padding-vertical, "large");
    h3,
    h4 {
      margin: -1px auto;
    }
    h3 {
      font-weight: 600;
    }
    h4 {
      font-weight: 500;
    }
  }
  .el-avatar {
    background-color: $--color-dark-blue;
    font-weight: 600;
  }
  .el-divider {
    margin: 8px auto;
    max-width: 90px;
  }

  .App-Talent-T-B {
    text-align: right;
    padding: 4px 10px;
  }
}
</style>
