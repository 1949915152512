<template>
  <el-space :size="14" fill w-100 class="App-biddings-List">
    <app-bidding
      v-for="(bidd, index) in biddings"
      :bidding="bidd"
      :key="`bidd-${bidd._id}-${index}`"
    >
    </app-bidding>
  </el-space>
</template>

<script lang="ts">
import { Bidding } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  @Prop({ type: Array })
  biddings!: Bidding[];
}
</script>

<style lang="scss">
.App-biddings-List {
}
</style>
