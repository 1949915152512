
import { SkillFunction } from "@/@business/views/opportunities-page/components/skill-fetch.mixins";
import { ErrorUtil } from "@/shared";
import { Opportunity, regionFilterTypes, WOWs } from "@/shared/interfaces";
import { ObjectUtil } from "@/shared/utils";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import countriesRegions from "./opportunity-create/countries.json";

@Options({
  emits: ["opened", "closed"],
  components: {},
})
export default class extends Vue {
  /**
   * Variables for the component
   */
  loading = false;

  @Prop({ type: Boolean })
  isModalOpen!: boolean;

  @Prop({ type: Object })
  opportunity!: Opportunity;

  showClose = true;
  /**
   * List of skills fetched from the DB and their salaries
   */
  skills: SkillFunction[] = [];
  wows = WOWs;

  /**
   * @call this to check if it should be open
   * because there are no opportunities
   */
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.pickFields();
  }

  editFields = [
    "talents",
    "startDate",
    "endDate",
    "outcome",
    "description",
    "wow",
    "isPublished",
    "compensation",
    "location",
    "regionFilter",
  ];

  form = {} as any;

  /**
   * Region filtering
   */
  regionFilterTypes = regionFilterTypes.slice(0, 2);
  showRegionFilter = false;
  changeRegionFilter() {
    if (this.showRegionFilter) {
      this.form.regionFilter = {};
    } else {
      this.form.regionFilter = undefined;
    }
  }
  changeRegionFilterType() {
    this.form.regionFilter.options = undefined;
  }
  get regionOptions() {
    if (this.form.regionFilter.type == "region") {
      return Array.from(
        new Set(countriesRegions.map((c) => c.region)).values()
      );
    } else if (this.form.regionFilter.type == "country") {
      return countriesRegions.map((c) => c.country.toLowerCase());
    }
    return [];
  }
  /**
   * @param score
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<Opportunity>(
        `/v1/opportunities/${this.opportunity._id}`,
        this.form
      )
      .then((resp) => {
        this.finalizePosting(resp.data);
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * @triggers updates after creating a new Opportunity profile
   */
  @Emit("update")
  finalizePosting(opp: Opportunity) {
    this.$store.dispatch("opportunities/opportunity", opp);
    this.closeModal();
  }

  pickFields() {
    this.form = ObjectUtil.mergeDeep(
      ObjectUtil.pick(this.opportunity, this.editFields)
    );
    if (this.form.regionFilter != undefined) {
      this.showRegionFilter = true;
    }
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console;
  }

  @Emit("opened")
  opened() {
    this.pickFields();
  }

  /**
   * @improve this so it can check whether the form is empty or not
   */
  get disabledSave() {
    return false;
  }
}
