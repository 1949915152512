import { Store } from "vuex";
import { AccountUtil } from "../vuex";

export class WindowUtil {
  /**
   *
   * @param document
   * @param store
   */
  static listenToChange(document: Document, store: Store<any>) {
    document.addEventListener(
      "visibilitychange",
      () => {
        if (!document.hidden) {
          AccountUtil.fetchCurrent(store);
          console.log("Just activated the window");
        }
      },
      false
    );
  }
}
