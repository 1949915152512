<template>
  <div class="Acc-Page-hd" :class="padded ? 'isPadded' : ''">
    <div class="Acc-Page-hd-Icon">
      <slot name="icon"> </slot>
    </div>
    <div class="Acc-Page-hd-Cont">
      <p>
        <slot name="content"> </slot>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  @Prop({ type: Boolean, default: false })
  padded!: boolean;
}
</script>

<style lang="scss">
.Acc-Page-hd {
  background: $--background-base-light;
  padding: 14px 14px 20px 14px;
  text-align: center;
  &.isPadded {
    margin-bottom: 6px;
  }
  .Acc-Page-hd-Icon {
    background: white;
    display: inline-block;
    width: 54px;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    margin: 20px auto;
  }
  .el-icon {
    font-size: 24px;
    font-weight: bold;
    color: $--color-dark-blue;
    height: 50px;
    svg {
      height: 50px;
      font-weight: bold;
    }
  }
  p {
    font-size: 16px;
    font-weight: 600;
    max-width: 350px;
    margin: auto;
    color: $--color-dark-blue;
  }
}
</style>
