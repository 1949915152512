
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * This component when given two pictures
 * It displays one over the top of the other
 */
@Options({
  components: {},

})
export default class extends Vue {
  @Prop()
  size = 50;
}
