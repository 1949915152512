
import { FounderAccountUtil } from "@/@business/store";
import { ErrorUtil } from "@/shared";
import {
  Business,
  BusinessModels,
  BusinessStages,
  IndustriesList,
} from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";
import { BusinessFormRules } from "./business-form";

@Options({
  components: {},
})
export default class extends Vue {
  /**
   *
   */
  isModalOpen = false;
  showClose = true;
  //
  industries = IndustriesList;
  businessModels = BusinessModels;

  /**
   * @call this to check if it should be open
   */
  @Emit("checked")
  mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      this.$store.state.account.businesses === undefined ||
      this.$store.state.account.businesses.length === 0;
    if (this.isModalOpen) this.showClose = false;
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to close
   */
  @Watch("$store.state.modals.isBusinessOpen")
  watchForOpen() {
    this.isModalOpen = this.$store.state.modals.isBusinessOpen;
    if (this.isModalOpen) this.showClose = true;
  }

  form = {
    name: undefined,
    industry: undefined,
    businessModel: undefined,
    location: undefined,
    stage: undefined,
    teamSize: undefined,
    coFoundersSize: 0,
    website: undefined,
    foundingYear: undefined,
    marketSize: undefined,
    funding: {} as any,
    about: undefined,
  };

  tempform = {
    hasCoFounders: false,
  };
  /**
   *
   */
  loading = false;
  /**
   * @param score
   */
  submit() {
    this.loading = true;
    this.$api.main
      .post<Business>("/v1/businesses", this.form)
      .then((resp) => {
        // set it a sa default business now
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Created business profile successfully!",
        });
      })
      .then(() => {
        return this.makeUpdatesAfterCreate();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * @triggers updates after creating a new business profile
   */
  makeUpdatesAfterCreate() {
    this.loading = true;
    this.renewKey();
  }

  /**
   * @handles the renew of key right after creating a new business
   */
  renewKey() {
    FounderAccountUtil.renewKey(this.$store).finally(() => {
      this.loading = false;
      this.closeModal();
    });
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    this.$store.commit("modals/business", false);
    this.isModalOpen = false;
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
  }

  /**
   *
   */
  rules = BusinessFormRules;

  get disabledSave() {
    return false;
  }
}
