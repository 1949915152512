
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class extends Vue {
  /**
   * @returns current signed in user
   */
  get user() {
    return this.$store.state.account.user;
  }
}
