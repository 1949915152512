
import { Opportunity } from "@/shared";
import { ApiResourceResult } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { CircleCheck, Opportunity as Bulb } from "@element-plus/icons";

@Options({
  components: { CircleCheck, Bulb },
  methods: {},
})
export default class extends Vue {
  /**
   * @variables indicating the opportunies fetch
   */
  opportunities: Opportunity[] = [];
  loading = true;
  count = undefined as any;

  get user() {
    return this.$store.state.account.user;
  }

  mounted() {
    this.fetchOpportunities();
  }

  /**
   * @fetches the opportunies availble from this business profile
   */
  fetchOpportunities() {
    return this.$api.main
      .get("/v1/opportunities?limit=5&sort=-createdAt")
      .then((resp) => {
        this.opportunities =
          this.user?.type === "founder" ? resp.data.resources : resp.data || [];
        this.count =
          this.user?.type === "founder" ? resp.data.count : resp.data?.length;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
