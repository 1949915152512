import { AuthToken } from "@/@auth/classes";
import Axios, { AxiosInstance } from "axios";
import { App } from "vue";

/**
 *
 */
export type API_KEY = "main" | "admin";
export type ApiPluginOptions = {
  apis: Record<API_KEY, string>;
};

/**
 * This plugin attaches axios to the the global thing
 * And then installs
 * this.api.main
 * this.api.admin to the global
 */
export let $api: Record<API_KEY, AxiosInstance>;
export const apiPlugin = {
  install: (app: App, options: ApiPluginOptions): void => {
    /*  our code for the plugin goes here
     *  app is the result of createApp()
     *  options is user options passed in
     */
    app.config.globalProperties.$api = {};
    for (const api in options.apis) {
      /**
       * Make a copy of axios
       */
      const apiAxios = Object.assign({}, Axios.create());
      /**
       * Interceptors
       */
      apiAxios.interceptors.request.use((config) => {
        console.log(AuthToken.getToken());
        config.headers = {
          ...config.headers,
          Authorization: AuthToken.getToken(),
        };
        return config;
      });
      /**
       *
       */
      apiAxios.defaults.baseURL = options.apis[api as API_KEY];
      app.config.globalProperties.$api[api] = apiAxios;
    }
    /**
     * Mark these instances as global too
     */
    $api = app.config.globalProperties.$api;
    /**
     * And then attach bare axios
     */
    app.config.globalProperties.$axios = Axios;
  },
};
