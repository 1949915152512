
import { Business } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import img from "@/assets/illustrations/startup/team.svg";
import { WarningFilled, CircleCheckFilled } from "@element-plus/icons";
import BusinessCofounderModal from "./business-cofounder-modal.vue";

@Options({
  emits: ["checked", "opened", "closed"],
  components: {
    WarningFilled,
    CircleCheckFilled,
    BusinessCofounderModal,
  },
})
export default class extends Vue {
  img = img;
  isModalOpen = false;
  showClose = false;

  /** Indicates the modals opening */
  modals: Array<number> = [];

  @Prop({ type: Object })
  business!: Business;

  loading = false;

  @Emit("checked")
  mounted() {
    this.shouldOpen();
  }

  @Watch("business")
  listenToChanges() {
    this.shouldOpen();
  }

  exit() {
    this.closeModal();
  }

  someInfoIsMissing() {
    console.log("Check if some info is missing ", this.business.coFounders);
    console.log("Check if some info is missing ", this.business.coFoundersSize);
    if (
      this.business.coFounders === undefined ||
      this.business.coFoundersSize !== this.business.coFounders?.length
    ) {
      console.log("coFounder ", true)
      return true;
    }
    this.business.coFounders?.forEach((coFounder) => {
      console.log("coFounder in coFounder", coFounder)
      // If any of the cofounders doesn't have the skills set
      // Education is not mandatory
      if (
        coFounder === undefined ||
        coFounder.names === undefined ||
        coFounder.skills === undefined ||
        coFounder.skills.length === 0
      ) {
        console.log("coFounder 2", true)
        return true;
      }
    });
  }
  /**
   * @loops within the co-founders and checks if their information
   * Was filled in
   */
  shouldOpen(): boolean {
    // Size is different?
    console.log("In business cofounders");
    if (this.someInfoIsMissing()) {
      console.log("Some info is missing");
      this.$store.commit("modals/coFounders", true);
      this.isModalOpen = true;
    }
    return this.isModalOpen;
  }

  @Watch("$store.state.modals.coFounders")
  watchForOpen(): void {
    this.isModalOpen = this.$store.state.modals.coFounders;
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal(): void {
    this.$store.commit("modals/coFounders", false); // This is enough itself
    this.isModalOpen = false;
  }

  @Emit("opened")
  opened() {
    console;
  }

  get coFounders(): Array<any> {
    if (this.business.coFounders.length === this.business.coFoundersSize) {
      return this.business.coFounders;
    } else {
      return this.business.coFounders.concat(
        new Array(
          this.business.coFoundersSize - this.business.coFounders.length
        )
      );
    }
  }

  isCoFounderFilled(index): boolean {
    if (
      this.coFounders[index]?.names === undefined ||
      this.coFounders[index]?.names === "" ||
      this.coFounders[index]?.skills === undefined ||
      this.coFounders[index]?.skills.length === 0
    ) {
      return false;
    }
    return true;
  }
  /**
   * Trigger editing the cofounder info
   * @param userIndex
   */
  editCofounder(userIndex: number): void {
    this.modals[userIndex] = true as any;
  }
}
