<template>
  <div>
    <!--  -->
    <!-- If content exit -->
    <OpportunityTalentsList
      v-for="opp of opportunities"
      :key="`opp-${opp._id}`"
      :opportunity="opp"
    />
    <!--  -->
    <app-loader-opportunities
      :loading="loading"
      :resources="opportunities"
      content="Can't find any opportunities to match with."
    />
    <!--  -->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import OpportunityTalentsList from "./components/opportunity-talents-list.vue";

/**
 * @this component fetches the current opportunities
 * loops through them
 * and fetches matched talent for each opportunity
 */
@Options({
  components: { OpportunityTalentsList },
})
export default class extends Vue {
  /**
   * @opportunities
   */
  get opportunities() {
    return this.$store.state.opportunities.opportunities;
  }
  /**
   * @loading
   */
  get loading() {
    return this.$store.state.opportunities.loading;
  }
  /**
   * @count
   */
  get count() {
    return this.$store.state.opportunities.count;
  }
  /**
   *
   */
  mounted() {
    this.$store.dispatch("opportunities/fetch");
  }
}
</script>

<style lang="scss"></style>
