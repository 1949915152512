
import img from "@/assets/illustrations/user/education.svg";
import { ErrorUtil } from "@/shared/classes";
import { StandarTests, User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";

@Options({
  data() {
    return {
      haveTakenTests: undefined,
    };
  },
  name: "Add-Test",
  emits: ["checked", "opened", "closed"],
})
export default class extends Vue {
  img = img;
  standarTests = StandarTests;
  /**
   * Modal variables
   */
  loading = false;
  isModalOpen = false;
  showClose = true;
  /**
   * standardTests dull
   */
  nullstandardTest = {
    name: undefined,
    score: undefined,
  };
  /**
   * Form Data
   */
  form = {
    standardTests: [Object.assign({}, this.nullstandardTest)],
  };

  addTest() {
    this.form.standardTests.push(Object.assign({}, this.nullstandardTest));
  }
  removeTest(index: number) {
    this.form.standardTests.splice(index, 1);
  }
  get user() {
    return this.$store.state.account.user;
  }

  get showSave(): boolean {
    if ((this.$data as any).haveTakenTests) {
      return this.form.standardTests.length != 0;
    }
    return true;
  }
  /**
   * @when the modal is checked
   */
  @Emit("checked")
  mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      (this.user?.standardTests === undefined ||
        this.user?.standardTests?.length === 0) &&
      !this.user?.onboarding.skippedStandardTests; // If has zero tests, and haven't skipped the tests onboarding process
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    if ((this.$data as any).haveTakenTests != true) {
      return this.skipTests();
    } else {
      this.loading = true;
      this.$api.main
        .patch<User>("/v1/account/profile", this.form)
        .then((resp) => {
          AccountUtil.fetchCurrent(this.$store).finally(
            () => (this.loading = false)
          );
          this.closeModal();
        })
        .catch((err) => {
          this.$message({
            duration: ErrorUtil.duration,
            type: "error",
            message: ErrorUtil.stringify(err),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  /**
   * When it was a no
   */
  skipTests() {
    this.$api.main
      .patch<User>("/v1/account/onboard", {
        skippedStandardTests: true,
      })
      .finally(() => {
        this.closeModal();
      });
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the standardized tests modal");
    this.$store.commit("modals/standardTests", false); // This is enough itself
    this.isModalOpen = false;
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to add standardTests
   */
  @Watch("$store.state.modals.standardTests")
  watchForOPen() {
    this.isModalOpen = this.$store.state.modals.standardTests;
    if (this.isModalOpen) this.showClose = true;
  }
  /**
   * @update account after some changes
   */
  makeUpdatesAfterCreate() {
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
    this.prepareExistingData();
  }

  /**
   * If the form is changed to true
   * Show atleast the form with 0
   */
  changedHaveTakenTests() {
    if ((this as any).$data.haveTakenTests) {
      if (!this.form.standardTests || this.form.standardTests.length === 0) {
        this.form.standardTests.push({ name: undefined, score: undefined });
      }
    } else {
      // else clear the array
      this.form.standardTests = [];
    }
  }

  /**
   * @on open add the existing data to the form
   */
  prepareExistingData() {
    this.form.standardTests =
      this.user?.standardTests?.map((r) => ({
        name: r.name,
        score: r.score,
      })) || ([] as any);

    if (this.form.standardTests.length > 0) {
      (this.$data as any).haveTakenTests = true;
    } else if (this.user?.onboarding.skippedStandardTests) {
      (this.$data as any).haveTakenTests = false;
    }
  }
}
