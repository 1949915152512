import {
  Skill,
  SkillFunction,
} from "@/@business/views/opportunities-page/components/skill-fetch.mixins";
import { Filters } from "@/shared/filters";
import { University } from "@/shared/interfaces";
import { $api } from "@/shared/plugins";
import { AxiosInstance } from "axios";
import { StoreOptions } from "vuex";

export interface SharedModalsVuexState {
  isSurveyOpen: boolean;
  loading?: boolean;
  //
  addEducation: boolean;
  addExperience: boolean;
  //
  mainSkills: boolean;
  startupExperience: boolean;
  bioInfo: boolean;

  avatar: boolean;

  standardTests: boolean;

  //
  fetchingSkills: boolean;
  fetchingUniversities: boolean;
  skillsList?: Array<SkillFunction>;
  universitiesList: Array<University>;
}

export const SharedModalsVuex: StoreOptions<SharedModalsVuexState> = {
  ...{ namespaced: true },
  state: {
    isSurveyOpen: false,
    loading: false,
    addEducation: false,
    standardTests: false,
    addExperience: false,
    mainSkills: false,
    startupExperience: false,
    bioInfo: false,
    avatar: false,
    fetchingSkills: false,
    fetchingUniversities: false,
    universitiesList: [],
  },
  mutations: {
    /**
     *
     * @param state
     * @param status
     */
    survey(state, status: boolean) {
      state.isSurveyOpen = status;
    },
    /**
     *
     * @param state
     * @param status
     */
    addEducation(state, status: boolean) {
      state.addEducation = status;
    },
    /**
     *
     * @param state
     * @param status
     */
    standardTests(state, status: boolean) {
      state.standardTests = status;
    },
    /**
     *
     * @param state
     * @param status
     */
    addExperience(state, status: boolean) {
      state.addExperience = status;
    },
    /**
     *
     * @param state
     * @param status
     */
    mainSkills(state, status: boolean) {
      state.mainSkills = status;
    },
    /**
     *
     * @param state
     * @param status
     */
    startupExperience(state, status: boolean) {
      state.startupExperience = status;
    },
    /**
     *
     * @param state
     * @param status
     */
    bioInfo(state, status: boolean) {
      state.bioInfo = status;
    },
    /**
     *
     * @param state
     * @param status
     */
    avatar(state, status: boolean) {
      state.avatar = status;
    },
  },
  actions: {
    /**
     *
     * @param context
     * @param status
     */
    fetchSkills(context, force = false) {
      // If not forced and skills exist just return
      if (!force && (context.state.skillsList?.length ?? 0) > 0) {
        return;
      }
      context.state.fetchingSkills = true;
      $api.main
        .get<SkillFunction[]>("/v1/skills")
        .then((resp) => {
          context.state.skillsList = resp.data.map((fn) => {
            fn.value = fn.function;
            fn.label = Filters.capitalize(fn.function); // Capitalize Label
            fn.children = fn.skills.map((skill) => {
              skill.value = skill.skill;
              skill.label = Filters.capitalize(skill.skill); // Capitalize Label
              return skill;
            });
            return fn;
          });
          console.log("Fetched the skill");
        })
        .finally(() => {
          setTimeout(() => {
            context.state.fetchingSkills = false;
          }, 800);
        });
    },
    /**
     * Fetch the universities
     * @param context
     * @param force
     */
    fetchUniversities(context, force = false) {
      // If not forced and skills exist just return
      if (force || (context.state.universitiesList?.length ?? 0) == 0) {
        context.state.fetchingUniversities = true;
        $api.main
          .get<Array<University>>("/v1/universities")
          .then((resp) => {
            context.state.universitiesList = resp.data;
          })
          .finally(() => {
            context.state.fetchingUniversities = false;
          });
      }
    },
    /**
     * Add university to list
     * @param context
     * @param uni
     */
    addUniversity(context, uni: University) {
      context.state.universitiesList = [...context.state.universitiesList, uni];
    },
  },
  modules: {},
  //
  getters: {},
};
