import { AxiosError } from "axios";
import { StringUtil } from "./string-util.class";

export class ErrorUtil {
  static duration = 9000;
  /**
   * When given axios error it tries and stringifies it to a
   * readable friendly text
   * {statusCode: 400, message: Array(3), error: 'Bad Request'}
    error: "Bad Request"
    message: (3) ['email must be longer than or equal to 5 characters', 'email must be an email', 'password must be longer than or equal to 8 characters']
    statusCode: 400
   * @param err
   */
  static stringify(
    err: AxiosError,
    conf: { shorten?: number; message?: string } = { shorten: 60 }
  ) {
    if (conf.shorten) {
      return StringUtil.shorten(this.formulateFromAxios(err), conf.shorten);
    }
    return this.formulateFromAxios(err);
  }

  /**
   *
   * @param err
   * @returns
   */
  private static formulateFromAxios(err: AxiosError) {
    if (err.response?.data.message) {
      // If just text return  it
      if (typeof err.response?.data.message === "string")
        return err.response?.data.message;
      // if array join it
      else if (typeof err.response?.data.message === "object")
        return err.response?.data.message?.join(", ");
    }
    // if no message just return error
    if (typeof err.response?.data.error === "string")
      return err.response?.data.error;
  }
}
