export * from "./business";
export * from "./survey";
export * from "./user";
export * from "./industry";
export * from "./interest";

export type FilterType = "region" | "country" | "timezone";
export const regionFilterTypes = ["region", "country", "timezone"];

export interface OpportunityRegionFiltering {
  type: FilterType;
  options: string[];
}
export interface Opportunity {
  _id: string;
  talents: number;
  regionFilter?: OpportunityRegionFiltering;
}

export interface University {
  _id: string;
  name: string;
  rank: number;
  score?: number;
  region?: string;
  country?: string;
}

/**
 * University levels
 */
export const UniversityLevels = [
  { name: "PhD" },
  { name: "Master's" },
  { name: "Bachelor's" },
  { name: "High School Diploma" },
];

/**
 * School programs
 */
export const relevantPrograms = [
  "Graphic design",
  "Computer Science",
  "Animation",
  "Information Systems",
  "Software Engineering",
  "Data Science",
  "Physics",
  "Mathematics",
  "Statistics",
  "Business",
  "Economics",
  "Finance",
  "Mechanical Engineering",
  "Robotics Engineering",
  "Engineering",
  "Other",
];
