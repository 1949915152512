
import { Business, ErrorUtil } from "@/shared";
import AccPageHeader from "@/shared/views/account-pages/components/account-page-header-component.vue";
import AccPageRightNav from "@/shared/views/account-pages/components/account-pages-right-nav.vue";
import { ApiResourceResult } from "@/shared/vuex";
import { OfficeBuilding } from "@element-plus/icons";
import { Options, Vue } from "vue-class-component";
import AppStartupComponent from "./startup-component.vue";

@Options({
  components: {
    AccPageHeader,
    OfficeBuilding,
    AccPageRightNav,
    AppStartupComponent,
  },
})
export default class extends Vue {
  /**
   * So the user can be able to edit
   */
  isSelf = true;
  loading = false;
  businessesData: ApiResourceResult<Business> = {
    count: undefined,
    skipped: undefined,
    next: undefined,
    resources: [],
  };
  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }

  /**
   * @commits to open the business modal to open
   */
  createStartup() {
    this.$store.commit("modals/business", true);
  }
  /**
   * @
   */
  mounted() {
    this.fetchBusinesses();
  }
  /**
   * @returns businesses
   */
  fetchBusinesses() {
    this.loading = true;
    return this.$api.main
      .get<ApiResourceResult<Business>>("/v1/businesses")
      .then((resp) => {
        this.businessesData = resp.data;
      })
      .catch((err) => {
   this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });      })
      .finally(() => {
        this.loading = false;
      });
  }
}
