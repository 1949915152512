<template>
  <div>
    <el-dialog
      custom-class="App-User-StandartTest-Modal App-Unpadded"
      :model-value="isModalOpen"
      width="550px"
      :show-close="showClose"
      :close-on-click-modal="showClose"
      @open="opened"
      title="Standardized Tests"
      center
      :append-to-body="true"
      :lock-scroll="true"
      :open-delay="200"
      :close-delay="200"
      :before-close="closeModal"
      :close-on-press-escape="false"
    >
      <!--  -->
      <el-scrollbar :always="true" max-height="600px">
        <div v-loading="loading">
          <!--  -->
          <div>
            <div txt-center>
              <el-image :style="`width: 150px;`" :src="img"></el-image>
              <p>
                Have you ever sat down for any standardized <br />
                test? E.g. SAT, GMAT, etc
              </p>
            </div>
            <!--  -->
            <div class="App-Styled-Form">
              <div class="App-User-StandartTest-Promp">
                <el-radio-group
                  @change="changedHaveTakenTests"
                  v-model="haveTakenTests"
                  size="large"
                >
                  <el-radio class="App-Test-Radio" :label="true" size="large">
                    <div>
                      <h4>Yes</h4>
                      <span>I have done one or more tests</span>
                    </div>
                  </el-radio>
                  <el-radio class="App-Test-Radio" :label="false" size="large">
                    <div>
                      <h4>No</h4>
                      <span>I have never done any.</span>
                    </div>
                  </el-radio>
                </el-radio-group>
              </div>
              <el-divider v-if="haveTakenTests === true" />
            </div>
          </div>
          <!--  -->
          <el-form
            v-if="haveTakenTests === true"
            @submit.prevent
            class="App-Styled-Form"
            :label-position="'top'"
            label-width="100px"
            :model="form"
            style="margin-top: 12px"
          >
            <!--  -->
            <div
              v-for="(test, index) of form.standardTests"
              :key="Math.random() + index"
            >
              <el-row align="middle" :gutter="12">
                <el-col :span="16">
                  <el-form-item prop="TestName" label="Test Name">
                    <el-select
                      v-model="form.standardTests[index].name"
                      placeholder="SAT"
                      w-100
                    >
                      <el-option
                        v-for="test in standarTests"
                        :value="test.name"
                        :label="test.name"
                        :key="`${test.name}-ind`"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="Score" label="Score">
                    <el-input-number
                      w-100
                      :controls="false"
                      placeholder="300"
                      v-model="form.standardTests[index].score"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <el-button
                    v-if="index > 0"
                    @click="removeTest(index)"
                    icon="el-icon-delete"
                    circle
                    size="mini"
                  />
                </el-col>
              </el-row>
            </div>
            <!--  -->
            <div txt-center>
              <el-button
                type="info"
                is-borderless
                round
                @click="addTest"
                size="small"
                icon="el-icon-plus"
                >Add Test Score</el-button
              >
            </div>
            <!--  -->
          </el-form>
        </div>
      </el-scrollbar>
      <!--  -->
      <template #footer>
        <div txt-center>
          <el-button
            is-block
            icon="el-icon-check"
            @click="submit"
            size="large"
            type="primary"
            :disabled="!showSave || loading"
          >
            Save
          </el-button>
        </div>
      </template>
      <!--  -->
    </el-dialog>
  </div>
</template>

<script lang="ts">
import img from "@/assets/illustrations/user/education.svg";
import { ErrorUtil } from "@/shared/classes";
import { StandarTests, User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";

@Options({
  data() {
    return {
      haveTakenTests: undefined,
    };
  },
  name: "Add-Test",
  emits: ["checked", "opened", "closed"],
})
export default class extends Vue {
  img = img;
  standarTests = StandarTests;
  /**
   * Modal variables
   */
  loading = false;
  isModalOpen = false;
  showClose = true;
  /**
   * standardTests dull
   */
  nullstandardTest = {
    name: undefined,
    score: undefined,
  };
  /**
   * Form Data
   */
  form = {
    standardTests: [Object.assign({}, this.nullstandardTest)],
  };

  addTest() {
    this.form.standardTests.push(Object.assign({}, this.nullstandardTest));
  }
  removeTest(index: number) {
    this.form.standardTests.splice(index, 1);
  }
  get user() {
    return this.$store.state.account.user;
  }

  get showSave(): boolean {
    if ((this.$data as any).haveTakenTests) {
      return this.form.standardTests.length != 0;
    }
    return true;
  }
  /**
   * @when the modal is checked
   */
  @Emit("checked")
  mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      (this.user?.standardTests === undefined ||
        this.user?.standardTests?.length === 0) &&
      !this.user?.onboarding.skippedStandardTests; // If has zero tests, and haven't skipped the tests onboarding process
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    if ((this.$data as any).haveTakenTests != true) {
      return this.skipTests();
    } else {
      this.loading = true;
      this.$api.main
        .patch<User>("/v1/account/profile", this.form)
        .then((resp) => {
          AccountUtil.fetchCurrent(this.$store).finally(
            () => (this.loading = false)
          );
          this.closeModal();
        })
        .catch((err) => {
          this.$message({
            duration: ErrorUtil.duration,
            type: "error",
            message: ErrorUtil.stringify(err),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  /**
   * When it was a no
   */
  skipTests() {
    this.$api.main
      .patch<User>("/v1/account/onboard", {
        skippedStandardTests: true,
      })
      .finally(() => {
        this.closeModal();
      });
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the standardized tests modal");
    this.$store.commit("modals/standardTests", false); // This is enough itself
    this.isModalOpen = false;
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to add standardTests
   */
  @Watch("$store.state.modals.standardTests")
  watchForOPen() {
    this.isModalOpen = this.$store.state.modals.standardTests;
    if (this.isModalOpen) this.showClose = true;
  }
  /**
   * @update account after some changes
   */
  makeUpdatesAfterCreate() {
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
    this.prepareExistingData();
  }

  /**
   * If the form is changed to true
   * Show atleast the form with 0
   */
  changedHaveTakenTests() {
    if ((this as any).$data.haveTakenTests) {
      if (!this.form.standardTests || this.form.standardTests.length === 0) {
        this.form.standardTests.push({ name: undefined, score: undefined });
      }
    } else {
      // else clear the array
      this.form.standardTests = [];
    }
  }

  /**
   * @on open add the existing data to the form
   */
  prepareExistingData() {
    this.form.standardTests =
      this.user?.standardTests?.map((r) => ({
        name: r.name,
        score: r.score,
      })) || ([] as any);

    if (this.form.standardTests.length > 0) {
      (this.$data as any).haveTakenTests = true;
    } else if (this.user?.onboarding.skippedStandardTests) {
      (this.$data as any).haveTakenTests = false;
    }
  }
}
</script>

<style lang="scss">
.App-User-StandartTest-Modal {
  .App-Styled-Form {
    max-width: 400px;
    margin: auto;
    .el-input-number {
      width: auto !important;
    }
  }
  .App-User-StandartTest-Promp {
    padding: 10px 20px;
  }
  .App-Test-Radio {
    display: flex;
    width: 100%;
    align-items: center;
    &:first-child {
      margin-bottom: 30px;
    }
    .el-radio__label {
      h4 {
        margin: 0;
      }
      span {
        opacity: 0.8;
      }
    }
    .el-radio__inner::after {
      width: 10px !important;
      height: 10px !important;
    }
    .el-radio__inner {
      height: 24px !important;
      width: 24px !important;
    }
  }
}
</style>
