<template>
  <div class="App-Opportunity-Talents">
    <!--  -->
    <!-- Opportunity details -->
    <div class="App-Opportunity-Opp">
      <!--  -->
      <el-space
        style="justify-content: space-between"
        w-100
        :size="10"
        justify="space-between"
      >
        <!--  -->
        <div class="App-Opportunity-Opp-L">
          <app-over-avatars v-if="showHeader" :size="50">
            <template #one>
              <el-avatar
                :src="opportunity.createdBy.avatar"
                icon="el-icon-user-solid"
                :size="45"
              />
            </template>
            <template #two>
              <el-avatar
                :src="opportunity.business.avatar"
                icon="el-icon-office-building"
                :size="45"
              />
            </template>
          </app-over-avatars>
          <div>
            <h4>
              {{ opportunity.outcome }} /
              <span mute-primary>{{ opportunity.skill[1] }}</span>
            </h4>
            <el-space wrap alignment="center" :size="8">
              <span>{{ business.name }}</span>
              <span mute-primary>
                {{
                  $filters.capitalize($moment(opportunity.createdAt).fromNow())
                }}
              </span>
            </el-space>
          </div>
        </div>
        <!--  -->
        <!-- @improve and add v-if="count > 0" -->
        <router-link v-if="showLink" :to="`/opportunities/${opportunity._id}`">
          <el-link type="primary">View Details</el-link>
        </router-link>
        <!--  -->
      </el-space>
    </div>
    <!--  -->
    <!-- If content exit -->
    <div v-if="talents">
      <talentsList
        :opportunity="opportunity"
        :talents="ignoreRegionFilter ? otherTalents : talents"
      />
    </div>
    <!--  -->
    <app-loader-talents
      v-if="otherTalents.length == 0"
      :loading="loading"
      :resources="talents"
      description="Can't find any talents to match <br /> with on this opportunity."
    />
    <!--  -->
    <app-loader-talents
      :loading="loading"
      class="App-Other-Talents-Available"
      v-if="
        talents.length === 0 &&
        otherTalents.length !== 0 &&
        ignoreRegionFilter == false
      "
      :description="`Based on your region filter we couldn't find talents,<br />but there are <b style='color:black;'>${otherTalents.length} talents from other regions</b>!`"
    >
      <el-button @click="ignoreRegionFilter = true" type="primary">
        Ignore Region Filter
      </el-button>
    </app-loader-talents>
    <!--  -->
    <!--  -->
  </div>
</template>

<script lang="ts">
import { OpportunityMixin } from "@/@business/views/opportunities-page/components/opportunity-mixin";
import { Opportunity, User } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import talentsList from "./talents-list.vue";

/**
 * @this component fetches the current talents
 * loops through them
 * and fetches matched talent for each opportunity
 */
@Options({
  mixins: [OpportunityMixin],
  components: { talentsList },
})
export default class extends Vue {
  @Prop({ type: Object })
  opportunity!: Opportunity;

  @Prop({ default: true, type: Boolean })
  showHeader!: boolean;

  @Prop({ default: true, type: Boolean })
  showLink!: boolean;

  ignoreRegionFilter = false;
  /**
   * @how many talents were retrieved
   */
  count() {
    return this.talents.length;
  }

  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
  talents: any[] = [];
  otherTalents: any[] = [];

  loading = false;
  /**
   *
   */
  mounted() {
    this.fetchTalents();
  }

  @Watch("opportunity")
  opportunityChanged() {
    this.fetchTalents();
  }
  /**
   * @fetches talents for a specific opportunity in a view more way
   */
  fetchTalents() {
    this.loading = true;
    this.$api.main
      .get<User[]>(`/v1/talents?opportunity=${this.opportunity._id}`)
      .then(async (resp) => {
        this.talents = resp.data;
        if (this.talents.length == 0) {
          await this.fetchTalentsWithoutRegion();
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  fetchTalentsWithoutRegion() {
    this.$api.main
      .get<User[]>(
        `/v1/talents?opportunity=${this.opportunity._id}&ignoreRegion=true`
      )
      .then((resp) => {
        this.otherTalents = resp.data;
      });
  }
}
</script>

<style lang="scss">
.App-Opportunity-Talents {
  padding: 8px 0;
  margin-bottom: map.get($--button-padding-vertical, "large") * 1.5;
}
.App-Opportunity-Opp {
  min-height: 60px;
  padding: 8px 8px;
}
.App-Opportunity-Opp-L {
  display: flex;
  align-items: center;
  > * {
    margin-right: 12px;
  }
  // i {
  //   font-size: 30px;
  //   font-weight: bolder;
  //   color: $--color-primary;
  //   margin-right: 8px;
  // }
  h4 {
    margin: 0;
  }
}
.App-Other-Talents-Available {
  background: white;
  padding: 10px;
  border-radius: 4px;
}
</style>
