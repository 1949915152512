
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    padded: { type: Boolean, default: true },
    showLeft: { type: Boolean, default: true },
    showMiddle: { type: Boolean, default: true },
    showRight: { type: Boolean, default: true },
  },
  components: {},
})
export default class extends Vue {}
