<template>
  <el-space :size="8" fill w-100 class="App-Opportunities-List">
    <Opportunity
      v-for="(opp, index) in opportunities"
      :opportunity="opp"
      :key="`opp-${index}`"
    >
    </Opportunity>
  </el-space>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Opportunity from "./opportunity.component.vue";

@Options({
  components: {
    Opportunity,
  },
})
export default class extends Vue {
  @Prop({ type: Array })
  opportunities!: Opportunity[];
}
</script>

<style lang="scss">
.App-Opportunities-List {
}
</style>
