import { SharedModalsVuex, SharedModalsVuexState } from "@/shared/vuex";
import { StoreOptions } from "vuex";

export interface ModalsVuexState extends SharedModalsVuexState {
  isBusinessOpen: boolean;
  isOpportunityOpen: boolean;
  isBiddingOpen: boolean;
  isFutureOpen: boolean;
  coFounders: boolean;
  loading?: boolean;
}

export const ModalsVuex: StoreOptions<ModalsVuexState> = {
  ...{ namespaced: true },
  state: {
    ...(SharedModalsVuex.state as SharedModalsVuexState),
    isBusinessOpen: false,
    isOpportunityOpen: false,
    isBiddingOpen: false,
    isFutureOpen: false,
    coFounders: false,
  },
  mutations: {
    ...SharedModalsVuex.mutations,
    /**
     *
     * @param state
     * @param status
     */
    business(state, status: boolean) {
      state.isBusinessOpen = status;
    },
    /**
     *
     * @param state
     * @param status
     */
    future(state, status: boolean) {
      state.isFutureOpen = status;
    },
    /**
     *
     * @param state
     * @param status
     */
    coFounders(state, status: boolean) {
      state.coFounders = status;
    },
    /**
     *
     * @param state
     * @param status
     */
    opportunity(state, status: boolean) {
      state.isOpportunityOpen = status;
    },
    /**
     *
     * @param state
     * @param status
     */
    bidding(state, status: boolean) {
      state.isBiddingOpen = status;
    },
  },
  actions: {
    ...SharedModalsVuex.actions,
  },
  modules: {},
  //
  getters: {
    ...SharedModalsVuex.getters,
  },
};
