<template>
  <div class="App-User-Experience">
    <app-section-divider content="Experience">
      <i class="el-icon-postcard"></i>
      <template v-if="isSelf" #action>
        <el-button
          @click="addExperience()"
          class="el-icon-edit"
          circle
          size="mini"
        />
      </template>
    </app-section-divider>
    <!--  -->
    <el-empty
      v-if="user.experience === undefined || user.experience.length === 0"
      :image="img"
    >
      <template #description>
        <h2 m-tb-0>Oops!</h2>
        <p>
          Experience information <br />
          for this user was not found
        </p>
      </template>
      <!--  -->
      <!--  -->
      <el-button
        v-if="isSelf"
        @click="addExperience"
        is-borderless
        plain
        type="primary"
        icon="el-icon-plus"
        size="large"
      >
        Add Experience Information
      </el-button>
    </el-empty>
    <!--  -->
    <!--  -->
    <div>
      <div>
        <el-timeline m-t-3>
          <el-timeline-item
            v-for="(exp, index) of experience"
            :key="`experience-${index}`"
            size="large"
            :center="true"
            placement="bottom"
          >
            <div>
              <h3 m-tb-0>{{ exp.role }}</h3>
              <p m-tb-0>{{ exp.organisation }}</p>
              <div>
                <span>
                  <span v-if="exp.from">
                    {{ $moment(exp.from).format("MMM yy") }}
                  </span>
                  <span> - </span>
                  <span v-if="exp.to">
                    {{ $moment(exp.to).format("MMM yy") }}
                  </span>
                  <span v-else>Present</span>
                </span>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
    <!--  -->
    <!--  -->
  </div>
</template>

<script lang="ts">
import img from "@/assets/illustrations/user/experience.svg";
import { User } from "@/shared";
import { MomentUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class extends Vue {
  img = img;

  @Prop({ type: Object })
  user!: User;

  @Prop({ type: Boolean })
  isSelf!: boolean;
  /**
   * @clicked add
   */
  addExperience() {
    this.$store.commit("modals/addExperience", true);
  }

  get experience() {
    return [...(this.user.experience || [])]?.sort((a, b) => {
      return new Date(b.from).getTime() - new Date(a.from).getTime();
    });
  }

  dateDiff = MomentUtil.dateDifference;
}
</script>

<style lang="scss">
.el-timeline-item__wrapper {
  top: -12px !important;
}
</style>
