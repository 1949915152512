
import {
  relevantPrograms,
  University,
  UniversityLevels,
  UserEducation,
} from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import AddUniComponent from "./add-uni-dialog.vue";

@Options({
  name: "EducationInput",
  emits: ["update:modelValue"],
  mixins: [],
  components: {
    AddUniComponent,
  },
  computed: {},
  methods: {},
})
export default class extends Vue {
  universityLevels = UniversityLevels;
  relevantPrograms = relevantPrograms;

  query = "";

  @Prop({ type: Object })
  modelValue?: UserEducation;

  @Prop({})
  index!: number;

  removeEducation() {
    this.$emit("remove");
  }

  get universities() {
    return this.$store.state.modals.universitiesList;
  }
  get loadingUniversities() {
    return this.$store.state.modals.fetchingUniversities;
  }

  get getAllOptions() {
    if (this.query) return this.uniSearchOptions;
    else {
      return this.formulateOptions();
    }
  }

  uniSearchOptions: Array<{ value: string; label: string }> = [];

  formulateOptions() {
    return this.universities.map((uni, idx) => {
      return { value: uni._id, label: uni.name };
    });
  }

  /**
   * Searches for keywords in the uni
   * @param uni
   * @param query
   */
  searchString(uni: University, query: string): boolean {
    return query
      .toLowerCase()
      .split(" ")
      .map((str) => str.trim())
      .every((qWord) => uni.name.toLowerCase().includes(qWord));
  }
  /**
   * Searches for unis that satisfy the case
   * @search method
   */
  uniSearchMethod(query: string) {
    this.query = query;
    console.log("Searching uni ", query);
    if (query !== "") {
      this.uniSearchOptions = this.universities
        .filter((uni) => {
          return this.searchString(uni, query);
        })
        .map((uni, idx) => {
          return { value: uni._id, label: uni.name };
        });
    } else {
      this.uniSearchOptions = [];
    }
  }
  mounted() {
    console.log("Mounted the skill input element");
    this.assignForm();
    this.fetchUniversities();
  }
  // updated() {}

  form: UserEducation = {} as any;

  stillThere = false;

  assignForm() {
    this.form.organisation =
      typeof this.modelValue?.organisation === "string"
        ? this.modelValue?.organisation
        : (this.modelValue?.organisation?._id as any);
    this.form.from = this.modelValue?.from as any;
    this.form.to = this.modelValue?.to as any;
    this.form.program = this.modelValue?.program as any;
    this.form.degree = this.modelValue?.degree as any;
    this.form.description = this.modelValue?.description as any;
    if (this.form.from !== undefined && this.form.to == undefined) {
      this.stillThere = true;
    }
  }

  setValue() {
    this.$emit("update:modelValue", this.form);
  }

  fetchUniversities() {
    this.$store.dispatch("modals/fetchUniversities");
  }

  /**
   * When a uni is created
   */
  async createdUni(uni: University) {
    this.$store.dispatch("modals/addUniversity", uni);
    this.form.organisation = uni._id as any;
    this.setValue();
  }
}
