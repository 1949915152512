
import img from "@/assets/illustrations/user/experience.svg";
import { ErrorUtil } from "@/shared/classes";
import { BusinessModels, BusinessStages, User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  img = img;
  /**
   * Modal variables
   */
  loading = false;
  isModalOpen = false;
  showClose = false;

  stages = BusinessStages;
  businessModels = BusinessModels;
  /**
   * Form Data
   */
  form = {
    startupExperience: {
      models: [],
      stages: [],
    },
  };

  /**
   * @is the save button disabled
   */
  get disabledSave() {
    return (
      this.form.startupExperience.models?.length === 0 ||
      this.form.startupExperience.stages?.length === 0
    );
  }

  /**
   * @when the modal is checked
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      this.$store.state.account.user?.startupExperience === undefined ||
      this.$store.state.account.user?.startupExperience?.models?.length === 0;
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<User>("/v1/account/profile", this.form)
      .then((resp) => {
        AccountUtil.fetchCurrent(this.$store).finally(
          () => (this.loading = false)
        );
        this.closeModal();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.$store.commit("modals/startupExperience", false); // This is enough itself
    this.isModalOpen = false;
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to add education
   */
  @Watch("$store.state.modals.startupExperience")
  watchForOPen() {
    this.isModalOpen = this.$store.state.modals.startupExperience;
    if (this.isModalOpen) this.showClose = true;
  }
  /**
   * @update account after some changes
   */
  makeUpdatesAfterCreate() {
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    this.prepareExistingData();
  }

  get user() {
    return this.$store.state.account.user;
  }
  /**
   * @on open add the existing data to the form
   */
  prepareExistingData() {
    this.form.startupExperience =
      this.user?.startupExperience ||
      ({
        models: [],
        stages: [],
      } as any);
  }
}
