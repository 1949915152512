
import { ErrorUtil } from "@/shared/classes";
import { User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit } from "vue-property-decorator";

@Options({
  emits: ["checked", "opened", "closed"],
})
export default class extends Vue {
  /**
   * Modal variables
   */
  loading = true;
  isModalOpen = false;
  showClose = true;
  /**
   * Form Data
   */
  form = { toldToFillProfile: true };

  talentSections = [
    { title: "Work Experience", canFillLater: false },
    { title: "Startup Experience", canFillLater: false },
    { title: "Skills", canFillLater: false },
    { title: "Education History", canFillLater: true },
    { title: "Standardized Tests", canFillLater: false },
    { title: "Bio Information", canFillLater: false },
    { title: "Profile Picture", canFillLater: true },
  ];

  founderSections = [
    { title: "Founder Skills", canFillLater: false },
    { title: "Founder Education", canFillLater: true },
    { title: "Standardized Tests", canFillLater: false },
    { title: "Profile Picture", canFillLater: true },
    { title: "Co-founders' Profiles", canFillLater: true },
  ];

  get sections() {
    if (this.user?.type === "founder") {
      return this.founderSections;
    } else {
      return this.talentSections;
    }
  }
  /**
   * @when the modal is checked
   */
  @Emit("checked")
  async mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      this.$store.state.account.user?.onboarding?.toldToFillProfile !== true;
    this.loading = false;
  }

  /**
   * @saves the changes of made on the user profile
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<User>("/v1/account/onboard", this.form)
      .then((resp) => {
        AccountUtil.fetchCurrent(this.$store).finally(
          () => (this.loading = false)
        );
        this.closeModal();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.isModalOpen = false;
  }

  // /**
  //  * @Watch for open trigger
  //  * if triggered the user should be able to add education
  //  */
  // @Watch("$store.state.modals")
  // watchForOPen() {
  //   this.isModalOpen = this.$store.state.modals;
  //   if (this.isModalOpen) this.showClose = true;
  // }
  /**
   * @update account after some changes
   */
  makeUpdatesAfterCreate() {
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   * @user info
   */
  get user() {
    return this.$store.state.account.user;
  }
  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
  }
}
