
import { Business } from "@/shared";
import {
  Checked,
  InfoFilled,
  Opportunity as OpportunityBulb,
  UserFilled,
} from "@element-plus/icons";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import appStartupUsersList from "../account-pages/account-startups-page/startup-users-list.vue";
import appStartupOpp from "../opportunity-page/opportunity-startup-details.vue";
import startupPageHeader from "./business-page-header.vue";
import BusinessUpdateModal from "./components/edit-business-modal.vue";

@Options({
  name: "AppBusinessPage",
  components: {
    startupPageHeader,
    appStartupUsersList,
    Checked,
    InfoFilled,
    UserFilled,
    appStartupOpp,
    OpportunityBulb,
    BusinessUpdateModal,
  },
  data() {
    return {
      business: undefined,
    };
  },
})
export default class extends Vue {
  loading = true;
  isEditing = false;
  isEditingFuture = false;

  get isSelf() {
    return this.user?.type === "founder";
  }

  get user() {
    return this.$store.state.account.user;
  }

  mounted() {
    this.businessFetch();
  }

  @Watch("$route.params.id")
  whenRouteChanges() {
    this.businessFetch();
  }

  /**
   * @param command
   */
  handleCommand(command: "edit" | "editFuture" | "cofounders" | "archive") {
    if (command === "edit") this.isEditing = true;
    if (command === "editFuture") {
      this.$store.commit("modals/future", true);
    }
    if (command === "cofounders") {
      this.$store.commit("modals/coFounders", true);
    } else this.archiveBusiness();
  }

  archiveBusiness() {
    console;
  }

  /**
   * @update business data
   */
  @Watch("$store.state.account.businesses")
  updatedBusiness() {
    this.businessFetch();
  }
  /**
   * checks if @current browsed user exists
   */
  businessFetch() {
    this.loading = true;
    this.$api.main
      .get<Business>(`/v1/businesses/${this.$route.params.id}`)
      .then((resp) => {
        (this.$data as any).business = resp.data;
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 300);
      });
  }
}
