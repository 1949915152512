
import { Business, Opportunity } from "@/shared";
import { Calendar } from "@element-plus/icons";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: { Calendar },

})
export default class extends Vue {
  @Prop({ default: {}, type: Object })
  opportunity!: Opportunity;

  @Prop({ default: {}, type: Object })
  business!: Business;
}
