
import { Options, Vue } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import img from "@/assets/illustrations/talent/no-talents.svg";

@Options({
  components: {},
})
export default class extends Vue {
  img = img;

  @Prop({ type: String, default: "No Talents Were Found" })
  description!: string;

  @Prop({ type: Boolean })
  loading!: boolean;

  @Prop({ type: Object })
  resources!: any[];

  get isFounder() {
    return this.$store.state.account.user?.type === "founder";
  }

  @Emit("clickedEmpty")
  clickedEmpty() {
    this.$store.commit("modals/opportunity", true);
  }
}
