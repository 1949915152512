<template>
  <div class="App-Oppp-Startup">
    <!--  -->
    <app-section-divider v-if="showHeader" content="Startup">
      <el-icon>
        <OfficeBuilding />
      </el-icon>
    </app-section-divider>
    <!--  -->
    <!-- If web -->
    <div>
      <div class="App-Opp-Star-C" v-if="business.website">
        <div class="App-Opp-Star-C-S" mute-secondary>Website Url</div>
        <div class="App-Opp-Star-C-B">
          <el-link target="__blank" :href="business.website" type="primary">{{
            business.website
          }}</el-link>
        </div>
      </div>
    </div>
    <!--  -->
    <el-row>
      <el-col :span="12">
        <div class="App-Opp-Star-C">
          <div class="App-Opp-Star-C-S" mute-secondary>Founders</div>
          <div class="App-Opp-Star-C-B">{{ business.coFoundersSize + 1 }}</div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="App-Opp-Star-C">
          <div class="App-Opp-Star-C-S" mute-secondary>Team Size</div>
          <div class="App-Opp-Star-C-B">{{ business.teamSize }}</div>
        </div>
      </el-col>
    </el-row>
    <!--  -->
    <el-row>
      <el-col :span="12">
        <div class="App-Opp-Star-C">
          <div class="App-Opp-Star-C-S" mute-secondary>Founding Year</div>
          <div class="App-Opp-Star-C-B">{{ business.foundingYear }}</div>
        </div>
      </el-col>
      <el-col v-if="business.marketSize !== undefined" :span="12">
        <div class="App-Opp-Star-C">
          <div class="App-Opp-Star-C-S" mute-secondary>Market Size</div>
          <div v-if="business.marketSize" class="App-Opp-Star-C-B">
            <span v-if="typeof business.marketSize === 'number'">
              {{ $filters.number(business.marketSize) }}</span
            >
            <span v-else> {{ business.marketSize }}</span>
          </div>
        </div>
      </el-col>
    </el-row>
    <!--  -->
    <el-row>
      <el-col v-if="business.funding !== undefined" :span="12">
        <div class="App-Opp-Star-C">
          <div class="App-Opp-Star-C-S" mute-secondary>Fund Raised</div>
          <div class="App-Opp-Star-C-B">
            <span
              v-if="
                typeof business.funding === 'object' &&
                business.funding !== null
              "
            >
              {{ (business.funding.currency + "").toLocaleUpperCase() }}
              {{ $filters.number(business.funding.amount) }}
            </span>
            <span v-else>{{ business.funding }}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="App-Opp-Star-C">
          <div class="App-Opp-Star-C-S" mute-secondary>Industry</div>
          <div class="App-Opp-Star-C-B">{{ business.industry }}</div>
        </div>
      </el-col>
    </el-row>
    <!--  -->
    <div v-if="business.nextStage">
      <el-divider />
      <el-row>
        <el-col :span="24">
          <div class="App-Opp-Star-C">
            <div class="App-Opp-Star-C-S" mute-secondary>Next Stage</div>
            <div class="App-Opp-Star-C-B">
              {{ business.nextStage }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!--  -->
    <div v-if="business.skillsNeeded">
      <el-divider />
      <el-row>
        <el-col :span="24">
          <div class="App-Opp-Star-C">
            <div class="App-Opp-Star-C-S" mute-secondary>Needed Skills</div>
            <div class="App-Opp-Star-C-B">
              <el-space wrap m-tb-5px :size="12">
                <el-tag
                  round
                  v-for="(skill, index) of business.skillsNeeded"
                  :key="`skill-${skill.name}${index}`"
                  type="info"
                >
                  {{ $filters.capitalize(skill.name.join(", ")) }}
                </el-tag>
              </el-space>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--  -->
  </div>
</template>

<script lang="ts">
import { Business } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { OfficeBuilding } from "@element-plus/icons";
@Options({
  components: { OfficeBuilding },
  methods: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  business!: Business;

  @Prop({ type: Boolean, default: true })
  showHeader!: boolean;
}
</script>

<style lang="scss">
.App-Oppp-Startup {
  margin-bottom: 3rem;
  .App-Opp-Star-C {
    padding: 5px 4px;
  }
  .App-Opp-Star-C-S {
    margin-bottom: -1px;
    font-size: 90%;
  }
  .App-Opp-Star-C-B {
    font-weight: 700;
    font-size: 110%;
  }
}
</style>
