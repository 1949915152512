<template>
  <el-dialog
    :model-value="isOpen"
    title="Paying For Talent"
    width="500px"
    :before-close="handleClose"
    custom-class="App-Bidd-Pay-Modal App-Unpadded"
    center
    :destroy-on-close="true"
  >
    <div v-loading="loading">
      <!--  -->
      <div v-if="cardElement !== undefined">
        <appBiddingProfileModal :opportunity="opportunity" :talent="talent" />
        <div class="App-Bidd-Pay-Modal-H">
          <!--  -->
          <div v-if="talentPrice">
            <el-divider />

            <el-descriptions
              class="App-Pricing-Break"
              title="Pricing Breakdown"
              :column="1"
              size="small"
              direction="horizontal"
              border
            >
              <el-descriptions-item label="Amount Before Tax:">
                <b>$ {{ $filters.number(talentPrice.amountBeforeTax) }}</b>
              </el-descriptions-item>
              <el-descriptions-item label="Tax:">
                <b>{{ talentPrice.tax }}%</b>
              </el-descriptions-item>
              <el-divider></el-divider>
              <el-descriptions-item label="Total Amount:">
                <b primary>$ {{ $filters.number(talentPrice.amount) }}</b>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <!--  -->
          <el-divider />
          <!--  -->
          <p txt-center>
            Secure Payments <br />
            Powered by
            <el-link href="https://stripe.com/" target="__blank" type="primary">
              Stripe Inc
            </el-link>
          </p>
          <StripeElement
            v-if="!result.paymentIntent"
            class="App-Stripe-Ele"
            :element="cardElement"
            @change="changedStripeCard"
          />
          <p
            txt-center
            class="App-Bidd-Pay-Modal-Err"
            v-if="event && event.error"
          >
            {{ event.error.message }}
          </p>
          <span v-if="result">
            <p txt-center class="App-Bidd-Pay-Modal-Err" v-if="result.error">
              {{ result.error.message }}
            </p>
            <p
              txt-center
              class="App-Bidd-Pay-Modal-Succ"
              v-else-if="result.paymentIntent !== undefined"
            >
              <el-icon style="font-size: 40px">
                <circle-check-filled />
              </el-icon>
              <br />
              <span> Payment Successful </span>
            </p>
          </span>
          <el-divider />
        </div>
      </div>
      <!--  -->
    </div>
    <template #footer>
      <div txt-center class="dialog-footer">
        <el-button
          v-if="!hasPaid"
          @click="submitPayment"
          :disabled="loading"
          type="primary"
          size="large"
          is-block
          v-loading="!talentPrice || loading"
        >
          Pay
          <span v-if="talentPrice" style="padding-left: 14px">
            {{ talentPrice.symbol }} {{ $filters.number(talentPrice.amount) }}
          </span>
          <!-- <span> to reveal full profile. </span> -->
        </el-button>
        <el-button
          is-all-borderless
          plain
          v-else
          @click="visitTalent"
          :disabled="loading"
          type="primary"
          size="large"
          is-block
          v-loading="!talentPrice || loading"
        >
          Visit Talent's Profile
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import appBiddingProfileModal from "@/shared/components/business-talent/bidding-module/bidding-profile-modal.vue";
import { Bidding, Opportunity, Transaction, User } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { StripeElement, StripeOptions, useStripe } from "vue-use-stripe";
import { PayForTalent } from "./bidding-component.vue";
import { CircleCheckFilled } from "@element-plus/icons";
import { ErrorUtil } from "@/shared/classes";
import { $api } from "@/shared/plugins";
import BusinessRouter from "../../../../@business/router";
@Options({
  name: "BiddPayModal",
  emits: ["paymentSuccess", "close", "open"],
  components: { StripeElement, appBiddingProfileModal, CircleCheckFilled },
})
export default class extends Vue {
  @Prop({ type: Object }) talent!: User;

  @Prop({ type: Object }) opportunity!: Opportunity;

  @Prop({ type: Object }) bidding!: Bidding;

  @Prop({ type: Boolean }) isOpen!: boolean;

  @Prop({ type: Object }) talentPrice!: PayForTalent;

  loading = true;

  result: Record<string, any> = {};

  stripe: Record<string, any> = {};
  cardElement: Record<string, any> = {};
  event: Record<string, any> = {};

  stripeOptions: StripeOptions = {
    key: process.env.VUE_APP_STRIPE_PUB_KEY,
    elements: [
      {
        type: "card",
        options: {
          style: {
            base: {
              backgroundColor: "#ddeee8",
              lineHeight: "40px",
              iconColor: "#f15b22",
              color: "#000000",
              fontWeight: "500",
              fontFamily: "Poppins, sans-serif",
              fontSize: "16px",
              fontSmoothing: "antialiased",
              ":-webkit-autofill": {
                color: "#fce883",
              },
              "::placeholder": {
                color: "#c0c4cc",
              },
            },
            invalid: {
              iconColor: "#f56c6c",
              color: "#f56c6c",
            },
          },
        },
      },
    ],
  };

  beforeCreate() {
    const s = useStripe(this.stripeOptions);
    console.log("s === ", s);
    this.stripe = s.stripe;
    this.cardElement = s.elements[0];
    console.log("stripe ", this.stripe);
    console.log("cardElement ", this.cardElement);
  }

  @Watch("cardElement")
  cardLoaded() {
    if (this.cardElement)
      this.cardElement.on("ready", (event) => {
        this.loading = false;
      });
  }

  /**
   * @payment handler
   */
  submitPayment = () => {
    console.log("Should pay ", this.event);
    if (this.event.complete) {
      this.loading = true;
      this.fetchPaymentTransaction()
        .then((resp) => {
          console.log("Found the transaction");
          return this.stripe.confirmCardPayment(resp.clientSecret, {
            payment_method: { card: this.cardElement },
          });
        })
        .then((result) => {
          if (result.error) {
            this.paymentFailed(result);
          } else {
            this.paymentSuccess(result);
          }
        })
        .catch((err) => {
          this.$message({
            duration: ErrorUtil.duration,
            type: "error",
            message: "Had trouble process the payment.",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  };
  /**
   * @Improve this and fix the $api error thing
   * @fetches or initiates payment intent associated with this
   */
  fetchPaymentTransaction() {
    console.log("Started the transaction fetch , ", this);
    this.loading = true;
    console.log("API ", $api);
    return $api.main
      .post<Transaction>("/v1/transactions", {
        bidding: (this.$props as any).bidding._id,
      })
      .then((resp) => resp.data)
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
        throw err;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  /**
   * @param result
   */
  paymentFailed(result: Record<string, any>) {
    this.result = result;
  }

  hasPaid = false;
  /**
   * @param result
   */
  paymentSuccess(result: Record<string, any>) {
    this.result = result;
    this.hasPaid = true;
    this.$emit("paymentSuccess");
  }

  visitTalent() {
    this.$router.push(`/talent/${this.talent._id}/${this.opportunity._id}`);
  }
  /**
   * @having an issue on pay
   */
  navigateToTalent() {
    setTimeout(() => {
      this.handleClose();
    }, 4000);
  }
  /**
   * @param eve
   */
  changedStripeCard(eve: Record<string, any>) {
    this.event = eve;
    console.log("changed eve", eve);
  }

  /**
   * For closing the modal
   */
  handleClose() {
    this.$emit("close");
  }
}
</script>

<style lang="scss">
.App-Bidd-Pay-Modal-H {
  max-width: 400px;
  margin: auto;

  .App-Stripe-Ele {
    background: $--input-background-color !important;
    border-radius: 6px;
    padding: 16px 10px;
  }

  .App-Bidd-Pay-Modal-Err,
  .App-Bidd-Pay-Modal-Succ {
    text-align: center;
    margin: 1.5rem 0 5px 0;
    padding: 5px;
  }
  .App-Bidd-Pay-Modal-Err {
    color: var(--el-color-danger);
  }
  .App-Bidd-Pay-Modal-Succ {
    color: var(--el-color-success);
  }
  .el-dialog__body {
    padding-bottom: 5px;
  }

  //
  .App-Pricing-Break .is-bordered .el-descriptions__cell {
    padding: 4px 8px;
    border: none;
    border-radius: 6px;
  }
  .App-Pricing-Break
    .el-descriptions__body
    .el-descriptions__table
    .el-descriptions__cell {
    background: transparent;
  }
}
</style>
