import { $api, ErrorUtil, UserType } from "@/shared";
import { ElMessage } from "element-plus";
import jsCookie from "js-cookie";

export class AuthUtil {
  /**
   * Redirects a user to a respective
   * portal based on their role
   * @param user
   */
  static redirectUser(user: Record<string, any> & { type: UserType }) {
    switch (user.type) {
      case "founder":
        window.location.assign(process.env.VUE_APP_BASE_URL_BUSINESS);
        break;
      case "talent":
        window.location.assign(process.env.VUE_APP_BASE_URL_TALENT);
        break;
      case "admin":
        window.location.assign(process.env.VUE_APP_BASE_URL_ADMIN);
        break;
    }
  }

  /**
   * Call the logout endpoint
   * @on success clear the cookie and redirect to auth
   */
  static logout() {
    return $api.main
      .delete("/v1/auth/logout")
      .then(() => {
        this.finalizeLogout();
      })
      .catch((err) => {
        this.finalizeLogout();
        // ElMessage.error(ErrorUtil.stringify(err));
      });
  }

  private static finalizeLogout() {
    AuthToken.deleteToken();
    window.location.replace(process.env.VUE_APP_BASE_URL_AUTH); // Should redirect
  }
}

/**
 * Class in charge of authentication Tokens
 */
export class AuthToken {
  private static tokenOptions = {
    domain: process.env.VUE_APP_DOMAIN,
    expires: 30,
    path: "/",
    secure: process.env.NODE_ENV === "production",
  };
  /**
   * returns the token
   * @returns
   */
  static getToken() {
    console.log(jsCookie);
    return jsCookie.get(`UUID_${process.env.NODE_ENV}`) as string;
  }

  /**
   *
   * @returns decoded token data
   */
  static decodeToken() {
    try {
      return JSON.parse(atob(AuthToken.getToken().split(".")[1]));
    } catch {
      return undefined;
    }
  }
  /**
   * @removes the token
   */
  static deleteToken() {
    jsCookie.remove(`UUID_${process.env.NODE_ENV}`, AuthToken.tokenOptions);
  }
  /**
   * returns the token
   * @returns
   */
  static setToken(token: string) {
    console.log("Token ", process.env.VUE_APP_DOMAIN);
    jsCookie.set(`UUID_${process.env.NODE_ENV}`, token, AuthToken.tokenOptions);
  }
}
