
import { Options, Vue } from "vue-class-component";
import interestedTalents from "./components/interests-list.component.vue";
import { Checked, Star } from "@element-plus/icons";
import { Interest } from "@/shared";
import { ApiResourceResult } from "@/shared/vuex";
@Options({
  components: { interestedTalents, Checked, Star },
})
export default class extends Vue {
  /**
   * @interests
   */
  interests: Interest[] | any = [];
  loading = true;
  count!: number | undefined;

  /**
   * @fetches the interests
   */
  fetchInterestedTalents() {
    this.$api.main
      .get<ApiResourceResult<Interest>>("/v1/interest")
      .then((resp) => {
        this.count = resp.data.count;
        this.interests.concat(resp.data.resources);
      });
  }
}
