<template>
  <div
    class="App-Ent-Standing"
    :class="`${$filters.capitalize(size)} ${standingSelf ? 'Loaded' : ''}`"
  >
    <el-skeleton
      :style="{ width: width, minHeight: height, margin: 'auto' }"
      :loading="!standingSelf"
      animated
    >
      <!--  -->
      <template #template>
        <el-space class="App-Ent-Standing-Cols">
          <el-skeleton-item
            v-for="col of columns"
            :key="`col-stand-load-${col}`"
            variant="h2"
            :style="{
              width: `26px`,
              height: `${calculateHeight(loadingStanding[col], colHeight)}px`,
            }"
          />
        </el-space>
      </template>
      <!--  -->
      <template #default>
        <div txt-center>
          <h4 txt-center class="App-Ent-Standing-T">
            Entrepreneurial Dimensions
          </h4>
          <div txt-center>
            <el-link target="_blank" :href="glossary">
              <small> How can this help me?</small>
            </el-link>
          </div>
        </div>
        <!-- Results maybe one or two and sized differently -->
        <!--  -->
        <div class="App-Ent-Standing-Cols">
          <!--  -->
          <el-space :size="space" class="App-Ent-Standing-Cols">
            <div
              v-for="col of columns"
              :key="`col-standing-${col}`"
              class="App-Ent-Standing-Col"
              :style="{
                height: `${calculateHeight(maxCol, colHeight) + 10}px`,
              }"
            >
              <!-- Base Column -->
              <div
                class="App-Ent-Stand-Color App-Ent-Standing-Base"
                :style="{
                  height: `${calculateHeight(maxCol, colHeight)}px`,
                }"
              >
                {{ maxCol }}
              </div>
              <!-- Self Column -->
              <el-tooltip
                v-if="standingOther !== undefined"
                :content="`${$filters.capitalize(fullColName(col))} ${
                  standingOther[col]
                }`"
              >
                <div
                  class="App-Ent-Stand-Color App-Ent-Standing-Other"
                  :class="
                    standingOther[col] < standingSelf[col]
                      ? 'App-Ent-Stan-Raised'
                      : ''
                  "
                  :style="{
                    height: `${calculateHeight(
                      standingOther[col],
                      colHeight
                    )}px`,
                  }"
                >
                  {{ standingOther[col] }}
                </div>
              </el-tooltip>
              <!--  -->
              <!-- Other Column -->
              <el-tooltip
                v-if="standingSelf"
                :content="`${$filters.capitalize(fullColName(col))} ${
                  standingSelf[col]
                }`"
              >
                <div
                  class="App-Ent-Stand-Color App-Ent-Standing-Self"
                  :style="{
                    height: `${calculateHeight(
                      standingSelf[col],
                      colHeight
                    )}px`,
                  }"
                >
                  {{ standingSelf[col] }}
                </div>
              </el-tooltip>

              <!--  -->
            </div>
          </el-space>
          <!--  -->
        </div>
        <!-- Labels -->
        <div>
          <div class="Ent-Stand-Lbls">
            <div class="Ent-Stand-Lbl">
              <div class="Ent-Stand-Lbl-Self" v-if="labelSelf">
                <span class="Ent-Lbl-Dot"></span>
                <span> {{ labelSelf }} </span>
              </div>
            </div>
            <div class="Ent-Stand-Lbl">
              <div class="Ent-Stand-Lbl-Other" v-if="labelOther">
                <span class="Ent-Lbl-Dot"></span>
                <span> {{ labelOther }} </span>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </template>
    </el-skeleton>
  </div>
</template>

<script lang="ts">
import { QuestionCategoryFull, SurveyScore } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "AppEntStanding",
  components: {},
})
export default class extends Vue {
  @Prop({ type: String, default: "medium" })
  size!: "mini" | "small" | "medium";

  heights = {
    mini: 100,
    small: 180,
    medium: 210,
  };
  get height() {
    return `${this.heights[this.size]}px`;
  }

  get colHeight() {
    return this.heights[this.size];
  }

  widths = {
    mini: 220,
    small: 320,
    medium: 340,
  };
  get width() {
    return `${this.widths[this.size]}px`;
  }

  spaces = {
    mini: 9,
    small: 10,
    medium: 12,
  };
  get space() {
    return this.spaces[this.size];
  }
  /**
   * Labels
   */
  @Prop({ type: String, require: true })
  labelSelf!: string;

  @Prop({ type: String })
  labelOther!: string;
  /**
   * Default variables
   */
  max = 8;
  get loadingStanding() {
    return {
      thinking: 3,
      perseverance: 5,
      confidence: 4,
      decision: 6,
      motivation: 4,
      planning: 8,
      vision: 3,
      leadership: 6,
    };
  }
  /**
   * @param score
   * Out of a hundred calculates the height of the column element
   */
  calculateHeight(score: number, height = 100) {
    return (score / this.max) * height; // In terms of 100% by default
  }
  /**
   * Standing
   */
  @Prop({ type: Object, require: true })
  standingSelf!: SurveyScore;

  @Prop({ type: Object })
  standingOther!: SurveyScore;
  /**
   * glossary link
   */
  get glossary(): string {
    return `${process.env.VUE_APP_BASE_URL_ABOUT}/glossary`;
  }

  /**
   * Now the height is nolonger dynamic
   * @improve the code to reflect it
   */
  get maxCol(): number {
    return 8;
  }

  /**
   * @returns Columns Names
   */
  get columns() {
    return Object.keys(this.loadingStanding);
  }

  /**
   * @param col
   */
  fullColName(col: string) {
    return QuestionCategoryFull[col] || col;
  }
}
</script>

<style lang="scss">
.App-Ent-Standing {
  margin: auto;
  border-radius: 6px;
  &.Loaded {
    background: #eff6f4;
    min-height: 150px;
    margin: auto;
  }
  .Unloaded {
    min-height: 200px;
  }

  &.Medium {
    padding: map.get($--button-padding-vertical, "medium")
      map.get($--button-padding-vertical, "medium")
      map.get($--button-padding-vertical, "medium")
      map.get($--button-padding-vertical, "medium");
    max-width: 500px;
  }
  &.Small,
  &.Mini {
    padding: map.get($--button-padding-vertical, "small")
      map.get($--button-padding-vertical, "small")
      map.get($--button-padding-vertical, "small")
      map.get($--button-padding-vertical, "small");
    max-width: 350px;
  }
  //
  small {
    line-height: 14px;
  }
  //
  // Title
  .App-Ent-Standing-T {
    margin: 0 0;
  }
  //
  // Columns holder
  .App-Ent-Standing-Cols {
    // max-width: 300px;
    margin: map.get($--button-padding-vertical, "medium") auto 2px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: flex-end !important;
  }
  //
  // column
  .App-Ent-Standing-Col {
    position: relative;
    .App-Ent-Stand-Color {
      border-radius: 20px;
      color: rgba(255, 255, 255, 0.857);
      display: flex;
      align-items: flex-start;
      text-align: center;
      justify-content: center;
      padding: 2px 1px 3px 1px;
      //
      position: absolute;
      bottom: 0;
      left: 0;
      transition: 300ms ease-in;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  //
  .App-Ent-Stan-Raised {
    z-index: 1;
  }

  //
  .App-Ent-Standing-Self {
    background-color: $--color-primary;
  }
  .App-Ent-Standing-Other {
    background-color: #0d4392;
  }
  .App-Ent-Standing-Base {
    background-color: #dbe4e2;
    z-index: 0;
    color: rgba(145, 142, 141, 0.515) !important;
  }
}
//
// Specific medium
.App-Ent-Standing {
  &.Medium {
    // column
    .App-Ent-Standing-Col {
      max-width: 35px;
      width: 35px;
      > * {
        width: 34px;
      }
    }
  }
  &.Small {
    // column
    .App-Ent-Standing-Col {
      max-width: 36px;
      width: 36px;
      > * {
        width: 25px;
        font-size: 11px;
        font-weight: bold;
      }
    }
  }
  &.Mini {
    // column
    .App-Ent-Standing-Col {
      max-width: 28px;
      width: 28px;
      > * {
        width: 20px;
        font-size: 10px;
        font-weight: 500;
      }
    }
  }

  //
  //
  // Labels
  &.Medium {
    .Ent-Stand-Lbls {
      max-width: 400px;
    }
  }
  &.Small,
  &.Mini {
    .Ent-Stand-Lbls {
      max-width: 300px;
    }
  }
  // Labels
  .Ent-Stand-Lbls {
    padding: 10px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    > * {
      text-align: center;
    }
  }
  .Ent-Stand-Lbl {
    margin-right: 5px;
    margin-left: 5px;
    .Ent-Lbl-Dot {
      display: inline-block;
      margin-right: 4px;
      height: 10px;
      width: 10px;
      border-radius: 20px;
    }
    .Ent-Stand-Lbl-Self {
      .Ent-Lbl-Dot {
        background-color: $--color-primary;
      }
    }
    .Ent-Stand-Lbl-Other {
      .Ent-Lbl-Dot {
        background-color: #0d4392;
      }
    }
    .Ent-Stand-Lbl-Self,
    .Ent-Stand-Lbl-Other {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 90%;
    }
  }
}
//
</style>
