
import { SurveyScore, User } from "@/shared/interfaces";
import { AccountUtil } from "@/shared/vuex";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import AppSurveyRating from "./survey-rating.component.vue";
import AppSurveyResults from "./survey-results.component.vue";
import AppSurveyStart from "./survey-start.component.vue";
@Options({
  name: "SurveyModal",
  emits: ["checked", "opened", "closed"],
  components: {
    AppSurveyStart,
    AppSurveyRating,
    AppSurveyResults,
  },
})
export default class extends Vue {
  isSurveyModalOpen = false;

  /**
   *
   */
  @Emit("checked")
  mounted() {
    let user: User = this.$store.state.account.user!;
    if (user.type === "founder")
      this.isSurveyModalOpen =
        this.business?.standing === undefined ||
        this.business?.canRetakeSurvey === true ||
        this.$store.state.modals.isSurveyOpen;
    else if (user.type === "talent") {
      this.isSurveyModalOpen =
        user.standing === undefined ||
        user.canRetakeSurvey === true ||
        this.$store.state.modals.isSurveyOpen;
    }
  }

  @Prop({ type: Boolean }) isOpen!: boolean;

  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
  /**
   * Determines the step of the ent survey
   */
  step: "start" | "rating" | "results" = "start"; //"start";
  loading = false;
  /**
   * Stores results
   */
  score!: SurveyScore;
  /**
   *
   */
  get isStart() {
    return this.step === "start";
  }
  get isRating() {
    return this.step === "rating";
  }
  get isResults() {
    return this.step === "results";
  }

  /**
   * When clicked now brings the rating
   * Part step by step
   * @starts the survey rating
   */
  startSurveyRating() {
    this.step = "rating";
  }
  /**
   * Receives the survey score results for the just submited survey
   * @param score
   */
  showSurveyResults(score: SurveyScore) {
    this.score = score;
    this.step = "results";
    this.loading = true;
    AccountUtil.fetchCurrent(this.$store).finally(() => (this.loading = false));
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    console.log("Clicked close the modal");
    this.$store.commit("modals/survey", false);
    this.isSurveyModalOpen = false;
  }

  @Emit("opened")
  opened() {
    console;
  }
}
