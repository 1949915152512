import {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";
import { Store } from "vuex";
import { User } from "..";
import { AccountUtil } from "../vuex";
import { InNavigateRespectively } from "./guards.util";

export const InGuardGenerate = (store: Store<any>) => {
  /**
   *
   * @param to
   * @param from
   * @param next
   */
  return function (to, from, next) {
    AccountUtil.fetchCurrent(store)
      .then((user) => {
        return InNavigateRespectively(user, to, from, next);
      })
      .catch((err) => {
        // If internet leave it
        // If not direct to auth
        console.log("Redirecting to Auth ", err);
        document.location.replace(`${process.env.VUE_APP_BASE_URL_AUTH}/login`);
      });
  } as NavigationGuard;
};
