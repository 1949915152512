
import { FounderAccountUtil } from "@/@business/store";
import img from "@/assets/illustrations/startup/future.svg";
import { ErrorUtil } from "@/shared";
import { Business, User } from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { ElScrollbar } from "element-plus";
import { Filters } from "@/shared/filters";

@Options({
  emits: ["checked", "opened", "closed"],
  components: {},
})
export default class extends Vue {
  img = img;
  isModalOpen = false;
  showClose = false;
  loading = false;

  get scrollbarRef(): typeof ElScrollbar {
    return this.$refs.scrollbarRef as any;
  }
  get innerRef(): HTMLDivElement {
    return this.$refs.innerRef as any;
  }

  @Prop({ type: Object })
  business!: Business;

  get startupName() {
    return Filters.capitalize(this.business.name);
  }

  @Emit("checked")
  mounted() {
    this.isModalOpen = this.shouldOpenModal;
    console.log(`Business Next Modal ${this.isModalOpen}`);
  }

  /**
   * Determines if this modal shoould open
   */
  get shouldOpenModal() {
    return (
      this.business.nextStage === undefined ||
      this.business.nextStage.length == 0 ||
      this.business.skillsNeeded?.length === 0
    );
  }
  /**
   * Form to later on submit
   */
  form = {
    nextStage: undefined,
    skillsNeeded: [{}],
  };

  /**
   * @param score
   */
  submit() {
    this.loading = true;
    this.$api.main
      .patch<Business>(`/v1/businesses/${this.business._id}`, this.form)
      .then((resp) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Saved the future!",
        });
        return this.finalizeUpdates(resp.data);
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * @triggers updates after creating a new business profile
   */
  finalizeUpdates(business: Business) {
    this.loading = true;
    this.closeModal();
    FounderAccountUtil.finalizeFounderFetch(
      this.$store,
      this.$store.state.account.user as User
    );
  }

  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    this.$store.commit("modals/future", false); // This is enough itself
    this.isModalOpen = false;
  }

  @Watch("$store.state.modals.isFutureOpen")
  watchForOpen() {
    this.isModalOpen = this.$store.state.modals.isFutureOpen;
  }

  @Emit("opened")
  opened() {
    this.assignForm();
  }

  assignForm() {
    this.form.nextStage = this.business.nextStage as any;
    this.form.skillsNeeded = this.business.skillsNeeded as any;
    if ((this.form.skillsNeeded?.length ?? 0) === 0) {
      this.form.skillsNeeded = [{ name: [] }] as any;
    }
  }

  addSkill() {
    this.form.skillsNeeded.push({ name: [] });
    setTimeout(() => {
      this.scrollbarRef?.setScrollTop(this.innerRef?.clientHeight + 100);
    }, 600);
  }
  removeSkill(index) {
    if (this.form.skillsNeeded.length > 0)
      this.form.skillsNeeded.splice(index, 1);
  }

  get disabledSave() {
    return this.form.nextStage == undefined;
  }
}
