<template>
  <div class="App-Page-Header App-Opportunity-T">
    <div class="App-Opp-Top">
      <el-space w-100 alignment="center" :size="12">
        <div>
          <app-over-avatars :size="56">
            <template #one>
              <el-avatar
                :src="opportunity.createdBy.avatar"
                icon="el-icon-user-solid"
                :size="51"
              />
            </template>
            <template #two>
              <el-avatar
                :src="business.avatar"
                icon="el-icon-office-building"
                :size="51"
              />
            </template>
          </app-over-avatars>
        </div>
        <div
          style="
            line-height: 20px;
            margin-bottom: 4px;
            display: flex;
            width: 100;
            align-items: center;
            justify-content: space-between;
          "
          w-100
          :size="12"
        >
          <div>
            <h3 m-tb-0>{{ business.name }}</h3>
            <span>
              {{ business.industry }} Industry, 
              {{ business.stage }} Stage
            </span>
          </div>
          <span mute-secondary>
            {{ $filters.capitalize($moment(opportunity.createdAt).fromNow()) }}
          </span>
        </div>
        <!--  -->
      </el-space>
      <!--  -->
      <!-- Ent standing -->
      <div>
        <div m-t-1 v-if="viewedBy === 'founder'">
          <app-ent-standing
            :labelSelf="`${$filters.capitalize(
              $filters.slice(business.name, 30)
            )}`"
            :standingSelf="business.standing"
            size="medium"
          />
        </div>
        <div v-else>
          <app-ent-standing
            :labelSelf="`${$filters.capitalize(user.firstName)}`"
            :labelOther="`${$filters.capitalize(business.name)}`"
            :standingSelf="user.standing"
            :standingOther="business.standing"
            size="medium"
          />
        </div>
      </div>
      <!--  -->
      <!--  -->
    </div>
  </div>
</template>

<script lang="ts">
import { Business, Opportunity } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class extends Vue {
  @Prop({ default: {}, type: Object })
  opportunity!: Opportunity;

  @Prop({ default: {}, type: Object })
  business!: Business;

  get user() {
    return this.$store.state.account.user;
  }
  /**
   * @returns the user type viewing this opportunity
   */
  get viewedBy() {
    return this.$store.state.account.user?.type;
  }
}
</script>

<style lang="scss">
.App-Page-Header {
  .el-space {
    .el-space__item {
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
}
</style>
