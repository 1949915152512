
import { Options, Vue } from "vue-class-component";

@Options({})
export default class extends Vue {
  /**
   * @business
   */
  get business() {
    return this.$store.state.account.businesses?.filter(
      (b) => b._id === this.$store.state.account.business
    )[0];
  }
}
