<template>
  <div>
    <el-dialog
      custom-class="App-Create-Business-Modal App-Unpadded"
      :model-value="isModalOpen"
      width="550px"
      :show-close="showClose"
      :close-on-click-modal="showClose"
      :close-on-press-escape="showClose"
      @open="opened"
      title="Create Startup Profile"
      center
      :append-to-body="true"
      :lock-scroll="true"
      :open-delay="200"
      :before-close="closeModal"
      :close-delay="200"
    >
      <!--  -->
      <el-scrollbar :always="true" v-loading="loading" max-height="70vh">
        <div>
          <el-form
            @submit.prevent
            class="App-Styled-Form"
            :label-position="'top'"
            label-width="100px"
            :model="form"
            style="margin-top: 12px"
            :rules="rules"
          >
            <!--  -->
            <!-- name -->
            <el-form-item prop="name" label="Name">
              <el-input placeholder="E4T" v-model="form.name" />
            </el-form-item>
            <!--  -->
            <!-- Industry -->
            <el-form-item prop="industry" label="Industry">
              <el-select
                v-model="form.industry"
                placeholder="Industry: ex: Edtech"
                w-100
              >
                <el-option
                  v-for="ind in industries"
                  :value="ind"
                  :label="ind"
                  :key="`${ind}-ind`"
                >
                  <el-space>
                    <i class="App-Loc-Icon el-icon-business" />
                    <span>{{ ind }}</span>
                  </el-space>
                </el-option>
              </el-select>
            </el-form-item>
            <!--  -->
            <!-- businessModel -->
            <el-form-item
              prop="businessModel"
              label="What is your business model?"
            >
              <el-select
                v-model="form.businessModel"
                placeholder="Business Model: ex: Edtech"
                w-100
              >
                <el-option
                  v-for="ind in businessModels"
                  :value="ind"
                  :label="ind"
                  :key="`${ind}-ind`"
                >
                  <el-space>
                    <i class="App-Loc-Icon el-icon-business" />
                    <span>{{ ind }}</span>
                  </el-space>
                </el-option>
              </el-select>
            </el-form-item>
            <!--  -->
            <el-divider />
            <!-- Location -->
            <app-location-search
              label="Where are you based?"
              :size="'default'"
              v-model="form.location"
            />
            <!--  -->
            <el-divider />
            <!--  -->
            <!-- stage -->
            <app-business-stage-input v-model="form.stage">
            </app-business-stage-input>
            <!--  -->
            <!--  -->
            <div m-tb-1>
              <div>
                <h4 m-tb-0>Do you have coFounders?</h4>
              </div>
              <el-switch
                v-model="tempform.hasCoFounders"
                active-text="Yes, I do"
                inactive-text="No, I don't"
              />
            </div>
            <!--  -->
            <el-space
              w-100
              style="justify-content: space-between"
              alignment="center"
              gutter="12"
              :wrap="false"
            >
              <el-form-item
                v-if="tempform.hasCoFounders"
                prop="coFoundersSize"
                label="How many co-Founders?"
              >
                <el-input-number
                  w-100
                  :controls="false"
                  placeholder="Nbr of co-founders"
                  v-model="form.coFoundersSize"
                />
              </el-form-item>
              <el-form-item
                prop="teamSize"
                label="Team Size (Founders & others)"
              >
                <el-input-number
                  w-100
                  :controls="false"
                  placeholder="How big is the team?"
                  v-model="form.teamSize"
                />
              </el-form-item>
            </el-space>
            <!--  -->
            <!--  -->
            <el-divider />
            <!-- website -->
            <el-form-item prop="website" label="Website if any">
              <el-input
                placeholder="https://equity4talent.com"
                v-model="form.website"
              />
            </el-form-item>
            <!--  -->
            <el-divider />
            <!--  -->
            <!-- foundingYear -->
            <el-form-item prop="foundingYear" label="Founding Year">
              <el-input-number
                w-100
                v-model="form.foundingYear"
                :controls="false"
                placeholder="Pick a year"
              />
            </el-form-item>
            <!--  -->
            <!-- marketSize -->
            <el-form-item prop="marketSize" label="Market Size">
              <el-input
                :controls="false"
                placeholder="200,000"
                v-model="form.marketSize"
              />
            </el-form-item>
            <!--  -->
            <!-- Raised Fund -->
            <el-form-item prop="funding" label="Funds Raised">
              <el-space :size="12">
                <el-select
                  v-model="form.funding.currency"
                  placeholder="Currency"
                  style="width: 150px"
                >
                  <el-option label="£ (Pound)" value="gbp"></el-option>
                  <el-option label="$ (US Dollars)" value="usd"></el-option>
                  <el-option label="€ (Euros)" value="euro"></el-option>
                </el-select>
                <el-input-number
                  w-100
                  :controls="false"
                  placeholder="200,000"
                  v-model="form.funding.amount"
                />
              </el-space>
            </el-form-item>
            <!--  -->
            <!--  -->
            <!-- Bio About -->
            <el-form-item prop="about" label="Startup description">
              <el-input
                rows="5"
                type="textarea"
                placeholder="Startup description"
                v-model="form.about"
              />
            </el-form-item>
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
          </el-form>
        </div>
      </el-scrollbar>
      <!--  -->
      <template #footer>
        <div txt-center>
          <el-button
            is-block
            icon="el-icon-check"
            @click="submit"
            size="large"
            type="primary"
            :disabled="disabledSave || loading"
          >
            Save Startup Profile
          </el-button>
        </div>
      </template>
      <!--  -->
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { FounderAccountUtil } from "@/@business/store";
import { ErrorUtil } from "@/shared";
import {
  Business,
  BusinessModels,
  BusinessStages,
  IndustriesList,
} from "@/shared/interfaces";
import { Options, Vue } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";
import { BusinessFormRules } from "./business-form";

@Options({
  components: {},
})
export default class extends Vue {
  /**
   *
   */
  isModalOpen = false;
  showClose = true;
  //
  industries = IndustriesList;
  businessModels = BusinessModels;

  /**
   * @call this to check if it should be open
   */
  @Emit("checked")
  mounted() {
    console.log("Mounted with ", this.$store.state.account);
    this.isModalOpen =
      this.$store.state.account.businesses === undefined ||
      this.$store.state.account.businesses.length === 0;
    if (this.isModalOpen) this.showClose = false;
  }

  /**
   * @Watch for open trigger
   * if triggered the user should be able to close
   */
  @Watch("$store.state.modals.isBusinessOpen")
  watchForOpen() {
    this.isModalOpen = this.$store.state.modals.isBusinessOpen;
    if (this.isModalOpen) this.showClose = true;
  }

  form = {
    name: undefined,
    industry: undefined,
    businessModel: undefined,
    location: undefined,
    stage: undefined,
    teamSize: undefined,
    coFoundersSize: 0,
    website: undefined,
    foundingYear: undefined,
    marketSize: undefined,
    funding: {} as any,
    about: undefined,
  };

  tempform = {
    hasCoFounders: false,
  };
  /**
   *
   */
  loading = false;
  /**
   * @param score
   */
  submit() {
    this.loading = true;
    this.$api.main
      .post<Business>("/v1/businesses", this.form)
      .then((resp) => {
        // set it a sa default business now
        this.$message({
          duration: ErrorUtil.duration,
          type: "success",
          message: "Created business profile successfully!",
        });
      })
      .then(() => {
        return this.makeUpdatesAfterCreate();
      })
      .catch((err) => {
        this.$message({
          duration: ErrorUtil.duration,
          type: "error",
          message: ErrorUtil.stringify(err),
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * @triggers updates after creating a new business profile
   */
  makeUpdatesAfterCreate() {
    this.loading = true;
    this.renewKey();
  }

  /**
   * @handles the renew of key right after creating a new business
   */
  renewKey() {
    FounderAccountUtil.renewKey(this.$store).finally(() => {
      this.loading = false;
      this.closeModal();
    });
  }
  /**
   * Close modal
   */
  @Emit("closed")
  closeModal() {
    this.$store.commit("modals/business", false);
    this.isModalOpen = false;
  }

  /**
   *
   */
  @Emit("opened")
  opened() {
    console;
  }

  /**
   *
   */
  rules = BusinessFormRules;

  get disabledSave() {
    return false;
  }
}
</script>

<style lang="scss">
.App-Create-Business-Modal {
  .App-Styled-Form {
    max-width: 400px;
    margin: auto;
  }
}
</style>
