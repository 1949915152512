
import { Business } from "@/shared";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  methods: {},
})
export default class extends Vue {
  @Prop({ type: Object })
  business!: Business;

  @Prop({ type: Boolean })
  loading!: boolean;
}
