
import { Business } from "@/shared";
import appSectionDivider from "@/shared/components/app-section-divider.vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { User } from "@element-plus/icons";
import StartupUsersComponent from "./startup-users-component.vue";
import { FounderAccountUtil } from "@/@business/store";

@Options({
  components: { appSectionDivider, User, StartupUsersComponent },
})
export default class extends Vue {
  @Prop({ type: Object })
  business!: Business;

  /**
   * @when clicked we go to the new
   */
  clickedComponent() {
    this.$router.push(`/startups/${this.business._id}`);
  }

  /**
   * Is this business founder's default?
   */
  get isDefault() {
    return (
      this.$store.state.account.user?.defaultBusiness === this.business._id
    );
  }
  /**
   * Sets this startup as default
   */
  setDefault() {
    this.$api.main
      .post(`/v1/auth/default-business/${this.business._id}`)
      .then((resp) => {
        FounderAccountUtil.renewKey(this.$store);
      });
  }
}
